import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPagesRoute from "./pages/landingpages/landingPagesRoute";
import AdvertiserPagesRoute from "./pages/advertiserdashboardpages/advertiserPagesRoute";
import AdminPagesRoute from "./pages/admindashboardpages/adminPagesRoute";
import PublisherPagesRoute from "./pages/publisherdashboardpages/publisherPagesRoute";
import UserAuthRoutes from "./pages/userAuthentication/userAuthRoute";
import useAuth from "./hooks/useAuth";
import { ProtectedRoute } from "./pages/ProtectedRoute/ProtectedRoutePage";
import AdminSignin from "./pages/admindashboardpages/AdminSignin/AdminSignin";

const App = () => {
  const authState = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<LandingPagesRoute />} />
        <Route path="landing/*" element={<LandingPagesRoute />} />
        <Route
          path="publisher/*"
          element={
            <ProtectedRoute>
              <PublisherPagesRoute />
            </ProtectedRoute>
          }
        />
        <Route
          path="advertiser/*"
          element={
            <ProtectedRoute>
              <AdvertiserPagesRoute />
            </ProtectedRoute>
          }
        />
        <Route
          path="adminpath/*"
          element={
            <ProtectedRoute
              VerificationUserType="admin"
              customUrl="/admin-auth/signin"
            >
              <AdminPagesRoute />
            </ProtectedRoute>
          }
        />
        <Route path="admin-auth/signin" element={<AdminSignin />} />
        <Route path="userauth/*" element={<UserAuthRoutes />} />
        <Route path="*" element={<LandingPagesRoute />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
