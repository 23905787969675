import { ExclamationCircleFilled } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import { Modal, notification } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { adminUpdatePostRequest } from "../../../apiservice/admin-Users-Clone-Service";
import {
  acceptGuestPostRequest,
  rejectGuestPostRequest,
} from "../../../apiservice/offers.service";
import { IGuestPostRequestData } from "../../../apiservice/offers.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { appZIndex } from "../../../utils/appconst";
import { getFormData } from "../../../utils/basic.utils";

import "./AdvertiserViewContentDetails.css";

type NotificationType = "success" | "info" | "warning" | "error";
type IAdvertiserContentRequestDetailsCardGPost = {
  guestPostRequest?: IGuestPostRequestData | null;
  refreshPage?: () => void;
};

export const AdvertiserContentRequestDetailsCardGPost: React.FC<
  IAdvertiserContentRequestDetailsCardGPost
> = ({ guestPostRequest, refreshPage }) => {
  const [payLoad, setpayLoad] = useState<any>({});
  const [loadApi, setLoadApi] = useState(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApprovedTask, setLoadApprovedTask] = useState(false);
  const [loadRejectTask, setLoadRejectTask] = useState(false);
  const [payLoadFormData, setpayLoadFormData] = useState<FormData>(
    getFormData({})
  );
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  //Accept Task
  const showApprovedTaskConfirm = () => {
    confirm({
      title: "Are you sure you want to accept this task",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadApprovedTask(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Accept Task
  const approveTaskApiResult = useFormatApiRequest(
    () => acceptGuestPostRequest(guestPostRequest?.id || 0),
    loadApprovedTask,
    () => {
      setLoadApprovedTask(false);
    },
    () => {
      processApprovedTask();
    }
  );

  // Process The Accept Task
  const processApprovedTask = async () => {
    if (approveTaskApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "This Task Has being Approved");
      if (refreshPage) {
        refreshPage();
      }
    } else if (approveTaskApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Approving this task");
    }
  };

  //Reject Task
  const showRejectTaskConfirm = () => {
    confirm({
      title: "Are you want to reject this work",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadRejectTask(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Reject Task
  const rejectTaskApiResult = useFormatApiRequest(
    () => rejectGuestPostRequest(guestPostRequest?.id || 0, payLoad),
    loadRejectTask,
    () => {
      setLoadRejectTask(false);
    },
    () => {
      processRejectTask();
    }
  );

  // Process The Reject Task
  const processRejectTask = async () => {
    if (rejectTaskApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "This Task Has being rejected");
      if (refreshPage) {
        refreshPage();
      }
    } else if (rejectTaskApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Rejecting this task");
    }
  };

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    // console.log(event);
    const name = event.target?.name || event.target?.id;
    const value =
      name === "attachmentFile"
        ? event.target.files[0]
        : event.target.value || event.target.getContent();
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };
  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col w3-padding w3-padding-bottom">
        {/* Task List  */}
        <div className="w3-col" style={{ padding: "10px" }}>
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <span className="taskDetailsTitle"> List Task </span>
            </div>
            <div
              className="w3-col l6 s6 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              <Link
                to="/advertiser/manage-content-progress"
                className="w3-btn taskBackBtn"
              >
                Back
              </Link>
            </div>
          </div>
        </div>

        {/* Task Details */}
        <div className="w3-col l12 s12 m12" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "10px", minHeight: "200px" }}
          >
            <div className="w3-col w3-margin-bottom">
              <p>Task Info </p>
            </div>
            {/* Topic of Artcle */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Topic of Article</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {guestPostRequest?.topic} </span>
              </div>
            </div>

            {/* Anchor Text */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Anchor Text</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {guestPostRequest?.anchorText} </span>
              </div>
            </div>

            {/* Main Link*/}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Main Link</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {guestPostRequest?.mainLink} </span>
              </div>
            </div>

            {/* Anchor Link*/}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Anchor Link</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {guestPostRequest?.anchorLink} </span>
              </div>
            </div>

            {/* Key words */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> KeyWords</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {guestPostRequest?.keywords} </span>
              </div>
            </div>

            {/* Other Requirement */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Other Requirement</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {guestPostRequest?.otherRequirements} </span>
              </div>
            </div>

            {/* Description */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Description </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span>{guestPostRequest?.description}</span>
              </div>
            </div>

            {/* Numbers of Words */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3">
                <span> Numbers of Words</span>
              </div>
              <div className="w3-col l9 s12 m9">
                <div className="w3-col l9 s12 m9 w3-margin-bottom">
                  <span>{guestPostRequest?.noOfWords}</span>
                </div>
              </div>
            </div>

            {/* Article */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l12 s12 m12">
                <span>
                  <b>Article</b>
                </span>
              </div>
              <div
                className="w3-col l12 s12 m12 w3-border w3-round"
                style={{ minHeight: "200px" }}
                dangerouslySetInnerHTML={{
                  __html: guestPostRequest?.articleText
                    ? guestPostRequest?.articleText.replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      )
                    : "",
                }}
              ></div>
            </div>

            {/* Attachment Preview */}
            {guestPostRequest?.articleUrl && (
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l12 s12 m12">
                  <span>
                    <b>Attachment </b>
                  </span>
                </div>
                <div className="w3-col l12 s12 m12">
                  <a
                    download={guestPostRequest?.articleUrl}
                    target="_blank"
                    rel="noreferrer"
                    href={guestPostRequest?.articleUrl}
                    className="w3-btn taskBtnPrimaryColor w3-round"
                  >
                    Download
                  </a>
                </div>
              </div>
            )}

            {/* Save Button  */}
            <div className="w3-col w3-margin-bottom">
              <button
                onClick={showApprovedTaskConfirm}
                className="w3-btn requestBtnPrimaryBorderColor w3-round"
              >
                Accept Job
              </button>
            </div>

            {/* FeedBack */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l12 s12 m12">
                <span>
                  <b>Add Correction</b>
                </span>
              </div>
              <div className="w3-col l12 s12 m12">
                <textarea
                  required
                  name="correction"
                  value={payLoad?.correction || ""}
                  style={{ width: "100%", height: "200px", resize: "none" }}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Description"
                ></textarea>
              </div>
            </div>

            {/* Reject Button  */}
            <div className="w3-col w3-margin-bottom">
              <button
                onClick={showRejectTaskConfirm}
                className="w3-btn w3-red w3-round"
              >
                Reject Job With Post Feedback
              </button>
            </div>
          </div>
        </div>

        <div></div>
      </div>
    </>
  );
};

export default AdvertiserContentRequestDetailsCardGPost;
