import {
  Button,
  Collapse,
  Empty,
  Modal,
  notification,
  Result,
  Spin,
} from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { useEffect, useState } from "react";
import { adminGetCategories } from "../../../../apiservice/admin-Users-Clone-Service";
import {
  CategoryDataSubCategory,
  IAdminSiteCategoryData,
} from "../../../../apiservice/admin-Users-clone.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { appZIndex } from "../../../../utils/appconst";
import { ILoadState } from "../../../../utils/loading.utils.";
import AdminCreateCategoryForm from "../Admin-Setting-Create-category/Admin-Setting-Create-category";
import AdminCreateSubCategoryForm from "../Admin-Setting-Create-sub-category/Admin-Setting-Create-sub-category";
import "./Admin-Setting-Category-List.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminSettingCategoryList = {
  title?: string;
  externalFilter?: any;
};

export const AdminSettingCategoryList: React.FC<IAdminSettingCategoryList> = ({
  externalFilter,
  title,
}) => {
  const [categoryLoadState, setCategoryLoadState] =
    useState<ILoadState>("loading");
  const [loadCategoryData, setLoadCategoryData] = useState(true);
  const [categoryDefaultFilter, setCategoryDefaultFilter] = useState({});
  const [tableData, setTableData] = useState<IAdminSiteCategoryData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState<IAdminSiteCategoryData | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<CategoryDataSubCategory | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [api, contextHolder] = notification.useNotification();

  const handleCancelModal = () => {
    setOpenModal(false);
    setTabIndex(-1);
  };

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCategoryDefaultFilter({ ...categoryDefaultFilter, ...externalFilter });
      setLoadCategoryData(true);
      setCategoryLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Category Details
  const categoryDataResult = useFormatApiRequest(
    () => adminGetCategories(),
    loadCategoryData,
    () => {
      setLoadCategoryData(false);
    },
    () => {
      processCategoryResult();
    }
  );

  // Process The Current Category Data Result
  const processCategoryResult = async () => {
    if (categoryDataResult.httpState === "SUCCESS") {
      setTableData(categoryDataResult.data?.data);
      setCategoryLoadState("completed");
    } else if (categoryDataResult.httpState === "ERROR") {
      setCategoryLoadState("error");
    } else if (categoryDataResult.httpState === "LOADING") {
      setCategoryLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col">
        <h4 className=""> {title || "List of category"}</h4>

        {/* " Show Loading Indicator" */}
        {categoryLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {categoryLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title="Error"
              subTitle="Sorry, something went wrong, it could be a network Related error"
              extra={
                <Button
                  onClick={() => setLoadCategoryData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {categoryLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show When there is data " */}
        {categoryLoadState === "completed" && (
          <>
            <Collapse accordion>
              {tableData.map((item: IAdminSiteCategoryData, index: number) => (
                <CollapsePanel
                  className="w3-container"
                  key={index}
                  header={item.title}
                >
                  <div className="w3-container">
                    {(item.subCategories || []).map(
                      (subItem: CategoryDataSubCategory, subIndex: number) => (
                        <div key={subIndex} className="w3-col w3-padding">
                          <span className="w3-left"> {subItem.title}</span>
                          <span
                            onClick={() => {
                              setTabIndex(1);
                              setSelectedCategoryId(item.id);
                              setSelectedSubCategory(
                                (item?.subCategories || [])[subIndex] || null
                              );
                              setIsModalEdit(true);
                              setOpenModal(true);
                            }}
                            className="w3-right w3-text-green"
                          >
                            <u> Edit </u>
                          </span>
                        </div>
                      )
                    )}

                    <div className="w3-col">
                      <button
                        onClick={() => {
                          setTabIndex(1);
                          setSelectedCategoryId(item.id);
                          setSelectedSubCategory(null);
                          setIsModalEdit(false);
                          setOpenModal(true);
                        }}
                        className="w3-btn w3-margin-bottom w3-margin-top w3-border w3-round w3-border-green w3-text-green w3-small"
                      >
                        Add Sub Category
                      </button>
                      &nbsp;
                      <button
                        onClick={() => {
                          setTabIndex(0);
                          setSelectedCategory(item);
                          setIsModalEdit(true);
                          setOpenModal(true);
                        }}
                        className="w3-btn w3-margin-bottom w3-margin-top w3-border w3-round w3-border-green w3-text-green w3-small"
                      >
                        Edit Category
                      </button>
                    </div>
                  </div>
                </CollapsePanel>
              ))}
            </Collapse>
          </>
        )}
      </div>

      <div className="w3-col">
        <button
          onClick={() => {
            setTabIndex(0);
            setSelectedCategory(null);
            setIsModalEdit(false);
            setOpenModal(true);
          }}
          className="w3-btn w3-margin-bottom w3-margin-top w3-border w3-round w3-blue"
        >
          Add Category
        </button>
      </div>

      <Modal
        style={{ top: "20px", height: "500px", overflow: "scroll" }}
        zIndex={appZIndex.modal}
        open={openModal}
        title={isModalEdit ? "Edit Category" : "Add Category"}
        onCancel={handleCancelModal}
        width={700}
        className={"w3-round-large"}
        footer={[
          <p style={{ minHeight: "200px" }}>
            &nbsp; <br />
            &nbsp; <br />
          </p>,
        ]}
      >
        {tabIndex === 0 && (
          <AdminCreateCategoryForm
            adminSiteCategory={selectedCategory}
            isEditMode={isModalEdit}
            refreshPage={() => {
              setLoadCategoryData(true);
            }}
          ></AdminCreateCategoryForm>
        )}

        {tabIndex === 1 && (
          <AdminCreateSubCategoryForm
            categoryDataSubCategory={selectedSubCategory}
            selectedCategoryID={selectedCategoryId}
            isEditMode={isModalEdit}
            refreshPage={() => {
              setLoadCategoryData(true);
            }}
          ></AdminCreateSubCategoryForm>
        )}
      </Modal>
    </>
  );
};

export default AdminSettingCategoryList;
