import { MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import "./Navbar.css";

const Navbar = () => {
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="w3-col navbarcontainerwrapper w3-card-2 w3-border-bottom">
        <div className="w3-content">
          <div className="w3-col l2 m6 s6">
            <div className="linkmie-header">
              <Link className="link" to="/">
                <span>
                  <img
                    style={{ maxHeight: "60px" }}
                    className="favicon-header"
                    src="/Favicon.jpg"
                    alt=""
                  />
                </span>
                <span className="textLogo"> Linkmie </span>
              </Link>
            </div>
          </div>

          <div className="w3-col l2 m6 s6 w3-right-align w3-hide-large">
            <MenuOutlined onClick={showDrawer} rev={undefined} />
          </div>

          <div className="w3-col l6 m6 s12 w3-right-align w3-hide-medium w3-hide-small">
            <div className="linkwrapper">
              <Link className="link" to="/">
                Home
              </Link>
              <Link className="link" to="./about">
                About
              </Link>
              <Link className="link" to="./contactus">
                Contact
              </Link>
            </div>
          </div>

          {authData.data ? (
            <div className="w3-col l4 m6 s6 w3-center w3-hide-small w3-hide-medium">
              <Link
                className="loginBtn"
                to={"/" + authData.data.credentials.activeUserType}
              >
                <span className="loginText"> Dashboard</span>
              </Link>
            </div>
          ) : (
            <div className="w3-col l4 m6 s6 w3-center w3-hide-small w3-hide-medium">
              <Link className="registetBtn" to="/userauth/signup">
                <span className="registerText"> Register </span>
              </Link>
              <Link className="loginBtn" to="/userauth/signin">
                <span className="loginText"> Login</span>
              </Link>
            </div>
          )}
        </div>
      </div>

      <Drawer
        title="Linkmie"
        placement="right"
        onClose={onClose}
        open={open}
        width={300}
      >
        <p onClick={() => setOpen(!open)}>
          <Link to="/">Home</Link>
        </p>
        <p onClick={() => setOpen(!open)}>
          <Link to="./about">About</Link>.
        </p>
        <p onClick={() => setOpen(!open)}>
          <Link to="./contactus">Contact</Link>
        </p>

        {authData.data ? (
          <p onClick={() => setOpen(!open)}>
            <Link
              className="registetBtn w3-center w3-col"
              to={"/" + authData.data.credentials.activeUserType}
            >
              <span className="registerText"> My Dashboard</span>
            </Link>
          </p>
        ) : (
          <div>
            <p onClick={() => setOpen(!open)}>
              <Link
                className="registetBtn w3-center w3-col"
                to="/userauth/signup"
              >
                <span className="registerText"> Register </span>
              </Link>
            </p>
            <p onClick={() => setOpen(!open)}>
              <Link className="loginBtn w3-center w3-col" to="/userauth/signin">
                <span className="loginText"> Login</span>
              </Link>
            </p>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default Navbar;
