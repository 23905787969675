import { Button, Modal, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPortal } from "../../../apiservice/portals.service";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppDispatch } from "../../../Redux/reduxCustomHook";
import { appZIndex } from "../../../utils/appconst";
import { ILoadState } from "../../../utils/loading.utils.";
import { convertIPortalDataToIPortalData } from "../../../utils/portals.utils";
import PortalFormOne from "../PortalForms/PortalForm-1";
import PortalFormTwo from "../PortalForms/PortalForm-2";
import PortalFormThree from "../PortalForms/PortalForm-3";
import PortalFormFour from "../PortalForms/PortalForm-4";
import PortalFormFive from "../PortalForms/PortalForm-5";
import PortalDetailsCard from "./PortalDetailsCard";
import "./PortalDetailsWrapper.css";

type NotificationType = "success" | "info" | "warning" | "error";

export const PortalDetailsWrapper = () => {
  const [portalFormIndex, setPortalFormIndex] = useState(-1);
  const [portalLoadState, setPortalLoadState] = useState<ILoadState>("loading");
  const [loadCurrentPortalData, setLoadCurrentPortalData] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const params = useParams();
  const dispatch = useAppDispatch();

  // This is use to Update The Params Value
  useEffect(() => {
    if (params?.portalId) {
      dispatch({ type: "PORTAL_UPDATE_ID", payload: params?.portalId });
    }
  }, []);

  // A custom hook to Load Current/Active Portal based on the Url
  const resultCurrentPortalData = useFormatApiRequest(
    () => getPortal(Number(params?.portalId)),
    loadCurrentPortalData,
    () => {
      setLoadCurrentPortalData(false);
    },
    () => {
      processCurrentPortalResult();
    }
  );

  // Process The Current Portal Data Result
  const processCurrentPortalResult = async () => {
    if (resultCurrentPortalData.httpState === "SUCCESS") {
      dispatch({
        type: "PORTAL_UPDATE_ALL_FORMS",
        payload: convertIPortalDataToIPortalData(
          resultCurrentPortalData.data?.data
        ),
      });
      setPortalLoadState("completed");
      setPortalFormIndex(0);
    } else if (resultCurrentPortalData.httpState === "ERROR") {
      setPortalLoadState("error");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Set Index
  const setIndex = (index: number) => {
    setPortalFormIndex(index);
    dispatch({ type: "PORTAL_UPDATE_STAGE", payload: index });
    setOpenModal(true);
  };

  const success = () => {
    openNotificationWithIcon(
      "success",
      "",
      "Your Entry has being saved succesfully"
    );
    handleCancelModal();
    setLoadCurrentPortalData(true);
  };

  const failure = () => {
    openNotificationWithIcon("error", "", "Error Saving your entry");
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Indicator" */}
      {portalLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {portalLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={<Button onClick={() => setLoadCurrentPortalData(true)} type="primary">Reload</Button>}
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {portalLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken create a new portal."
            extra={<Button type="primary">Create a new Portal</Button>}
          />
        </div>
      )}

      {/* " Display form when Portal Load State is Not loading for new form entry & completed for editing old entry" */}
      {(portalLoadState === "notLoading" ||
        portalLoadState === "completed") && (
        <div className="w3-col w3-padding-bottom">
          <PortalDetailsCard onEditClick={setIndex}></PortalDetailsCard>
        </div>
      )}

      <Modal
        zIndex={appZIndex.modal}
        open={openModal}
        title="Edit"
        onCancel={handleCancelModal}
        footer={[
          <Button onClick={handleCancelModal} key="back">
            Return
          </Button>,
        ]}
      >
        {portalFormIndex === 0 && (
          <PortalFormOne
            onFormSuccess={success}
            onFormFailure={failure}
          ></PortalFormOne>
        )}
        {portalFormIndex === 1 && (
          <PortalFormTwo
            onFormSuccess={success}
            onFormFailure={failure}
          ></PortalFormTwo>
        )}
        {portalFormIndex === 2 && (
          <PortalFormThree
            onFormSuccess={success}
            onFormFailure={failure}
          ></PortalFormThree>
        )}
        {portalFormIndex === 3 && (
          <PortalFormFour
            onFormSuccess={success}
            onFormFailure={failure}
          ></PortalFormFour>
        )}
        {portalFormIndex === 4 && (
          <PortalFormFive
            onFormSuccess={success}
            onFormFailure={failure}
          ></PortalFormFive>
        )}
      </Modal>
    </>
  );
};

export default PortalDetailsWrapper;
