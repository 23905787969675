import { useState } from "react";
import AdminDashboardGraph from "./Admin-Dashboard-Graph";
import "./Admin-Dashboard-profile.css";

export const AdminDashboardGraphWrappper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "type" && value === "default") {

      const currentDate = new Date();
      // Instantiate another date object to avoid mutating the current date
      const newDate = new Date(currentDate);
      newDate.setDate(newDate.getDate() - 7);


      setpayLoadFilter({
        startDate: currentDate,
        endDate: newDate,
      });
      setExternalFilter({
        startDate: currentDate,
        endDate: newDate,
      });
    }
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };
  return (
    <>
      <div className="w3-col w3-card w3-white w3-padding w3-round">
        <div className="w3-col">
          <h3>User Growth Statitics </h3>
        </div>
        <div className="w3-col">
          <div className="w3-col l2 s2 m2" style={{ padding: "2px" }}>
            <span className="w3-small"> Period</span>
            <select
              name="type"
              value={payLoadFilter?.type || ""}
              onChange={handleInputChange}
              className="w3-col inputSelect"
            >
              <option value="default">Default</option>
              <option value="custom"> Custom Period </option>
            </select>
          </div>

          {payLoadFilter?.type === "custom" && (
            <>
              {" "}
              <div className="w3-col l4 s4 m4" style={{ padding: "5px" }}>
                <span className="w3-small"> From </span>
                <input
                  name="startDate"
                  value={payLoadFilter?.startDate || ""}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Date"
                  type={"date"}
                />
              </div>
              <div className="w3-col l4 s4 m4" style={{ padding: "5px" }}>
                <span className="w3-small"> To </span>
                <input
                  name="endDate"
                  value={payLoadFilter?.endDate || ""}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Date"
                  type={"date"}
                />
              </div>
              <div className="w3-col l2 s2 m2" style={{ padding: "5px" }}>
                <span className="w3-small">
                  {" "}
                  <br /> &nbsp;{" "}
                </span>
                <button
                  onClick={updateFilter}
                  className="w3-btn w3-round selectBtn"
                >
                  {" "}
                  Apply{" "}
                </button>
              </div>
            </>
          )}
        </div>
        <div className="w3-col">
          <AdminDashboardGraph
            externalFilter={externalFilter}
          ></AdminDashboardGraph>
        </div>
      </div>
    </>
  );
};

export default AdminDashboardGraphWrappper;
