import { IUserListData } from "../../../../apiservice/admin-AuthService.type";
import "./User-Details-Wrapper.css";

type IAdminSiteUserDetailsWalletBalance = {
  siteUserData?: IUserListData | null;
  refreshPage?: () => void;
};

export const AdminSiteUserDetailsWalletBalance: React.FC<
  IAdminSiteUserDetailsWalletBalance
> = ({ siteUserData, refreshPage }) => {
  return (
    <>
      <div className="w3-col w3-container" style={{ minHeight: "200px" }}>
        <div className="w3-col l4 s12 m4" style={{ padding: "5px" }}>
          {/* Life Time Earning */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col w3-border adminSiteUserWalletBalanceContainer">
              <div
                className="w3-col w3-right-align"
                style={{ marginBottom: "5px" }}
              >
                <span className=""></span>
                <br />
              </div>
              <div className="w3-center">
                <span className="adminSiteUserLifeTimeEarningText">
                  Your Life Time Earning
                </span>
                <br />
                <br />
                <span className="adminSiteUserLifeTimeEarningTotal">
                  ${siteUserData?.totalEarnings || 0}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w3-col l4 s12 m4" style={{ padding: "5px" }}>
          {/* Wallet Balance */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col w3-border adminSiteUserWalletBalanceContainer">
              <div
                className="w3-col w3-right-align"
                style={{ marginBottom: "10px" }}
              >
                <span className=""></span>
                <br />
              </div>
              <div className="w3-center">
                <span className="adminSiteUserBalanceText">Wallet Balance</span>
                <br />
                <br />
                <span className="adminSiteUserBalanceAmount">
                  ${siteUserData?.walletBalance || 0}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w3-col l4 s12 m4" style={{ padding: "5px" }}>
          {/* Referral Earning */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col w3-border adminSiteUserWalletBalanceContainer">
              <div
                className="w3-col w3-right-align"
                style={{ marginBottom: "5px" }}
              >
                <span className=""></span>
                <br />
              </div>
              <div className="w3-center">
                <span className="adminSiteUserLifeTimeEarningText">
                  Your Referral Balance
                </span>
                <br />
                <br />
                <span className="adminSiteUserLifeTimeEarningTotal">
                  ${siteUserData?.totalEarnings || 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSiteUserDetailsWalletBalance;
