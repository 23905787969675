import "../../App.css";
import { Routes, Route } from "react-router-dom";
import AdminLayout from "./AdminLayout/AdminLayout";
import AdminHome from "./AdminHome/AdminHome";
import Nopage from "../Nopage/Nopage";
import AdminManageAdvertiser from "./AdminManageAdvertiser/AdminManageAdvertiser";
import AdminManagePublisher from "./AdminManagePublisher/AdminManagePublisher";
import AdminManageReferral from "./AdminManageReferral/AdminManageReferral";
import AdminManageUser from "./AdminManageUser/AdminManageUser";
import AdminManageWallet from "./AdminManageWallet/AdminManageWallet";
import AdminManageWithdrawal from "./AdminManageWithdrawal/AdminManageWithdrawal";
import AdminSupport from "./AdminSupport/AdminSupport";
import AdminLogout from "./AdminLogout/AdminLogout";
import AdminManagePublisherPortals from "./AdminManagePublisher-Portals/AdminManagePublisher-Portals";
import AdminManageAdmins from "./AdminManageAdmins/AdminManageAdmins";
import AdminManageAdminCreate from "./AdminManageAdmin-Create/AdminManageAdmin-Create";
import AdminManageBannerRequest from "./AdminManageBannerRequest/AdminManageBannerRequest";
import AdminManageGuestPostRequest from "./AdminManageGuestPostRequest/AdminManageGuestPostRequest";
import AdminManageAdminsDetails from "./AdminManageAdminsDetails/AdminManageAdminsDetails";
import AdminManagePublisherPortalsDetails from "./AdminManagePublisher-Portals-Details/AdminManagePublisher-Portals-Details";
import { AdminManageRequestgGPostDetails } from "./AdminManageRequestgGPostDetails/AdminManageRequestgGPostDetails";
import AdminNotifications from "./AdminNotifications/AdminNotifications";
import AdminManageRequestgBannerDetails from "./AdminManageRequestgBannerDetails/AdminManageRequestgBannerDetails";
import { AdminSettings } from "./AdminSettings/AdminSettings";
import AdminWithdrawalDetailsWrapper from "../../components/Admin-Component-Groups/Admin-Manage-Wallets-Comp/AdminWalletWithdrawalDetailsWrapper/AdminWithdrawalDetailsWrapper";
import { AdminManageUserDetails } from "./AdminManageUserDetails/AdminManageUserDetails";

const AdminPagesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<AdminHome />} />
        <Route path="/manage-admin" element={<AdminManageAdmins />} />
        <Route
          path="/manage-admin-create-admin"
          element={<AdminManageAdminCreate />}
        />
        <Route
          path="/manage-admin/:id"
          element={<AdminManageAdminsDetails />}
        />
        <Route path="/manage-advertiser" element={<AdminManageAdvertiser />} />
        <Route path="/manage-publisher" element={<AdminManagePublisher />} />
        <Route
          path="/manage-publisher-portal"
          element={<AdminManagePublisherPortals />}
        />
        <Route
          path="/manage-publisher-portal/:id"
          element={<AdminManagePublisherPortalsDetails />}
        />
        <Route path="/manage-referral" element={<AdminManageReferral />} />
        <Route path="/manage-users" element={<AdminManageUser />} />
        <Route path="/manage-users/:id" element={<AdminManageUserDetails />} />
        <Route path="/manage-wallet" element={<AdminManageWallet />} />
        <Route path="/manage-withdrawal" element={<AdminManageWithdrawal />} />
        <Route
          path="/manage-withdrawal/:id/:typeId"
          element={<AdminWithdrawalDetailsWrapper />}
        />
        <Route
          path="/manage-banner-request"
          element={<AdminManageBannerRequest />}
        />
        <Route
          path="/manage-guestpost-request"
          element={<AdminManageGuestPostRequest />}
        />
        <Route
          path="/manage-request-guest-post/:id"
          element={<AdminManageRequestgGPostDetails />}
        />
        <Route
          path="/manage-request-banner/:id"
          element={<AdminManageRequestgBannerDetails />}
        />
        <Route path="/support" element={<AdminSupport />} />
        <Route path="/settings" element={<AdminSettings />} />
        <Route path="/notifications" element={<AdminNotifications />} />
        <Route path="/logout" element={<AdminLogout />} />
        <Route path="*" element={<Nopage />} />
      </Route>
    </Routes>
  );
};

export default AdminPagesRoute;
