import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";

export const getPublisherTasks = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/publisher/tasks${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getPublisherTask = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/publisher/tasks/${id}`);
  const result: any = await data;
  return result;
};

export const acceptTask = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(`api/v1/publisher/tasks/${id}/accept`);
  const result: any = await data;
  return result;
};

export const rejectTask = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(`api/v1/publisher/tasks/${id}/reject`);
  const result: any = await data;
  return result;
};

export const completeTask = async (id: string | number, body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(
    `api/v1/publisher/tasks/${id}/complete`,
    body
  );
  const result: any = await data;
  return result;
};
