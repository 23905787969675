import AdminContentRequestDetailsWrapperGpost from "../../../components/Admin-Component-Groups/Admin-Manage-ContentRequest-Comp/Admin-ContentRequest-Details-Wrapper/Admin-ContentRequest-Details-Wrapper-Gpost";
import "./AdminManageRequestgGPostDetails.css";

export const AdminManageRequestgGPostDetails = () => {
  return (
    <div>
      <AdminContentRequestDetailsWrapperGpost></AdminContentRequestDetailsWrapperGpost>
    </div>
  );
};

export default AdminManageRequestgGPostDetails;
