import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";

export const sampleApiCall = async (body?: any) => {
  const axios = await instance("", "https://dummyjson.com/", true);
  const { data } = await axios.get("/todos");
  const result: any = await data;
  return result;
};

export const portalFormOneSave = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.put("api/v1/publisher/portals/section-1", body);
  const result: any = await data;
  return result;
};

export const portalFormTwoSave = async (id: string | number, body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch(
    `api/v1/publisher/portals/${id}/section-2`,
    body
  );
  const result: any = await data;
  return result;
};

export const portalFormThreeSave = async (id: string | number, body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch(
    `api/v1/publisher/portals/${id}/section-3`,
    body
  );
  const result: any = await data;
  return result;
};

export const portalFormFourSave = async (id: string | number, body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch(
    `api/v1/publisher/portals/${id}/section-4`,
    body
  );
  const result: any = await data;
  return result;
};

export const getPortal = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/publisher/portals/${id}`);
  const result: any = await data;
  return result;
};

export const getPortals = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/publisher/portals${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const portalDelete = async (id: string | number | null) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.delete(`api/v1/publisher/portals/${id}`);
  const result: any = await data;
  return result;
};

export const portalActivate = async (id: string | number | null) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch(`api/v1/publisher/portals/${id}/activate`);
  const result: any = await data;
  return result;
};
