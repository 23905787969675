import "../../App.css";
import { Routes, Route } from "react-router-dom";
import PublisherLayout from "./PublisherLayout/PublisherLayout";
import PublisherHome from "./PublisherHome/PublisherHome";
import Nopage from "../Nopage/Nopage";
import PublisherPortals from "./PublisherPortals/PublisherPortals";
import PublisherAddPortals from "./PublisherPortalAdd/PublisherAddPortal";
import UserProfile from "../userProfileDashboard/UserProfile/UserProfile";
import UserLogout from "../userProfileDashboard/UserLogout/UserLogout";
import PublisherDetails from "./PublisherPortalDetails/PublisherDetails";
import UserWallet from "../userProfileDashboard/UserWallet/userWallet";
import PublisherTask from "./PublisherTask/PublisherTask";
import PublisherTaskDetails from "./PublisherTaskDetails/PublisherTaskDetails";
import UserProfileEdit from "../userProfileDashboard/UserProfile edit/UserProfileEdit";
import PublisherWithDrawal from "./PublisherWithDrawal/PublisherWithDrawal";
import UserSupport from "../userProfileDashboard/UserSupport/UserSupport";
import { UserReferral } from "../userProfileDashboard/UserReferral/UserReferral";
import UserNotifications from "../userProfileDashboard/UserNotifications/UserNotifications";

const PublisherPagesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<PublisherLayout />}>
        <Route index element={<PublisherHome />} />
        <Route path="/portals" element={<PublisherPortals />} />
        <Route path="/portal-form" element={<PublisherAddPortals />} />
        <Route
          path="/portal-form/:portalId"
          element={<PublisherAddPortals />}
        />
        <Route path="/portal/:portalId" element={<PublisherDetails />} />
        <Route path="/task" element={<PublisherTask />} />
        <Route path="/task/:taskId" element={<PublisherTaskDetails />} />
        <Route path="/wallet" element={<UserWallet />} />
        <Route path="/withdrawal" element={<PublisherWithDrawal />} />
        <Route path="/support" element={<UserSupport />} />
        <Route path="/referral" element={<UserReferral />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/profile-edit" element={<UserProfileEdit />} />
        <Route path="/notifications" element={<UserNotifications />} />
        <Route path="/logout" element={<UserLogout />} />
        <Route path="*" element={<Nopage />} />
      </Route>
    </Routes>
  );
};

export default PublisherPagesRoute;
