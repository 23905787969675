import "./AdminManagePublisher.css";
import UserListswrapperPublisher from "../../../components/Admin-Component-Groups/Admin-Manage-Site-Users-Comps/User-Lists-wrapper/User-Lists-wrapper-Publisher";

export const AdminManagePublisher = () => {
  return (
    <div>
      <UserListswrapperPublisher></UserListswrapperPublisher>
    </div>
  );
};

export default AdminManagePublisher;
