import { Alert, Input, Radio } from "antd";
import { useState } from "react";
import PublisherOfferList from "../AdvertiserViewOffer-List/AdvertiserViewOffer-List";
import "./AdvertiserViewOffer-List-wrapper.css";

export const AdvertiserViewOfferListWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values, taskType: value }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <div className="w3-col">
      <div className="w3-container">
        <div className="w3-hide">
          <Alert
            message={<b> List Offer </b>}
            description={
              <span>
                You have 3 unconfirm Offer, Check your offer list to confirm
                that the task has being done.
              </span>
            }
            type="info"
            closable
            showIcon
          />
        </div>

        <div className="w3-col" style={{ marginTop: "50px" }}>
          <div className="w3-col w3-margin-bottom">
            <Input
              name="searchstring"
              value={payLoadFilter?.searchstring || ""}
              onChange={handleInputChange}
              onBlur={updateFilter}
              className="w3-col inputField"
              placeholder="Search Offer"
            />
          </div>

          <div className="w3-col w3-hide-large w3-hide-medium">
            <select
              required
              name="status"
              value={payLoadFilter?.status || "0"}
              onChange={handleInputChange}
              className="w3-col inputSelect"
            >
              <option value="">Select Offer Status</option>
              <option value="0"> List Offer</option>
              <option value="1"> Accepted Offer</option>
              <option value="2"> Rejected </option>
              <option value="3"> Completed</option>
              <option value="4"> Comfirmed</option>
            </select>
          </div>

          <div className="w3-col w3-hide-small">
            <Radio.Group
              name="status"
              value={payLoadFilter?.status || "0"}
              onChange={(e) => {
                handleInputChange(e);
              }}
              buttonStyle="solid"
              style={{ width: "100%" }}
            >
              <Radio.Button value="0">List Offer</Radio.Button>
              <Radio.Button value="1">Accepted Offer</Radio.Button>
              <Radio.Button value="2">Rejected Offer</Radio.Button>
              <Radio.Button value="3">Completed Offer</Radio.Button>
              <Radio.Button value="4">Confirmed Offer</Radio.Button>
            </Radio.Group>
          </div>
        </div>

        <div className="w3-col w3-margin-top">
          <PublisherOfferList
            externalFilter={externalFilter}
          ></PublisherOfferList>
        </div>
      </div>
    </div>
  );
};

export default AdvertiserViewOfferListWrapper;
