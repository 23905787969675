import WalletWrapperWithdrawal from "../../../components/walletsComps/walletWrapper-Withdrawal/walletWrapper-Withdrawal";
import "./PublisherWithDrawal.css";

export const PublisherWithDrawal = () => {
  return (
    <WalletWrapperWithdrawal></WalletWrapperWithdrawal>
  );
};

export default PublisherWithDrawal;
