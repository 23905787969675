import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Input, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { portalFormOneSave } from "../../../apiservice/portals.service";
import { IPortalFormType } from "../../../apiservice/portals.type";
import { getSiteNiches } from "../../../apiservice/site-divisions.service";
import { ISiteNicheData } from "../../../apiservice/site-divisions.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppDispatch, useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import "./PortalForm.css";
const { Option } = Select;

type IPortalFormOne = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
  onPrevClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const PortalFormOne: React.FC<IPortalFormOne> = ({
  onFormFailure,
  onFormSuccess,
  onPrevClick,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadSiteNiceApi, setLoadSiteNiceApi] = useState(true);
  const [siteNicheData, setSiteNicheData] = useState<ISiteNicheData[]>([]);
  const [selectIsValid, setSelectIsValid] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const portalFormData: IPortalFormType = useAppSelector(
    (state: RootState) => state?.PortalForm
  );

  // This is use to Update Redux Dispatch Values
  useEffect(() => {
    setpayLoad({ ...portalFormData.stepOne });
  }, []);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Check Select Change Validity
  useEffect(() => {
    if (payLoad?.websiteLanguage !== "" && payLoad?.siteNicheId !== "") {
      setSelectIsValid(true);
    }
  }, [payLoad]);

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!payLoad?.siteNicheId) {
      alert("Enter A Valid Site Niche");
      return;
    }

    if (!payLoad?.websiteLanguage) {
      alert("Enter A Valid Site Language");
      return;
    }

    if (portalFormData.id) {
      setpayLoad((values) => ({ ...values, id: portalFormData.id }));
    }
    setpayLoad((values) => ({ ...values, websiteRole: "owner" }));
    dispatch({ type: "PORTAL_UPDATE_STEP_ONE_DATA", payload: payLoad });
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to save form data
  const result = useFormatApiRequest(
    () => portalFormOneSave(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess();
      }
      dispatch({
        type: "PORTAL_UPDATE_ID",
        payload: result.data?.data?.id || 1,
      });

      dispatch({
        type: "PORTAL_UPDATE_STEP_TWO_DATA",
        payload: {
          domainAuthority: result.data?.data?.domainAuthority,
          spamScore: result.data?.data?.spamScore,
          domainRating: result.data?.data?.domainRating,
          siteDescription: result.data?.data?.siteDescription,
          organicTraffic: result.data?.data?.organicTraffic,
        },
      });
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  // A custom hook to Load Site Niche from the server
  const siteNicheResult = useFormatApiRequest(
    () => getSiteNiches(),
    loadSiteNiceApi,
    () => {
      setLoadSiteNiceApi(loadSiteNiceApi);
    },
    () => {
      processSiteNicheResultApi();
    }
  );

  // Process Site Niche Result
  const processSiteNicheResultApi = async () => {
    if (siteNicheResult.httpState === "SUCCESS") {
      setSiteNicheData(siteNicheResult.data?.data);
      // console.log(siteNicheResult.data);
    } else if (siteNicheResult.httpState === "ERROR") {
      // console.log(siteNicheResult.errorMsg);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Forms Here */}
        <div>
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Web Site Name <span className="w3-text-red">*</span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="websiteName"
                value={payLoad?.websiteName || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Web Site Name"
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Domain Name <span className="w3-text-red">*</span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="domainName"
                value={payLoad?.domainName || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Domain Name"
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Website Language <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="The primary language of your website"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Select
                showSearch
                id="websiteLanguage"
                value={payLoad?.websiteLanguage || ""}
                style={{ width: "90%", height: "50px", textAlign: "left" }}
                onChange={(val) => {
                  handleSelectChange(val, "websiteLanguage");
                }}
                className="w3-col"
              >
                <Option value="">Select Website Language</Option>
                <Option value="English"> English - (UK) </Option>
                <Option value="en">English - (US)</Option>
                <Option value="zh">Chinese - 中文</Option>
                <Option value="es">Spanish - español</Option>
                <Option value="hi">Hindi - हिन्दी</Option>
                <Option value="ar">Arabic - العربية</Option>
                <Option value="pt">Portuguese - português</Option>
                <Option value="bn">Bengali - বাংলা</Option>
                <Option value="ru">Russian - русский</Option>
                <Option value="ja">Japanese - 日本語</Option>
                <Option value="pa">Punjabi - ਪੰਜਾਬੀ</Option>
              </Select>
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Site Niche <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="The specific topic or industry focus of your site"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Select
                showSearch
                id="siteNicheId"
                value={payLoad?.siteNicheId || ""}
                style={{ width: "90%", height: "50px", textAlign: "left" }}
                onChange={(val) => {
                  handleSelectChange(val, "siteNicheId");
                }}
                className="w3-col"
              >
                <Option value="">Select Site Niche</Option>
                {siteNicheData.map((item: ISiteNicheData, index: number) => (
                  <Option key={index} value={item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        {/* Button Here */}
        <div
          className="w3-padding w3-center"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <button
            onClick={onPrevClick}
            disabled={formLoading}
            className="prevButton w3-btn"
          >
            Back
          </button>
          <button
            className="nextButton w3-btn"
            disabled={formLoading && !selectIsValid}
          >
            {!formLoading ? (
              "Save and Continue"
            ) : (
              <LoadingOutlined rev={undefined} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PortalFormOne;
