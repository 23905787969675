import "./TermsAndCondtions.css";
const TermsAndCondtions = () => {
  return (
    <div className="w3-white w3-col" style={{ minHeight: "400px" }}>
      <div className="w3-col">
        <div className="w3-content">
          <div className="w3-container w3-margin-top">
            <p>
              <h3>
                <span>Linkmie TERMS AND CONDITIONS</span>
              </h3>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                This agreement governs your use of the services provided by Link
                Mie. By accessing linkmie.com and using the services, you agree
                to read, understand, and be legally bound by the Terms and
                Conditions. By using the services, you confirm that you do not
                perform any prohibited activities listed in PayPal's Acceptable
                Use Policy. By accessing Linkmie.com you also agree to the
                Acceptable Use Policy, Terms of Service, and legal documentation
                of credit card processing or payment gateway providers used by
                Linkmie.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                You must not use the services for unlawful purposes or in
                furtherance of illegal activities. By using Linkmie, you agree
                to comply with all applicable laws governing your online conduct
                and content. You are independently responsible for complying
                with all applicable laws in all actions related to your use of
                Linkmie services.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Linkmie reserves the right to change the Terms and Conditions
                without notifying the User. By accepting these Terms and
                Conditions, you become responsible for periodically checking for
                changes and/or updates. By continuing to use the site, you agree
                to read, understand, and oblige to the Terms and Conditions,
                Privacy Policies, and Acceptable Use Policies of Linkmie and all
                payment providers used by Linkmie.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Linkmie has the right to break the existing partnership between
                registered users if signs of bad faith are detected.
              </span>
            </p>
            <h3>
              <span>Definitions</span>
            </h3>
            <ol>
              <li>
                <span>
                  Administration means representatives of the Linkmie support
                  system.
                </span>
              </li>
              <li>
                <span>
                  An account is a unique profile of a user in the Linkmie
                  system, allowed to use all available functions.
                </span>
              </li>
              <li>
                <span>
                  User is defined as anyone who uses the Linkmie websites and
                  any information and services provided by Linkmie.
                </span>
              </li>
              <li>
                <span>
                  Publisher is a natural or legal person registered with
                  Linkmie, who uses his account as Publisher to create and
                  distribute sponsored content to receive compensation for such
                  activities.
                </span>
              </li>
              <li>
                <span>
                  Advertiser is a natural or legal person registered with
                  Linkmie who uses his account a an advertiser to create tasks
                  or open offers to use the publisher's services.
                </span>
              </li>
            </ol>
            <h3>
              <span>Eligibility</span>
            </h3>
            <p>
              <span>
                To register for Linkmie.com, you must be at least 16 years old
                and provide accurate and complete information. The company's
                Privacy Policy outlines how they will use your information and
                you agree to this. Linkmieaccounts cannot be sold, given away,
                or transferred to another person, and you must agree to maintain
                the accuracy of your information.
              </span>
            </p>
            <h3>
              <span>Account Registration</span>
            </h3>
            <p>
              <span>
                3.1. To sign up for the Services, you may register for a
                personal user account (Account). You must provide
              </span>
            </p>
            <ul>
              <li>
                <span>
                  accurate and complete information and keep your Account
                  information updated.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  You shall not select or use as a username a name: (a) of
                  another person with the intent to impersonate that person; (b)
                  subject to any rights of a person other than you without
                  appropriate authorization; or (c) that is otherwise offensive,
                  vulgar or obscene.&nbsp;
                </span>
              </li>
              <li>&nbsp;</li>
            </ul>
            <p>
              <span>
                You are solely responsible for the activity that occurs on your
                Account and for keeping your account password secure. You may
                never use another person&rsquo;s user account or registration
                information for the Services without permission. You must notify
                us immediately of any security breach or unauthorized use of
                your Account. You should never publish, distribute or post login
                information for your Account.
              </span>
            </p>
            <h3>
              <span>Terms and conditions for Advertisers&nbsp;</span>
            </h3>
            <p>
              <span>
                Advertisers choose a platform to advertise on based on
                information provided by Publishers on the Linkmieserver.
                However, a job may be refused if it has been in the "Publisher's
                Acceptance" or "In Progress" status for over 7 days or 14 days
                without the Publisher sending the task for approval, even if
                multiple attempts have been made. Additionally, after 30 days,
                all bids with the status "New" will be automatically removed
                from the open offer.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                The Advertiser can request to reject a assigned Publisher as a
                task performer during the job if all conditions are met: the
                task has been ongoing for at least five days, the Publisher has
                not responded to the Advertiser's letters, and there are no
                indications of the Publisher's involvement in the task.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                When disputes arise, all conditions of the tasks, allowing
                double interpretations, will be interpreted in favor of the
                Publisher.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                4.6. Publishers set prices for available service types.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                4.7. All correspondence between Advertisers and Publishers is
                stored on the Linkmieserver and can be used by the
                administration to resolve disputes.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                All information about the tasks and the payments received by
                Publishers from Advertisers is confidential and not subject to
                distribution.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                The advertiser must verify the publisher's completed job within
                three days of receiving notice. If not done, the task is sent to
                site administration for approval. The advertiser cannot request
                the publisher to redo a task without specifying any corrections
                that were not outlined in the first work criteria.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                The purchaser has the legal right to return unsatisfactory
                content to the Publisher. If the Advertiser redoes the material
                more than three times or fails to deliver redoing conditions
                within three days, the Publisher can appeal to the
                Administration over the task's acceptance and payment transfer.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                4.11. The Advertiser does not have the right to change task
                requirements after Publisher has started to complete the task.
                According to the reasoned statement from Publisher, the
                Advertiser is required to clarify task particular provisions by
                the internal message system of the Linkmieservice.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                4.12. Changes to published and paid content are not allowed.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                4.13. Advertiser agrees to pay commission for using
                LinkmieServices.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                4.14. Task payment is reserved on the Advertiser's balance as
                soon as the task is created and sent to Publisher. Once the task
                is completed (i.e. task was approved by the Advertiser), payment
                for it is transferred to Publisher&rsquo;s main balance.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                The Advertiser is responsible for paying Linkmieall charges made
                to their account for agreed-upon fees or use of the Services in
                accordance with these Terms and Conditions. If the Advertiser's
                account balance is insufficient to cover the fees, a charge will
                be made to their credit card or other preferred payment method.
                The Advertiser must pay all amounts due on demand and
                Linkmiereserves the right to suspend or terminate their account,
                including deletion of campaigns until the balance is paid in
                full. All fees for the Services and deposits for future Services
                are non-refundable. If disputes arise, the Advertiser must
                submit them in writing within 30 days. If the payment method
                cannot be charged, the account will be locked until an update
                method is provided. If the Advertiser fails to make payment,
                they will be responsible for all reasonable expenses incurred by
                Linkmiein collecting such amounts. Prices do not include taxes
                imposed by any jurisdiction. The Advertiser must promptly update
                all information to keep account and credit card billing current,
                complete, and accurate, notify Linkmiein writing if it becomes
                aware of a potential security breach, and notify Linkmiein
                writing if its credit card is canceled.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                4.17. If the Advertiser doesn&rsquo;t log in to his account
                within 180 days, he is considered inactive, and any funds on his
                account are charged off as an inactive fee.
              </span>
            </p>
            <h3>
              <span>Content Purchase</span>
            </h3>
            <p>
              <span>
                An Advertiser can purchase content or articles by placing an
                order using their account. They can select the article's
                categories, topics, themes, and issues, as well as the minimum
                and maximum word count for the author. Other variables and
                information can also be included in the purchase..
              </span>
            </p>
            <h4>
              <span>Ordering Articles</span>
            </h4>
            <p>
              <span>
                Advertisers are required to provide accurate and comprehensive
                information when placing an order for Articles. If the
                advertiser provides inaccurate, erroneous, or unfaithful
                information, they are fully liable for any consequences and
                misunderstandings that may arise. Advertisers acknowledge that
                articles are written by the respective authors and that
                Linkmieis not responsible for any damages, breaches,
                infringements, misappropriation, or violation of third party
                rights related to the article. Advertisers also accept that
                Linkmieassumes no liability for any breaches of confidentiality
                by authors, despite Linkmie's request for authors to keep all
                information submitted to them confidential.
              </span>
            </p>
            <h4>
              <span>Acceptance of the Article</span>
            </h4>
            <p>
              <span>
                The Advertiser can review and critique an ordered article after
                it is completed. They can accept the article, request a revision
                within five days, or reject it once a revision request has been
                made. The Linkmieteam can reject a job at any point during its
                active statuses if they suspect fraud or if a dispute has been
                made regarding the assignment. This process is subject to the
                advertiser's approval.
              </span>
            </p>
            <h3>
              <span>Revision Request</span>
            </h3>
            <p>
              <span>
                If a revision is requested, the article's terms may be extended.
                If the revision request is not received within five days, the
                article is considered accepted without objection. The Advertiser
                must clearly define what they need changed or enhanced in the
                article and make the request to the author. If the author does
                not modify or improve the article, the Advertiser may reject it
                entirely. The reserved money for the article will be transferred
                from the Advertiser's reserved balance to the main balance,
                preventing any loss. The Advertiser acknowledges that Linkmiehas
                the authority to make the final determination on the article's
                justification and accepts Linkmie's determination as final.
              </span>
            </p>
            <h4>
              <span>Rights to accepted articles.</span>
            </h4>
            <p>
              <span>
                Advertisers do not have ownership rights to an article until it
                is accepted and payment is made. Once accepted, they have the
                right to publish, perform, display, reproduce, distribute,
                create derivative works, and sell the article in any media,
                including print and online, anywhere in the world, including the
                ability to sell the article.
              </span>
            </p>
            <h3>
              <span>Terms and conditions for Publishers</span>
            </h3>
            <p>
              <span>
                The publisher must accept task offers from advertisers within 5
                days, However, tasks can be automatically rejected if they have
                been in the "Your Acceptance" status for over 7 days, in the "In
                Progress" status for over 14 days, and the publisher hasn't
                attempted to send the task for Advertiser's approval. Tasks can
                also be rejected by the Linkmieteam due to fraud or open
                disputes.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                5.3. All the bids with the New status are automatically deleted
                from the open offer after 30 days
              </span>
            </p>
            <p>
              <span>
                5.6. Prices for available service types are set by Publishers.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                When Publisher accepts a task offer from Advertiser, they are
                required to finish the task given by Advertiser and make sure it
                meets their requirements. If Advertiser's needs aren't met in
                the finished task or if paid content that Publisher deleted is
                found, Advertiser's fees are returned as long as Publisher has
                enough money in their account. If Publisher's account doesn't
                have enough money in it, the Administration can freeze his
                account until all the details of how the wrong content was
                removed or put there are made clear.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                It is not possible to change information that has already been
                published and approved by the advertiser. Also, you can't put
                any other links, ads, or other things in the task content
                besides the ones that Advertiser has asked you to.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                The advertiser can go back to the publisher job and do it again
                if it doesn't meet the requirements. Advertiser is only allowed
                to make three changes to a job before they have to send the
                agreed-upon payment to Publisher.
              </span>
            </p>
            <p>
              <span>
                What to do If Advertiser sends the task back to be redone more
                than three times or doesn't send the redoing conditions to
                Publisher in more than three calendar days, Publisher can ask
                Administration to accept the work and move payment. All emails
                and messages sent between Advertisers and Publishers are saved
                on the Linkmiecomputer and can be used by Administration to
                settle disagreements.
              </span>
            </p>
            <p>
              <br />
              <br />
              <br />
            </p>
            <p>
              <span>
                All information about tasks and payments received by Publishers
                from Advertisers, is confidential and not subject to
                distribution.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Publishers must ensure their platforms are open for all without
                prior registration, with specific entries allowed but all paid
                ones must be opened. They must not delete, mask, or modify link
                text, alter the content of their publication, or hinder crawler
                or visitor indexing. Posts published on requested pages must
                remain there for at least 90 days from the publication date. If
                issues are discovered, the Linkmieteam may restrict the account,
                remove the site from inventory, reduce its rating, and withhold
                publication cost from its balance.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Administration considers disputes between Publishers and
                Advertisers based on the Advertiser's task and correspondence.
                If a breach occurs, the Administration has the right to suspend
                or delete the Publisher's account within 7 days. If the
                Advertiser accepted but failed to pay for completed tasks, the
                remaining balance will be transferred to the Publisher's last
                known payment details. If payment details are unavailable, the
                Administration will notify the Publisher about the balance size
                via email, and the Publisher must agree to the repayment method
                and period.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Task payment is reserved on Publisher's balance as soon as the
                task is created by Advertiser and sent for Publisher's
                acceptance. Once the task is completed (i.e. task was approved
                by Advertiser), payment for it is transferred to
                Publisher&rsquo;s main balance.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Withdrawals can be made for completed tasks only (i.e. task was
                approved by Advertiser). Publisher is responsible for providing
                the correct payment address while making the withdrawal request.
                If the address provided is incorrect, and the payment has been
                sent to it, the withdrawal is considered to be completed.
                Linkmiewill not send the same payment again to the corrected
                address.
              </span>
            </p>
            <p>
              <span>
                When Publisher withdraws money, Linkmiewill apply a commission
                based on the chosen payment method.
              </span>
            </p>
            <p>
              <span>
                Requirements for websites being added to the Linkmiesystem:
              </span>
            </p>
            <ul>
              <li>
                <span>
                  Number of website pages indexed by Google must be no less than
                  50 pages.
                </span>
              </li>
              <li>
                <span>Age of domain must be no less than 6 months.</span>
              </li>
              <li>
                <span>Domain Authority must be no less than 15.</span>
              </li>
              <li>
                <span>
                  The MOZ Spam Score of a website can be no more than 30%.
                </span>
              </li>
              <li>
                <span>Free web-hosting is forbidden.</span>
              </li>
              <li>
                <span>
                  Website must have unique, readable and frequently updated
                  content.
                </span>
              </li>
              <li>
                <span>
                  Limited website space is allowed to be covered with
                  advertising not to disturb website visitors. Website must not
                  have any intrusive advertising, pop-ups, pop-unders,
                  click-unders, etc. Website must have reasonable number of
                  outgoing links and must not be spammy.
                </span>
              </li>
              <li>
                <span>
                  Websites that violate the laws of the USA are forbidden.
                </span>
              </li>
              <li>
                <span>Websites that violate copyrights are forbidden.</span>
              </li>
              <li>
                <span>
                  Websites that do not conform to the public moral and ethical
                  standards are forbidden. Forbidden website categories:
                </span>
              </li>
            </ul>
            <p>
              <span>Adult</span>
            </p>
            <p>
              <span>Dating</span>
            </p>
            <p>
              <span>Gambling</span>
            </p>
            <p>
              <span>Sports betting</span>
            </p>
            <p>
              <span>Competitive services</span>
            </p>
            <p>
              <span>Marketplaces</span>
            </p>
            <p>
              <span>Binary options</span>
            </p>
            <p>
              <span>Hacking, warez, cheat codes, unlicensed software</span>
            </p>
            <p>
              <span>Occult and magical services</span>
            </p>
            <p>
              <span>Sale of fakes</span>
            </p>
            <p>
              <span>Smoking, cigarettes, tobacco, etc.</span>
            </p>
            <p>
              <span>Alcohol</span>
            </p>
            <p>
              <span>Weapon, arms, armament</span>
            </p>
            <p>
              <span>Forums and open web platforms</span>
            </p>
            <p>
              <span>Announcement marketplaces</span>
            </p>
            <p>
              <span>Fraudulent activities</span>
            </p>
            <p>
              <span>Financial pyramids</span>
            </p>
            <p>
              <span>Forex trading</span>
            </p>
            <p>
              <span>Medical websites without providing valid licences</span>
            </p>
            <p>
              <span>
                Pharmaceutical goods, including, but not limited to dietary
                supplements, drugs, herbs, vitamins, sports nutrition, etc.
              </span>
            </p>
            <p>
              <span>
                Moderator also evaluates websites from a user perspective. Thus,
                a website can be rejected if it is not visually appealing and
                user friendly.
              </span>
            </p>
            <p>
              <span>
                Moderation decision can be changed by Administration after the
                website has been approved or rejected. Website can be rejected
                after having been approved if its quality has deteriorated, and
                it is no longer in conformity with existing moderation rules.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                If Publisher deletes a site before a task associated with it
                moves to the &ldquo;Completed&rdquo; status, the money for the
                task will be sent back to Advertiser.
              </span>
            </p>
            <h2>
              <span>Refund policy</span>
            </h2>
            <p>
              <span>
                6.1. All prices are listed in Linkmiecredits. 1 credit equals 1
                USD. By adding funds to his account User purchases credits. At
                the moment User can purchase credits using PayPal and credit
                cards. Once credits are purchased, Linkmieservices are
                considered to have been provided and payment becomes final and
                non-refundable. However, User has the right to contact
                Linkmieand ask for a refund to his PayPal account or a credit
                card within 30 days of such payment if he believes it has been
                made by mistake. No refunds will be made for any payments which
                were done more than 30 days ago.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                6.2. All task payments are final and non-refundable. However, in
                case of improper task performance Advertiser has the right to
                request a dispute and ask for a refund. Linkmiereserves the
                right to arbitrate any disputes between Advertisers and
                Publishers. If the dispute is resolved in favor of Advertiser, a
                refund will be made to Advertiser&rsquo;s balance to be spent
                within the Linkmiesystem.
              </span>
            </p>
            <h2>
              <span>Prohibited activities</span>
            </h2>
            <p>
              <span>
                You may not use the Linkmieservice for activities that:
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>violate any law, statute, ordinance or regulation.</span>
            </p>
            <p>
              <span>
                relate to actions involving (a) narcotics, steroids, certain
                controlled substances or other products that present a risk to
                consumer safety, (b) drug paraphernalia, (c) cigarettes, (d)
                items that encourage, promote, facilitate or instruct others to
                engage in illegal activity, (e) stolen goods including digital
                and virtual goods, (f) the promotion of hate, violence, racial
                or other forms of intolerance that is discriminatory or the
                financial exploitation of a crime, (g) items that are considered
                obscene, (h) items that infringe or violate any copyright,
                trademark, right of publicity or privacy or any other
                proprietary right under the laws of any jurisdiction, (i)
                certain sexually oriented materials or services, (j) ammunition,
                firearms, or certain firearm parts or accessories, or (k)
                certain weapons or knives regulated under applicable law.
              </span>
            </p>
            <p>
              <span>
                relate to actions that (a) show the personal information of
                third parties in violation of applicable law, (b) support
                pyramid or ponzi schemes, matrix programs, other "get rich
                quick" schemes or certain multi-level marketing programs, (c)
                are associated with purchases of annuities or lottery contracts,
                lay-away systems, off-shore banking or transactions to finance
                or refinance debts funded by a credit card, (d) are for the sale
                of certain items before the seller has control or possession of
                the item, (e) are by payment processors to collect payments on
                behalf of merchants, (f) are associated with the sale of
                traveler's checks or money orders, (h) involve currency
                exchanges or check cashing businesses, (i) involve certain
                credit repair, debt settlement services, credit transactions or
                insurance activities, or (k) involve offering or receiving
                payments for the purpose of bribery or corruption.
              </span>
            </p>
            <p>
              <span>
                involve the sales of products or services identified by
                government agencies to have a high likelihood of being
                fraudulent.
              </span>
            </p>
            <h2>
              <span>
                Ownership and intellectual property; use of trademarks
              </span>
            </h2>
            <p>
              <span>
                All Rights Reserved. User acknowledges and agrees that all
                rights, title, and interest to, any intellectual property rights
                of all types or nature whatsoever, including, without
                limitation, patent, copyright, trademark, database rights as
                well as moral rights, know-how, and trade secrets (and any
                licenses in connection with any of the same), whether or not
                registered or capable of registration, and whether subsisting in
                any specific country or countries or any other part of the
                world, in the Services, the platform used to provide the
                Services (technology, hardware, software, etc.), any code or
                software (SDK, API, etc.) which may be provided to User or for
                User&rsquo;s use under this Agreement and any work products
                created and/or delivered herein and related documentation
                (forming the Linkmie.com and theLinkmie.com Websites and
                Services) are and will remain solely and exclusively our
                property and/or the property of Linkmie, Linkmielicensors or
                affiliates. User is granted no title or ownership rights in the
                Linkmie.com or theLinkmie.com Websites or Service.
                User&rsquo;s right to use the Websites, Services and any part
                thereof is strictly limited to the provisions of this Agreement
                and we reserve all rights not expressly granted herein.
              </span>
            </p>
            <p>
              <span>
                LinkmieMarks. Linkmie.com&reg; and theLinkmie.com&reg; are
                trademarks, DBAs, and trade dress and/or service marks of LINK
                MIE.com, theLinkmie.com and/or our affiliates and subsidiaries.
                Other marks, graphics, icons, names and logos used or displayed
                on or through the Websites are trademarks, trade dress and/or
                service marks (&ldquo;Marks&rdquo;) of us and our affiliates and
                subsidiaries or otherwise are the property of their respective
                owners, who may or may not be affiliated with, connected to, or
                sponsored by us and may be subject to such third parties&rsquo;
                terms and conditions. User may not use any metatags or any other
                &ldquo;hidden text&rdquo; utilizing any of the aforementioned
                trademarks, trade dress and/or service marks without our and
                respective owner&rsquo;s prior written permission. User&rsquo;s
                right to use the Marks is strictly limited to the manner of use
                as instructed and approved by us, which right may be revoked or
                changed at any time at our (or the respective owners&rsquo; or
                licensors&rsquo;) sole discretion. User will accordingly change
                or remove such display of materials immediately upon request by
                us or the respective Mark owners or licensors. User acknowledges
                and agrees that User shall not contest the ownership of the
                Marks on the Websites for any reason. User&rsquo;s use or
                display of Marks will terminate effective upon the termination
                of this Agreement, suspension of the Services or upon
                notification by us or the respective owner or licensor to
                discontinue such use or display.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                9.3. User&rsquo;s Marks. User hereby grants us a worldwide,
                non-exclusive, unlimited and royalty-free license to use
                User&rsquo;s brands, names, logos, trademarks, trade names and
                service marks as used by User for informational and advertising
                purposes only.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                9.4. Feedback. Users are under no obligation to give Linkmieany
                ideas, suggestions, comments or other feedback related to the
                Websites, the Services, or the business or operations of LINK
                MIE.com and/or theLinkmie.com. If any User shares ideas,
                suggestions, comments, or other feedback with Linkmie,
                Linkmiewill own such idea, suggestion, comment or feedback. User
                hereby assigns all of User&rsquo;s right, title, and interest in
                such idea, suggestion, comment, or feedback to Linkmie.com
                and/or theLinkmie.com and agrees that Linkmiewill be free to
                use and implement same, without restriction or obligation of any
                kind, without, however, any obligation to do so.
              </span>
            </p>
            <h3>
              <span>Termination</span>
            </h3>
            <p>
              <span>
                This agreement allows either party to terminate it at any time
                with written notice. The affected User's rights to use the
                Websites or Services will be immediately terminated upon
                termination. Any provisions regarding indemnification, warranty,
                liability, confidentiality, and protection of proprietary rights
                and trade secrets will survive until they no longer need to be
                necessary for their purpose to be achieved. The termination of
                this agreement will result in the immediate termination of all
                user rights.
              </span>
            </p>
            <h3>
              <span>Warranty Disclaimer &amp; Limitation of Liability</span>
            </h3>
            <p>
              <span>
                Linkmieprovides its Services and Websites "as-is" and "as
                available," with no warranties or conditions, including
                merchantability, fitness for a particular purpose, title,
                accuracy, or non-infringement. The Services and Websites are not
                guaranteed to meet user requirements, be uninterrupted, timely,
                secure, or error-free, or to be accurate, reliable, free of
                viruses or harmful code. Linkmiealso makes no warranties or
                representations regarding the accuracy or completeness of
                content on linked sites.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Limitation of Liability: Linkmie, its officers, directors,
                employees, or agents, are not liable for any direct, indirect,
                incidental, special, punitive, or consequential damages arising
                from or related to this Agreement or use of the Services or
                Websites. Their liability is limited to the greater of $50 or
                amounts paid to Linkmiein the prior 12 months. The limitation of
                liability applies to the fullest extent permitted by law in the
                applicable jurisdiction.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Linkmiedoes not warrant, endorse, guarantee, or assume
                responsibility for any product or service advertised or offered
                by a third party through the Websites or any hyperlinked
                website. It is not responsible for monitoring transactions
                between users and third-party providers of products or services.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Other Jurisdictions: Linkmiedoes not make any representations
                that the Services or Websites are appropriate or available for
                use in all locations. Users accessing or using the Services or
                Websites from these jurisdictions are responsible for compliance
                with local law.
              </span>
            </p>
            <h3>
              <span>Indemnity</span>
            </h3>
            <p>
              <span>
                You agree to defend, indemnify, and hold Linkmieand its
                officers, directors, employees, and agents harmless from any
                claims, damages, obligations, losses, liabilities, costs, debts,
                and expenses arising from your use of the Services and the
                Websites, violation of any term of this Agreement, or violation
                of any third party right, including copyright rights. This
                responsibility remains in effect even after the Agreement and
                use of the Services and Websites have been terminated.
              </span>
            </p>
            <h3>
              <span>Miscellaneous</span>
            </h3>
            <p>
              <span>
                The Agreement constitutes the entire agreement and understanding
                between the parties regarding the subject matter contained
                herein and supersedes all previous and contemporaneous
                agreements, proposals and communications in all forms of media
                (including all instructions, advertisements, messages and
                policies), written and oral, between you and Linkmieregarding
                the subject matter contained herein. Only a written instrument
                executed by the party waiving compliance may waive the terms or
                covenants of the Agreement. No waiver by either party of a
                breach of any provision hereof shall be taken or held to be a
                waiver of any succeeding breach of such provision or as a waiver
                of the provision itself. If any provision of the Agreement is
                held or made invalid or unenforceable for any reason, such
                invalidity shall not affect the remainder of the Agreement, and
                the invalid or unenforceable provisions shall be replaced by a
                mutually acceptable provision, which being valid, legal and
                enforceable comes closest to the original intentions of the
                parties to the Agreement and has like economic effect.
                Linkmieshall have no liability under the Agreement by reason of
                any failure or delay in the performance of its obligations on
                account of strikes, shortages, riots, acts of terrorism,
                insurrection, fires, flood, storm, explosions, earthquakes,
                Internet outages, computer virus, Acts of God, war, governmental
                action, or any other cause that is beyond its reasonable
                control. The parties are independent contractors and nothing in
                the Agreement shall be construed to create, evidence, or imply
                any agency, employment, partnership, or joint venture between
                the parties. Neither Advertiser nor Publisher are employees of
                Linkmie. Neither Linkmie, Advertiser nor Publisher shall have
                any right, power, or authority to create any obligation or
                responsibility on behalf of the other party. The Agreement is
                not intended to benefit, nor shall it be deemed to give rise to
                any rights in, any third party. You may not assign or transfer
                the Agreement, or sublicense, assign or delegate any right or
                duty under the Agreement without Linkmie&rsquo;s prior written
                consent. Any assignment, transfer or attempted assignment or
                transfer in violation of this Section shall be void and of no
                force and effect. Linkmieand its subsequent assignees may assign
                the Agreement, in whole or in part, or any of its rights or
                delegate any of its duties, under the Agreement to any party.
                Any rights not expressly granted in the Agreement are reserved
                by Linkmie, and all implied licenses are disclaimed. Headings
                used in the Agreement are for reference purposes only and in no
                way affect the Agreement. The term &ldquo;including&rdquo; is a
                term of enlargement meaning &ldquo;including without
                limitation,&rdquo; and does not denote exclusivity. Linkmiemay
                change the Agreement and/or the Privacy Policy at any time. Any
                use by you, your affiliates, agents, representatives, employees,
                or any person or entity acting on your behalf, of the Service
                after such notice shall be deemed to be continued acceptance by
                you of the Agreement and Privacy Policy, including any
                amendments and modifications thereto. All information or
                material in connection with a Service must be submitted in the
                form requested by Linkmie. Linkmiereserves the right to
                discontinue offering, and/or modify the Services at any time.
                The organization, specifications, structure, or appearance of
                any Linkmieproperty or any page where your information
                (including listings) may be displayed may be redesigned or
                modified at any time.
              </span>
            </p>
            <h2>
              <span>Content Upload</span>
            </h2>
            <p>
              <span>
                Uploading content confirms ownership or permission to use it,
                and it is against the rules to add pornographic,
                violence-promoting, drug propaganda, or illegal substances.
                Users must agree that their content does not violate any
                copyrights and confirm that they have permission to use it.
              </span>
            </p>
            <p>
              <span>
                All rights belong to the copyright holders. We reserve the right
                to remove any posted submission that infringes on any person's
                copyright. We have adopted and reasonably implemented a policy
                for termination in appropriate circumstances for repeat
                infringers.
              </span>
            </p>
            <h2>
              <span>Longevity and Guarantees of Publications</span>
            </h2>
            <p>
              <span>
                Linkmieis not responsible for influencing third-party conduct,
                including publication availability, domain, metrics, and page
                code preservation. It reviews publications every 90 days,
                considering any advertiser claims made during this time, and
                does not accept responsibility for influencing third-party
                conduct.
              </span>
            </p>
            <p>
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondtions;
