import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./Admin-Setting-Create-sub-category.css";
import { Input, notification } from "antd";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { CategoryDataSubCategory } from "../../../../apiservice/admin-Users-clone.type";
import {
  adminAddSubCategory,
  adminUpdateSubCategory,
} from "../../../../apiservice/admin-Users-Clone-Service";
type NotificationType = "success" | "info" | "warning" | "error";

type ICreateSubCategoryForm = {
  selectedCategoryID?: number | string;
  categoryDataSubCategory?: CategoryDataSubCategory | null;
  isEditMode?: boolean;
  refreshPage?: () => void;
};

const AdminCreateSubCategoryForm: React.FC<ICreateSubCategoryForm> = ({
  selectedCategoryID,
  categoryDataSubCategory,
  isEditMode = false,
  refreshPage,
}) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [payLoad, setPayLoad] = useState<any>({});
  const [loadApi, setLoadApi] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const adminButtonText = isEditMode
    ? "Edit Sub Category"
    : "Create Sub Category";
  // This is use to Update Form if in Edit Mode
  useEffect(() => {
    setPayLoad({ ...categoryDataSubCategory });
  }, []);

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPayLoad({
      ...payLoad,
      siteCategoryId: selectedCategoryID,
    });
    setFormLoading(true);
    setLoadApi(true);
  };

  // A custom hook to format the Sign Up Api
  const result = useFormatApiRequest(
    () =>
      isEditMode
        ? adminUpdateSubCategory(categoryDataSubCategory?.id || 0, payLoad)
        : adminAddSubCategory(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      // Handle Success Here
      openNotificationWithIcon(
        "success",
        "",
        "Category Sub Category Created Succesful"
      );
      if (refreshPage) {
        refreshPage();
      }
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      // Handle Error Here
      openNotificationWithIcon(
        "error",
        "",
        result.data?.response?.data?.message || result.errorMsg
      );
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        <div style={{ paddingTop: "10px" }}>
          <form onSubmit={handleSubmit}>
            {/* Sub Category Name*/}
            <div className="w3-col w3-margin-bottom">
              <span> Sub Category Name </span>
              <Input
                required
                name="title"
                value={payLoad?.title}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Sub Category Name"
              />
            </div>

            {/* Button */}
            <div className="w3-col w3-margin-bottom w3-margin-top">
              <button
                disabled={formLoading}
                className="w3-btn w3-col submitButton"
              >
                <span className="submitButtonText">
                  {!formLoading ? (
                    adminButtonText
                  ) : (
                    <LoadingOutlined rev={undefined} />
                  )}
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminCreateSubCategoryForm;
