import { Button, Result, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getAdmin } from "../../../../apiservice/admin-AuthService";
import { IAdminListsData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import AdminUserLog from "../Admin-User-Log/Admin-User-Log";
import AdminDetailsCard from "./Admin-Details-Card";
import "./Admin-Details-Wrapper.css";

export const AdminDetailsWrapper = () => {
  const [adminUserLoadState, setAdminUserLoadState] =
    useState<ILoadState>("loading");
  const [loadCurrentAdminData, setLoadCurrentAdminData] = useState(true);
  const [adminUserData, setAdminUserData] = useState<IAdminListsData | null>(
    null
  );
  const params = useParams();

  // A custom hook to Load Current/Active Portal based on the Url
  const resultCurrentAdminData = useFormatApiRequest(
    () => getAdmin(Number(params?.id)),
    loadCurrentAdminData,
    () => {
      setLoadCurrentAdminData(false);
    },
    () => {
      processCurrentAdminResult();
    }
  );

  // Process The Current Portal Data Result
  const processCurrentAdminResult = async () => {
    if (resultCurrentAdminData.httpState === "SUCCESS") {
      setAdminUserLoadState("completed");
      setAdminUserData(resultCurrentAdminData?.data?.data);
    } else if (resultCurrentAdminData.httpState === "ERROR") {
      setAdminUserLoadState("error");
    }
  };

  return (
    <>
      {/* " Show Loading Indicator" */}
      {adminUserLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {adminUserLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadCurrentAdminData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {adminUserLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken create a new portal."
            extra={<Button type="primary">Go Back</Button>}
          />
        </div>
      )}

      {/* " Display form when Portal Load State is Not loading for new form entry & completed for editing old entry" */}
      {(adminUserLoadState === "notLoading" ||
        adminUserLoadState === "completed") && (
        <div className="w3-col w3-padding-bottom">
          {/* <PortalDetailsCard onEditClick={setIndex}></PortalDetailsCard> */}
          <AdminDetailsCard
            adminUserData={adminUserData}
            refreshPage={() => {
              setLoadCurrentAdminData(true);
            }}
          ></AdminDetailsCard>

          <AdminUserLog
            initialDefaultFilter={{
              adminId: adminUserData?.id,
              page: 1,
              perPage: 10,
            }}
          ></AdminUserLog>
        </div>
      )}
    </>
  );
};

export default AdminDetailsWrapper;
