import { Link } from "react-router-dom";
import { IOfferPortalsData } from "../../../../apiservice/offers.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import "./AddOffer-Portal-Details.css";

type IAddOfferPortalDetailsCard = {
  offerPortalData?: IOfferPortalsData;
};

export const AddOfferPortalDetailsCard: React.FC<
  IAddOfferPortalDetailsCard
> = ({ offerPortalData }) => {
  const selectedOfferPortalData: IOfferPortalsData =
    useAppSelector((state: RootState) => state?.SelectedOfferPortal) ||
    offerPortalData;

  return (
    <>
      <div className="w3-col w3-padding w3-padding-bottom">
        <div className="w3-col w3-margin-bottom" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "15px", height: "60px" }}
          >
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <span className="portalNameHeader">Portal Name and Info</span>
              <br />
              <span className="portalNameValue">
                {selectedOfferPortalData?.domainName}
              </span>
            </div>
            <div
              className="w3-col l6 s6 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              <Link
                to="/advertiser/offer-add-select-Portal"
                className="w3-btn backBtn"
              >
                Back
              </Link>
            </div>
          </div>

          <div
            className="w3-col w3-round-xlarge w3-margin-bottom"
            style={{ padding: "5px", paddingTop: "15px" }}
          >
            <span className="descriptionText">
              {selectedOfferPortalData?.siteDescription}
            </span>
          </div>

          <div
            className="w3-col w3-margin-bottom"
            style={{ paddingTop: "15px" }}
          >
            <div className="w3-col l6 s12 m12" style={{ padding: "5px" }}>
              {/* Website Name */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle"> Website Name</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.websiteName}
                  </span>
                </div>
              </div>
              {/* Website Name */}

              {/* Domain Name */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle"> Domain Name</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.domainName}
                  </span>
                </div>
              </div>
              {/* Domain Name */}

              {/* Website Language */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Website Language</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.websiteLanguage}
                  </span>
                </div>
              </div>
              {/* Website Language */}

              {/* Site Niche */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Site Niche</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.siteNiche?.title}
                  </span>
                </div>
              </div>
              {/* Site niche */}

              {/* Website Role */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Website Role</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.websiteRole}
                  </span>
                </div>
              </div>
              {/* Website Role */}

              {/* Domain Authority */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Domain Authority</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.domainAuthority}
                  </span>
                </div>
              </div>
              {/* Domain Authority */}

              {/* Domain Rating */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Domain Rating </span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.domainRating}
                  </span>
                </div>
              </div>
              {/* Domain Rating*/}

              {/* Minimum Word Count */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Minimum Word Count</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.minimumWordCount}
                  </span>
                </div>
              </div>
              {/* Minimum Word Count */}
            </div>

            <div className="w3-col l6 s12 m12" style={{ padding: "5px" }}>
              {/* Website Category*/}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle"> Category</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue"></span>
                </div>
              </div>
              {/* Website Category */}

              {/* Leading Country */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Leading Country </span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.leadingCountry}
                  </span>
                </div>
              </div>
              {/* Leading country */}

              {/* Marked Sponsor */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Marked Sponsored </span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.markedSponsored + ""}
                  </span>
                </div>
              </div>
              {/* Marked Sponsor */}

              {/* Linked Type*/}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Link Type</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.linkTypeId}
                  </span>
                </div>
              </div>
              {/* Link Type */}

              {/* Turn Around Time */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Turn Around Time</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.turnAroundTime}
                  </span>
                </div>
              </div>
              {/* Turn Around Time */}

              {/* Spam Score */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Spam Score</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.spamScore}
                  </span>
                </div>
              </div>
              {/* Spam Score */}

              {/* Organic Traffic */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Organic Traffic</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.organicTraffic}
                  </span>
                </div>
              </div>
              {/* Organic Traffic */}

              {/* Maximum Link Count */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextTitle">Maximum Link Count</span>
                </div>
                <div className="w3-col l6 s6 m6">
                  <span className="otherTextValue">
                    {selectedOfferPortalData?.maximumLinkCount}
                  </span>
                </div>
              </div>
              {/* Maximum Link Count */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOfferPortalDetailsCard;
