import { BellOutlined } from "@ant-design/icons";
import { Button, Empty, notification, Pagination, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { getAdminNotifications } from "../../../../apiservice/admin-AuthService";
import { IAdminNotificationData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import useAuth from "../../../../hooks/useAuth";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./notifications-list.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminNotificationsList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const AdminNotificationsList: React.FC<IAdminNotificationsList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [notificationLoadState, setNotificationLoadState] =
    useState<ILoadState>("loading");
  const [loadNotificationsData, setLoadNotificationsData] = useState(true);
  const [notificationDefaultFilter, setNotificationDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10, setAsRead: true }
  );
  const [tableData, setTableData] = useState<IAdminNotificationData[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setNotificationDefaultFilter({
        ...notificationDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadNotificationsData(true);
      setNotificationLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Notifications Details
  const notificationDataResult = useFormatApiRequest(
    () => getAdminNotifications(notificationDefaultFilter),
    loadNotificationsData,
    () => {
      setLoadNotificationsData(false);
    },
    () => {
      processNotificationsResult();
    }
  );

  // Process The Current Notification Data Result
  const processNotificationsResult = async () => {
    if (notificationDataResult.httpState === "SUCCESS") {
      setTableData(notificationDataResult.data?.data);
      setTotalItems(notificationDataResult.data?.meta?.total || 1);
      setLoadAuth(true);
      setNotificationLoadState("completed");
    } else if (notificationDataResult.httpState === "ERROR") {
      setNotificationLoadState("error");
    } else if (notificationDataResult.httpState === "LOADING") {
      setNotificationLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setNotificationDefaultFilter({
      ...notificationDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadNotificationsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        {/* " Show Loading Indicator" */}
        {notificationLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {notificationLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title="Error"
              subTitle="Sorry, something went wrong, it could be a network Related error"
              extra={
                <Button
                  onClick={() => setLoadNotificationsData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {notificationLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show No data" */}
        {notificationLoadState === "completed" && (
          <>
            {tableData.map((item: IAdminNotificationData, index: number) => (
              <div
                key={index}
                style={{ marginBottom: "30px" }}
                className=" w3-col w3-margin-top w3-margin-bottom"
              >
                <div className="w3-col" style={{ width: "60px" }}>
                  <span className="notificationIcon">
                    <BellOutlined ref={undefined} />
                  </span>
                </div>
                <div className="w3-col w3-right" style={{ width: "150px" }}>
                  <span className="notificationTitle">
                    {convertToShortDate(item.dateCreated)}
                  </span>
                </div>
                <div className="w3-rest">
                  <span className="notificationTitle">{item.title}</span>
                  <br />
                  <span className="notificationSubTitle">{item.message}</span>
                </div>
              </div>
            ))}

            {!hidePagination && (
              <div className="w3-col w3-margin-top">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default IAdminNotificationsList;
