import {
  DeleteOutlined,
  ExclamationCircleFilled,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Button, Modal, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { adminGetPortals } from "../../../../apiservice/admin-Users-Clone-Service";
import { IAdminPortalsData } from "../../../../apiservice/admin-Users-clone.type";
import {
  portalDelete,
  portalActivate,
} from "../../../../apiservice/portals.service";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { appZIndex } from "../../../../utils/appconst";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./AdminPortalList.css";
type NotificationType = "success" | "info" | "warning" | "error";

const tagColorsMatch = {
  Approved: "green",
  Pending: "gold",
  NotApproved: "red",
};

type IPortalsList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const AdminPortalList: React.FC<IPortalsList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [portalLoadState, setPortalLoadState] = useState<ILoadState>("loading");
  const [loadPortalsData, setLoadPortalsData] = useState(true);
  const [loadDeletePortal, setLoadDeletePortals] = useState(false);
  const [loadDeActivatePortal, setLoadDeActivatePortal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [portalDefaultFilter, setportalDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const { confirm } = Modal;

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setportalDefaultFilter({
        ...portalDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadPortalsData(true);
      setPortalLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const PortalDataResult = useFormatApiRequest(
    () => adminGetPortals(portalDefaultFilter),
    loadPortalsData,
    () => {
      setLoadPortalsData(false);
    },
    () => {
      processPortalsResult();
    }
  );

  // Process The Current Portal Data Result
  const processPortalsResult = async () => {
    if (PortalDataResult.httpState === "SUCCESS") {
      setTableData(PortalDataResult.data?.data);
      setTotalItems(PortalDataResult.data?.meta?.total || 1);
      setPortalLoadState("completed");
    } else if (PortalDataResult.httpState === "ERROR") {
      setPortalLoadState("error");
    } else if (PortalDataResult.httpState === "LOADING") {
      setPortalLoadState("loading");
    }
  };

  //Delete Confirmation
  const showDeleteConfirm = (itemId?) => {
    setSelectedItemId(itemId);
    confirm({
      title: "Are you sure you want to delete this Portal?",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        setLoadDeletePortals(true);
        setPortalLoadState("loading");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //Activate/Deactivate Confirmation
  const showDeActivateConfirm = (itemId?) => {
    setSelectedItemId(itemId);
    confirm({
      title: "Are you sure you want to deactivate this Portal?",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Delete Portal
  const deletePortalApiResult = useFormatApiRequest(
    () => portalDelete(selectedItemId),
    loadDeletePortal,
    () => {
      setLoadDeletePortals(false);
    },
    () => {
      processDeletePortal();
    }
  );

  // Process The Delete Portal Result
  const processDeletePortal = async () => {
    if (deletePortalApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "Your Portal has being deleted");
      // Reload all portal again
      setLoadPortalsData(true);
    } else if (deletePortalApiResult.httpState === "ERROR") {
      setPortalLoadState("completed");
      openNotificationWithIcon("error", "", "Error Deleting your portal");
    }
  };

  // A custom hook to Activate/Deactivate Portal
  const deActivatePortalApiResult = useFormatApiRequest(
    () => portalActivate(selectedItemId),
    loadDeActivatePortal,
    () => {
      setLoadDeActivatePortal(false);
    },
    () => {
      processDeActivatePortal();
    }
  );

  // Process The Activate/Deactivate Portal Result
  const processDeActivatePortal = async () => {
    if (deActivatePortalApiResult.httpState === "SUCCESS") {
    } else if (deActivatePortalApiResult.httpState === "ERROR") {
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<IAdminPortalsData> = [
    {
      title: "S/N",
      width: 60,
      dataIndex: "sn",
      key: "sn",
      render: (text, records, index) => <span>{index + 1}</span>,
    },
    {
      title: "Portal Name",
      dataIndex: "websiteName",
      key: "websiteName",
      render: (text, records, index) => (
        <Link to={`/adminpath/manage-publisher-portal/${records.id}`}>
          <u>{text}</u>
        </Link>
      ),
    },
    {
      title: "Domain Name",
      dataIndex: "domainName",
      key: "domainName",
    },
    {
      title: "Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (date) => <span>{convertToShortDate(date)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <Tag
          className="tagText"
          color={
            tagColorsMatch[text || "Pending"]
              ? tagColorsMatch[text || "Pending"]
              : "error"
          }
        >
          {text || "Pending"}
        </Tag>
      ),
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setportalDefaultFilter({
      ...portalDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadPortalsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {portalLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadPortalsData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {portalLoadState !== "error" && (
        <>
          <Table
            loading={portalLoadState === "loading"}
            rowKey="id"
            size="small"
            columns={columns}
            scroll={{ x: 950 }}
            dataSource={tableData}
            pagination={{ hideOnSinglePage: true }}
          />

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AdminPortalList;
