import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import "./Home.css";
import { useEffect } from "react";

export const HomePage = () => {
  type IhowItworksType = {
    header: string;
    Content: string;
  };

  type IAdvertiserBenefit = {
    Content: string;
  };

  type IWhatweOffer = {
    icon?: string;
    header: string;
    Content: string;
  };

  type IReviewItems = {
    image: string;
    starIcon: string;
    caption: string;
    name: string;
    jobTitle: string;
  };

  const advertiserContent = [
    "Whether you want to grow authority, make sales, or attract customers. With our matching system, we match you with relevant websites with your target audience within your niche to amplify your brand's visibility",
  ];

  const publisherContent = [
    "Publishers! On our platform, turning your online presence into a reliable source of revenue is simpler than ever. Linkmie connects you with advertisers in your niche who are eager to collaborate, and we ensure a seamless collaboration process.",
  ];

  const howItWorkText: IhowItworksType[] = [
    {
      header: "Content",
      Content:
        "Do you want a wide range of content tailored to your specific needs? We have the right team to create your desired content, including articles, guest posts, marketing copies, graphics, etc. Our team of creators creates high-quality, well-researched, and engaging content for you to generate engagement, boost conversions, and achieve your advertising objectives.",
    },
    {
      header: "Advertiser",
      Content:
        "Why pay for fake traffic when you can get real visitors? We put your ads where they convert the most. Creating a profile on Linkmie gives you access to over 700k+ global authority websites. You get to place ads and order high-quality guest posts to build links and Authority, as Linkmie will match you with the most relevant websites tailored to your needs as a brand.",
    },
    {
      header: "Publisher",
      Content:
        "Registering on the platform offers you a friendly work environment that gives you the avenue to earn revenue doing what you love: creating and placing content. You create a free profile on Linkmie and upload your details. Linkmie connects you with the right advertisers to start earning right away.",
    },
  ];

  const whatWeOffer: IWhatweOffer[] = [
    {
      icon: "",
      header: "Guest Posting",
      Content:
        "Guest posting boosts brand visibility and credibility, leading to more traffic, stronger audience relationships, and industry authority. We offer you collaboration with industry-specific websites to boost your online presence and establish yourself as an authority in your niche.",
    },
    {
      icon: "",
      header: "Link Insertions",
      Content:
        "Having high-quality backlinks helps your search engine optimisation (SEO) and boosts organic traffic to your website. So, we offer you access to building credible and authoritative links from trustworthy publishers to increase website credibility and climb search engine ranks.",
    },
    {
      icon: "",
      header: "Content Writing",
      Content:
        "We offer a team of experienced writers to provide quality marketing copy, engaging blog posts, and articles. Get engaging content that will resonate with your target customers by working with our top-notch writers to produce high-quality articles tailored to your brand needs.",
    },
    {
      icon: "",
      header: "Ads Placement",
      Content:
        "Our platform offers you a simplified process for placing product advertisements in strategic spots on websites, allowing you to increase visibility, reach your target customers, and boost sales and conversions.",
    },
  ];

  const publisherKnowlederBullet = [
    "As a publisher, you’ll receive high-quality SEO-friendly articles that will increase your SEO score and site metrics.",
    "You can earn on Linkmie by accepting ads and guest posting on your website.",
    "Linkmie is transparent and has a reliable payment payout structure (Same-day payout!)",
    "Linkmie gives you complete control over what content and ads you accept and place on your website",
  ];

  const advertiserBenefit: IAdvertiserBenefit[] = [
    {
      Content:
        "We offer you over 700k+ high-traffic verified Authority websites to choose from",
    },
    {
      Content:
        "Track your ads and post-performance with our powerful analytics available on your dashboard.",
    },
    {
      Content:
        "Boost your Domain Authority and build links that convert to revenue and brand visibility.",
    },
    {
      Content:
        "We build trust by acting as an escrow for you. The publisher is only paid when you confirm services.",
    },
  ];

  const reviewsItems: IReviewItems[] = [
    {
      image: "/images/testimonyImage1.svg",
      starIcon: "/images/testimonystar.svg",
      caption:
        "When it comes to content creation, I would recommend Linkmie without reservation. Over the years, I've used several other content writing services, but none compare to Linkmie. They provided exceptional articles for my website, showing that they extensively researched my company and target market. Working with their creative authors was truly a breath of fresh air!",
      name: "Jenny Wilson",
      jobTitle: "Grower.blogger",
    },
    {
      image: "/images/testimonyImage2.svg",
      starIcon: "/images/testimonystar.svg",
      caption:
        "What I have accomplished with Linkmie is beyond praise. As a marketer, I always search for useful tools, and Linkmie has far exceeded my expectations. It has completely changed the game for me to identify website owners ready to host my ads and insert relevant links. It's unusual to find a user-friendly advertising solution covering every angle.",
      name: "Devon Lane",
      jobTitle: "DLDesign.co",
    },
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "http://code.tidio.co/ut1uy5chjphoti0znreqy6llo2hblvnv.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="w3-white w3-col" style={{ minHeight: "400px" }}>
        {/* Hero Section */}
        <div className="heroSectionbg w3-col">
          <div className="w3-content">
            <div className="w3-container">
              <div className="w3-col l7 s12 m6">
                <h2 className="heroHeaderText">
                  Advertise
                  <span className="heroHeaderTextBlue"> on websites </span>
                  <br />{" "}
                  <span className="heroHeaderTextRed">around the globe </span>
                  with &nbsp;
                  <span className="heroHeaderTextGold"> Linkmie </span>
                </h2>
                <p className="herocontent">
                  {/* “    with .”
                  <br /> */}
                  At Linkmie, we have an all-inclusive solution for publishers
                  and advertisers to guest post, place ads on relevant sites,
                  and get high-quality marketing content.
                </p>

                <p style={{ marginTop: "50px" }}>
                  <Link className="heroRegisterButton" to={"/userauth/signup"}>
                    <span className="heroRegisterButtonText">
                      Register with us
                    </span>
                  </Link>
                </p>
              </div>

              <div className="w3-col l5 s12 m6">
                <Slide right>
                  <img
                    style={{ width: "100%" }}
                    alt="heroImage"
                    src={`${
                      process.env.PUBLIC_URL +
                      "/images/landinghomeheroimage.svg"
                    }`}
                  />
                </Slide>
              </div>
            </div>
          </div>
        </div>
        {/* End Hero Section */}

        {/* Advert/Publisher Section */}
        <div className="w3-col advertPublisherBg">
          <div className="w3-content">
            <div className="w3-container">
              <div className="w3-col l6 s12 m6 w3-padding">
                <Slide left>
                  <div>
                    <h2 className="advertiserHeader">
                      Advertise your content,
                    </h2>
                    <br />
                    <h2 className="advertiserSubHeader">
                      let the world see it
                    </h2>
                    <br /> <br />
                    {advertiserContent.map((item: string, index: number) => (
                      <span key={index}>
                        <span className="advertTextHolder">
                          <img
                            style={{ width: "20px" }}
                            alt="heroImage"
                            src={`${
                              process.env.PUBLIC_URL +
                              "/images/landingcheck.svg"
                            }`}
                          />
                          &nbsp;
                          <span className="advertiserText">{item}</span>
                        </span>
                        <br /> <br />
                      </span>
                    ))}
                  </div>
                </Slide>
              </div>

              <div className="w3-col l6 s12 m6 w3-padding">
                <Slide right>
                  <div>
                    <h2 className="advertiserHeader">Get paid by,</h2>
                    <br />
                    <h2 className="advertiserSubHeader">publishing Content</h2>
                    <br /> <br />
                    {publisherContent.map((item: string, index: number) => (
                      <span key={index}>
                        <span className="advertTextHolder">
                          <img
                            style={{ width: "20px" }}
                            alt="heroImage"
                            src={`${
                              process.env.PUBLIC_URL +
                              "/images/landingcheck.svg"
                            }`}
                          />
                          &nbsp;
                          <span className="advertiserText">{item}</span>
                        </span>
                        <br /> <br />
                      </span>
                    ))}
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
        {/* Advert/Publisher  Hero Section */}

        {/* How it works */}
        <div className="w3-col howItworksBg">
          <img
            style={{ width: "220px", maxWidth: "100%" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/howitworksdots.svg"}`}
          />
          <div className="w3-content" style={{ marginTop: "100px" }}>
            <div className="w3-container">
              <div className="w3-col l6 s12 m6 w3-center">
                <Bounce left>
                  <img
                    style={{
                      width: "80%",
                      marginTop: "80px",
                      marginBottom: "50px",
                    }}
                    alt="heroImage"
                    src={`${
                      process.env.PUBLIC_URL + "/images/howitworksimage.svg"
                    }`}
                  />
                </Bounce>
              </div>
              <div className="w3-col l6 s12 m6">
                <div className="w3-col l2 s2 m2">
                  <br />
                </div>

                <div className="w3-col l8 s8 m8">
                  <div style={{ marginBottom: "60px" }}>
                    <h2 className="howItworksTitle w3-center">How it works</h2>
                  </div>

                  {howItWorkText.map((item: IhowItworksType, index: number) => (
                    <Fade bottom>
                      <div
                        key={index}
                        className="w3-border w3-round w3-border-blue"
                        style={{
                          padding: "15px",
                          position: "relative",
                          marginBottom: "50px",
                        }}
                      >
                        <div className="w3-center howItworkHeaderWrapper">
                          <span className="howItworkHeader howItworkHeaderText">
                            {item.header}
                          </span>
                        </div>
                        <p className="howItworkContent">{item.Content}</p>
                      </div>
                    </Fade>
                  ))}
                </div>

                <div className="w3-col l2 s2 m2">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* How it works */}

        {/* What we offer */}
        <div className="w3-col whatWeOfferBg">
          <div className="w3-content">
            <div
              className="w3-container"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <h2 className="w3-center whatWeOfferHeader w3-padding">
                What we can offer you ?
              </h2>

              {whatWeOffer.map((item: IhowItworksType, index: number) => (
                <Fade bottom>
                  <div
                    key={index}
                    className="w3-col l4 s12 m6"
                    style={{ padding: "20px", height: "350px" }}
                  >
                    <img
                      style={{ width: "120px" }}
                      alt="heroImage"
                      src={`${
                        process.env.PUBLIC_URL +
                        "/images/landingwhatwecanofferIcon.svg"
                      }`}
                    />
                    <br />
                    <br />
                    <h3 className="whatWeOfferTitle"> {item.header} </h3>
                    <p className="whatWeOffertext">
                      <br />
                      {item.Content}
                    </p>
                  </div>
                </Fade>
              ))}
            </div>
          </div>
        </div>
        {/* What we offer */}

        {/* What You need to know about  Publisher */}
        <div className="w3-col whatToKnowPublisherBg">
          <div className="w3-content">
            <div
              className="w3-container"
              style={{ paddingTop: "50px", paddingBottom: "50px" }}
            >
              <div className="w3-col l6 s12 m6 w3-center">
                <Slide left>
                  <img
                    style={{
                      width: "80%",
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                    alt="heroImage"
                    src={`${
                      process.env.PUBLIC_URL +
                      "/images/landingpublisherneedtoknow.svg"
                    }`}
                  />
                </Slide>
              </div>
              <div className="w3-col l6 s12 m6">
                <Slide right>
                  <div>
                    <div style={{ marginBottom: "30px" }}>
                      <h2 className="whatToKnowPublisherHeader">
                        What you need to know, <br /> as a publisher
                      </h2>
                    </div>
                    {publisherKnowlederBullet.map(
                      (item: string, index: number) => (
                        <div className="w3-col l6 s6 m6" key={index}>
                          <span className="whatToKnowPublisherBulletHolder">
                            <img
                              style={{ width: "20px" }}
                              alt="heroImage"
                              src={`${
                                process.env.PUBLIC_URL +
                                "/images/landinghowitworkCheck.svg"
                              }`}
                            />
                            &nbsp;
                            <span className="whatToKnowPublisherBullet">
                              {item}
                            </span>
                          </span>
                          <br /> <br />
                        </div>
                      )
                    )}
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
        {/* What You need to know about  Publisher  */}

        {/* Benefit of Advertiser */}
        <div className="w3-col advertiserBenefitBg">
          <div className="w3-content">
            <div className="w3-container">
              <div className="w3-col">
                <div className="w3-col l2 s1 m2">
                  <br />
                </div>
                <div className="w3-col l8 s10 m8">
                  <div className="w3-col">
                    <h2 className="w3-center advertiserBenefitHeader">
                      What you need to know, <br /> as a advertiser
                    </h2>
                  </div>

                  <div className="w3-col">
                    {advertiserBenefit.map(
                      (item: IAdvertiserBenefit, index: number) => (
                        <Fade bottom>
                          <div
                            key={index}
                            className="w3-col l6 s12 m6"
                            style={{ padding: "20px" }}
                          >
                            <div className="w3-border w3-white w3-round-large w3-padding">
                              <div className="w3-col" style={{ width: "20px" }}>
                                <img
                                  style={{ width: "20px" }}
                                  alt="heroImage"
                                  src={`${
                                    process.env.PUBLIC_URL +
                                    "/images/landinghowitworkCheck.svg"
                                  }`}
                                />
                              </div>
                              <div
                                className="w3-rest"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                              >
                                <span className="advertiserBenefitBulletText">
                                  {item.Content}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Fade>
                      )
                    )}
                  </div>
                </div>
                <div className="w3-col l2 s1 m2">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Benefit of Advertiser  */}

        {/* Review from client  */}
        <div className="w3-col reviewBg">
          <div className="w3-content">
            <div
              className="w3-container"
              style={{ paddingTop: "50px", paddingBottom: "50px" }}
            >
              <div className="w3-col">
                <h2 className="w3-center advertiserBenefitHeader">
                  Review from our client
                </h2>
              </div>

              <div className="w3-col">
                {reviewsItems.map((item: IReviewItems, index: number) => (
                  <Fade bottom>
                    <div
                      key={index}
                      className="w3-col l6 s12 m6"
                      style={{ padding: "20px" }}
                    >
                      <div className="w3-col w3-white">
                        <div className="w3-col" style={{ width: "140px" }}>
                          <img
                            style={{ width: "140px", marginTop: "10px" }}
                            alt="heroImage"
                            src={`${process.env.PUBLIC_URL + item.image}`}
                          />
                        </div>
                        <div
                          className="w3-rest"
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "5px",
                          }}
                        >
                          <img
                            style={{ width: "120px" }}
                            alt="heroImage"
                            src={`${process.env.PUBLIC_URL + item.starIcon}`}
                          />
                          <br />
                          <br />
                          <p className="reviewCaption">{item.caption}</p>
                          <div>
                            <span className="reviewName"> {item.name} </span>
                            <span
                              className="reviewJobTitle"
                              style={{ float: "right" }}
                            >
                              {item.jobTitle}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Review from client  */}
        {/* Tidio live chat button */}
        <div className="support-container"></div>
      </div>
    </>
  );
};

export default HomePage;
