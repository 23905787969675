import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Alert, Input, Tooltip } from "antd";
import { useState } from "react";
import { advertiserAddLinkInsertionOffer } from "../../../../apiservice/offers.service";
import {
  IOfferFormResponse,
  IOfferPortalsData,
  IOfferPricing,
} from "../../../../apiservice/offers.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import { appZIndex } from "../../../../utils/appconst";
import "./OfferForm.css";

type IOfferFormOne = {
  onFormSuccess?: (resultData?: IOfferFormResponse) => void;
  onFormFailure?: (err?: string) => void;
};

export const OfferFormOne: React.FC<IOfferFormOne> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const [selectedCategoryPricing, setSelectedCategoryPricing] =
    useState<IOfferPricing | null>(null);

  const selectedOfferPortalData: IOfferPortalsData = useAppSelector(
    (state: RootState) => state?.SelectedOfferPortal
  );

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));

    // If categoryId changes then get the current offer portal price Object
    if (name === "categoryId") {
      if (value) {
        setSelectedCategoryPricing(getSelectedCategoryPricing(value));
      } else {
        setSelectedCategoryPricing(null);
      }
    }
  };

  // Get the selected Offer Pricing
  const getSelectedCategoryPricing = (id: number) => {
    return (
      selectedOfferPortalData.offerPricings.filter(
        (offerPricing) => offerPricing.id === Number(id)
      )?.[0] || null
    );
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (selectedCategoryPricing?.price.linkInsertion) {
      setpayLoad({
        ...payLoad,
        portalId: selectedOfferPortalData.id,
        amount: selectedCategoryPricing?.price.linkInsertion,
      });
      setLoadApi(true);
      setFormLoading(true);
    } else {
      alert("Please Invalid.....");
    }
  };

  // A custom hook to save form data
  const result = useFormatApiRequest(
    () => advertiserAddLinkInsertionOffer(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess(result.data);
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Forms Here */}
        <div>
          {/* Select Category */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Choose Categeory <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title=" Choose a relevant category for your website to help advertisers find the right audience for their ads."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <select
                required
                name="categoryId"
                value={payLoad?.categoryId || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputSelect"
              >
                <option value="">Select Category</option>
                {selectedOfferPortalData.offerPricings.map(
                  (item: IOfferPricing, index: number) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          {/* Catgory Details Box */}
          <div className="w3-col w3-margin-bottom">
            <Alert
              description={
                <span>
                  {selectedCategoryPricing
                    ? selectedCategoryPricing?.description
                    : "-----"}
                  <br />
                  <span>
                    {" "}
                    Link Insertion price for the Category{" "}
                    {selectedCategoryPricing && selectedCategoryPricing?.title}
                    {" is "}
                    {"$"}
                    {selectedCategoryPricing
                      ? selectedCategoryPricing?.price.linkInsertion
                      : "0"}
                  </span>
                </span>
              }
              style={{ width: "92%" }}
              type="info"
              showIcon
            />
          </div>

          {/* Add Link */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Add Link <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Add a new link to provide advertisers with opportunities to showcase their content on your website."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="linkUrl"
                value={payLoad?.linkUrl || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Add Link"
              />
            </div>
          </div>

          {/* Anchor Text */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Add Anchor Text <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Enter the anchor text for the link. It is the clickable text that users will see."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="anchorText"
                value={payLoad?.anchorText || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Anchor Text"
              />
            </div>
          </div>

          {/* Page Link */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Page Link (Optional)
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Specify the link to the page where the ad will be placed. It should be accurate and functional."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                name="pageLink"
                value={payLoad?.pageLink || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Page Link (Optional)"
              />
            </div>
          </div>

          {/* Description */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Page Description <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Describe the page where the ad will be placed, detailing its content and audience."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <textarea
                required
                name="description"
                value={payLoad?.description || ""}
                style={{ width: "90%", height: "200px", resize: "none" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Description"
              ></textarea>
            </div>
          </div>
        </div>
        {/* Button Here */}
        <div
          className="w3-padding"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <div className="w3-col w3-border w3-border-blue w3-padding w3-container w3-round-xlarge">
            <div
              className="w3-col l6 s12 m6"
              style={{ padding: "5px", paddingTop: "15px" }}
            >
              Total{" $"}
              {selectedCategoryPricing
                ? selectedCategoryPricing?.price.linkInsertion
                : "0"}
            </div>
            <div
              className="w3-col l6 s12 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              <button className="nextButton w3-btn" disabled={formLoading}>
                {!formLoading ? (
                  "Make Payment"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OfferFormOne;
