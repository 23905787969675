import { LoadingOutlined } from "@ant-design/icons";
import { Collapse, Input } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { portalFormFourSave } from "../../../apiservice/portals.service";
import {
  IPortalFormType,
  IPortalPricingsCategory,
} from "../../../apiservice/portals.type";
import { getSiteCategories } from "../../../apiservice/site-divisions.service";
import { ICategoryData } from "../../../apiservice/site-divisions.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppDispatch, useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import PortalFormPricing, {
  IOnChangeIPortalFormPricing,
} from "./PortalForm-Pricing";
import "./PortalForm.css";

type IPortalFormFour = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
  onPrevClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const PortalFormFour: React.FC<IPortalFormFour> = ({
  onFormFailure,
  onFormSuccess,
  onPrevClick,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [pricingArray, setPricingArray] = useState<
    IOnChangeIPortalFormPricing[]
  >([]);
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const [loadCategoryApi, setLoadCategoryApi] = useState(false);
  const [categoryData, setCategoryData] = useState<
    ICategoryData[] | IPortalPricingsCategory[]
  >([]);
  const [generalCategoryData, setGeneralCategoryData] = useState<
    ICategoryData[]
  >([]);

  const dispatch = useAppDispatch();
  const portalFormData: IPortalFormType = useAppSelector(
    (state: RootState) => state?.PortalForm
  );
  const navigate = useNavigate();

  // This is use to Update Redux Dispatch Values
  useEffect(() => {
    if (!portalFormData.stepOne?.websiteName) {
      dispatch({ type: "PORTAL_UPDATE_STAGE", payload: 0 });
      navigate("/publisher/portals");
    }

    const stageFourData: {
      pricings: IOnChangeIPortalFormPricing[];
      portalPricingsCategory: IPortalPricingsCategory[];
    } = portalFormData.stepFour;

    if (stageFourData?.pricings) {
      setPricingArray([...stageFourData?.pricings]);
      setpayLoad({ pricings: stageFourData?.pricings });
    }
    if (
      stageFourData?.portalPricingsCategory &&
      stageFourData?.portalPricingsCategory.length
    ) {
      splitCategory([...stageFourData?.portalPricingsCategory]);
    } else {
      setLoadCategoryApi(true);
    }

    // console.log(stageFourData);
  }, []);

  // Use to collect Site Description Change
  const handleInputChange = (val: IOnChangeIPortalFormPricing) => {
    // // console.log(getIDIndex(event.id));
    if (getIDIndex(val.id) === -1) {
      pricingArray.push(val);
    } else {
      pricingArray[getIDIndex(val.id)] = val;
    }
    setpayLoad({ pricings: pricingArray });
    // // console.log(pricingArray);
    // // console.log(payLoad);
  };

  const getIDIndex = (id: number | string) => {
    return pricingArray.findIndex(
      (payloadArrayValue) => payloadArrayValue.id === id
    );
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch({ type: "PORTAL_UPDATE_STEP_FOUR_DATA", payload: payLoad });
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => portalFormFourSave(Number(portalFormData.id), payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  // A custom hook to Load Sub Category from the server
  const categoryResult = useFormatApiRequest(
    () => getSiteCategories(),
    loadCategoryApi,
    () => {
      setLoadCategoryApi(loadCategoryApi);
    },
    () => {
      processCategoryResultApi();
    }
  );

  // Process Site Sub Category Result
  const processCategoryResultApi = async () => {
    if (categoryResult.httpState === "SUCCESS") {
      const categories: ICategoryData[] = categoryResult.data?.data;
      splitCategory(categories);
    } else if (categoryResult.httpState === "ERROR") {
      // console.log(categoryResult.errorMsg);
    }
  };

  // Split Categories to two part
  const splitCategory = async (
    categoryData: ICategoryData[] | IPortalPricingsCategory[]
  ) => {
    const generalCategoryIndex = categoryData.findIndex(
      (category) => category.title === "General"
    );

    const generalCategory = categoryData.splice(generalCategoryIndex, 1);
    setGeneralCategoryData(generalCategory);
    setCategoryData(categoryData);
    // console.log(categoryResult.data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Forms Here */}
        <div>
          <div className="w3-col w3-margin-bottom w3-padding">
            General Category
          </div>

          <div className="w3-col">
            {generalCategoryData.map(
              (
                item: ICategoryData & IPortalPricingsCategory,
                index: number
              ) => (
                <PortalFormPricing
                  key={index}
                  required={true}
                  id={item.id}
                  description={item.description}
                  onFormChange={handleInputChange}
                  initialValue={item?.price}
                ></PortalFormPricing>
              )
            )}
          </div>

          <div className="w3-col">
            <Collapse accordion>
              {categoryData.map(
                (
                  item: ICategoryData & IPortalPricingsCategory,
                  index: number
                ) => (
                  <CollapsePanel
                    className="w3-container"
                    key={index}
                    header={item.title}
                  >
                    <div className="w3-container">
                      <PortalFormPricing
                        id={item.id}
                        onFormChange={handleInputChange}
                        description={item?.description}
                        initialValue={item?.price}
                      ></PortalFormPricing>
                    </div>
                  </CollapsePanel>
                )
              )}
            </Collapse>
          </div>
        </div>
        {/* Button Here */}
        <div
          className="w3-padding w3-center"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <button
            onClick={onPrevClick}
            disabled={formLoading}
            className="prevButton w3-btn"
          >
            Back
          </button>
          <button className="nextButton w3-btn" disabled={formLoading}>
            {!formLoading ? (
              "Save and Continue"
            ) : (
              <LoadingOutlined rev={undefined} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PortalFormFour;
