import { Button, Empty, notification, Pagination, Result, Spin } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { adminGetWithdrawals } from "../../../../apiservice/admin-Users-Clone-Service";
import { IAdminWithdrawalData } from "../../../../apiservice/admin-Users-clone.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { formatCurrency } from "../../../../utils/basic.utils";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./AdminWalletWithdrawalList.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminWalletWithdrawalList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const AdminWalletWithdrawalList: React.FC<
  IAdminWalletWithdrawalList
> = ({ externalFilter, initialDefaultFilter, hidePagination = false }) => {
  const [walletLoadState, setWalletLoadState] = useState<ILoadState>("loading");
  const [loadWalletsData, setLoadWalletsData] = useState(true);
  const [walletDefaultFilter, setWalletDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<IAdminWithdrawalData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setWalletDefaultFilter({
        ...walletDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadWalletsData(true);
      setWalletLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Wallets Details
  const walletDataResult = useFormatApiRequest(
    () => adminGetWithdrawals(walletDefaultFilter),
    loadWalletsData,
    () => {
      setLoadWalletsData(false);
    },
    () => {
      processWalletsResult();
    }
  );

  // Process The Current Wallet Data Result
  const processWalletsResult = async () => {
    if (walletDataResult.httpState === "SUCCESS") {
      setTableData(walletDataResult.data?.data);
      setTotalItems(walletDataResult.data?.meta?.total || 1);
      setWalletLoadState("completed");
    } else if (walletDataResult.httpState === "ERROR") {
      setWalletLoadState("error");
    } else if (walletDataResult.httpState === "LOADING") {
      setWalletLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<IAdminWithdrawalData> = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: 60,
      render: (text, records, index) => <span>{index + 1}</span>,
    },
    {
      title: "UserName",
      dataIndex: "id",
      key: "userName",
      render: (fullName, records, index) => (
        <Link to={`/adminpath/manage-users/${records.user.id}`}>
          <u>{records.user.fullName}</u>
        </Link>
      ),
    },
    {
      title: "Payment Made",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <span>{formatCurrency(amount)}</span>,
    },
    {
      title: "Withdrawal Type",
      dataIndex: "withdrawalType",
      key: "withdrawalType",
      render: (withdrawalType, records, index) => <span>{records.type}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <span>{status}</span>,
    },
    {
      title: "Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (date) => <span>{convertToShortDate(date)}</span>,
    },
    {
      title: "Action",
      dataIndex: "withdrawalId",
      key: "select",
      fixed: "right",
      width: 120,
      render: (withdrawalId, records, index) => (
        <div className="w3-center">
          <Link
            className="w3-btn w3-round selectBtn"
            to={`/adminpath/manage-withdrawal/${withdrawalId}/${records.typeId}`}
          >
            Details
          </Link>
        </div>
      ),
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setWalletDefaultFilter({
      ...walletDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadWalletsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col">
        {/* " Show Loading Error" */}
        {walletLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title="Error"
              subTitle="Sorry, something went wrong, it could be a network Related error"
              extra={
                <Button onClick={() => setLoadWalletsData(true)} type="primary">
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {walletLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show When there is data " */}
        {walletLoadState !== "error" && (
          <>
            <Table
              loading={walletLoadState === "loading"}
              rowKey="id"
              size="small"
              columns={columns}
              scroll={{ x: 950 }}
              dataSource={tableData}
              pagination={{ hideOnSinglePage: true }}
            />

            {!hidePagination && (
              <div className="w3-col w3-margin-top">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AdminWalletWithdrawalList;
