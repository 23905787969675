import WalletActions from "../walletActions/walletActions";
import WalletSummary from "../walletSummary/walletSummary";
import WalletTransactions from "../walletTransactions/walletTransactions";
import "./walletWrapper-Withdrawal.css";

export const WalletWrapperWithdrawal = () => {
  return (
    <>
      <div>
        <div className="w3-container">
          <div className="w3-col">
            <br />
            <br />
          </div>
          <div className="w3-col l12 s12 m12" style={{ padding: "5px" }}>
            <WalletActions title="Withdrawal"></WalletActions>
          </div>
          <div className="w3-col l5 s12 m6 w3-hide" style={{ padding: "5px" }}>
            <WalletSummary title="Withdrawal Summary"></WalletSummary>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletWrapperWithdrawal;
