import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, notification } from "antd";
import { useState } from "react";
import { blockUser } from "../../../../apiservice/admin-AuthService";
import { IUserListData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { appZIndex } from "../../../../utils/appconst";
import { convertToShortDate } from "../../../../utils/date.utils";
import "./User-Details-Wrapper.css";
type NotificationType = "success" | "info" | "warning" | "error";
type IAdminSiteUserDetailsCard = {
  siteUserData?: IUserListData | null;
  refreshPage?: () => void;
};

export const AdminSiteUserDetailsCardDetails: React.FC<
  IAdminSiteUserDetailsCard
> = ({ siteUserData, refreshPage }) => {
  const [loadBlockProfileApi, setLoadBlockProfileApi] = useState(false);

  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  // Block Site User
  const showBlockProfileApiConfirm = () => {
    confirm({
      title: siteUserData?.blocked
        ? "Are you sure you want to unblock this Site User"
        : "Are you sure you want to block this Site User",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadBlockProfileApi(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Block Site User
  const blockProfileApiResult = useFormatApiRequest(
    () =>
      blockUser(siteUserData?.id || 0, {
        unblock: siteUserData?.blocked,
      }),
    loadBlockProfileApi,
    () => {
      setLoadBlockProfileApi(false);
    },
    () => {
      processBlockProfileApi();
    }
  );

  // Process The Block
  const processBlockProfileApi = async () => {
    if (blockProfileApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon(
        "success",
        "",
        siteUserData?.blocked
          ? "This User has being Unblock"
          : "This User has being block"
      );
      if (refreshPage) {
        refreshPage();
      }
    } else if (blockProfileApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Blocking this user");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };
  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col w3-container" style={{ minHeight: "200px" }}>
        <div className="w3-border-bottom w3-padding">About User</div>
        <div className="w3-col l6 s12 m6">
          {/* FullName */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>FullName</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{siteUserData?.fullName}</span>
              </p>
            </div>
          </div>
          {/* User Type*/}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>User Type</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{siteUserData?.userType}</span>
              </p>
            </div>
          </div>
          {/* Phone Number */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Phone Number</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>({siteUserData?.countryCode})</span>
                {siteUserData?.phoneNumber}
              </p>
            </div>
          </div>
          {/* Email Address */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Email Address</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{siteUserData?.email}</span>
              </p>
            </div>
          </div>
          {/* City*/}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>City</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{siteUserData?.city}</span>
              </p>
            </div>
          </div>
          {/* Country */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Country</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{siteUserData?.country}</span>
              </p>
            </div>
          </div>
          {/* Date Created */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Date</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>
                  {convertToShortDate(
                    siteUserData?.dateCreated || Date.toString()
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="w3-col l6 s12 m6 w3-right-align">
          <button
            onClick={() => {
              showBlockProfileApiConfirm();
            }}
            className="w3-btn w3-margin-bottom w3-margin-top w3-border w3-round w3-border-yellow w3-text-yellow"
          >
            {siteUserData?.blocked
              ? "UnBlock Account"
              : "Suspend(Block) Account"}
          </button>
          &nbsp;
        </div>
      </div>
    </>
  );
};

export default AdminSiteUserDetailsCardDetails;
