import { Alert, Input } from "antd";
import { useState } from "react";
import "./PortalForm.css";

type IPortalFormPricing = {
  initialValue?: IPrice | null | undefined;
  id: number | string;
  description?: string;
  required?: boolean;
  onFormChange?: (val: IOnChangeIPortalFormPricing) => void;
};

type IPrice = {
  banner: number | string;
  guestPost: number | string;
  linkInsertion: number | string;
};

export type IOnChangeIPortalFormPricing = {
  id: number | string;
  price: IPrice;
};

export const PortalFormPricing: React.FC<IPortalFormPricing> = ({
  id,
  onFormChange,
  initialValue,
  required,
  description,
}) => {
  const [price, setPrice] = useState<IPrice>(
    initialValue || {
      banner: 0,
      guestPost: 0,
      linkInsertion: 0,
    }
  );

  // Use to collect Site Input Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPrice((values) => ({ ...values, [name]: value }));
    if (onFormChange) {
      onFormChange({ id, price });
    }
  };

  return (
    <div>
      <div className="w3-col w3-margin-bottom">
        <Alert
          style={{ width: "92%" }}
          description={<span>{description||'Description Here'}</span>}
        />
      </div>
      {/* Forms Here */}
      <div className="w3-col w3-margin-top">
        {/* Link Insertion Price */}
        <div className="w3-col w3-margin-bottom">
          <div className="w3-col l3 s12 m3 w3-padding">
            <span>
              Link Insertion Price <span className="w3-text-red">*</span>
            </span>
          </div>
          <div className="w3-col l9 s12 m9 w3-center">
            <Input
              type="number"
              required={required}
              name={"linkInsertion"}
              value={price?.linkInsertion || ""}
              style={{ width: "90%" }}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              className="w3-col inputField"
              placeholder={"Link Insertion Price"}
            />
          </div>
        </div>
        {/* Link Insertion Price */}

        {/* Guest Post Price */}
        <div className="w3-col w3-margin-bottom">
          <div className="w3-col l3 s12 m3 w3-padding">
            <span>
              Guest Post Price <span className="w3-text-red">*</span>
            </span>
          </div>
          <div className="w3-col l9 s12 m9 w3-center">
            <Input
              type="number"
              required={required}
              name={"guestPost"}
              value={price?.guestPost || ""}
              style={{ width: "90%" }}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              className="w3-col inputField"
              placeholder={"Guest Post Price"}
            />
          </div>
        </div>
        {/* Guest Post Price */}

        {/* Banner Price */}
        <div className="w3-col w3-margin-bottom">
          <div className="w3-col l3 s12 m3 w3-padding">
            <span>
              Banner Price <span className="w3-text-red">*</span>
            </span>
          </div>
          <div className="w3-col l9 s12 m9 w3-center">
            <Input
              type="number"
              required={required}
              name={"banner"}
              value={price?.banner || ""}
              style={{ width: "90%" }}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              className="w3-col inputField"
              placeholder={"Banner Price"}
            />
          </div>
        </div>
        {/* Banner Price */}
      </div>
    </div>
  );
};

export default PortalFormPricing;
