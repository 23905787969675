import "../../App.css";
import { Routes, Route } from "react-router-dom";
import UserSignIn from "./userSignIn/userSignin";
import UserSignUp from "./userSignup/userSignup";
import UserSignUpDetails from "./userSignup-step-2/userSignUpDetails";
import UserSignupVerifyEmail from "./userSignup-step-3-email-otp/userSignupVerifyEmail";
import UserForgetPassword from "./userforgetPassword/userForgetPassword";
import UserSetNewPassword from "./userforgetPassword-setnewpassword/userSetNewPassword";
import Nopage from "../Nopage/Nopage";

const UserAuthRoutes = () => {
  return (
    <Routes>
      <Route index element={<UserSignIn />} />
      <Route path="/signin" element={<UserSignIn />} />
      <Route path="/signup" element={<UserSignUp />} />
      <Route path="/signup-step-2" element={<UserSignUpDetails />} />
      <Route path="/verify-email" element={<UserSignupVerifyEmail />} />
      <Route path="/user-forget-password" element={<UserForgetPassword />} />
      <Route path="/user-setnew-password" element={<UserSetNewPassword />} />
      <Route path="*" element={<Nopage />} />
    </Routes>
  );
};

export default UserAuthRoutes;
