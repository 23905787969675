import { LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./CreateAdmin-Form.css";
import { Input } from "antd";
import PasswordInput from "../../../Sharedcomponents/PasswordBtn/Password-input";
import UserAuthWrapper, {
  IOpenNotificationType,
} from "../../../../pages/userAuthentication/userAuthWrapper/userAuthwrapper";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import {
  adminAddAdmin,
  adminEditAdmin,
  getAdminRoles,
} from "../../../../apiservice/admin-AuthService";
import {
  IAdminListsData,
  IAdminRolesData,
} from "../../../../apiservice/admin-AuthService.type";

type ICreateAdminForm = {
  adminUserData?: IAdminListsData | null;
  isEditMode?: boolean;
  refreshPage?: () => void;
};

const CreateAdminForm: React.FC<ICreateAdminForm> = ({
  adminUserData,
  isEditMode = false,
  refreshPage,
}) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [loadApi, setLoadApi] = useState(false);

  const [loadAdminRoleDataApi, setLoadAdminRoleDataApi] = useState(true);
  const [adminRoleData, setAdminRoleData] = useState<IAdminRolesData[]>([]);

  const [notificationMessage, setNotificationMessage] =
    useState<IOpenNotificationType | null>(null);

  const adminButtonText = isEditMode ? "Edit Admin" : "Create Admin";

  // This is use to Update Form if in Edit Mode
  useEffect(() => {
    setUser({
      ...adminUserData,
      userName: adminUserData?.username,
      roleId: adminUserData?.role?.id,
    });
  }, []);

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setUser({
      ...user,
      isSuperAdmin: user?.roleId === 1 ? true : false,
    });
    setFormLoading(true);
    setLoadApi(true);
  };

  // A custom hook to format the Sign Up Api
  const result = useFormatApiRequest(
    () =>
      isEditMode
        ? adminEditAdmin(adminUserData?.id || 0, user)
        : adminAddAdmin(user),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      // Handle Success Here
      setNotificationMessage({
        type: "info",
        message: "",
        description: isEditMode ? "Admin Updated " : "Admin Created Succesful",
        background: "#D9FFB5",
      });
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      // Handle Error Here
      setNotificationMessage({
        type: "error",
        message: "",
        description:
          result.data?.response?.data?.message || result.errorMsg || "Error",
        background: "#FFC2B7",
      });
    }
  };

  // A custom hook to Load Admin Role from the server
  const adminRoleResult = useFormatApiRequest(
    () => getAdminRoles(),
    loadAdminRoleDataApi,
    () => {
      setLoadAdminRoleDataApi(loadAdminRoleDataApi);
    },
    () => {
      processAdminRoleResultApi();
    }
  );

  // Process Admin Role Result
  const processAdminRoleResultApi = async () => {
    if (adminRoleResult.httpState === "SUCCESS") {
      setAdminRoleData(adminRoleResult.data?.data);
      console.log(adminRoleResult.data);
    } else if (adminRoleResult.httpState === "ERROR") {
      // console.log(adminRoleResult.errorMsg);
    }
  };

  return (
    <>
      {!isEditMode && (
        <div className="w3-container">
          <h4> Create Admin</h4>
        </div>
      )}
      <UserAuthWrapper
        notificationMessage={notificationMessage}
        imageType={"admin"}
      >
        <div>
          <div style={{ paddingTop: "10px" }}>
            <form onSubmit={handleSubmit}>
              {/* First Name */}
              <div className="w3-col w3-margin-bottom">
                <span> First Name </span>
                <Input
                  required
                  name="firstName"
                  value={user?.firstName}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="First Name"
                  prefix={
                    <MailOutlined className="inputPrefix" rev={undefined} />
                  }
                />
              </div>
              {/* Last Name */}
              <div className="w3-col w3-margin-bottom">
                <span> Last Name </span>
                <Input
                  required
                  name="lastName"
                  value={user?.lastName}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Last Name"
                  prefix={
                    <MailOutlined className="inputPrefix" rev={undefined} />
                  }
                />
              </div>

              {/* User Name */}
              <div className="w3-col w3-margin-bottom">
                <span> UserName </span>
                <Input
                  required
                  name="username"
                  value={user?.userName}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Username"
                  prefix={
                    <MailOutlined className="inputPrefix" rev={undefined} />
                  }
                />
              </div>

              {/* Email */}
              <div className="w3-col w3-margin-bottom">
                <span> Email </span>
                <Input
                  required
                  name="email"
                  value={user?.email}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Email Address"
                  prefix={
                    <MailOutlined className="inputPrefix" rev={undefined} />
                  }
                />
              </div>

              {/* Password */}
              {!isEditMode && (
                <div className="w3-col w3-margin-bottom w3-margin-top">
                  <span> Password </span>
                  <PasswordInput
                    name="password"
                    placeholder="Password"
                    value={user?.password}
                    onChange={handleInputChange}
                  />
                </div>
              )}

              {/* Role ID */}
              <div className="w3-col w3-margin-bottom w3-margin-top">
                <span> Admin Role </span>
                <select
                  required
                  name="roleId"
                  value={user?.roleId || ""}
                  onChange={handleInputChange}
                  className="w3-col inputSelect"
                >
                  <option value="">Select User Role</option>
                  {adminRoleData.map((item: IAdminRolesData, index: number) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w3-col w3-margin-bottom w3-margin-top">
                <button
                  disabled={formLoading}
                  className="w3-btn w3-col submitButton"
                >
                  <span className="submitButtonText">
                    {!formLoading ? (
                      adminButtonText
                    ) : (
                      <LoadingOutlined rev={undefined} />
                    )}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </UserAuthWrapper>
    </>
  );
};

export default CreateAdminForm;
