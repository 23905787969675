import { Button, Empty, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { getAdminUserGrowth } from "../../../../apiservice/admin-AuthService";
import { IAdminDataGrowthData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { NotificationType } from "../../../../pages/userAuthentication/userAuthWrapper/userAuthwrapper";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./Admin-Dashboard-profile.css";

type IGraphData = {
  title?: string;
  externalFilter?: any;
  initialDefaultFilter?: any;
};

export const AdminDashboardGraph: React.FC<IGraphData> = ({
  externalFilter,
  title,
  initialDefaultFilter,
}) => {
  const [chartLoadState, setChartLoadState] = useState<ILoadState>("loading");
  const [loadChartsData, setLoadChartsData] = useState(true);
  const [chartDefaultFilter, setChartDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [api, contextHolder] = notification.useNotification();

  //Chart String Data
  const [chartLabels, setChartLabels] = useState([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]);
  const [chartData, setChartData] = useState([12, 19, 3, 5, 2, 3, 3]);

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setChartDefaultFilter({
        ...chartDefaultFilter,
        ...externalFilter,
      });
      setLoadChartsData(true);
      setChartLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Charts Details
  const chartDataResult = useFormatApiRequest(
    () => getAdminUserGrowth(chartDefaultFilter),
    loadChartsData,
    () => {
      setLoadChartsData(false);
    },
    () => {
      processChartsResult();
    }
  );

  // Process The Current Chart Data Result
  const processChartsResult = async () => {
    if (chartDataResult.httpState === "SUCCESS") {
      const chartData: IAdminDataGrowthData[] = chartDataResult.data?.data;
      setChartLabels(chartData.map((data) => data.dateName));
      setChartData(chartData.map((data) => data.noOfUsers));
      setChartLoadState("completed");
    } else if (chartDataResult.httpState === "ERROR") {
      setChartLoadState("error");
    } else if (chartDataResult.httpState === "LOADING") {
      setChartLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Chart Data
  const graphData = {
    type: "line",
    data: {
      labels: chartLabels,
      datasets: [
        {
          label: "",
          data: chartData,
          cubicInterpolationMode: "monotone",
          borderWidth: 2,
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
  const graphHtmlString = `<div>
  <canvas id="myChart"></canvas>
</div>

<script src="https://cdn.jsdelivr.net/npm/chart.js"></script>

<script>
  const ctx = document.getElementById("myChart");

  new Chart(ctx, ${JSON.stringify(graphData)});
</script>
`;

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        {/* " Show Loading Indicator" */}
        {chartLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {chartLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title="Error"
              subTitle="Sorry, something went wrong, it could be a network Related error"
              extra={
                <Button onClick={() => setLoadChartsData(true)} type="primary">
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {chartLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show When there is data " */}
        {chartLoadState === "completed" && (
          <>
            <iframe
              style={{ width: "100%", height: "360px", border: "0px" }}
              srcDoc={graphHtmlString}
              title="Graph"
            ></iframe>
          </>
        )}
      </div>
    </>
  );
};

export default AdminDashboardGraph;
