import { Button, Empty, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUsers } from "../../../../apiservice/admin-AuthService";
import { IUserListData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./user-card-list.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IUserLists = {
  externalFilter?: any;
  initialDefaultFilter?: any;
};

export const UserCardList: React.FC<IUserLists> = ({
  externalFilter,
  initialDefaultFilter,
}) => {
  const [siteUserLoadState, setSiteUserLoadState] =
    useState<ILoadState>("loading");
  const [loadSiteUserData, setLoadSiteUserData] = useState(true);
  const [defaultFilter, setDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<IUserListData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setDefaultFilter({ ...defaultFilter, ...externalFilter });
      setLoadSiteUserData(true);
      setSiteUserLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const userDataResult = useFormatApiRequest(
    () => getUsers(defaultFilter),
    loadSiteUserData,
    () => {
      setLoadSiteUserData(false);
    },
    () => {
      processSiteUsersResult();
    }
  );

  // Process The Current Portal Data Result
  const processSiteUsersResult = async () => {
    if (userDataResult.httpState === "SUCCESS") {
      setTableData(userDataResult.data?.data);
      setSiteUserLoadState("completed");
    } else if (userDataResult.httpState === "ERROR") {
      setSiteUserLoadState("error");
    } else if (userDataResult.httpState === "LOADING") {
      setSiteUserLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="admin-members-wrapper">
        <h2 className="admin-new-member-header">New Member</h2>
        {/* " Show Loading Indicator" */}
        {siteUserLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {siteUserLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title="Error"
              subTitle="Sorry, something went wrong, it could be a network Related error"
              extra={<Button onClick={() => setLoadSiteUserData(true)} type="primary">Reload</Button>}
            />
          </div>
        )}

        {/* " Show No data" */}
        {siteUserLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show No data" */}
        {siteUserLoadState === "completed" && (
          <div className="w3-margin-top">
            <div className="per-admin-members">
              {tableData.map((member) => (
                <div className="admin-member-container" key={member?.id}>
                  <div className="w3-col">
                    <div className="w3-content">
                      {/* style={{marginRight: "5px"}} */}
                      <div className="w3-col s3 w3-padding">
                        <span className="admin-member-icon">
                          <img
                            className="w3-circle"
                            src={member?.dpUrl}
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="w3-col s6">
                        <p className="admin-member-name">{member.fullName}</p>
                        <p className="admin-member-description">
                          {member.userType}
                        </p>
                      </div>
                      <div className="w3-col s3 w3-padding">
                        <Button className="admin-member-button" size="small">
                          <Link to={`/adminpath/manage-users/${member.id}`}>
                            View
                          </Link>
                        </Button>
                        {/* <button className="admin-member-button"></button> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
