import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Input, Result, Tooltip } from "antd";
import { useState } from "react";
import { IAuthType } from "../../../apiservice/authService.type";
import {
  withDrawReferralTransaction,
  withDrawTransaction,
} from "../../../apiservice/transactions.service";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import { formatCurrency } from "../../../utils/basic.utils";
import "./walletForms.css";

type IWalletFormWithdrawal = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const WalletFormWithdrawal: React.FC<IWalletFormWithdrawal> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showFormResult, setShowFormResult] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () =>
      payLoad?.withdrawalType === "1"
        ? withDrawTransaction(payLoad)
        : withDrawReferralTransaction(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setShowFormResult(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      alert(result.data?.response?.data?.message || result.errorMsg);
      //Handle Error Here
    }
  };

  return (
    <div>
      {/* Wallet Form */}
      {!showFormResult && (
        <form onSubmit={handleSubmit}>
          {/* Forms Here */}
          <div>
            {/* Select Withdrawal Type */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                <span>
                  Withdrawal Type<span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Choose your preferred withdrawal type"
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>

              <div className="w3-col l9 s12 m9 w3-center">
                <select
                  required
                  name="withdrawalType"
                  value={payLoad?.withdrawalType || ""}
                  style={{ width: "90%" }}
                  onChange={handleInputChange}
                  className="w3-col inputSelect"
                >
                  <option value="">Select Type</option>
                  <option value="1"> Account Wallet </option>
                  <option value="2"> Referral Wallet</option>
                </select>
              </div>
            </div>
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                Balance :{" "}
                <span className="w3-text-teal">
                  {payLoad?.withdrawalType === "2"
                    ? formatCurrency(
                        authData.data?.credentials.referralWallet.balance || 0
                      )
                    : formatCurrency(
                        authData.data?.credentials.wallet.balance || 0
                      )}
                </span>
              </div>
            </div>

            {/* Select Withdrawal Method */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                <span>
                  Withdrawal Method <span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Select your preferred withdrawal method "
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-center">
                <select
                  required
                  name="withdrawalOption"
                  value={payLoad?.withdrawalOption || ""}
                  style={{ width: "90%" }}
                  onChange={handleInputChange}
                  className="w3-col inputSelect"
                >
                  <option value="">Select Method</option>
                  <option value="paypal"> Paypal</option>
                  <option value="bank"> Bank for (Uk and Nigeria)</option>
                </select>
              </div>
            </div>
            {/* Amount */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                <span>
                  Amount <span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Specify the amount you want to withdraw "
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-center">
                <Input
                  required
                  name="amount"
                  type="number"
                  value={payLoad?.amount || ""}
                  style={{ width: "90%" }}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Amount"
                />
              </div>
            </div>
            {/* Withdarawal Method for Paypal */}
            {payLoad?.withdrawalOption === "paypal" && (
              <div>
                {/* Paypal Email */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-padding">
                    <span>
                      PayPal Email <span className="w3-text-red">*</span>
                      <span className="tooltips" style={{ marginLeft: "5px" }}>
                        <Tooltip
                          color={"#2f3542"}
                          placement="right"
                          title="Enter your PayPal email address to link your PayPal account with your wallet."
                          zIndex={appZIndex.tooltips}
                        >
                          <InfoCircleOutlined rev={undefined} />
                        </Tooltip>
                      </span>
                    </span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-center">
                    <Input
                      required
                      name="paypalEmail"
                      value={payLoad?.paypalEmail || ""}
                      style={{ width: "90%" }}
                      onChange={handleInputChange}
                      className="w3-col inputField"
                      placeholder="PayPal Email "
                    />
                  </div>
                </div>
                {/* PayPal Name */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-padding">
                    <span>
                      PayPal UserName <span className="w3-text-red">*</span>
                      <span className="tooltips" style={{ marginLeft: "5px" }}>
                        <Tooltip
                          color={"#2f3542"}
                          placement="right"
                          title="Input your PayPal username to secure your transactions and ensure accurate transfers."
                          zIndex={appZIndex.tooltips}
                        >
                          <InfoCircleOutlined rev={undefined} />
                        </Tooltip>
                      </span>
                    </span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-center">
                    <Input
                      required
                      name="paypalUserName"
                      value={payLoad?.paypalUserName || ""}
                      style={{ width: "90%" }}
                      onChange={handleInputChange}
                      className="w3-col inputField"
                      placeholder="PayPal UserName"
                    />
                  </div>
                </div>
              </div>
            )}
            {/* Withdarawal Method for bank */}
            {payLoad?.withdrawalOption === "bank" && (
              <div>
                {/* Bank Name */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-padding">
                    <span>
                      Bank Name <span className="w3-text-red">*</span>
                      <span className="tooltips" style={{ marginLeft: "5px" }}>
                        <Tooltip
                          color={"#2f3542"}
                          placement="right"
                          title="Lospem ndnd"
                          zIndex={appZIndex.tooltips}
                        >
                          <InfoCircleOutlined rev={undefined} />
                        </Tooltip>
                      </span>
                    </span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-center">
                    <Input
                      required
                      name="bankName"
                      value={payLoad?.bankName || ""}
                      style={{ width: "90%" }}
                      onChange={handleInputChange}
                      className="w3-col inputField"
                      placeholder="BanK Name"
                    />
                  </div>
                </div>
                {/* Account Number */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-padding">
                    <span>
                      Account Number <span className="w3-text-red">*</span>
                      <span className="tooltips" style={{ marginLeft: "5px" }}>
                        <Tooltip
                          color={"#2f3542"}
                          placement="right"
                          title="Lospem ndnd"
                          zIndex={appZIndex.tooltips}
                        >
                          <InfoCircleOutlined rev={undefined} />
                        </Tooltip>
                      </span>
                    </span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-center">
                    <Input
                      required
                      name="accountNumber"
                      value={payLoad?.accountNumber || ""}
                      style={{ width: "90%" }}
                      onChange={handleInputChange}
                      className="w3-col inputField"
                      placeholder="Account Number"
                    />
                  </div>
                </div>
                {/* Account Name */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-padding">
                    <span>
                      Account Name <span className="w3-text-red">*</span>
                      <span className="tooltips" style={{ marginLeft: "5px" }}>
                        <Tooltip
                          color={"#2f3542"}
                          placement="right"
                          title="Lospem ndnd"
                          zIndex={appZIndex.tooltips}
                        >
                          <InfoCircleOutlined rev={undefined} />
                        </Tooltip>
                      </span>
                    </span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-center">
                    <Input
                      required
                      name="accountName"
                      value={payLoad?.accountName || ""}
                      style={{ width: "90%" }}
                      onChange={handleInputChange}
                      className="w3-col inputField"
                      placeholder="Account Name"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Button Here */}
          <div
            className="w3-padding"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge">
              <button className="nextButton w3-btn" disabled={formLoading}>
                {!formLoading ? (
                  "Withdraw"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </form>
      )}
      {showFormResult && (
        <div>
          <Result
            status="success"
            title=""
            subTitle="You have succesfully placed withdrawal, you may need to refresh this page for the transaction to reflect"
            extra={[
              <Button
                onClick={() => {
                  setShowFormResult(false);
                }}
                type="primary"
                key="console"
              >
                Okay
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default WalletFormWithdrawal;
