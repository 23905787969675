import { Button, Empty, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { getUserRecentActivities } from "../../../apiservice/authService";
import { IUserActivitiesData } from "../../../apiservice/authService.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../utils/date.utils";
import { ILoadState } from "../../../utils/loading.utils.";
import "./activities-list.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IActivitiesList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
};

export const ActivitiesList: React.FC<IActivitiesList> = ({
  externalFilter,
  initialDefaultFilter,
}) => {
  const [activityLoadState, setActivityLoadState] =
    useState<ILoadState>("loading");
  const [loadActivitysData, setLoadActivitysData] = useState(true);
  const [activityDefaultFilter, setActivityDefaultFilter] = useState(
    initialDefaultFilter || {}
  );
  const [tableData, setTableData] = useState<IUserActivitiesData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setActivityDefaultFilter({ ...activityDefaultFilter, ...externalFilter });
      setLoadActivitysData(true);
      setActivityLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Activitys Details
  const activityDataResult = useFormatApiRequest(
    () => getUserRecentActivities(activityDefaultFilter),
    loadActivitysData,
    () => {
      setLoadActivitysData(false);
    },
    () => {
      processActivitysResult();
    }
  );

  // Process The Current Activity Data Result
  const processActivitysResult = async () => {
    if (activityDataResult.httpState === "SUCCESS") {
      setTableData(activityDataResult.data?.data);
      setActivityLoadState("completed");
    } else if (activityDataResult.httpState === "ERROR") {
      setActivityLoadState("completed");
    } else if (activityDataResult.httpState === "LOADING") {
      setActivityLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        {/* " Show Loading Indicator" */}
        {activityLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {activityLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title="Error"
              subTitle="Sorry, something went wrong, it could be a network Related error"
              extra={
                <Button
                  onClick={() => setLoadActivitysData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {activityLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show No data" */}
        {activityLoadState === "completed" && (
          <div className="w3-margin-top">
            <div className="w3-col">
              <div className="w3-content">
                <div>
                  {/* Recent activities */}
                  <div className="w3-col l12 s12">
                    <div className="w3-col w3-light-grey w3-padding">
                      <div className="w3-col">
                        {tableData.map((activity) => (
                          <div className="w3-col w3-padding w3-round-large w3-white w3-margin-top">
                            <div className="w3-col" style={{ width: "60px" }}>
                              <img
                                className="w3-circle"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                }}
                                src={
                                  "https://test-linkmie.onrender.com/assets/user/dp.png"
                                }
                                alt="avi display"
                              />
                            </div>
                            <div className="w3-rest">
                              <div className="w3-col l3 s6 m3">
                                <p className="recent-activity-name">
                                  {activity.title}
                                </p>
                              </div>

                              <div className="w3-col l3 s6 m3 w3-right w3-right-align">
                                <p className="recent-activity-timedisplay">
                                  {convertToShortDate(activity.date)}
                                </p>
                              </div>

                              <div className="w3-col l6 s12 m6">
                                <p className="recent-activity-description">
                                  {activity.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
