import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, notification, Result } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { adminGetReferrals } from "../../../../../apiservice/admin-Users-Clone-Service";
import useFormatApiRequest from "../../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../../utils/date.utils";
import { ILoadState } from "../../../../../utils/loading.utils.";
import "./Admin-User-Referral-list.css";

type IAdminUserReferralList = {
  externalFilter?: any;
};

export const AdminUserReferralList: React.FC<IAdminUserReferralList> = ({
  externalFilter,
}) => {
  const [referralsLoadState, setOfferLoadState] =
    useState<ILoadState>("loading");
  const [loadReferralsData, setLoadReferralsData] = useState(true);
  const [referralsDefaultFilter, setreferralsDefaultFilter] = useState({});
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setreferralsDefaultFilter({
        ...referralsDefaultFilter,
        ...externalFilter,
      });
      setLoadReferralsData(true);
      setOfferLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const referralsDataResult = useFormatApiRequest(
    () => adminGetReferrals(referralsDefaultFilter),
    loadReferralsData,
    () => {
      setLoadReferralsData(false);
    },
    () => {
      processReferralsResult();
    }
  );

  // Process The Current Offer Data Result
  const processReferralsResult = async () => {
    if (referralsDataResult.httpState === "SUCCESS") {
      setTableData([] || referralsDataResult.data?.data);
      setOfferLoadState("completed");
    } else if (referralsDataResult.httpState === "ERROR") {
      setOfferLoadState("error");
    } else if (referralsDataResult.httpState === "LOADING") {
      setOfferLoadState("loading");
    }
  };

  // Table Configuration
  const columns: ColumnsType<any> = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "id",
      render: (text, records, index) => <span>{index + 1}</span>,
    },
    {
      title: "Username",
      dataIndex: "id",
      key: "username",
      render: (text, records, index) => <span> User Name</span>,
    },
    {
      title: "Amount",
      dataIndex: "id",
      key: "Amount",
      render: (text, records, index) => <span>$0</span>,
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
  ];

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {referralsLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadReferralsData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {referralsLoadState !== "error" && (
        <Table
          loading={referralsLoadState === "loading"}
          rowKey="id"
          size="small"
          columns={columns}
          scroll={{ x: 950 }}
          dataSource={tableData}
          pagination={{ position: ["bottomLeft"] }}
        />
      )}
    </>
  );
};

export default AdminUserReferralList;
