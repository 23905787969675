import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, notification } from "antd";
import { useState } from "react";
import {
  adminApprovedPortal,
  adminRejectPortal,
} from "../../../../apiservice/admin-Users-Clone-Service";
import { IAdminPortalsData } from "../../../../apiservice/admin-Users-clone.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { appZIndex } from "../../../../utils/appconst";
import "./Admin-Portal-Details-Wrapper.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminPortalDetailsCard = {
  adminPortalData?: IAdminPortalsData | null;
  refreshPage?: () => void;
};

export const AdminPortalDetailsCard: React.FC<IAdminPortalDetailsCard> = ({
  adminPortalData,
  refreshPage,
}) => {
  const [loadAcceptPortal, setLoadAcceptPortal] = useState(false);
  const [loadRejectPortal, setLoadRejectPortal] = useState(false);
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  //Accept Portal
  const showAcceptPortalConfirm = () => {
    confirm({
      title: "Are you sure you want to accept this portal",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadAcceptPortal(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Accept Portal
  const acceptPortalApiResult = useFormatApiRequest(
    () => adminApprovedPortal(adminPortalData?.id || 0),
    loadAcceptPortal,
    () => {
      setLoadAcceptPortal(false);
    },
    () => {
      processAcceptPortal();
    }
  );

  // Process The Accept Portal
  const processAcceptPortal = async () => {
    if (acceptPortalApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "This Portal Has Being Accepted");
      if (refreshPage) {
        refreshPage();
      }
    } else if (acceptPortalApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Accepting this portal");
    }
  };

  //Reject Portal
  const showRejectPortalConfirm = () => {
    confirm({
      title: "Are you sure you want to reject this portal",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadRejectPortal(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Reject Portal
  const rejectPortalApiResult = useFormatApiRequest(
    () => adminRejectPortal(adminPortalData?.id || 0),
    loadRejectPortal,
    () => {
      setLoadRejectPortal(false);
    },
    () => {
      processRejectPortal();
    }
  );

  // Process The Reject Portal
  const processRejectPortal = async () => {
    if (rejectPortalApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "This Portal Has Being Rejected");
      if (refreshPage) {
        refreshPage();
      }
    } else if (rejectPortalApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Rejecting this portal");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };
  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col w3-padding w3-padding-bottom">
        <div className="w3-col" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "15px", height: "60px" }}
          >
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <span className="portalDetailsTitle">
                Portal Name -{adminPortalData?.websiteName}
              </span>
            </div>
            <div
              className="w3-col l6 s6 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              Status:{adminPortalData?.status}
            </div>
          </div>
        </div>

        <div className="w3-row">
          {/* Basic Info Card */}
          <div className="w3-col l6 s12 m6" style={{ padding: "10px" }}>
            <div
              className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge"
              style={{ paddingTop: "15px", minHeight: "300px" }}
            >
              <h5 className="portalDetailsCardTitle"> Basic Information </h5>

              {/* Website Name */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Website name </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.websiteName}
                  </span>
                </div>
              </div>
              {/* Website Name */}

              {/* Domain Name */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Domain name </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.domainName}
                  </span>
                </div>
              </div>
              {/* Dommain Name */}

              {/* Website Lanaguage */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> WebSite Language </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.websiteLanguage}
                  </span>
                </div>
              </div>
              {/*  Website Lanaguage */}

              {/* Website Niche */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Site Niche </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.siteNiche?.title}
                  </span>
                </div>
              </div>
              {/*  Website Niche */}

              {/* Website Role */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Website Role</span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.websiteRole}
                  </span>
                </div>
              </div>
              {/*  Website Role  */}
            </div>
          </div>
          {/* Basic Info Card */}

          {/* SEO METRIC */}
          <div className="w3-col l6 s12 m6" style={{ padding: "10px" }}>
            <div
              className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge"
              style={{ paddingTop: "15px", minHeight: "300px" }}
            >
              <h5 className="portalDetailsCardTitle"> SEO Metrics </h5>
              {/* Site Description */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Site Description </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.siteDescription}
                  </span>
                </div>
              </div>
              {/* Site Description  */}

              {/* Domain Authority */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Domain Authority </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.domainAuthority}
                  </span>
                </div>
              </div>
              {/* Dommain Authority */}

              {/* Spam Score */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Spam Score </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.spamScore}
                  </span>
                </div>
              </div>
              {/*  Spam Score */}

              {/* Domain Rating  */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Domain Rating </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.domainRating}
                  </span>
                </div>
              </div>
              {/*  Domain Rating  */}

              {/* Organic Traffic */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Organic Traffic </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.organicTraffic}
                  </span>
                </div>
              </div>
              {/*  Organic Traffic  */}
            </div>
          </div>
          {/* SEO METRIC */}
        </div>

        <div className="w3-row">
          {/* Site Info */}
          <div className="w3-col l6 s12 m6" style={{ padding: "10px" }}>
            <div
              className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge"
              style={{ paddingTop: "15px", minHeight: "300px" }}
            >
              <h5 className="portalDetailsCardTitle"> Site Info </h5>

              {/* Category */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Category </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue"></span>
                </div>
              </div>
              {/* Category  */}

              {/* Leading Country */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Leading Country </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.leadingCountry}
                  </span>
                </div>
              </div>
              {/* Leading Country */}

              {/*  Marked Sponsor */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Marked Sponsored </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.markedSponsored}
                  </span>
                </div>
              </div>
              {/*  Marked Sponsor */}

              {/* Link Type */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Link Type</span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.linkTypeId}
                  </span>
                </div>
              </div>
              {/*  Link Type  */}

              {/* Turn Around Time  */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Turn Around Time </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.turnAroundTime}
                  </span>
                </div>
              </div>
              {/*  Turn Around Time   */}

              {/* Minimum Word Count  */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Minimum Word Count </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.minimumWordCount}
                  </span>
                </div>
              </div>
              {/*  Minimum Link Count    */}

              {/* Maximum Word Count  */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Maximum Link Count </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {adminPortalData?.maximumLinkCount}
                  </span>
                </div>
              </div>
              {/*  Maximum Link Count    */}
            </div>
          </div>
          {/* Site Info */}

          {/* Pricing  */}
          <div className="w3-col l6 s12 m6" style={{ padding: "10px" }}>
            <div
              className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge"
              style={{ paddingTop: "15px", minHeight: "420px" }}
            >
              <h5 className="portalDetailsCardTitle"> Prices</h5>
              <div>
                {adminPortalData?.portalPricings.map((item, index: number) => (
                  <div key={index}>
                    <h4> {item?.title}</h4>
                    <span className="paramTitle">
                      Guest Post Price {item.price?.guestPost}
                    </span>
                    <br />
                    <span className="paramTitle">
                      Banner Price {item.price?.banner}
                    </span>
                    <br />
                    <span className="paramTitle">
                      Link Insertion Price {item.price?.linkInsertion}
                    </span>
                    <br />
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Pricing  */}
        </div>

        <div className="w3-col" style={{ padding: "10px" }}>
          <div
            className="w3-col  w3-container  w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "15px", height: "60px" }}
          >
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <button
                onClick={showAcceptPortalConfirm}
                className="w3-btn w3-round w3-green w3-col"
              >
                Approved Portal
              </button>
            </div>
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <button
                onClick={showRejectPortalConfirm}
                className="w3-btn w3-round w3-text-red w3-border w3-border-red w3-col"
              >
                Reject Portal
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPortalDetailsCard;
