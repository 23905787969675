import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Input, Radio, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { portalFormThreeSave } from "../../../apiservice/portals.service";
import { IPortalFormType } from "../../../apiservice/portals.type";
import {
  getLinkValidities,
  getSiteSubCategories,
} from "../../../apiservice/site-divisions.service";
import {
  ILinkValidityData,
  ISubCategoryData,
} from "../../../apiservice/site-divisions.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppDispatch, useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import "./PortalForm.css";

const { Option } = Select;

type IPortalFormThree = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
  onPrevClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

type IAntDesignSelectValue = { value: number; label: string };

export const PortalFormThree: React.FC<IPortalFormThree> = ({
  onFormFailure,
  onFormSuccess,
  onPrevClick,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const [loadSubCategoryApi, setLoadSubCategoryApi] = useState(true);
  const [subCategoryData, setSubCategoryData] = useState<ISubCategoryData[]>(
    []
  );
  const [formattedSubCategoryData, setFormattedSubCategoryData] = useState<
    IAntDesignSelectValue[]
  >([]);

  const [loadLinkValidityApi, setLoadLinkValidityApi] = useState(true);
  const [linkValidityData, setLinkValidityData] = useState<ILinkValidityData[]>(
    []
  );
  const [selectIsValid, setSelectIsValid] = useState<boolean>(false);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const portalFormData: IPortalFormType = useAppSelector(
    (state: RootState) => state?.PortalForm
  );

  // This is use to Update Redux Dispatch Values
  useEffect(() => {
    // console.log(getCategoriesIds([...(portalFormData?.stepThree?.categories || [])]));

    if (!portalFormData.stepOne?.websiteName) {
      dispatch({ type: "PORTAL_UPDATE_STAGE", payload: 0 });
      navigate("/publisher/portals");
    }

    setpayLoad({
      linkType: 0,
      markedSponsored: false,
      ...portalFormData.stepThree,
      categories: getCategoriesIds([
        ...(portalFormData?.stepThree?.categories || []),
      ]),
    });
  }, []);

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Check Select Change Validity
  useEffect(() => {
    if (payLoad?.leadingCountry !== "") {
      setSelectIsValid(true);
    }
  }, [payLoad]);

  // Use to collect Site Description Change
  const handleCustomSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!payLoad?.leadingCountry) {
      alert("Enter A Leading Country");
      return;
    }

    dispatch({ type: "PORTAL_UPDATE_STEP_THREE_DATA", payload: payLoad });
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => portalFormThreeSave(Number(portalFormData.id), payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  // A custom hook to Load Sub Category from the server
  const subCategoryResult = useFormatApiRequest(
    () => getSiteSubCategories(),
    loadSubCategoryApi,
    () => {
      setLoadSubCategoryApi(loadSubCategoryApi);
    },
    () => {
      processSubCategoryResultApi();
    }
  );

  // Process Site Sub Category Result
  const processSubCategoryResultApi = async () => {
    if (subCategoryResult.httpState === "SUCCESS") {
      setSubCategoryData(subCategoryResult.data?.data);
      setFormattedSubCategoryData(
        subCategoryResult.data?.data?.map((data) => {
          return { value: data.id, label: data.title };
        }) || []
      );
    } else if (subCategoryResult.httpState === "ERROR") {
      // console.log(subCategoryResult.errorMsg);
    }
  };

  // This is use to Further Format the Category Data for Ant Design *  Refacor to be done here to use a method instea of Use Effect
  // useEffect(() => {
  //   let processSubCategoryData = subCategoryData.map((data) => {
  //     return { value: data.id, label: data.title };
  //   });
  //   setFormattedSubCategoryData(processSubCategoryData);
  // }, [subCategoryData]);

  // A custom hook to Load LinkValidity from the server
  const linkValidityResult = useFormatApiRequest(
    () => getLinkValidities(),
    loadLinkValidityApi,
    () => {
      setLoadLinkValidityApi(loadLinkValidityApi);
    },
    () => {
      processLinkValidityResultApi();
    }
  );

  // Process LinkValidity Result
  const processLinkValidityResultApi = async () => {
    if (linkValidityResult.httpState === "SUCCESS") {
      setLinkValidityData(linkValidityResult.data?.data);
      // console.log(linkValidityResult.data);
    } else if (linkValidityResult.httpState === "ERROR") {
      // console.log(linkValidityResult.errorMsg);
    }
  };

  const getCategoriesIds = (categoriesResult: any[]) => {
    return (
      categoriesResult.map((data) => {
        return data.id;
      }) || []
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Forms Here */}
        <div>
          {/* Category */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Category <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="The general category your site falls within"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Select
                size="large"
                mode="multiple"
                style={{ width: "90%", height: "50px", textAlign: "left" }}
                placeholder="Please select"
                value={payLoad?.categories || []}
                className="w3-col"
                onChange={(val) => {
                  handleCustomSelectChange(val, "categories");
                }}
                options={formattedSubCategoryData}
              />
            </div>
          </div>

          {/* Leading Country */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Leading Country <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="The country where your site is mostly accessed from"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Select
                id="leadingCountry"
                value={payLoad?.leadingCountry || ""}
                onChange={(val) => {
                  handleSelectChange(val, "leadingCountry");
                }}
                showSearch
                className="w3-col"
                style={{ width: "90%", height: "50px", textAlign: "left" }}
              >
                <Option value="">Select Country</Option>
                {/* <Option value="nigeria"> Nigeria </Option> */}
                <Option value="Afghanistan">Afghanistan</Option>
                <Option value="Åland Islands">Åland Islands</Option>
                <Option value="Albania">Albania</Option>
                <Option value="Algeria">Algeria</Option>
                <Option value="American Samoa">American Samoa</Option>
                <Option value="Andorra">Andorra</Option>
                <Option value="Angola">Angola</Option>
                <Option value="Anguilla">Anguilla</Option>
                <Option value="Antarctica">Antarctica</Option>
                <Option value="Antigua and Barbuda">Antigua and Barbuda</Option>
                <Option value="Argentina">Argentina</Option>
                <Option value="Armenia">Armenia</Option>
                <Option value="Aruba">Aruba</Option>
                <Option value="Australia">Australia</Option>
                <Option value="Austria">Austria</Option>
                <Option value="Azerbaijan">Azerbaijan</Option>
                <Option value="Bahamas">Bahamas</Option>
                <Option value="Bahrain">Bahrain</Option>
                <Option value="Bangladesh">Bangladesh</Option>
                <Option value="Barbados">Barbados</Option>
                <Option value="Belarus">Belarus</Option>
                <Option value="Belgium">Belgium</Option>
                <Option value="Belize">Belize</Option>
                <Option value="Benin">Benin</Option>
                <Option value="Bermuda">Bermuda</Option>
                <Option value="Bhutan">Bhutan</Option>
                <Option value="Bolivia">Bolivia</Option>
                <Option value="Bosnia and Herzegovina">
                  Bosnia and Herzegovina
                </Option>
                <Option value="Botswana">Botswana</Option>
                <Option value="Bouvet Island">Bouvet Island</Option>
                <Option value="Brazil">Brazil</Option>
                <Option value="British Indian Ocean Territory">
                  British Indian Ocean Territory
                </Option>
                <Option value="Brunei Darussalam">Brunei Darussalam</Option>
                <Option value="Bulgaria">Bulgaria</Option>
                <Option value="Burkina Faso">Burkina Faso</Option>
                <Option value="Burundi">Burundi</Option>
                <Option value="Cambodia">Cambodia</Option>
                <Option value="Cameroon">Cameroon</Option>
                <Option value="Canada">Canada</Option>
                <Option value="Cape Verde">Cape Verde</Option>
                <Option value="Cayman Islands">Cayman Islands</Option>
                <Option value="Central African Republic">
                  Central African Republic
                </Option>
                <Option value="Chad">Chad</Option>
                <Option value="Chile">Chile</Option>
                <Option value="China">China</Option>
                <Option value="Christmas Island">Christmas Island</Option>
                <Option value="Cocos (Keeling) Islands">
                  Cocos (Keeling) Islands
                </Option>
                <Option value="Colombia">Colombia</Option>
                <Option value="Comoros">Comoros</Option>
                <Option value="Congo">Congo</Option>
                <Option value="Congo, The Democratic Republic of The">
                  Congo, The Democratic Republic of The
                </Option>
                <Option value="Cook Islands">Cook Islands</Option>
                <Option value="Costa Rica">Costa Rica</Option>
                <Option value="Cote D'ivoire">Cote D'ivoire</Option>
                <Option value="Croatia">Croatia</Option>
                <Option value="Cuba">Cuba</Option>
                <Option value="Cyprus">Cyprus</Option>
                <Option value="Czech Republic">Czech Republic</Option>
                <Option value="Denmark">Denmark</Option>
                <Option value="Djibouti">Djibouti</Option>
                <Option value="Dominica">Dominica</Option>
                <Option value="Dominican Republic">Dominican Republic</Option>
                <Option value="Ecuador">Ecuador</Option>
                <Option value="Egypt">Egypt</Option>
                <Option value="El Salvador">El Salvador</Option>
                <Option value="Equatorial Guinea">Equatorial Guinea</Option>
                <Option value="Eritrea">Eritrea</Option>
                <Option value="Estonia">Estonia</Option>
                <Option value="Ethiopia">Ethiopia</Option>
                <Option value="Falkland Islands (Malvinas)">
                  Falkland Islands (Malvinas)
                </Option>
                <Option value="Faroe Islands">Faroe Islands</Option>
                <Option value="Fiji">Fiji</Option>
                <Option value="Finland">Finland</Option>
                <Option value="France">France</Option>
                <Option value="French Guiana">French Guiana</Option>
                <Option value="French Polynesia">French Polynesia</Option>
                <Option value="French Southern Territories">
                  French Southern Territories
                </Option>
                <Option value="Gabon">Gabon</Option>
                <Option value="Gambia">Gambia</Option>
                <Option value="Georgia">Georgia</Option>
                <Option value="Germany">Germany</Option>
                <Option value="Ghana">Ghana</Option>
                <Option value="Gibraltar">Gibraltar</Option>
                <Option value="Greece">Greece</Option>
                <Option value="Greenland">Greenland</Option>
                <Option value="Grenada">Grenada</Option>
                <Option value="Guadeloupe">Guadeloupe</Option>
                <Option value="Guam">Guam</Option>
                <Option value="Guatemala">Guatemala</Option>
                <Option value="Guernsey">Guernsey</Option>
                <Option value="Guinea">Guinea</Option>
                <Option value="Guinea-bissau">Guinea-bissau</Option>
                <Option value="Guyana">Guyana</Option>
                <Option value="Haiti">Haiti</Option>
                <Option value="Heard Island and Mcdonald Islands">
                  Heard Island and Mcdonald Islands
                </Option>
                <Option value="Holy See (Vatican City State)">
                  Holy See (Vatican City State)
                </Option>
                <Option value="Honduras">Honduras</Option>
                <Option value="Hong Kong">Hong Kong</Option>
                <Option value="Hungary">Hungary</Option>
                <Option value="Iceland">Iceland</Option>
                <Option value="India">India</Option>
                <Option value="Indonesia">Indonesia</Option>
                <Option value="Iran, Islamic Republic of">
                  Iran, Islamic Republic of
                </Option>
                <Option value="Iraq">Iraq</Option>
                <Option value="Ireland">Ireland</Option>
                <Option value="Isle of Man">Isle of Man</Option>
                <Option value="Israel">Israel</Option>
                <Option value="Italy">Italy</Option>
                <Option value="Jamaica">Jamaica</Option>
                <Option value="Japan">Japan</Option>
                <Option value="Jersey">Jersey</Option>
                <Option value="Jordan">Jordan</Option>
                <Option value="Kazakhstan">Kazakhstan</Option>
                <Option value="Kenya">Kenya</Option>
                <Option value="Kiribati">Kiribati</Option>
                <Option value="Korea, Democratic People's Republic of">
                  Korea, Democratic People's Republic of
                </Option>
                <Option value="Korea, Republic of">Korea, Republic of</Option>
                <Option value="Kuwait">Kuwait</Option>
                <Option value="Kyrgyzstan">Kyrgyzstan</Option>
                <Option value="Lao People's Democratic Republic">
                  Lao People's Democratic Republic
                </Option>
                <Option value="Latvia">Latvia</Option>
                <Option value="Lebanon">Lebanon</Option>
                <Option value="Lesotho">Lesotho</Option>
                <Option value="Liberia">Liberia</Option>
                <Option value="Libyan Arab Jamahiriya">
                  Libyan Arab Jamahiriya
                </Option>
                <Option value="Liechtenstein">Liechtenstein</Option>
                <Option value="Lithuania">Lithuania</Option>
                <Option value="Luxembourg">Luxembourg</Option>
                <Option value="Macao">Macao</Option>
                <Option value="Macedonia, The Former Yugoslav Republic of">
                  Macedonia, The Former Yugoslav Republic of
                </Option>
                <Option value="Madagascar">Madagascar</Option>
                <Option value="Malawi">Malawi</Option>
                <Option value="Malaysia">Malaysia</Option>
                <Option value="Maldives">Maldives</Option>
                <Option value="Mali">Mali</Option>
                <Option value="Malta">Malta</Option>
                <Option value="Marshall Islands">Marshall Islands</Option>
                <Option value="Martinique">Martinique</Option>
                <Option value="Mauritania">Mauritania</Option>
                <Option value="Mauritius">Mauritius</Option>
                <Option value="Mayotte">Mayotte</Option>
                <Option value="Mexico">Mexico</Option>
                <Option value="Micronesia, Federated States of">
                  Micronesia, Federated States of
                </Option>
                <Option value="Moldova, Republic of">
                  Moldova, Republic of
                </Option>
                <Option value="Monaco">Monaco</Option>
                <Option value="Mongolia">Mongolia</Option>
                <Option value="Montenegro">Montenegro</Option>
                <Option value="Montserrat">Montserrat</Option>
                <Option value="Morocco">Morocco</Option>
                <Option value="Mozambique">Mozambique</Option>
                <Option value="Myanmar">Myanmar</Option>
                <Option value="Namibia">Namibia</Option>
                <Option value="Nauru">Nauru</Option>
                <Option value="Nepal">Nepal</Option>
                <Option value="Netherlands">Netherlands</Option>
                <Option value="Netherlands Antilles">
                  Netherlands Antilles
                </Option>
                <Option value="New Caledonia">New Caledonia</Option>
                <Option value="New Zealand">New Zealand</Option>
                <Option value="Nicaragua">Nicaragua</Option>
                <Option value="Niger">Niger</Option>
                <Option value="Nigeria">Nigeria</Option>
                <Option value="Niue">Niue</Option>
                <Option value="Norfolk Island">Norfolk Island</Option>
                <Option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </Option>
                <Option value="Norway">Norway</Option>
                <Option value="Oman">Oman</Option>
                <Option value="Pakistan">Pakistan</Option>
                <Option value="Palau">Palau</Option>
                <Option value="Palestinian Territory, Occupied">
                  Palestinian Territory, Occupied
                </Option>
                <Option value="Panama">Panama</Option>
                <Option value="Papua New Guinea">Papua New Guinea</Option>
                <Option value="Paraguay">Paraguay</Option>
                <Option value="Peru">Peru</Option>
                <Option value="Philippines">Philippines</Option>
                <Option value="Pitcairn">Pitcairn</Option>
                <Option value="Poland">Poland</Option>
                <Option value="Portugal">Portugal</Option>
                <Option value="Puerto Rico">Puerto Rico</Option>
                <Option value="Qatar">Qatar</Option>
                <Option value="Reunion">Reunion</Option>
                <Option value="Romania">Romania</Option>
                <Option value="Russian Federation">Russian Federation</Option>
                <Option value="Rwanda">Rwanda</Option>
                <Option value="Saint Helena">Saint Helena</Option>
                <Option value="Saint Kitts and Nevis">
                  Saint Kitts and Nevis
                </Option>
                <Option value="Saint Lucia">Saint Lucia</Option>
                <Option value="Saint Pierre and Miquelon">
                  Saint Pierre and Miquelon
                </Option>
                <Option value="Saint Vincent and The Grenadines">
                  Saint Vincent and The Grenadines
                </Option>
                <Option value="Samoa">Samoa</Option>
                <Option value="San Marino">San Marino</Option>
                <Option value="Sao Tome and Principe">
                  Sao Tome and Principe
                </Option>
                <Option value="Saudi Arabia">Saudi Arabia</Option>
                <Option value="Senegal">Senegal</Option>
                <Option value="Serbia">Serbia</Option>
                <Option value="Seychelles">Seychelles</Option>
                <Option value="Sierra Leone">Sierra Leone</Option>
                <Option value="Singapore">Singapore</Option>
                <Option value="Slovakia">Slovakia</Option>
                <Option value="Slovenia">Slovenia</Option>
                <Option value="Solomon Islands">Solomon Islands</Option>
                <Option value="Somalia">Somalia</Option>
                <Option value="South Africa">South Africa</Option>
                <Option value="South Georgia and The South Sandwich Islands">
                  South Georgia and The South Sandwich Islands
                </Option>
                <Option value="Spain">Spain</Option>
                <Option value="Sri Lanka">Sri Lanka</Option>
                <Option value="Sudan">Sudan</Option>
                <Option value="Suriname">Suriname</Option>
                <Option value="Svalbard and Jan Mayen">
                  Svalbard and Jan Mayen
                </Option>
                <Option value="Swaziland">Swaziland</Option>
                <Option value="Sweden">Sweden</Option>
                <Option value="Switzerland">Switzerland</Option>
                <Option value="Syrian Arab Republic">
                  Syrian Arab Republic
                </Option>
                <Option value="Taiwan">Taiwan</Option>
                <Option value="Tajikistan">Tajikistan</Option>
                <Option value="Tanzania, United Republic of">
                  Tanzania, United Republic of
                </Option>
                <Option value="Thailand">Thailand</Option>
                <Option value="Timor-leste">Timor-leste</Option>
                <Option value="Togo">Togo</Option>
                <Option value="Tokelau">Tokelau</Option>
                <Option value="Tonga">Tonga</Option>
                <Option value="Trinidad and Tobago">Trinidad and Tobago</Option>
                <Option value="Tunisia">Tunisia</Option>
                <Option value="Turkey">Turkey</Option>
                <Option value="Turkmenistan">Turkmenistan</Option>
                <Option value="Turks and Caicos Islands">
                  Turks and Caicos Islands
                </Option>
                <Option value="Tuvalu">Tuvalu</Option>
                <Option value="Uganda">Uganda</Option>
                <Option value="Ukraine">Ukraine</Option>
                <Option value="United Arab Emirates">
                  United Arab Emirates
                </Option>
                <Option value="United Kingdom">United Kingdom</Option>
                <Option value="United States">United States</Option>
                <Option value="United States Minor Outlying Islands">
                  United States Minor Outlying Islands
                </Option>
                <Option value="Uruguay">Uruguay</Option>
                <Option value="Uzbekistan">Uzbekistan</Option>
                <Option value="Vanuatu">Vanuatu</Option>
                <Option value="Venezuela">Venezuela</Option>
                <Option value="Viet Nam">Viet Nam</Option>
                <Option value="Virgin Islands, British">
                  Virgin Islands, British
                </Option>
                <Option value="Virgin Islands, U.S.">
                  Virgin Islands, U.S.
                </Option>
                <Option value="Wallis and Futuna">Wallis and Futuna</Option>
                <Option value="Western Sahara">Western Sahara</Option>
                <Option value="Yemen">Yemen</Option>
                <Option value="Zambia">Zambia</Option>
                <Option value="Zimbabwe">Zimbabwe</Option>
              </Select>
            </div>
          </div>
          {/* Marked Sponsor */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Marked Sponsor? <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title=" Indicate if this content or link has been sponsored by an advertiser"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9">
              <span>
                <Radio.Group
                  name="markedSponsored"
                  onChange={handleInputChange}
                  value={payLoad?.markedSponsored || false}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </span>
            </div>
          </div>

          {/* Linked Type */}

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Linked Type <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="What type of advertising link does your site offer? "
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9">
              <span>
                <Radio.Group
                  name="linkType"
                  onChange={handleInputChange}
                  value={payLoad?.linkType || 0}
                >
                  <Radio value={0}>Follow</Radio>
                  <Radio value={1}>No Follow</Radio>
                </Radio.Group>
              </span>
            </div>
          </div>

          {/* Linked Validity */}

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Link Validity<span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title=" Status of the link's availability and functionality."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <select
                required
                name="linkValidityId"
                value={payLoad?.linkValidityId || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputSelect"
              >
                <option value="">Select Link Validity </option>
                {linkValidityData.map(
                  (item: ILinkValidityData, index: number) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          {/* Turn Around Time */}

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Turn Around Time <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Estimated time it takes for your advertisement to go live"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="turnAroundTime"
                value={payLoad?.turnAroundTime || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Turn Around Time"
                type={"number"}
              />
            </div>
          </div>

          {/* Maximum Link Count */}

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Maximum Link Count <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Highest number of advertising links available for purchase"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="maximumLinkCount"
                value={payLoad?.maximumLinkCount || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="MaximumLink Count"
                type={"number"}
              />
            </div>
          </div>

          {/* Minimum Link Count */}

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Minimum Link Count <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Minimum number of advertising links you can purchase"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="minimumWordCount"
                value={payLoad?.minimumWordCount || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Minimum Link Count"
                type={"number"}
              />
            </div>
          </div>
        </div>
        {/* Button Here */}
        <div
          className="w3-padding w3-center"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <button
            onClick={onPrevClick}
            disabled={formLoading}
            className="prevButton w3-btn"
          >
            Back
          </button>
          <button className="nextButton w3-btn" disabled={formLoading}>
            {!formLoading ? (
              "Save and Continue"
            ) : (
              <LoadingOutlined rev={undefined} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PortalFormThree;
