import AdminwalletWithdrawalListWrapper from "../../../components/Admin-Component-Groups/Admin-Manage-Wallets-Comp/AdminwalletWithdrawalList-Wrapper/AdminwalletWithdrawalList-Wrapper";
import "./AdminManageWithdrawal.css";

export const AdminManageWithdrawal = () => {
  return (
    <div>
      <AdminwalletWithdrawalListWrapper></AdminwalletWithdrawalListWrapper>
    </div>
  );
};

export default AdminManageWithdrawal;
