import "./AdminManageAdmins.css";
import AdminListswrapper from "../../../components/Admin-Component-Groups/Admin-Manage-Admin-Users-Comps/Admin-Lists-wrapper/Admin-Lists-wrapper";

export const AdminManageAdmins = () => {
  return (
    <div>
      <AdminListswrapper></AdminListswrapper>
    </div>
  );
};

export default AdminManageAdmins;
