import AdminSettingCategoryList from "../Admin-Setting-Category-List/Admin-Setting-Category-List";
import AdminSettingsform from "../Admin-Settings-form/Admin-Settings-form";
import "./Admin-Settings-Wrapper.css";

export const AdminSettingsWrapper = () => {
  return (
    <>
      <div className="w3-container">
        <AdminSettingsform></AdminSettingsform>
        <AdminSettingCategoryList></AdminSettingCategoryList>
      </div>
    </>
  );
};

export default AdminSettingsWrapper;
