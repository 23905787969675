import "./AdminManageReferral.css";
import { SmileOutlined } from "@ant-design/icons";
import { Result } from "antd";

export const AdminManageReferral = () => {
  return (
    <div>
      <Result
        status="404"
        icon={<SmileOutlined rev={undefined} />}
        title="Admin Manage Referral "
        subTitle="Please the patient our engineer is at work"
      />
    </div>
  );
};

export default AdminManageReferral;
