import { Alert } from "antd";
import { Link } from "react-router-dom";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import "./PortalForm.css";

type IPortalPreForm = {
  onNextClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const PortalPreForm: React.FC<IPortalPreForm> = ({ onNextClick }) => {
  const preFormBullet = [
    "Must have original content (articles, tips, news)",
    "Must have original content (articles, tips, news)",
    "Must have original content (articles, tips, news)",
    "Must have original content (articles, tips, news)",
  ];

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  return (
    <div className="w3-container">
      <div className="w3-col"></div>

      <div className="w3-col" style={{ marginTop: "20px" }}>
        <div className="w3-col l2  m2">
          <br />
        </div>

        <div className="w3-col l8 s12 m8">
          {(authData?.data?.credentials.publisherDashboard
            ?.noOfPendingPortals || 0) > 0 && (
            <Alert
              message={<b> Incomplete Portal </b>}
              description={
                <span>
                  you have an incomplete portal registration, Click
                  <Link to="/publisher/portals">
                    <u> here </u>
                  </Link>
                  to continue
                </span>
              }
              type="info"
              closable
              showIcon
            />
          )}

          <div className="w3-col w3-white w3-card w3-round w3-margin-top">
            <div className="w3-border-bottom  w3-padding">
              <h4 className="prePortalFormCardHeadeText w3-center">
                As a publisher to add portal, you must meet the following
                requirement
              </h4>
            </div>

            <div className="w3-padding" style={{ marginTop: "20px" }}>
              {preFormBullet.map((item: string, index: number) => (
                <p key={index}>
                  <span>
                    <img
                      style={{ width: "20px" }}
                      alt="heroImage"
                      src={`${
                        process.env.PUBLIC_URL +
                        "/images/landinghowitworkCheck.svg"
                      }`}
                    />
                    &nbsp;
                    <span className="prePortalBullet w3-padding">{item}</span>
                  </span>
                </p>
              ))}
            </div>

            <div
              className="w3-padding w3-center"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <button onClick={onNextClick} className="nextButton w3-btn">
                Continue
              </button>
            </div>
          </div>
        </div>

        <div className="w3-col l2 m2">
          <br />
        </div>
      </div>
    </div>
  );
};

export default PortalPreForm;
