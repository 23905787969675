import {
  CopyOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  ExclamationCircleFilled,
  FileAddOutlined,
  FolderAddOutlined,
  FormOutlined,
  LogoutOutlined,
  MoneyCollectOutlined,
  PhoneOutlined,
  PoundOutlined,
  UserAddOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import "./Sidebar.css";

type ISideBarType = {
  onLinkChange?: () => void;
  onUserType?: string;
};

const Sidebar: React.FC<ISideBarType> = ({ onLinkChange }) => {
  const location = useLocation();
  const [currentUrlPath, setCurrentUrlPath] = useState("");
  const [menu, setMenu] = useState<IMenuType[]>([]);
  const { confirm } = Modal;

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const navigate = useNavigate();

  type IMenuType = {
    icon: string | any;
    url: string;
    title: string;
    onClick?: () => void;
  };

  //Logout Confirmation
  const showLogoutConfirm = () => {
    confirm({
      title: "Are you sure you want to logout from this application.",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        navigate("/advertiser/logout");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const menuPublisher: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/publisher",
      title: "Dashboard",
    },
    {
      icon: <UserAddOutlined rev={undefined} />,
      url: "/publisher/task",
      title: "Task",
    },
    {
      icon: <CopyOutlined rev={undefined} />,
      url: "/publisher/portals",
      title: "Portal",
    },
    {
      icon: <FileAddOutlined rev={undefined} />,
      url: "/publisher/portal-form",
      title: "Add Portal",
    },
    {
      icon: <WalletOutlined rev={undefined} />,
      url: "/publisher/wallet",
      title: "Wallet",
    },
    {
      icon: <MoneyCollectOutlined rev={undefined} />,
      url: "/publisher/withdrawal",
      title: "WithDrawal",
    },
    {
      icon: <PoundOutlined rev={undefined} />,
      url: "/publisher/referral",
      title: "Referral",
    },
    {
      icon: <PhoneOutlined rev={undefined} />,
      url: "/publisher/support",
      title: "Support",
    },
    {
      icon: <LogoutOutlined rev={undefined} />,
      url: "/publisher/logout",
      title: "Logout",
      onClick: () => {
        showLogoutConfirm();
      },
    },
  ];

  const menuAdvertiser: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/advertiser",
      title: "Dashboard",
    },
    {
      icon: <FolderAddOutlined rev={undefined} />,
      url: "/advertiser/offer-add-intro",
      title: "Create Offer",
    },
    {
      icon: <DatabaseOutlined rev={undefined} />,
      url: "/advertiser/offers",
      title: "Offer",
    },
    {
      icon: <FormOutlined rev={undefined} />,
      url: "/advertiser/manage-content",
      title: "Get Content",
    },
    {
      icon: <WalletOutlined rev={undefined} />,
      url: "/advertiser/wallet",
      title: "Wallet",
    },
    {
      icon: <MoneyCollectOutlined rev={undefined} />,
      url: "/advertiser/payment",
      title: "Payment",
    },
    {
      icon: <PoundOutlined rev={undefined} />,
      url: "/advertiser/referral",
      title: "Referral",
    },
    {
      icon: <PhoneOutlined rev={undefined} />,
      url: "/advertiser/support",
      title: "Support",
    },
    {
      icon: <LogoutOutlined rev={undefined} />,
      url: "/advertiser/logout",
      title: "Logout",
      onClick: () => {
        showLogoutConfirm();
      },
    },
  ];

  useEffect(() => {
    // execute on location change
    setCurrentUrlPath(location.pathname);
    if (onLinkChange) {
      onLinkChange();
    }
    updateMenuType();
  }, [location, authData]);

  const updateMenuType = () => {
    // Used Url path to check
    if (currentUrlPath.includes("/publisher")) {
      setMenu(menuPublisher);
      // console.log(menu);
    } else if (currentUrlPath.includes("/advertiser")) {
      setMenu(menuAdvertiser);
    }

    // Used Redux value if it exist
    if (authData.data?.credentials.activeUserType === "Publisher") {
      setMenu(menuPublisher);
    } else if (authData.data?.credentials.activeUserType === "Advertiser") {
      setMenu(menuAdvertiser);
    }
  };

  return (
    <>
      <div className="w3-col">
        <div className="w3-padding">
          <Link to={"/"}>
            <img
              style={{ maxWidth: "100%" }}
              alt="logo"
              src={process.env.PUBLIC_URL + "/images/linkmieLogo.svg"}
            />
          </Link>
        </div>
        <div className="w3-col w3-padding">
          {menu.map((item: IMenuType, index: number) => (
            <div key={index}>
              {item.onClick ? (
                <button
                  onClick={item.onClick}
                  className={
                    "w3-btn botton " +
                    (currentUrlPath === item.url ? "buttonSelected" : "")
                  }
                >
                  <span
                    className={
                      "buttonText " +
                      (currentUrlPath === item.url
                        ? " buttonTextSelected "
                        : "")
                    }
                  >
                    {item.icon}
                    &nbsp;{item.title}
                  </span>
                </button>
              ) : (
                <Link
                  key={index}
                  className={
                    "w3-btn botton " +
                    (currentUrlPath === item.url ? "buttonSelected" : "")
                  }
                  to={item.url}
                >
                  <span
                    className={
                      "buttonText " +
                      (currentUrlPath === item.url
                        ? " buttonTextSelected "
                        : "")
                    }
                  >
                    {item.icon}
                    &nbsp;{item.title}
                  </span>
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
