import { Modal, notification, Tabs, TabsProps } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IOfferFormResponse } from "../../../apiservice/offers.type";
import { appZIndex } from "../../../utils/appconst";
import AdvertiserContentFormsOne from "../AdvertiserContentForms/AdvertiserContentForms-1";
import AdvertiserContentFormsTwo from "../AdvertiserContentForms/AdvertiserContentForms-2";
import AdvertiserContentFormsPayment from "../AdvertiserContentForms/AdvertiserContentForms-Payment";

import "./AdvertiserContentFormsWrapper.css";
type NotificationType = "success" | "info" | "warning" | "error";

export const AdvertiserContentFormsWrapper = () => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [formResultData, setFormResultData] =
    useState<IOfferFormResponse | null>(null);
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();

  const onChange = (key: string) => {
    // console.log(key);
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  const formSuccess = (resultData?: IOfferFormResponse) => {
    openNotificationWithIcon(
      "success",
      "",
      "Your Entry has being saved succesfully"
    );
    if (resultData) {
      console.log("Success", resultData);
      setFormResultData(resultData);
    }
    setOpenPaymentModal(true);
  };

  const paymentSuccess = () => {
    openNotificationWithIcon("success", "", "Your Payment has being made");
    navigate("/advertiser/manage-content-progress");
  };

  const failure = (err?: string) => {
    openNotificationWithIcon("error", "", err || "Error Saving your entry");
  };

  const handleCancelModal = () => {
    setOpenPaymentModal(false);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Create Guest Post",
      children: (
        <AdvertiserContentFormsOne
          onFormSuccess={formSuccess}
          onFormFailure={failure}
        ></AdvertiserContentFormsOne>
      ),
    },
    {
      key: "2",
      label: "Create Banner",
      children: (
        <AdvertiserContentFormsTwo
          onFormSuccess={formSuccess}
          onFormFailure={failure}
        ></AdvertiserContentFormsTwo>
      ),
    },
  ];

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-container">
        <Tabs
          type="card"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />

        <Modal
          zIndex={appZIndex.modal}
          open={openPaymentModal}
          title="Make Payment"
          onCancel={handleCancelModal}
          width={700}
          footer={[
            <p style={{ minHeight: "200px" }}>
              &nbsp; <br />
              &nbsp; <br />
            </p>,
          ]}
        >
          <p>
            <AdvertiserContentFormsPayment
              formResultData={formResultData}
              onPaymentSuccess={paymentSuccess}
              onPaymentFailure={failure}
            ></AdvertiserContentFormsPayment>
          </p>
        </Modal>
      </div>
    </>
  );
};

export default AdvertiserContentFormsWrapper;
