import "./SharedProfile-View.css";

export const SharedProfileViewActivityCard = () => {
  type IProfileActivityCard = {
    icon: string;
    activity: string;
    date: string;
  };

  const profileActivityCard: IProfileActivityCard[] = [
    {
      icon: "/images/shared-profile/recent-activity-icon.svg",
      activity: "you registered as advertiser on linkmie",
      date: "1/10/2023   12:34PM",
    },
    {
      icon: "/images/shared-profile/recent-activity-icon.svg",
      activity: "you edited your profile on linkmie ",
      date: "1/10/2023   12:34PM",
    },
  ];
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        {profileActivityCard.map((activity, index) => (
          <div key={index} className="recent-activity-wrapper">
            <div className="w3-col s1 l1">
              <span>
                <img src={activity.icon} alt="" />
              </span>
            </div>
            <div className="w3-col s7 l8">
              <p>{activity.activity}</p>
            </div>
            <div className="w3-col s4 l3">
              <p>{activity.date}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SharedProfileViewActivityCard;
