import { Button, Result, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { adminGetBannerRequest } from "../../../../apiservice/admin-Users-Clone-Service";
import { IAdminBannerRequestData } from "../../../../apiservice/admin-Users-clone.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import AdminContentRequestDetailsCardBanner from "./Admin-ContentRequest-Details-Card-Banner";
import "./Admin-ContentRequest-Details-Wrapper.css";

export const AdminContentRequestDetailsWrapperBanner = () => {
  const [contentLoadState, setContentLoadState] =
    useState<ILoadState>("loading");
  const [loadContentData, setLoadContentData] = useState(true);
  const [selectedContentData, setSelectedContentData] =
    useState<IAdminBannerRequestData | null>(null);
  const params = useParams();

  // A custom hook to Load Current/Active  based on the Url
  const resultCurrentContentData = useFormatApiRequest(
    () => adminGetBannerRequest(Number(params?.id)),
    loadContentData,
    () => {
      setLoadContentData(false);
    },
    () => {
      processCurrentContentResult();
    }
  );

  // Process The Current  Data Result
  const processCurrentContentResult = async () => {
    if (resultCurrentContentData.httpState === "SUCCESS") {
      setSelectedContentData(resultCurrentContentData?.data?.data);
      setContentLoadState("completed");
    } else if (resultCurrentContentData.httpState === "ERROR") {
      setContentLoadState("error");
    }
  };

  return (
    <>
      {/* " Show Loading Indicator" */}
      {contentLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {contentLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadContentData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {contentLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken create a new portal."
            extra={<Button type="primary">Go Back</Button>}
          />
        </div>
      )}

      {/* " Display form when  Load State is Not loading for new form entry & completed for editing old entry" */}
      {(contentLoadState === "notLoading" ||
        contentLoadState === "completed") && (
        <div className="w3-col w3-padding-bottom">
          <AdminContentRequestDetailsCardBanner
            refreshPage={() => {
              setLoadContentData(true);
            }}
            bannerRequest={selectedContentData}
          ></AdminContentRequestDetailsCardBanner>
        </div>
      )}
    </>
  );
};

export default AdminContentRequestDetailsWrapperBanner;
