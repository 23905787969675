import "./AdminManageAdmin-Create.css";
import CreateAdminForm from "../../../components/Admin-Component-Groups/Admin-Manage-Admin-Users-Comps/CreateAdmin-Form/CreateAdmin-Form";

export const AdminManageAdminCreate = () => {
  return (
    <div>
      <CreateAdminForm></CreateAdminForm>
    </div>
  );
};

export default AdminManageAdminCreate;
