import "./AdvertiserHome.css";
import AdvertiserDashboardprofile from "../../../components/Profile-User/Advertiser-Dashboard-profile/Advertiser-Dashboard-profile";

export const AdvertiserHome = () => {
  return (
    <AdvertiserDashboardprofile></AdvertiserDashboardprofile>
  );
};

export default AdvertiserHome;
