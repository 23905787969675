import "./AdminManagePublisher-Portals.css";
import AdminPortalListWrapper from "../../../components/Admin-Component-Groups/Admin-Manage-Portals-Comps/AdminPortalListWrapper/AdminPortalListWrapper";

export const AdminManagePublisherPortals = () => {
  return (
    <div>
      <AdminPortalListWrapper></AdminPortalListWrapper>
    </div>
  );
};

export default AdminManagePublisherPortals;
