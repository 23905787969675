import { Button, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPortal } from "../../../apiservice/portals.service";
import { IPortalFormType } from "../../../apiservice/portals.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppDispatch, useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { ILoadState } from "../../../utils/loading.utils.";
import { convertIPortalDataToIPortalData } from "../../../utils/portals.utils";
import PortalFormOne from "../PortalForms/PortalForm-1";
import PortalFormTwo from "../PortalForms/PortalForm-2";
import PortalFormThree from "../PortalForms/PortalForm-3";
import PortalFormFour from "../PortalForms/PortalForm-4";
import PortalFormFive from "../PortalForms/PortalForm-5";
import PortalFormCardWrapper from "../PortalForms/PortalForm-CardWrapper";
import PortalPreForm from "../PortalForms/PortalForm-Pre";
import "./PortalAddWrapper.css";

type NotificationType = "success" | "info" | "warning" | "error";

export const PortalAddWrapper = () => {
  const [portalFormIndex, setPortalFormIndex] = useState(-1);
  const [portalLoadState, setPortalLoadState] =
    useState<ILoadState>("notLoading");
  const [loadCurrentPortalData, setLoadCurrentPortalData] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const portalFormData: IPortalFormType = useAppSelector(
    (state: RootState) => state?.PortalForm
  );

  // A custom hook to Load Current/Active Portal based on the Url
  const resultCurrentPortalData = useFormatApiRequest(
    () => getPortal(Number(params?.portalId)),
    loadCurrentPortalData,
    () => {
      setLoadCurrentPortalData(false);
    },
    () => {
      processCurrentPortalResult();
    }
  );

  // Process The Current Portal Data Result
  const processCurrentPortalResult = async () => {
    if (resultCurrentPortalData.httpState === "SUCCESS") {
      dispatch({
        type: "PORTAL_UPDATE_ALL_FORMS",
        payload: convertIPortalDataToIPortalData(
          resultCurrentPortalData.data?.data
        ),
      });
      setPortalLoadState("completed");
      setPortalFormIndex(0);
    } else if (resultCurrentPortalData.httpState === "ERROR") {
      setPortalLoadState("error");
    }
  };

  // This is use to Update The Params Value
  useEffect(() => {
    if (params?.portalId) {
      dispatch({ type: "PORTAL_UPDATE_ID", payload: params?.portalId });
      setPortalLoadState("loading");
      setLoadCurrentPortalData(true);
    } else {
      dispatch({ type: "PORTAL_REMOVE_ALL_FORM" });
      navigate("/publisher/portal-form/");
    }
  }, []);

  // This is use to Update Redux Dispatch Values for Portal Stage Index
  useEffect(() => {
    if (portalFormData.stageIndex) {
      setIndex(Number(portalFormData.stageIndex));
    }
  }, []);

  // This is use to Update Redux Dispatch Values for Portal ID
  useEffect(() => {
    if (portalFormData.id) {
      navigate("/publisher/portal-form/" + portalFormData.id);
    } else {
      navigate("/publisher/portal-form/");
    }
  }, [portalFormData.id]);

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Set Index
  const setIndex = (index: number) => {
    setPortalFormIndex(index);
    dispatch({ type: "PORTAL_UPDATE_STAGE", payload: index });
  };

  const success = () => {
    openNotificationWithIcon(
      "success",
      "",
      "Your Entry has being saved succesfully"
    );

    if (portalFormIndex === 3) {
      openNotificationWithIcon(
        "success",
        "",
        "Your Portal Entry has being completed and will be review shortly"
      );
      dispatch({ type: "PORTAL_REMOVE_ALL_FORM" });
      setTimeout(() => {
        navigate("/publisher/portals");
      }, 1000);
    } else {
      setIndex(portalFormIndex + 1);
    }
  };

  const failure = (err?: string) => {
    openNotificationWithIcon("error", "", err || "Error Saving your entry");
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Indicator" */}
      {portalLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {portalLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={<Button onClick={() => setLoadCurrentPortalData(true)} type="primary">Reload</Button>}
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {portalLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken create a new portal."
            extra={<Button type="primary">Create a new Portal</Button>}
          />
        </div>
      )}

      {/* " Display form when Portal Load State is Not loading for new form entry & completed for editing old entry" */}
      {(portalLoadState === "notLoading" ||
        portalLoadState === "completed") && (
        <div className="w3-col w3-padding-bottom">
          {/* Display The Pre Portal Form Screen */}
          {portalFormIndex === -1 && (
            <PortalPreForm
              onNextClick={() => {
                setIndex(0);
              }}
            ></PortalPreForm>
          )}

          {/* This is the portal Form */}
          {portalFormIndex > -1 && (
            <PortalFormCardWrapper currentTabIndex={portalFormIndex}>
              {portalFormIndex === 0 && (
                <PortalFormOne
                  onPrevClick={() => {
                    setIndex(portalFormIndex - 1);
                  }}
                  onFormSuccess={success}
                  onFormFailure={failure}
                ></PortalFormOne>
              )}
              {portalFormIndex === 1 && (
                <PortalFormTwo
                  onPrevClick={() => {
                    setIndex(portalFormIndex - 1);
                  }}
                  onFormSuccess={success}
                  onFormFailure={failure}
                ></PortalFormTwo>
              )}
              {portalFormIndex === 2 && (
                <PortalFormThree
                  onPrevClick={() => {
                    setIndex(portalFormIndex - 1);
                  }}
                  onFormSuccess={success}
                  onFormFailure={failure}
                ></PortalFormThree>
              )}
              {portalFormIndex === 3 && (
                <PortalFormFour
                  onPrevClick={() => {
                    setIndex(portalFormIndex - 1);
                  }}
                  onFormSuccess={success}
                  onFormFailure={failure}
                ></PortalFormFour>
              )}
              {portalFormIndex === 4 && (
                <PortalFormFive
                  onPrevClick={() => {
                    setIndex(portalFormIndex - 1);
                  }}
                  onFormSuccess={success}
                  onFormFailure={failure}
                ></PortalFormFive>
              )}
            </PortalFormCardWrapper>
          )}
        </div>
      )}
    </>
  );
};

export default PortalAddWrapper;
