import { ExportOutlined } from "@ant-design/icons";
import { Modal, Button, notification } from "antd";
import { useState } from "react";
import { IAuthType } from "../../../apiservice/authService.type";
import useAuth from "../../../hooks/useAuth";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import { formatCurrency } from "../../../utils/basic.utils";
import WalletFormTopUp from "../walletForms/walletForms-TopUp";
import WalletFormTransfer from "../walletForms/walletForms-Transfer";
import WalletFormWithdrawal from "../walletForms/walletForms-Withdrawal";
import "./walletBalance.css";
type NotificationType = "success" | "info" | "warning" | "error";

export const WalletBalance = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [walletFormIndex, setWalletFormIndex] = useState(0);
  const [api, contextHolder] = notification.useNotification();

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const [loadAuth, setLoadAuth] = useState(false);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  // Set Index
  const setIndex = (index: number, modalTitle?: string) => {
    setWalletFormIndex(index);
    setModalTitle(modalTitle || "");
    setOpenModal(true);
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  const success = () => {
    openNotificationWithIcon("success", "", "Success, You may refresh page");
    handleCancelModal();
    setLoadAuth(true);
  };

  const failure = (err?: string) => {
    openNotificationWithIcon("error", "", err || "Error");
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };
  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      {/* Wallet Balance */}
      <div className="w3-col w3-margin-bottom">
        <div className="w3-col w3-border walletBalanceContainer">
          <div
            className="w3-col w3-right-align"
            style={{ marginBottom: "10px" }}
          >
            <span className=""></span>
            <br />
          </div>
          <div className="w3-center">
            <span className="yourBalanceText"> Wallet Balance </span>
            <br />
            <br />
            <span className="yourBalanceAmount">
              {formatCurrency(authData.data?.credentials.wallet.balance || 0)}
            </span>
          </div>
        </div>
      </div>

      {/* Wallet Menu Button */}
      <div className="w3-col w3-margin-bottom">
        {/* Transfer button */}
        <div className="w3-col l4 s4 m4" style={{ padding: "10px" }}>
          <div
            onClick={() => {
              setIndex(0, "Transfer");
            }}
            className="transferContainer"
          >
            <p style={{ marginBottom: "100px" }}>
              <br />
              <span
                className="transferTextColor w3-margin"
                style={{ zoom: 1.5 }}
              >
                <ExportOutlined rev={undefined} />
              </span>
              <br />
            </p>
            <p className="w3-center walletMenuButtonText transferTextColor">
              Transfer
            </p>
          </div>
        </div>

        {/* Received button */}
        <div className="w3-col l4 s4 m4" style={{ padding: "10px" }}>
          <div
            onClick={() => {
              setIndex(1, "Withdrawal");
            }}
            className="receivedContainer"
          >
            <p style={{ marginBottom: "100px" }}>
              <br />
              <span className="w3-text-white w3-margin" style={{ zoom: 1.5 }}>
                <ExportOutlined rev={undefined} />
              </span>
              <br />
            </p>
            <p className="w3-center walletMenuButtonText">Withdraw</p>
          </div>
        </div>

        {/* Top Up button */}
        <div className="w3-col l4 s4 m4" style={{ padding: "5px" }}>
          <div
            className="topUpContainer"
            onClick={() => {
              setIndex(2, "Top Up");
            }}
          >
            <p style={{ marginBottom: "100px" }}>
              <br />
              <span className="w3-text-white w3-margin" style={{ zoom: 1.5 }}>
                <ExportOutlined rev={undefined} />
              </span>
              <br />
            </p>
            <p className="w3-center walletMenuButtonText">Top Up</p>
          </div>
        </div>
      </div>

      {/* Life Time Earning*/}
      <div className="w3-col w3-margin-bottom">
        <div className="w3-col w3-border lifeTimeEarningContainer">
          <div
            className="w3-col w3-right-align"
            style={{ marginBottom: "5px" }}
          >
            <span className=""></span>
            <br />
          </div>
          <div className="w3-center">
            <span className="lifeTimeEarningText">
              {" "}
              Your Life Time Earning{" "}
            </span>
            <br />
            <br />
            <span className="lifeTimeEarningTotal">
              {formatCurrency(authData.data?.credentials.wallet.earnings || 0)}
            </span>
          </div>
        </div>
      </div>

      <Modal
        zIndex={appZIndex.modal}
        open={openModal}
        title={modalTitle}
        onCancel={handleCancelModal}
        width={700}
        footer={[
          <p style={{ minHeight: "200px" }}>
            &nbsp; <br />
            &nbsp; <br />
          </p>,
        ]}
      >
        {walletFormIndex === 0 && (
          <WalletFormTransfer
            onFormFailure={failure}
            onFormSuccess={success}
          ></WalletFormTransfer>
        )}
        {walletFormIndex === 1 && (
          <WalletFormWithdrawal
            onFormFailure={failure}
            onFormSuccess={success}
          ></WalletFormWithdrawal>
        )}
        {walletFormIndex === 2 && (
          <WalletFormTopUp
            onFormFailure={failure}
            onFormSuccess={success}
          ></WalletFormTopUp>
        )}
      </Modal>
    </>
  );
};

export default WalletBalance;
