import { Link } from "react-router-dom";
import { IAuthType } from "../../../../apiservice/authService.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import "./AddOfferIntro.css";

export const AddOfferIntro = () => {
  const preFormBullet = [
    "Ensure your offer is clear and concise",
    "Let your budget align with your expectations and the potential return on investment.",
    "Accurately define your target audience to attract publishers with the relevant audience base",
    "Use high-quality images or media that effectively represent your product or website.",
  ];

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  return (
    <div className="w3-container">
      <div className="w3-col w3-center">
        <h2 className="adevrtiserPreHeader"> Create an offer</h2>
        <p className="adevrtiserSubHeader">
          Hey! {authData.data?.credentials.fullName} What are your advertising
          goals?
          <br /> Create an attractive offer highlighting your advertising goals
          and connect with the right publishers to promote your product or
          website.
        </p>
      </div>

      <div className="w3-col" style={{ marginTop: "20px" }}>
        <div className="w3-col l6 s12 m12">
          <img
            style={{ maxWidth: "100%" }}
            alt="heroImage"
            src={`${
              process.env.PUBLIC_URL + "/images/offers/create-offer-intro.svg"
            }`}
          />
          <br />
        </div>

        <div className="w3-col l6 s12 m12">
          <div className="w3-col w3-margin-top">
            <div className="w3-padding">
              <h4 className="tipsHeader">Tips for creating an offer</h4>
            </div>

            <div className="w3-padding" style={{ marginTop: "20px" }}>
              {preFormBullet.map((item: string, index: number) => (
                <p key={index}>
                  <span>
                    <img
                      style={{ width: "20px" }}
                      alt="heroImage"
                      src={`${
                        process.env.PUBLIC_URL +
                        "/images/landinghowitworkCheck.svg"
                      }`}
                    />
                    &nbsp;
                    <span className="offerBullet w3-padding">{item}</span>
                  </span>
                </p>
              ))}
            </div>

            <div
              className="w3-padding"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Link to="/advertiser/offer-add-select-Portal">
                <button className="nextButton w3-btn">Continue</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOfferIntro;
