import { ActivitiesList } from "../activities-list/activities-list";
import "./activities-list-wrapper.css";

export const ActivitiesListWrapper = () => {
  return (
    <>
      <div>
        <div className="w3-container">
          <div className="w3-col w3-content">
            <h2 className="recent-activity-header">Recent activities</h2>
          </div>
          <div className="w3-col l12 s12 m12" style={{ padding: "5px" }}>
            <ActivitiesList
              initialDefaultFilter={{ perPage: 5 }}
            ></ActivitiesList>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivitiesListWrapper;
