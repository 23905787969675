import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  authConfirmToken,
  authSendverifyEmail,
} from "../../../apiservice/authService";
import { IAuthType } from "../../../apiservice/authService.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import useAuth from "../../../hooks/useAuth";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import UserAuthWrapper, {
  IOpenNotificationType,
} from "../userAuthWrapper/userAuthwrapper";
import "./../userAuth.css";

const UserSignupVerifyEmail = () => {
  const [loadTokenApi, setLoadTokenApi] = useState(true);
  const [formLoading, setFormLoading] = useState<boolean>(true);
  const [loadApi, setLoadApi] = useState(false);
  const [payload, setPayload] = useState<any>({});
  const [notificationMessage, setNotificationMessage] =
    useState<IOpenNotificationType | null>(null);

  const navigate = useNavigate();
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPayload((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  // A custom hook to Send Token
  const resultSendToken = useFormatApiRequest(
    () => authSendverifyEmail(),
    loadTokenApi,
    () => {
      setLoadTokenApi(false);
    },
    () => {
      processTokenApiResult();
    }
  );

  // Process Api Token Result
  const processTokenApiResult = async () => {
    if (resultSendToken.httpState === "SUCCESS") {
      setFormLoading(false);
      // Handle Success Here
      setNotificationMessage({
        type: "info",
        message: "",
        description: "A code has being sent to your email",
        background: "#D9FFB5",
      });
    } else if (resultSendToken.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      setNotificationMessage({
        type: "info",
        message: "",
        description: "Error in sending code",
        background: "#FFC2B7",
      });
    }
  };

  // A custom hook to format confirm OTP
  const result = useFormatApiRequest(
    () => authConfirmToken(payload),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      // Handle Success Here
      setNotificationMessage({
        type: "info",
        message: "",
        description: "Your Email has being verified",
        background: "#D9FFB5",
      });
      setTimeout(() => {
        if (authData.data?.credentials.activeUserType === "Publisher") {
          navigate("/publisher");
        } else if (authData.data?.credentials.activeUserType === "Advertiser") {
          navigate("/advertiser");
        }
      }, 2500);
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      setNotificationMessage({
        type: "info",
        message: "",
        description:
          result.data?.response?.data?.message ||
          result.errorMsg ||
          "Wrong Token",
        background: "#FFC2B7",
      });
    }
  };

  return (
    <>
      <UserAuthWrapper notificationMessage={notificationMessage}>
        <div>
          <div className="w3-col" style={{ paddingTop: "40px" }}>
            <div className="w3-col l8 s8 m8">
              <span className="getStartedText"> Verify your email </span>
              <br />
              <span className="verifyEmailText">
                A 6 digit code as been sent to{" "}
                {authData.data?.credentials?.email} ,check your email.
              </span>
            </div>
            <div className="w3-col l4 s4 m4">
              <span className="w3-right">
                <Link to="/userauth/signin">
                  <LeftOutlined style={{ zoom: "1.5" }} rev={undefined} />
                </Link>
              </span>
            </div>
          </div>
          <div
            className="w3-col"
            style={{ paddingTop: "60px", marginBottom: "60px" }}
          >
            <form onSubmit={handleSubmit}>
              <div className="w3-col w3-margin-bottom">
                <Input
                  required
                  type="number"
                  name="code"
                  value={payload?.code}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="OTP Code"
                />
              </div>
              <div className="w3-row">
                <div
                  className="w3-col l6 m6 s6 w3-margin-bottom w3-margin-top"
                  style={{ padding: "5px" }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setLoadTokenApi(true);
                      setFormLoading(true);
                    }}
                    disabled={formLoading}
                    className="w3-btn w3-col submitButton btnGreen"
                  >
                    <span className="submitButtonText">
                      {!formLoading ? (
                        "Reset OTP"
                      ) : (
                        <LoadingOutlined rev={undefined} />
                      )}
                    </span>
                  </button>
                </div>

                <div
                  className="w3-col l6 m6 s6 w3-margin-bottom w3-margin-top"
                  style={{ padding: "5px" }}
                >
                  <button
                    disabled={formLoading}
                    className="w3-btn w3-col submitButton"
                  >
                    <span className="submitButtonText">
                      {!formLoading ? (
                        "Continue"
                      ) : (
                        <LoadingOutlined rev={undefined} />
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </UserAuthWrapper>
    </>
  );
};

export default UserSignupVerifyEmail;
