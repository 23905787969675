import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";

export const sampleApiCall = async (body?: any) => {
  const axios = await instance("", "https://dummyjson.com/", true);
  const { data } = await axios.get("/todos");
  const result: any = await data;
  return result;
};

// Advrtiser Portals
export const getAdvertiserOfferPortals = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/advertiser/portals${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getAdvertiserOfferPortal = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/advertiser/portals/${id}`);
  const result: any = await data;
  return result;
};

// Offers forms

export const advertiserAddBannerOffer = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/v1/advertiser/offers/banners", body);
  const result: any = await data;
  return result;
};

export const advertiserAddGuestPostOffer = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(
    "api/v1/advertiser/offers/guest-posts",
    body
  );
  const result: any = await data;
  return result;
};

export const advertiserAddLinkInsertionOffer = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(
    "api/v1/advertiser/offers/link-insertions",
    body
  );
  const result: any = await data;
  return result;
};

export const getAdvertiserOffers = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/advertiser/offers${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getAdvertiserOffer = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/advertiser/offers/${id}`);
  const result: any = await data;
  return result;
};

// Banner Request
export const advertiserAddBannerRequest = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post("api/v1/advertiser/banner-requests", body);
  const result: any = await data;
  return result;
};

export const getBannerRequests = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/advertiser/banner-requests${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getBannerRequest = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/advertiser/banner-requests/${id}`);
  const result: any = await data;
  return result;
};

export const acceptBannerRequest = async (id: string | number | null) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch(
    `api/v1/advertiser/banner-requests/${id}/approve`
  );
  const result: any = await data;
  return result;
};

export const rejectBannerRequest = async (
  id: string | number | null,
  body?: any
) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch(
    `api/v1/advertiser/banner-requests/${id}/reject`,
    body
  );
  const result: any = await data;
  return result;
};

// Guest Post Request
export const advertiserAddGuestPostRequest = async (body: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(
    "api/v1/advertiser/guestpost-requests",
    body
  );
  const result: any = await data;
  return result;
};

export const getGuestPostRequests = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/advertiser/guestpost-requests${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getGuestPostRequest = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/advertiser/guestpost-requests/${id}`
  );
  const result: any = await data;
  return result;
};

export const acceptGuestPostRequest = async (id: string | number | null) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch(
    `api/v1/advertiser/guestpost-requests/${id}/approve`
  );
  const result: any = await data;
  return result;
};

export const rejectGuestPostRequest = async (
  id: string | number | null,
  body?: any
) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.patch(
    `api/v1/advertiser/guestpost-requests/${id}/reject`,
    body
  );
  const result: any = await data;
  return result;
};

// Confirm Offer Task
export const confirmOfferTask = async (id: string | number | null) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(`api/v1/advertiser/tasks/${id}/confirm`);
  const result: any = await data;
  return result;
};
