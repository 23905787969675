import "./Admin-Settings-form.css";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import { IAdminAuthType } from "../../../../apiservice/admin-AuthService.type";
import { useEffect, useState } from "react";
import { adminSaveSettings } from "../../../../apiservice/admin-AuthService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { LoadingOutlined } from "@ant-design/icons";
import { Input, notification } from "antd";
import useAuth from "../../../../hooks/useAuth";

type NotificationType = "success" | "info" | "warning" | "error";
export const AdminSettingsform = () => {
  const [api, contextHolder] = notification.useNotification();

  const authData: IAdminAuthType = useAppSelector(
    (state: RootState) => state?.AdminAuthData
  );
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [payLoad, setPayLoad] = useState<any>({});
  const [loadApi, setLoadApi] = useState(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  // This is use to Update Redux Dispatch Values
  useEffect(() => {
    setPayLoad({ ...authData.data?.credentials.settings });
  }, []);

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setFormLoading(true);
    setLoadApi(true);
  };

  // A custom hook to format the Sign Up Api
  const result = useFormatApiRequest(
    () => adminSaveSettings(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      // Handle Success Here
      openNotificationWithIcon("success", "", "Settings Saved");
      setLoadAuth(true);
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      // Handle Error Here
      openNotificationWithIcon(
        "error",
        "",
        result.data?.response?.data?.message || result.errorMsg
      );
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        <div style={{ paddingTop: "10px" }}>
          <form onSubmit={handleSubmit}>
            {/* Minimum Domain Authority */}
            <div className="w3-col w3-margin-bottom">
              <span> Set Minimum Domain Authority </span>
              <Input
                name="minimumDomainAuthority"
                value={payLoad?.minimumDomainAuthority}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Minumum Domain Authority"
              />
            </div>

            {/* minimumTraffic */}
            <div className="w3-col w3-margin-bottom">
              <span> Set Minimum Traffic </span>
              <Input
                name="minimumTraffic"
                value={payLoad?.minimumTraffic}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder=" Minimum Traffic"
              />
            </div>

            {/*Referral Percentage */}
            <div className="w3-col w3-margin-bottom">
              <span> Referral Percentage</span>
              <Input
                name="referralPercentage"
                value={payLoad?.referralPercentage}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Referral Percentage"
              />
            </div>

            {/* Referral Withdrawal Limit  */}
            <div className="w3-col w3-margin-bottom">
              <span>Referral Withdrawal Limit </span>
              <Input
                name="referralWithdrawalLimit"
                value={payLoad?.referralWithdrawalLimit}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Referral Withdrawal Limit"
              />
            </div>

            {/* Wallet Withdrawal Limit */}
            <div className="w3-col w3-margin-bottom">
              <span> Wallet Withdrawal Limit </span>
              <Input
                name="walletWithdrawalLimit"
                value={payLoad?.walletWithdrawalLimit}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder=" Wallet Withdrawal Limit "
              />
            </div>

            {/* USD To Naira Exchange Rate */}
            <div className="w3-col w3-margin-bottom">
              <span> USD To Naira Exchange Rate </span>
              <Input
                name="usdToNairaExchangeRate"
                value={payLoad?.usdToNairaExchangeRate}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder=" USD To Naira Exchange Rate"
              />
            </div>

            {/* Banner Request Price */}
            <div className="w3-col w3-margin-bottom">
              <span> Banner Request Price </span>
              <Input
                name="bannerRequestPrice"
                value={payLoad?.bannerRequestPrice}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Banner Request Price"
              />
            </div>

            {/* Guest Post Request Price */}
            <div className="w3-col w3-margin-bottom">
              <span> Guest Post Request Price </span>
              <Input
                name="guestPostRequestPrice"
                value={payLoad?.guestPostRequestPrice}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Guest Post Request Price"
              />
            </div>

            {/* Domain Check Expire */}
            <div className="w3-col w3-margin-bottom">
              <span> Domain Check Expire </span>
              <Input
                name="domainCheckExpiryDays"
                value={payLoad?.domainCheckExpiryDays}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Domain Check Expire"
              />
            </div>

            {/* Domain Check Maximum Calls */}
            <div className="w3-col w3-margin-bottom">
              <span> Domain Check Maximum Calls </span>
              <Input
                name="domainCheckMaxCalls"
                value={payLoad?.domainCheckMaxCalls}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Domain Check Maximum Calls"
              />
            </div>

            {/* Portal Price Percentage Charge */}
            <div className="w3-col w3-margin-bottom">
              <span> Portal Price Percentage Charge </span>
              <Input
                name="portalPricePercentageCharge"
                value={payLoad?.portalPricePercentageCharge}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Portal Price Percentage Charge"
              />
            </div>

            <div className="w3-col w3-margin-bottom w3-margin-top">
              <button
                disabled={formLoading}
                className="w3-btn w3-col w3-round adminColorBtn"
              >
                <span>
                  {!formLoading ? "Save" : <LoadingOutlined rev={undefined} />}
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminSettingsform;
