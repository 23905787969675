import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";

export const getSiteCategories = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/site-categories${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getSiteCategory = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/site-categories/${id}`);
  const result: any = await data;
  return result;
};

export const getSiteSubCategories = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/site-subcategories${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getSiteSubCategory = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/site-subcategories/${id}`);
  const result: any = await data;
  return result;
};

export const getSiteNiches = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/site-niches${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getSiteNiche = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/site-niches/${id}`);
  const result: any = await data;
  return result;
};

export const getLinkValidities = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/link-validities${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getLinkValidity = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/link-validities/${id}`);
  const result: any = await data;
  return result;
};

