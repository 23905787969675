import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPublisherTasks } from "../../../apiservice/tasks.service";
import { IPublisherTaskData } from "../../../apiservice/tasks.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { formatCurrency } from "../../../utils/basic.utils";
import { convertToShortDate } from "../../../utils/date.utils";
import { ILoadState } from "../../../utils/loading.utils.";
import "./PublisherTask-List.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IPublisherTaskList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const PublisherTaskList: React.FC<IPublisherTaskList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [taskLoadState, setTaskLoadState] = useState<ILoadState>("loading");
  const [loadTasksData, setLoadTasksData] = useState(true);
  const [taskDefaultFilter, setTaskDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setTaskDefaultFilter({
        ...taskDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadTasksData(true);
      setTaskLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const PortalDataResult = useFormatApiRequest(
    () => getPublisherTasks(taskDefaultFilter),
    loadTasksData,
    () => {
      setLoadTasksData(false);
    },
    () => {
      processPortalsResult();
    }
  );

  // Process The Current Portal Data Result
  const processPortalsResult = async () => {
    if (PortalDataResult.httpState === "SUCCESS") {
      setTableData(PortalDataResult.data?.data);
      setTotalItems(PortalDataResult.data?.meta?.total || 1);
      setTaskLoadState("completed");
    } else if (PortalDataResult.httpState === "ERROR") {
      setTaskLoadState("error");
    } else if (PortalDataResult.httpState === "LOADING") {
      setTaskLoadState("loading");
    }
  };
  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<IPublisherTaskData> = [
    {
      title: "Action",
      dataIndex: "id",
      key: "select",
      fixed: "left",
      width: 120,
      render: (text, records, index) => (
        <div className="w3-center">
          <Link
            to={`/publisher/task/${records.offerId}`}
            className="w3-btn taskViewBtn w3-white w3-border w3-border-blue"
          >
            View Task
          </Link>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "adType",
      key: "adType",
      render: (text, records, index) => <span>{records.offer.adType}</span>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, records, index) => (
        <span>{records.offer.category.title}</span>
      ),
    },
    {
      title: "Advertiser Name",
      dataIndex: "advertiserName",
      key: "advertiserName",
      render: (text, records, index) => (
        <span>{records.offer.user.fullName}</span>
      ),
    },
    {
      title: "Payment Made",
      dataIndex: "amount",
      key: "amount",
      render: (text, records, index) => <span>{formatCurrency(text)}</span>,
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setTaskDefaultFilter({
      ...taskDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadTasksData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {taskLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadTasksData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {taskLoadState !== "error" && (
        <>
          <div className="w3-col w3-margin-top">
            <Table
              loading={taskLoadState === "loading"}
              rowKey="id"
              size="small"
              columns={columns}
              scroll={{ x: 950 }}
              dataSource={tableData}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PublisherTaskList;
