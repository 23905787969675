import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";

// Get Portals
export const adminGetPortals = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/portals${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetPortal = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/admin/portals/${id}`);
  const result: any = await data;
  return result;
};

export const adminRejectPortal = async (id: string | Number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(`api/v1/admin/portals/${id}/reject`);
  const result: any = await data;
  return result;
};
export const adminApprovedPortal = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(`api/v1/admin/portals/${id}/approve`);
  const result: any = await data;
  return result;
};

// Get Offers
export const adminGetOffers = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/offers${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetOffer = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/admin/offers/${id}`);
  const result: any = await data;
  return result;
};

// Get Tasks
export const adminGetTasks = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/tasks${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetTask = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/admin/tasks/${id}`);
  const result: any = await data;
  return result;
};

//Get Referrals
export const adminGetReferrals = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/referrals${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetReferral = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/admin/referrals/${id}`);
  const result: any = await data;
  return result;
};

// Get Transactions
export const adminGetTransactions = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/transactions${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetTransaction = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/admin/transactions/${id}`);
  const result: any = await data;
  return result;
};

// Get Withdrwal
export const adminGetWithdrawals = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/withdrawals${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetWithdrawal = async (
  id: string | number,
  type?: number
) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/withdrawals/${id}?type=${type || 1}`
  );
  const result: any = await data;
  return result;
};

export const adminCompleteWithdrawalWallet = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(`api/v1/admin/withdrawals/complete`, body);
  const result: any = await data;
  return result;
};

export const adminRejectWithdrawalWallet = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post(`api/v1/admin/withdrawals/reject`, body);
  const result: any = await data;
  return result;
};

// Get Banner Request
export const adminGetBannerRequests = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/banner-requests${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetBannerRequest = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/admin/banner-requests/${id}`);
  const result: any = await data;
  return result;
};

export const adminAssignBannerRequest = async (
  id: string | number,
  body: any
) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch(
    `api/v1/admin/banner-requests/${id}/assign`,
    body
  );
  const result: any = await data;
  return result;
};

export const adminCompleteBannerRequest = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch(
    `api/v1/admin/banner-requests/${id}/complete`
  );
  const result: any = await data;
  return result;
};

export const adminUpdateBannerRequest = async (
  id: string | number,
  body: any
) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch(
    `api/v1/admin/banner-requests/${id}/image`,
    body
  );
  const result: any = await data;
  return result;
};

// Get Guest Post Request
export const adminGetGuestPostRequests = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/guest-post-requests${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetGuestPostRequest = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/admin/guest-post-requests/${id}`);
  const result: any = await data;
  return result;
};

export const adminAssignPostRequest = async (
  id: string | number,
  body?: any
) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch(
    `api/v1/admin/guest-post-requests/${id}/assign`,
    body
  );
  const result: any = await data;
  return result;
};

export const adminCompletePostRequest = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch(
    `api/v1/admin/guest-post-requests/${id}/complete`
  );
  const result: any = await data;
  return result;
};

export const adminUpdatePostRequest = async (
  id: string | number,
  body: any
) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch(
    `api/v1/admin/guest-post-requests/${id}`,
    body
  );
  const result: any = await data;
  return result;
};

export const adminUpdatePostRequestArticle = async (
  id: string | number,
  body: any
) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch(
    `api/v1/admin/guest-post-requests/${id}/article`,
    body
  );
  const result: any = await data;
  return result;
};

//Site Category
export const adminGetCategories = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/site-categories${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetCategory = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/admin/site-categories/${id}`);
  const result: any = await data;
  return result;
};

export const adminUpdateCategory = async (id: string | number, body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch(
    `api/v1/admin/site-categories/${id}`,
    body
  );
  const result: any = await data;
  return result;
};

export const adminAddCategory = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post("api/v1/admin/site-categories", body);
  const result: any = await data;
  return result;
};

// Sub Category
export const adminGetSubCategories = async (body?: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(
    `api/v1/admin/site-subcategories${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const adminGetSubCategory = async (id: string | number) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.get(`api/v1/admin/site-subcategories/${id}`);
  const result: any = await data;
  return result;
};

export const adminAddSubCategory = async (body: any) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.post("api/v1/admin/site-subcategories", body);
  const result: any = await data;
  return result;
};

export const adminUpdateSubCategory = async (
  id: string | number,
  body: any
) => {
  const axios = await instance(null, null, true, true);
  const { data } = await axios.patch(
    `api/v1/admin/site-subcategories/${id}`,
    body
  );
  const result: any = await data;
  return result;
};
