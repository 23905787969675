import "./AdminManageGuestPostRequest.css";
import AdminContentRequestListWrapperGuestPost from "../../../components/Admin-Component-Groups/Admin-Manage-ContentRequest-Comp/Admin-ContentRequest-List-Wrapper/Admin-ContentRequest-List-Wrapper-GPost";

export const AdminManageGuestPostRequest = () => {
  return (
    <div>
      <AdminContentRequestListWrapperGuestPost></AdminContentRequestListWrapperGuestPost>
    </div>
  );
};

export default AdminManageGuestPostRequest;
