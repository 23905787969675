import instance from "../utils/axios.wrapper";
import { convertObjToQueryParams } from "../utils/basic.utils";

// Get Transaction
export const getTransactions = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/transactions${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getTransaction = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/transactions/${id}`);
  const result: any = await data;
  return result;
};

//Get Withdrawal
export const getWithdrawals = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(
    `api/v1/withdrawals${convertObjToQueryParams(body)}`
  );
  const result: any = await data;
  return result;
};

export const getWithdrawal = async (id: string | number) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.get(`api/v1/withdrawals/${id}`);
  const result: any = await data;
  return result;
};

// Transaction Action

export const topUpTransaction = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(`api/v1/transactions/topup`, body);
  const result: any = await data;
  return result;
};

export const transferTransaction = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(`api/v1/transactions/transfer`, body);
  const result: any = await data;
  return result;
};

export const withDrawTransaction = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(`api/v1/transactions/withdraw`, body);
  const result: any = await data;
  return result;
};

export const withDrawReferralTransaction = async (body?: any) => {
  const axios = await instance(null, null, true);
  const { data } = await axios.post(
    `api/v1/referral-transactions/withdraw`,
    body
  );
  const result: any = await data;
  return result;
};

export const payPalCreateOrderLink = () => {
  return (
    process.env.REACT_APP_API_URL +
    "/api/v1/payments/paypal/sandbox/create-order"
  );
};

export const payPalCaptureOrderLink = () => {
  return (
    process.env.REACT_APP_API_URL +
    "/api/v1/payments/paypal/sandbox/capture-order"
  );
};
