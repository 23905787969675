import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Alert, Input, Radio, Tooltip } from "antd";
import { useState } from "react";
import { IAuthType } from "../../../../apiservice/authService.type";
import {
  advertiserAddGuestPostOffer,
  advertiserAddGuestPostRequest,
} from "../../../../apiservice/offers.service";
import {
  IOfferPricing,
  IOfferPortalsData,
  IOfferFormResponse,
} from "../../../../apiservice/offers.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import { appZIndex } from "../../../../utils/appconst";
import { getFormData } from "../../../../utils/basic.utils";
import "./OfferForm.css";

type IOfferFormTwo = {
  onFormSuccess?: (resultData?: IOfferFormResponse) => void;
  onFormFailure?: (err?: string) => void;
};

export const OfferFormTwo: React.FC<IOfferFormTwo> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApiGuestPost, setLoadApiGuestPost] = useState(false);
  const [loadApiGuestPostRequest, setLoadApiGuestPostRequest] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [payLoadFormData, setpayLoadFormData] = useState<FormData>(
    getFormData({})
  );
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const [selectedCategoryPricing, setSelectedCategoryPricing] =
    useState<IOfferPricing | null>(null);

  const selectedOfferPortalData: IOfferPortalsData = useAppSelector(
    (state: RootState) => state?.SelectedOfferPortal
  );

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const requestAmount =
    authData.data?.credentials?.settings?.guestPostRequestPrice;

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value =
      name === "articleFile" ? event.target.files[0] : event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));

    // If categoryId changes then get the current offer portal price Object
    if (name === "categoryId") {
      if (value) {
        setSelectedCategoryPricing(getSelectedCategoryPricing(value));
      } else {
        setSelectedCategoryPricing(null);
      }
    }
  };

  // Get the selected Offer Pricing
  const getSelectedCategoryPricing = (id: number) => {
    return (
      selectedOfferPortalData.offerPricings.filter(
        (offerPricing) => offerPricing.id === Number(id)
      )?.[0] || null
    );
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (selectedCategoryPricing?.price.guestPost && payLoad?.haveGuestPost) {
      if (payLoad?.haveGuestPost === 1) {
        setpayLoadFormData(
          getFormData({
            ...payLoad,
            portalId: selectedOfferPortalData.id,
            amount: selectedCategoryPricing?.price.guestPost,
          })
        );
        setLoadApiGuestPost(true);
        setFormLoading(true);
      } else if (payLoad?.haveGuestPost === 2) {
        setpayLoad({
          ...payLoad,
          portalId: selectedOfferPortalData.id,
          amount: selectedCategoryPricing?.price.guestPost + requestAmount,
          requestAmount: requestAmount,
          offerAmount: selectedCategoryPricing?.price.guestPost,
        });
        // alert("Adding Guest Post not active Yet");
        setLoadApiGuestPostRequest(true);
      }
    } else {
      alert("Please Invalid.....");
    }
  };

  // A custom hook to save Guest Post
  const resultGuestPost = useFormatApiRequest(
    () => advertiserAddGuestPostOffer(payLoadFormData),
    loadApiGuestPost,
    () => {
      setLoadApiGuestPost(false);
    },
    () => {
      processGuestPostFormApi();
    }
  );

  // Process Guest Post
  const processGuestPostFormApi = async () => {
    if (resultGuestPost.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess(resultGuestPost.data);
      }
      // Handle Success Here
    } else if (resultGuestPost.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(
          resultGuestPost.data?.response?.data?.message ||
            resultGuestPost.errorMsg
        );
      }
      //Handle Error Here
    }
  };

  // A custom hook to save Guest Post Request
  const resultGuestPostRequest = useFormatApiRequest(
    () => advertiserAddGuestPostRequest(payLoad),
    loadApiGuestPostRequest,
    () => {
      setLoadApiGuestPostRequest(false);
    },
    () => {
      processGuestPostRequestFormApi();
    }
  );

  // Process Guest Post Request
  const processGuestPostRequestFormApi = async () => {
    if (resultGuestPostRequest.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess(resultGuestPostRequest.data);
      }
      // Handle Success Here
    } else if (resultGuestPostRequest.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(
          resultGuestPostRequest.data?.response?.data?.message ||
            resultGuestPostRequest.errorMsg
        );
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Forms Here */}
        <div>
          {/* Select Category */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Choose Categeory <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title=" Choose a relevant category for your website to help advertisers find the right audience for their ads."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <select
                required
                name="categoryId"
                value={payLoad?.categoryId || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputSelect"
              >
                <option value="">Select Category</option>
                {selectedOfferPortalData.offerPricings.map(
                  (item: IOfferPricing, index: number) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          {/* Catgory Details Box */}
          <div className="w3-col w3-margin-bottom">
            <Alert
              description={
                <span>
                  {selectedCategoryPricing
                    ? selectedCategoryPricing?.description
                    : "-----"}
                  <br />
                  <span>
                    {" "}
                    Guest Post price for the Category{" "}
                    {selectedCategoryPricing && selectedCategoryPricing?.title}
                    {" is "}
                    {"$"}
                    {selectedCategoryPricing
                      ? selectedCategoryPricing?.price.guestPost
                      : "0"}
                  </span>
                </span>
              }
              style={{ width: "92%" }}
              type="info"
              showIcon
            />
          </div>

          {/* Do You have Guest Post */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Do you have a guest post? <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Indicate if you offer guest posting opportunities on your website for advertisers."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9">
              <span>
                <Radio.Group
                  name="haveGuestPost"
                  onChange={handleInputChange}
                  value={payLoad?.haveGuestPost || ""}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </span>
            </div>
          </div>

          {/* When You  have Guest Post */}
          {payLoad?.haveGuestPost === 1 && (
            <div>
              {/* Upload Article */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Upload Article <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Upload your articles for publisher to review."
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <input
                    type="file"
                    name="articleFile"
                    className="w3-col inputFieldUpload"
                    accept="image/*,.pdf,.docx,.ppt"
                    onChange={handleInputChange}
                    // value={payLoad?.articleFile || ""}
                    style={{ width: "90%" }}
                  />
                </div>
              </div>
              {/* Topic of Article */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Article Topic <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Specify the topic of your articles."
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="topic"
                    value={payLoad?.topic || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Topic of Article"
                  />
                </div>
              </div>
              {/* Main Link */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Main Link <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Enter the main URL where you want the the content to be published"
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="mainLink"
                    value={payLoad?.mainLink || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Main Link"
                  />
                </div>
              </div>
              {/* Anchor Text */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Add Anchor Text <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Specify the clickable text that user will see."
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="anchorText"
                    value={payLoad?.anchorText || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Anchor Text"
                  />
                </div>
              </div>
              {/* Anchor Link */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Anchor Link <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Specify the URL that corresponds to the anchor text"
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="anchorLink"
                    value={payLoad?.anchorLink || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Anchor Link"
                  />
                </div>
              </div>
              {/* Keywords */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Key Words <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="List keyword or phrase that are relevant to your guestpost"
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="keywords"
                    value={payLoad?.keywords || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Key Words"
                  />
                </div>
              </div>
              {/* Other Requirement */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Other Requirements<span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Details any specific requirement or guideline for the content"
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="otherRequirements"
                    value={payLoad?.otherRequirements || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Other Requirements"
                  />
                </div>
              </div>
              {/* Article */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Article (Optional)
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      {/* <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Specify the topic of your articles to help advertisers find content that aligns with their goals."
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip> */}
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <textarea
                    name="articleText"
                    value={payLoad?.articleText || ""}
                    style={{ width: "90%", height: "200px", resize: "none" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Article"
                  ></textarea>
                </div>
              </div>
            </div>
          )}

          {/* Two Part When you don't have guest Post*/}
          {payLoad?.haveGuestPost === 2 && (
            <div>
              {/* Topic of Article */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Article Topic <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Specify the topic of your articles to help advertisers find content that aligns with their goals. "
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="topic"
                    value={payLoad?.topic || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Topic of Article"
                  />
                </div>
              </div>

              {/* Main Link */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Main Link <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Enter the main URL where you want the the content to be published"
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="mainLink"
                    value={payLoad?.mainLink || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Main Link"
                  />
                </div>
              </div>

              {/* Anchor Text */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Anchor Text<span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Specify the clickable text that user will see."
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="anchorText"
                    value={payLoad?.anchorText || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Anchor Text"
                  />
                </div>
              </div>

              {/* Anchor Link */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Anchor Link <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Specify the URL that corresponds to the anchor text"
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="anchorLink"
                    value={payLoad?.anchorLink || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Anchor Link"
                  />
                </div>
              </div>
              {/* Keywords */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Key Words <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="List keyword or phrase that are relevant to the content you are requesting."
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="keywords"
                    value={payLoad?.keywords || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Key Words"
                  />
                </div>
              </div>
              {/* Other Requirement */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Other Requirements<span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Details any specific requirement or guideline for the content"
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    name="otherRequirements"
                    value={payLoad?.otherRequirements || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Other Requirements"
                  />
                </div>
              </div>
              {/* Number of Words */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Word Count <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Specify the desired word count for the content you're requesting."
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <Input
                    required
                    type="number"
                    name="noOfWords"
                    value={payLoad?.noOfWords || ""}
                    style={{ width: "90%" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Number of Words"
                  />
                </div>
              </div>
              {/*  Describe Your Guest Post */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-padding">
                  <span>
                    Describe Your Guest Post
                    <span className="w3-text-red">*</span>
                    <span className="tooltips" style={{ marginLeft: "5px" }}>
                      <Tooltip
                        color={"#2f3542"}
                        placement="right"
                        title="Briefly describe the content you want."
                        zIndex={appZIndex.tooltips}
                      >
                        <InfoCircleOutlined rev={undefined} />
                      </Tooltip>
                    </span>
                  </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-center">
                  <textarea
                    required
                    name="description"
                    value={payLoad?.description || ""}
                    style={{ width: "90%", height: "200px", resize: "none" }}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Describe Your Guest Post"
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Button Here */}
        <div
          className="w3-padding"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <div className="w3-col w3-border w3-border-blue w3-padding w3-container w3-round-xlarge">
            <div
              className="w3-col l6 s12 m6"
              style={{ padding: "5px", paddingTop: "15px" }}
            >
              Total{" $"}
              {selectedCategoryPricing
                ? selectedCategoryPricing?.price.guestPost
                : "0"}
              {payLoad?.haveGuestPost === 2 && (
                <span> + ${requestAmount} (Guest Post) </span>
              )}
            </div>
            <div
              className="w3-col l6 s12 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              <button className="nextButton w3-btn" disabled={formLoading}>
                {!formLoading ? (
                  "Make Payment"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OfferFormTwo;
