import "./AdminManageAdvertiser.css";
import UserListswrapperAdvertiser from "../../../components/Admin-Component-Groups/Admin-Manage-Site-Users-Comps/User-Lists-wrapper/User-Lists-wrapper-Advertiser";

export const AdminManageAdvertiser = () => {
  return (
    <div>
      <UserListswrapperAdvertiser></UserListswrapperAdvertiser>
    </div>
  );
};

export default AdminManageAdvertiser;
