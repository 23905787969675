import { BellOutlined } from "@ant-design/icons";
import { Button, Empty, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { getUserNotifications } from "../../../apiservice/authService";
import { INotificationData } from "../../../apiservice/authService.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import useAuth from "../../../hooks/useAuth";
import { convertToShortDate } from "../../../utils/date.utils";
import { ILoadState } from "../../../utils/loading.utils.";
import "./notifications-list.css";
type NotificationType = "success" | "info" | "warning" | "error";

type INotificationsList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
};

export const NotificationsList: React.FC<INotificationsList> = ({
  externalFilter,
  initialDefaultFilter,
}) => {
  const [notificationLoadState, setNotificationLoadState] =
    useState<ILoadState>("loading");
  const [loadNotificationsData, setLoadNotificationsData] = useState(true);
  const [notificationDefaultFilter, setNotificationDefaultFilter] = useState(
    initialDefaultFilter || {
      setAsRead: true,
    }
  );
  const [tableData, setTableData] = useState<INotificationData[]>([]);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });
  const [api, contextHolder] = notification.useNotification();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setNotificationDefaultFilter({
        ...notificationDefaultFilter,
        ...externalFilter,
      });
      setLoadNotificationsData(true);
      setNotificationLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Notifications Details
  const notificationDataResult = useFormatApiRequest(
    () => getUserNotifications(notificationDefaultFilter),
    loadNotificationsData,
    () => {
      setLoadNotificationsData(false);
    },
    () => {
      processNotificationsResult();
    }
  );

  // Process The Current Notification Data Result
  const processNotificationsResult = async () => {
    if (notificationDataResult.httpState === "SUCCESS") {
      setTableData(notificationDataResult.data?.data);
      setNotificationLoadState("completed");
      setLoadAuth(true);
    } else if (notificationDataResult.httpState === "ERROR") {
      setNotificationLoadState("error");
    } else if (notificationDataResult.httpState === "LOADING") {
      setNotificationLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        {/* " Show Loading Indicator" */}
        {notificationLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {notificationLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title="Error"
              subTitle="Sorry, something went wrong, it could be a network Related error"
              extra={
                <Button
                  onClick={() => setLoadNotificationsData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {notificationLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show No data" */}
        {notificationLoadState === "completed" && (
          <>
            {tableData.map((item: INotificationData, index: number) => (
              <div
                key={index}
                style={{ marginBottom: "30px" }}
                className=" w3-col w3-margin-top w3-margin-bottom"
              >
                <div className="w3-col" style={{ width: "40px" }}>
                  <span className="notificationIcon">
                    <BellOutlined ref={undefined} />
                  </span>
                </div>
                <div className="w3-col  w3-right" style={{ width: "100px" }}>
                  <span className="w3-tiny w3-text-dark-grey">
                    {convertToShortDate(item.dateCreated)}
                  </span>
                </div>
                <div className="w3-rest">
                  <span className="notificationTitle">{item.title}</span>
                  <br />
                  <span className="notificationSubTitle">{item.message}</span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default INotificationsList;
