import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, notification } from "antd";
import { useState } from "react";
import {
  adminCompleteWithdrawalWallet,
  adminRejectWithdrawalWallet,
} from "../../../../apiservice/admin-Users-Clone-Service";
import { IAdminWithdrawalData } from "../../../../apiservice/admin-Users-clone.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { appZIndex } from "../../../../utils/appconst";
import { formatCurrency } from "../../../../utils/basic.utils";
import { convertToShortDate } from "../../../../utils/date.utils";
import "./AdminWithdrawalDetailsWrapper.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminWithdrawalDetailsWrapperCard = {
  adminWithdrawalData?: IAdminWithdrawalData | null;
  refreshPage?: () => void;
};

export const AdminWithdrawalDetailsWrapperCard: React.FC<
  IAdminWithdrawalDetailsWrapperCard
> = ({ adminWithdrawalData, refreshPage }) => {
  const [loadMakePayment, setLoadMakePayment] = useState(false);
  const [loadRevertPayment, setLoadRevertPayment] = useState(false);
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  // Make payment Confirmation
  const showMakePaymentConfirm = () => {
    confirm({
      title: "Are you sure you want to Make this Payment",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadMakePayment(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Make payment
  const makePaymentApiResult = useFormatApiRequest(
    () =>
      adminCompleteWithdrawalWallet({
        transactionId: Number(adminWithdrawalData?.withdrawalId),
        type: Number(adminWithdrawalData?.typeId) === 1 ? "wallet" : "referral",
      }),
    loadMakePayment,
    () => {
      setLoadMakePayment(false);
    },
    () => {
      processMakePayment();
    }
  );

  // Process The Make payment
  const processMakePayment = async () => {
    if (makePaymentApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "Payment Has Being Made");
      if (refreshPage) {
        refreshPage();
      }
    } else if (makePaymentApiResult.httpState === "ERROR") {
      openNotificationWithIcon(
        "error",
        "",
        makePaymentApiResult.errorMsg.data?.response?.data?.message ||
          makePaymentApiResult.errorMsg ||
          "Error Making Payment"
      );
    }
  };

  //Revert Payment
  const showRevertPaymentConfirm = () => {
    confirm({
      title: "Are you sure you want to revert this payment",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadRevertPayment(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Revert Payment
  const revertPaymentApiResult = useFormatApiRequest(
    () =>
      adminRejectWithdrawalWallet({
        transactionId: Number(adminWithdrawalData?.withdrawalId),
        type: adminWithdrawalData?.typeId === 1 ? "wallet" : "referral",
      }),
    loadRevertPayment,
    () => {
      setLoadRevertPayment(false);
    },
    () => {
      processRevertPayment();
    }
  );

  // Process The Complete Task
  const processRevertPayment = async () => {
    if (revertPaymentApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon(
        "success",
        "",
        "This Payment has being reverted"
      );
      if (refreshPage) {
        refreshPage();
      }
    } else if (revertPaymentApiResult.httpState === "ERROR") {
      openNotificationWithIcon(
        "error",
        "",
        revertPaymentApiResult.errorMsg.data?.response?.data?.message ||
          revertPaymentApiResult.errorMsg ||
          "Error Reverting Transaction"
      );
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };
  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col w3-container" style={{ minHeight: "200px" }}>
        <div className="w3-border-bottom w3-padding">
          <h3> Payment Details </h3>
        </div>
        <div className="w3-col l12 s12 m12">
          {/* FullName */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>FullName</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminWithdrawalData?.user.fullName}</span>
              </p>
            </div>
          </div>
          {/* Phone number*/}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Phone Number</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminWithdrawalData?.user.phoneNumber}</span>
              </p>
            </div>
          </div>
          {/* Email */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Email</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminWithdrawalData?.user.email}</span>
              </p>
            </div>
          </div>

          {/* Pay Pal Email */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Paypal Email</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminWithdrawalData?.paypalEmail}</span>
              </p>
            </div>
          </div>

          {/* Bank Name  */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Bank Name</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminWithdrawalData?.bankName}</span>
              </p>
            </div>
          </div>

          {/*  Account Number  */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Account Number</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminWithdrawalData?.accountNumber}</span>
              </p>
            </div>
          </div>

          {/*  Account Name  */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Account Name</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminWithdrawalData?.user.fullName}</span>
              </p>
            </div>
          </div>

          {/* Amount*/}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Amount</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{formatCurrency(adminWithdrawalData?.amount)}</span>
              </p>
            </div>
          </div>

          {/*  Payment Status  */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Payment Status</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminWithdrawalData?.status}</span>
              </p>
            </div>
          </div>

          {/* Date Created */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Date</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>
                  {convertToShortDate(
                    adminWithdrawalData?.dateCreated || Date.toString()
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="w3-col l12 s12 m12">
          <button
            onClick={showMakePaymentConfirm}
            className="w3-btn w3-margin-bottom w3-margin-top w3-round w3-blue"
          >
            Mark As Paid
          </button>
          &nbsp;
          <button
            onClick={showRevertPaymentConfirm}
            className="w3-btn w3-margin-bottom w3-margin-top  w3-round w3-green"
          >
            Revert Payment
          </button>
          &nbsp;
        </div>
      </div>
    </>
  );
};

export default AdminWithdrawalDetailsWrapperCard;
