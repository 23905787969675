import { Button, Modal, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getBannerRequests,
  getGuestPostRequests,
} from "../../../../apiservice/offers.service";
import {
  IBannerRequestData,
  IGuestPostRequestData,
} from "../../../../apiservice/offers.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { formatCurrency } from "../../../../utils/basic.utils";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./AdvertiserContent-List.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdvertiserContentList = {
  externalFilter?: any;
  tableType?: "banner" | "guestPost";
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const AdvertiserContentList: React.FC<IAdvertiserContentList> = ({
  externalFilter,
  tableType,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [portalLoadState, setPortalLoadState] = useState<ILoadState>("loading");
  const [loadPortalsData, setLoadPortalsData] = useState(true);
  const [portalDefaultFilter, setportalDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10, paymentVerified: true }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const { confirm } = Modal;

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setportalDefaultFilter({
        ...portalDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadPortalsData(true);
      setPortalLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const PortalDataResult = useFormatApiRequest(
    () =>
      tableType === "banner"
        ? getBannerRequests(portalDefaultFilter)
        : getGuestPostRequests(portalDefaultFilter),
    loadPortalsData,
    () => {
      setLoadPortalsData(false);
    },
    () => {
      processPortalsResult();
    }
  );

  // Process The Current Portal Data Result
  const processPortalsResult = async () => {
    if (PortalDataResult.httpState === "SUCCESS") {
      setTableData(PortalDataResult.data?.data);
      setTotalItems(PortalDataResult.data?.meta?.total || 1);
      setPortalLoadState("completed");
    } else if (PortalDataResult.httpState === "ERROR") {
      setPortalLoadState("error");
    } else if (PortalDataResult.httpState === "LOADING") {
      setPortalLoadState("loading");
    }
  };
  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columnsBanner: ColumnsType<IBannerRequestData> = [
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      fixed: "left",
      width: 120,
      render: (text, records, index) => (
        <div className="w3-center">
          {records.statusId === 0 ? (
            <span className="w3-text-blue">{text}</span>
          ) : (
            <Link
              className="w3-btn w3-round selectBtn"
              to={"/advertiser/manage-content-details-Banner/" + records.id}
            >
              Open Banner
            </Link>
          )}
        </div>
      ),
    },
    {
      title: "Banner Size",
      dataIndex: "size",
      key: "size",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Banner Color",
      dataIndex: "primaryColour",
      key: "primaryColour",
      render: (text) => (
        <span className="w3-btn" style={{ backgroundColor: text }}>
          {text}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Payment Made",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <span>{formatCurrency(amount)}</span>,
    },
    {
      title: "Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (date) => <span>{convertToShortDate(date)}</span>,
    },
  ];

  const columnsGuestPost: ColumnsType<IGuestPostRequestData> = [
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      fixed: "left",
      width: 120,
      render: (text, records, index) => (
        <div className="w3-center">
          {records.statusId === 0 ? (
            <span className="w3-text-blue">{text}</span>
          ) : (
            <Link
              className="w3-btn w3-round selectBtn"
              to={"/advertiser/manage-content-details-guestPost/" + records.id}
            >
              Open Post
            </Link>
          )}
        </div>
      ),
    },
    {
      title: "Article Topic",
      dataIndex: "topic",
      key: "topic",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Word Count",
      dataIndex: "noOfWords",
      key: "noOfWords",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Payment Made",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <span>{formatCurrency(amount)}</span>,
    },
    {
      title: "Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (date) => <span>{convertToShortDate(date)}</span>,
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setportalDefaultFilter({
      ...portalDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadPortalsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {portalLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadPortalsData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {portalLoadState !== "error" && (
        <>
          <div className="w3-col w3-margin-top">
            <Table
              loading={portalLoadState === "loading"}
              rowKey="id"
              size="small"
              columns={
                tableType === "banner" ? columnsBanner : columnsGuestPost
              }
              scroll={{ x: 950 }}
              dataSource={tableData}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>
          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AdvertiserContentList;
