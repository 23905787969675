import AdminPortalDetailsWrapper from "../../../components/Admin-Component-Groups/Admin-Manage-Portals-Comps/Admin-Portal-Details-Wrapper/Admin-Portal-Details-Wrapper";
import "./AdminManagePublisher-Portals-Details.css";

export const AdminManagePublisherPortalsDetails = () => {
  return (
    <div>
      <AdminPortalDetailsWrapper></AdminPortalDetailsWrapper>
    </div>
  );
};

export default AdminManagePublisherPortalsDetails;
