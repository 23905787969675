import { LeftOutlined, LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { adminAuthSignIn } from "../../../apiservice/admin-AuthService";
import { IAdminAuthType } from "../../../apiservice/admin-AuthService.type";
import { IAuthType } from "../../../apiservice/authService.type";
import PasswordInput from "../../../components/Sharedcomponents/PasswordBtn/Password-input";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import {
  USER_TOKEN_KEY,
  USER_AUTH_DATA_KEY,
  ADMIN_AUTH_DATA_KEY,
  ADMIN_TOKEN_KEY,
} from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../Redux/reduxCustomHook";
import { storePlainString, storeJSON } from "../../../utils/localStorage";
import UserAuthWrapper, {
  IOpenNotificationType,
} from "../../userAuthentication/userAuthWrapper/userAuthwrapper";
import "./AdminSignin.css";

const AdminSignin = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [user, setUser] = useState<any>({});
  const [notificationMessage, setNotificationMessage] =
    useState<IOpenNotificationType | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => adminAuthSignIn(user),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      const signinResult: IAdminAuthType = result.data;
      storePlainString(ADMIN_TOKEN_KEY, signinResult?.data?.token || "");
      storeJSON(ADMIN_AUTH_DATA_KEY, signinResult);
      dispatch({ type: "ADMIN_AUTH_ADD_DATA", payload: signinResult });

      setTimeout(() => {
        navigate("/adminpath");
      }, 1500);

      // Handle Success Here
      setNotificationMessage({
        type: "info",
        message: "",
        description: "Admin Login Success",
        background: "#D9FFB5",
      });
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
      setNotificationMessage({
        type: "info",
        message: "",
        description: result.data?.response?.data?.message || result.errorMsg || "Login Error",
        background: "#FFC2B7",
      });
    }
  };

  return (
    <>
      <UserAuthWrapper
        notificationMessage={notificationMessage}
        imageType={"admin"}
      >
        <div>
          <div style={{ paddingTop: "20px" }}>
            <span className="getStartedText">Admin Sign In</span>
            <span className="w3-right">
              <Link to="/">
                <LeftOutlined style={{ zoom: "1.5" }} rev={undefined} />
              </Link>
            </span>
          </div>
          <div style={{ paddingTop: "60px" }}>
            <form onSubmit={handleSubmit}>
              <div className="w3-col w3-margin-bottom">
                <Input
                  required
                  name="email"
                  value={user?.email}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Email Address or UserName"
                  prefix={
                    <MailOutlined className="inputPrefix" rev={undefined} />
                  }
                />
              </div>
              <div className="w3-col w3-margin-bottom">
                <PasswordInput
                  required
                  name="password"
                  placeholder="Password"
                  value={user?.password}
                  onChange={handleInputChange}
                ></PasswordInput>
              </div>

              <div className="w3-col w3-margin-bottom">
                <button
                  disabled={formLoading}
                  className="w3-btn w3-col submitButton"
                >
                  <span className="submitButtonText">
                    {!formLoading ? (
                      "Sign In"
                    ) : (
                      <LoadingOutlined rev={undefined} />
                    )}
                  </span>
                </button>
              </div>
              <div
                style={{ marginTop: "20px" }}
                className="w3-col w3-margin-bottom"
              ></div>
            </form>
          </div>
        </div>
      </UserAuthWrapper>
    </>
  );
};

export default AdminSignin;
