import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAdmins } from "../../../../apiservice/admin-AuthService";
import { IAdminListsData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./Admin-Lists.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminLists = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const AdminLists: React.FC<IAdminLists> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [adminUserLoadState, setAdminUserLoadState] =
    useState<ILoadState>("loading");
  const [loadAdminData, setLoadAdminData] = useState(true);
  const [defaultFilter, setDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setDefaultFilter({ ...defaultFilter, ...externalFilter, page: 1 });
      setLoadAdminData(true);
      setAdminUserLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const adminDataResult = useFormatApiRequest(
    () => getAdmins(defaultFilter),
    loadAdminData,
    () => {
      setLoadAdminData(false);
    },
    () => {
      processAdminResult();
    }
  );

  // Process The Current Portal Data Result
  const processAdminResult = async () => {
    if (adminDataResult.httpState === "SUCCESS") {
      setTableData(adminDataResult.data?.data);
      setTotalItems(adminDataResult.data?.meta?.total || 1);
      setAdminUserLoadState("completed");
    } else if (adminDataResult.httpState === "ERROR") {
      setAdminUserLoadState("error");
    } else if (adminDataResult.httpState === "LOADING") {
      setAdminUserLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<IAdminListsData> = [
    {
      title: "S/N",
      dataIndex: "SN",
      key: "sn",
      width: 60,
      render: (text, records, index) => <span>{index + 1}</span>,
    },
    {
      title: "FullName",
      dataIndex: "fullName",
      key: "fullName",
      render: (fullName, records, index) => (
        <Link to={`/adminpath/manage-admin/${records.id}`}>
          <u>{fullName}</u>
        </Link>
      ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      render: (username, records, index) => <span>{username}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role, records, index) => <span>{role?.title || "-------"}</span>,
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setDefaultFilter({ ...defaultFilter, page: page, perPage: pageSize });
    setLoadAdminData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {adminUserLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadAdminData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {adminUserLoadState !== "error" && (
        <>
          <Table
            loading={adminUserLoadState === "loading"}
            rowKey="id"
            size="small"
            columns={columns}
            scroll={{ x: 950 }}
            dataSource={tableData}
            pagination={{ hideOnSinglePage: true }}
          />

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AdminLists;
