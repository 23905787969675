import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import "./Password-input.css";
import { Input } from "antd";

type IPasswordInput = {
  name?: string;
  testId?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number | readonly string[] | undefined;
  required?: boolean;
};

const PasswordInput: React.FC<IPasswordInput> = (props: IPasswordInput) => {
  const [togglePassword, setTogglePassword] = useState(false);

  return (
    <div className="w3-col passwordContainer">
      <Input
        name={props.name}
        required={props.required}
        type={togglePassword ? " text" : "password"}
        className="w3-col inputField"
        placeholder={props.placeholder || "Password"}
        value={props.value}
        onChange={props.onChange}
        prefix={<LockOutlined className="inputPrefix" rev={undefined} />}
      />
      <span
        onClick={() => {
          setTogglePassword(!togglePassword);
        }}
        className="passwordContainerIcon"
      >
        {togglePassword ? (
          <EyeOutlined className="passwordIcon" rev={undefined} />
        ) : (
          <EyeInvisibleOutlined className="passwordIcon" rev={undefined} />
        )}
      </span>
    </div>
  );
};

export default PasswordInput;
