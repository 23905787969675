import "./AdminLayout.css";
import { Outlet } from "react-router-dom";
import { Space, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import SideBarAdmin from "../../../components/LayoutComponent/SideBar-Admin/SideBar-Admin";
import TopBarAdmin from "../../../components/LayoutComponent/TopBar-Admin/TopBar-Admin";

const AdminLayout = () => {
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", height: "100%" }}
      size={[0, 48]}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          className="w3-hide-small w3-hide-medium"
          style={{ background: "#1b2e3e" }}
          width={300}
        >
          <div className="sideMenuFixed">
            <SideBarAdmin></SideBarAdmin>
          </div>
        </Sider>
        <Layout>
          <div className="w3-white fixedHeader">
            <TopBarAdmin></TopBarAdmin>
          </div>
          <Content className="content">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Space>
  );
};

export default AdminLayout;
