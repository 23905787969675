import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Input, Result, Select } from "antd";
import { useEffect, useState } from "react";
import { authUpdateDetails } from "../../../../apiservice/authService";
import { IAuthType } from "../../../../apiservice/authService.type";
import { places } from "../../../../apiservice/places";
import { IPlace } from "../../../../apiservice/places.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import useAuth from "../../../../hooks/useAuth";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import "./SharedUserProfile-Form.css";
const { Option } = Select;

type ISharedUserProfileFormEditProfile = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const SharedUserProfileFormEditProfile: React.FC<
  ISharedUserProfileFormEditProfile
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>(null);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showFormResult, setShowFormResult] = useState(false);

  const [country, setCountry] = useState<any[]>([]);
  const [city, setCity] = useState<any[]>([]);
  const [countryCode, setCountryCode] = useState<any[]>([]);

  const [loadAuth, setLoadAuth] = useState(false);
  const allPlace: IPlace[] = places as IPlace[];

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const updateCountry = () => {
    const countries = allPlace.map((place) => {
      return { name: place.emoji + " " + place.name, value: place.name };
    });

    const countriesCode = allPlace.map((place) => {
      return {
        name: place.emoji + " " + place.phone_code,
        value: place.phone_code,
      };
    });

    setCountry(countries);
    setCountryCode(countriesCode);
  };

  const updateState = (selectedCountry) => {
    // console.log(selectedCountry);
    // console.log(getCountryIndex(selectedCountry));

    let states;
    if (getCountryIndex(selectedCountry) === -1) {
      states = [];
    } else {
      states = allPlace[getCountryIndex(selectedCountry)].states.map(
        (state) => state.name
      );
    }

    // console.log(states);
    setCity(states);
  };

  const getCountryIndex = (selectedCountry: string) => {
    // console.log("Selected Country", selectedCountry);
    return country.findIndex((country) => selectedCountry === country?.value);
  };

  // Use to get Initial Payload
  const getInitialPayLoad = () => {
    const initialPayload = {
      firstName: authData.data?.credentials.firstName,
      lastName: authData.data?.credentials.lastName,
      otherNames: "",
      phoneNumber: authData.data?.credentials.phoneNumber,
      countryCode: authData.data?.credentials.countryCode,
      city: authData.data?.credentials.city,
      country: authData.data?.credentials.country,
      isAgency: authData.data?.credentials.advertiserDetails?.isAgency,
      agencyName: authData.data?.credentials.advertiserDetails?.agencyName,
      email: authData.data?.credentials.email,
      companyName: "Company 1",
    };
    setpayLoad(initialPayload);
    // console.log(initialPayload);
  };

  useEffect(() => {
    getInitialPayLoad();
    updateCountry();
  }, []);

  useEffect(() => {
    updateState(authData.data?.credentials.country);
  }, [country]);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setpayLoad((values) => ({ ...values, [name]: value }));
    if (name === "country") {
      updateState(value);
    }
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => authUpdateDetails(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setShowFormResult(true);
      setLoadAuth(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      {/* Profile Form */}
      {!showFormResult && (
        <>
          {payLoad && (
            <form onSubmit={handleSubmit}>
              {/* Forms Here */}
              <div className="w3-col">
                <div className="w3-content">
                  <div className="w3-container">
                    <div className="w3-col s12 l12">
                      <div className="user-profile-form-edit">
                        <p>Edit profile</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* first and last name section */}
              <div className="w3-col">
                <div className="w3-content">
                  <div className="w3-container">
                    <div className="w3-col w3-margin-bottom s12 l6 w3-padding">
                      <div className="user-profile-forms">
                        <span className="user-profile-form-labels">
                          First name <span className="w3-text-red">*</span>
                        </span>
                      </div>
                      <Input
                        required
                        name="firstName"
                        value={
                          payLoad?.firstName ||
                          authData.data?.credentials.firstName ||
                          ""
                        }
                        onChange={handleInputChange}
                        className="edit-profile-input"
                        placeholder="Patricia"
                      />
                    </div>

                    <div className="w3-col w3-margin-bottom s12 l6 w3-padding">
                      <div className="user-profile-forms">
                        <span className="user-profile-form-labels">
                          Last name <span className="w3-text-red">*</span>
                        </span>
                      </div>
                      <Input
                        required
                        name="lastName"
                        value={
                          payLoad?.lastName ||
                          authData.data?.credentials.lastName ||
                          ""
                        }
                        onChange={handleInputChange}
                        className="edit-profile-input"
                        placeholder="Houston"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* email and phone number section */}
              <div className="w3-col">
                <div className="w3-content">
                  <div className="w3-container">
                    <div className="w3-col w3-margin-bottom s12 l6 w3-padding">
                      {/* <div className="user-profile-forms">
                    <span className="user-profile-form-labels">
                      Phone Number <span className="w3-text-red">*</span>
                    </span>
                  </div>
                  <Input
                    required
                    name="phoneNumber"
                    value={payLoad?.phoneNumber || ""}
                    onChange={handleInputChange}
                    className="edit-profile-input"
                    placeholder="(USA) +146675789"
                  /> */}

                      <span className="inputTopName">
                        Phone Number <span className="asterisk"> * </span>
                      </span>
                      <div className="w3-col">
                        <div className="w3-col" style={{ width: "120px" }}>
                          <Select
                            id="countryCode"
                            showSearch
                            value={
                              payLoad?.countryCode ||
                              authData.data?.credentials.countryCode ||
                              "+234"
                            }
                            onChange={(val) => {
                              handleSelectChange(val, "countryCode");
                            }}
                            className="w3-col"
                            placeholder="Country Code"
                            style={{ width: "100%", height: "50px" }}
                          >
                            <Option value="+234">+234</Option>
                            {countryCode.map((item: any, index: number) => (
                              <Option key={index} value={item?.value}>
                                {item?.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="w3-rest">
                          <Input
                            required
                            name="phoneNumber"
                            value={
                              payLoad?.phoneNumber ||
                              authData.data?.credentials.phoneNumber ||
                              ""
                            }
                            onChange={handleInputChange}
                            className="w3-col inputField"
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w3-col w3-margin-bottom s12 l6 w3-padding">
                      <div className="user-profile-forms">
                        <span className="user-profile-form-labels">
                          Email Address <span className="w3-text-red">*</span>
                        </span>
                      </div>
                      <Input
                        required
                        name="email"
                        readOnly
                        value={
                          payLoad?.email ||
                          authData.data?.credentials.email ||
                          ""
                        }
                        onChange={handleInputChange}
                        className="edit-profile-input"
                        placeholder="Pathouston@gmail.com"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* country and city section */}
              <div className="w3-col">
                <div className="w3-content">
                  <div className="w3-container">
                    <div className="w3-col w3-margin-bottom s12 l6 w3-padding">
                      {/* <div className="user-profile-forms">
                    <span className="user-profile-form-labels">
                      Country <span className="w3-text-red">*</span>
                    </span>
                  </div>
                  <select
                    required
                    name="country"
                    value={payLoad?.country || ""}
                    style={{ background: "none" }}
                    onChange={handleInputChange}
                    className="w3-col inputSelect inputSelectProfile"
                  >
                    <option value="">Select Country</option>
                    <option value="usa"> USA </option>
                    <option value="nigeria"> Nigeria </option>
                    <option value="uk"> United Kingdom </option>
                  </select> */}

                      <span className="inputTopName"> Country (Optional)</span>
                      <br />
                      <Select
                        id="country"
                        value={
                          payLoad?.country ||
                          authData.data?.credentials.country ||
                          ""
                        }
                        onChange={(val) => {
                          handleSelectChange(val, "country");
                        }}
                        showSearch
                        className="w3-col"
                        style={{ width: "100%", height: "50px" }}
                      >
                        <Option value="">Select Country</Option>
                        {country.map((item: any, index: number) => (
                          <Option key={index} value={item?.value}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    <div className="w3-col w3-margin-bottom s12 l6 w3-padding">
                      {/* <div className="user-profile-forms">
                    <span className="user-profile-form-labels">
                      City <span className="w3-text-red">*</span>
                    </span>
                  </div>
                  <Input
                    required
                    name="city"
                    value={payLoad?.city || ""}
                    onChange={handleInputChange}
                    className="edit-profile-input"
                    placeholder="Lagos"
                  /> */}

                      <span className="inputTopName"> City (Optional)</span>
                      <Select
                        id="city"
                        value={
                          payLoad?.city || authData.data?.credentials.city || ""
                        }
                        onChange={(val) => {
                          handleSelectChange(val, "city");
                        }}
                        showSearch
                        className="w3-col"
                        style={{ width: "100%", height: "50px" }}
                      >
                        <Option value="">Select City</Option>
                        {city.map((item: string, index: number) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              {/* Button Here */}
              <div className="w3-col">
                <div className="w3-content">
                  <div className="w3-container">
                    <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge">
                      <button
                        className="nextButton w3-btn"
                        disabled={formLoading}
                      >
                        {!formLoading ? (
                          "Save Changes"
                        ) : (
                          <LoadingOutlined rev={undefined} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </>
      )}
      {showFormResult && (
        <div>
          <Result
            status="success"
            title=""
            subTitle="You have succesfully Updated Your Profile"
            extra={[
              <Button
                onClick={() => {
                  setShowFormResult(false);
                }}
                type="primary"
                key="console"
              >
                Okay
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default SharedUserProfileFormEditProfile;
