import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Alert, Tooltip } from "antd";
import { useState } from "react";
import { IAuthType } from "../../../apiservice/authService.type";
import { advertiserAddBannerRequest } from "../../../apiservice/offers.service";
import { IOfferFormResponse } from "../../../apiservice/offers.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import "./AdvertiserContentForms.css";

type IAdvertiserContentFormsTwo = {
  onFormSuccess?: (resultData?: IOfferFormResponse) => void;
  onFormFailure?: (err?: string) => void;
};

export const AdvertiserContentFormsTwo: React.FC<
  IAdvertiserContentFormsTwo
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const amount = authData.data?.credentials?.settings?.bannerRequestPrice;

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setpayLoad({
      ...payLoad,
      requestAmount: amount,
      offerAmount: amount,
    });
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to save form data
  const result = useFormatApiRequest(
    () => advertiserAddBannerRequest(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess(result.data);
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Forms Here */}
        <div>
          {/* Info Details Box */}
          <div className="w3-col w3-margin-bottom">
            <Alert
              description={
                <span>
                  Linkmie provide the best article to help your business or
                  organization grow, Input the necessary information as you wait
                  lets prepare an article for you.
                </span>
              }
              style={{ width: "92%" }}
              type="info"
              showIcon
            />
          </div>
          {/* Select Banner Size*/}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Banner Size <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Indicate the dimensions for the banner ad."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <select
                required
                name="size"
                value={payLoad?.size || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputSelect"
              >
                <option value="">Select Size</option>
                <option value="1"> 720 By 90</option>
                <option value="2"> 300 By 3000 </option>
                <option value="3"> 200 by 900 </option>
              </select>
            </div>
          </div>
          {/* Banner Primary Color */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Banner Primary Color<span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Select the primary color for the banner."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <input
                required
                name="primaryColour"
                type="color"
                value={payLoad?.primaryColour || "#2f3542"}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputColor"
                placeholder="Add Client Link"
              />
            </div>
          </div>
          {/*  Describe Your Banner */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Describe Your Banner
                <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Give a brief description of the banner you want"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <textarea
                required
                name="description"
                value={payLoad?.description || ""}
                style={{ width: "90%", height: "200px", resize: "none" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Your banner descriptions, likely images and other color to use"
              ></textarea>
            </div>
          </div>
        </div>

        {/* Button Here */}
        <div
          className="w3-padding"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <div className="w3-col w3-border w3-border-blue w3-padding w3-container w3-round-xlarge">
            <div
              className="w3-col l6 s12 m6"
              style={{ padding: "5px", paddingTop: "15px" }}
            >
              Total ${amount}
            </div>
            <div
              className="w3-col l6 s12 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              <button className="nextButton w3-btn" disabled={formLoading}>
                {!formLoading ? (
                  "Make Payment"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdvertiserContentFormsTwo;
