import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { getAdminsActivities } from "../../../../apiservice/admin-AuthService";
import { IAdminActivitiesData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./Admin-User-Log.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminLists = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const AdminUserLog: React.FC<IAdminLists> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [adminUserLogLoadState, setAdminUserLogLoadState] =
    useState<ILoadState>("loading");
  const [loaddminUserLogData, setLoaddminUserLogData] = useState(true);
  const [defaultFilter, setDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setDefaultFilter({ ...defaultFilter, ...externalFilter, page: 1 });
      setLoaddminUserLogData(true);
      setAdminUserLogLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const adminLogDataResult = useFormatApiRequest(
    () => getAdminsActivities(defaultFilter),
    loaddminUserLogData,
    () => {
      setLoaddminUserLogData(false);
    },
    () => {
      processPortalsResult();
    }
  );

  // Process The Current Portal Data Result
  const processPortalsResult = async () => {
    if (adminLogDataResult.httpState === "SUCCESS") {
      setTableData(adminLogDataResult.data?.data);
      setTotalItems(adminLogDataResult.data?.meta?.total || 1);
      setAdminUserLogLoadState("completed");
    } else if (adminLogDataResult.httpState === "ERROR") {
      setAdminUserLogLoadState("error");
    } else if (adminLogDataResult.httpState === "LOADING") {
      setAdminUserLogLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<IAdminActivitiesData> = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: 60,
      render: (text, records, index) => <span>{index + 1}</span>,
    },
    {
      title: "Activities",
      dataIndex: "message",
      key: "message",
      render: (activity, records, index) => <span>{activity}</span>,
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setDefaultFilter({ ...defaultFilter, page: page, perPage: pageSize });
    setLoaddminUserLogData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {adminUserLogLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoaddminUserLogData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {adminUserLogLoadState !== "error" && (
        <div className="w3-col w3-margin-top w3-container">
          <div>
            <h3 className="adminDetailsHeaderText"> Admin Log</h3>
          </div>
          <Table
            loading={adminUserLogLoadState === "loading"}
            rowKey="id"
            size="small"
            columns={columns}
            scroll={{ x: 950 }}
            dataSource={tableData}
            pagination={{ hideOnSinglePage: true }}
          />

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AdminUserLog;
