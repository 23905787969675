import { IPortalData } from "../apiservice/portals.type";

export function convertIPortalDataToIPortalData(data: IPortalData) {
  return {
    id: data.id,
    stageIndex: 0,
    stepOne: {
      id: 100,
      websiteName: data.websiteName,
      domainName: data.domainName,
      websiteLanguage: data.websiteLanguage,
      siteNicheId: data.siteNicheId,
      websiteRole: "owner",
    },
    stepTwo: {
      siteDescription: data.siteDescription,
      domainAuthority: data.domainAuthority,
      spamScore: data.spamScore,
      domainRating: data.domainRating,
      organicTraffic: data.organicTraffic,
    },
    stepThree: {
      leadingCountry: data.leadingCountry,
      markedSponsored: data.markedSponsored,
      linkType: data.linkTypeId,
      linkValidityId: data.linkValidityId,
      turnAroundTime: data.turnAroundTime,
      maximumLinkCount: data.maximumLinkCount,
      minimumWordCount: data.minimumWordCount,
      categories: data.subCategories,
    },
    stepFour: {
      pricings: data.portalPricings.map((pricing) => {
        return { id: pricing.id, price: pricing.price };
      }),
      portalPricingsCategory: data.portalPricings,
    },
    apiFields: { ...data },
  };
}
