import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Result, Tooltip } from "antd";
import { useState } from "react";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { appZIndex } from "../../../utils/appconst";
import "./walletForms.css";
import PaystackPop from "@paystack/inline-js";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import {
  payPalCaptureOrderLink,
  payPalCreateOrderLink,
  topUpTransaction,
} from "../../../apiservice/transactions.service";
import { IPaymentTransactionsResponse } from "../../../apiservice/transactions.type";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
type IWalletFormTopUp = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const WalletFormTopUp: React.FC<IWalletFormTopUp> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showPaypalModal, setShowPaypalModal] = useState<boolean>(false);
  const [showFormResult, setShowFormResult] = useState(false);

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const exchangeRateToNaira =
    authData.data?.credentials?.settings?.usdToNairaExchangeRate;

  // Initial PayPal Option
  const initialPayPalOptions = {
    clientId: "test",
    currency: "USD",
    intent: "capture",
  };

  const handleCancelModal = () => {
    setShowPaypalModal(false);
  };

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => topUpTransaction(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      // Pop up selected Payment Method
      if (payLoad?.topupMethod === "1") {
        payWithPayPal();
      } else if (payLoad?.topupMethod === "2") {
        payWithFlutterWave();
      } else if (payLoad?.topupMethod === "3") {
        payWithPayStack();
      } else {
        alert("Please select a payment method");
      }
      setFormLoading(false);
    } else if (result.httpState === "ERROR") {
      alert("Error Logging Amount");
      setFormLoading(false);
    }
  };

  const payWithPayStack = () => {
    const formResultData: IPaymentTransactionsResponse = result.data;
    console.log(formResultData?.paystackMetadata);
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: process.env.REACT_APP_PAYSTACK_PK,
      email: authData.data?.credentials.email || "annonymouslinkmie@gmail.com",
      amount: Number(payLoad?.amount) * 100 * exchangeRateToNaira,
      currency: "NGN",
      metadata: formResultData?.paystackMetadata,
      onSuccess: (transaction) => {
        setShowFormResult(true);
        if (onFormSuccess) {
          onFormSuccess();
        }
        //  console.log(transaction.reference);
      },
      onCancel: () => {
        console.log("Pop Up closed");
      },
      onerror: () => {
        console.log("Error");
      },
    });
  };

  const payWithFlutterWave = () => {
    alert("Payment Method not available");
  };

  const payWithPayPal = () => {
    setShowPaypalModal(true);
  };

  //PayPal Function

  async function createOrder() {
    const formResultData: IPaymentTransactionsResponse = result.data;

    const response = await fetch(payPalCreateOrderLink(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // use the "body" param to optionally pass additional order information
      // like product ids and quantities
      body: JSON.stringify(formResultData?.paypalMetadata),
    });
    const orderRes = await response.json();
    // console.log(orderRes);

    return orderRes?.data.orderId;
  }

  function onApprove(data) {
    // console.log(data);
    return fetch(payPalCaptureOrderLink(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderId: data.orderID,
      }),
    })
      .then((response) => response.json())
      .then((orderData) => {
        alert(`Transaction completed`);
        if (onFormSuccess) {
          onFormSuccess();
        }
      });
  }
  return (
    <div>
      {/* Wallet Form */}
      {!showFormResult && (
        <form onSubmit={handleSubmit}>
          {/* Forms Here */}
          <div>
            {/* Select Top Up Method */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                <span>
                  Top Up Method <span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Select the option that fits your preferences"
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-center">
                <select
                  required
                  name="topupMethod"
                  value={payLoad?.topupMethod || ""}
                  style={{ width: "90%" }}
                  onChange={handleInputChange}
                  className="w3-col inputSelect"
                >
                  <option value="">Select Method</option>
                  <option value="1"> Paypal</option>
                  <option value="3"> Paystack</option>
                </select>
              </div>
            </div>

            {/* Amount */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                <span>
                  Amount <span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Specify the amount you want to top-up"
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-center">
                <Input
                  required
                  name="amount"
                  type="number"
                  value={payLoad?.amount || ""}
                  style={{ width: "90%" }}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Amount"
                />
              </div>
            </div>
          </div>
          {/* Button Here */}
          <div
            className="w3-padding"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge">
              <button className="nextButton w3-btn" disabled={formLoading}>
                {!formLoading ? "Proceed" : <LoadingOutlined rev={undefined} />}
              </button>
            </div>
          </div>
        </form>
      )}
      {showFormResult && (
        <div>
          <Result
            status="success"
            title=""
            subTitle="You have succesfully top up your wallet, you may need to refresh this page for the transaction to reflect"
            extra={[
              <Button
                onClick={() => {
                  setShowFormResult(false);
                }}
                type="primary"
                key="console"
              >
                Okay
              </Button>,
            ]}
          />
        </div>
      )}

      {/* Paypal Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showPaypalModal}
        title={"Pay With PayPal"}
        onCancel={handleCancelModal}
        width={700}
        footer={[
          <p style={{ minHeight: "200px" }}>
            &nbsp; <br />
            &nbsp; <br />
          </p>,
        ]}
      >
        <div>
          <div className="w3-col" style={{ height: "100px" }}></div>

          {/* First Method */}
          <PayPalScriptProvider options={initialPayPalOptions}>
            <PayPalButtons createOrder={createOrder} onApprove={onApprove} />
          </PayPalScriptProvider>

          {/* Second Method */}
          {/* <PayPalScriptProvider
              options={{
                clientId: "test",
                dataClientToken:
                  "test",
              }}
            >
              <BraintreePayPalButtons
                createOrder={(data, actions) => {
                  return actions.braintree.createPayment({
                    flow: "checkout",
                    amount: "10.0",
                    currency: "USD",
                    intent: "capture",
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.braintree
                    .tokenizePayment(data)
                    .then((payload) => {
                      // call server-side endpoint to finish the sale
                    });
                }}
              />
            </PayPalScriptProvider> */}
        </div>
      </Modal>
    </div>
  );
};

export default WalletFormTopUp;
