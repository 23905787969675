import { Button, notification, Pagination, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUsers } from "../../../../apiservice/admin-AuthService";
import { IUserListData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./User-Lists.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IUserLists = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const UserLists: React.FC<IUserLists> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [siteUserLoadState, setSiteUserLoadState] =
    useState<ILoadState>("loading");
  const [loadSiteUserData, setLoadSiteUserData] = useState(true);
  const [defaultFilter, setDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setDefaultFilter({ ...defaultFilter, ...externalFilter, page: 1 });
      setLoadSiteUserData(true);
      setSiteUserLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const userDataResult = useFormatApiRequest(
    () => getUsers(defaultFilter),
    loadSiteUserData,
    () => {
      setLoadSiteUserData(false);
    },
    () => {
      processSiteUsersResult();
    }
  );

  // Process The Current Portal Data Result
  const processSiteUsersResult = async () => {
    if (userDataResult.httpState === "SUCCESS") {
      setTableData(userDataResult.data?.data);
      setTotalItems(userDataResult.data?.meta?.total || 1);
      setSiteUserLoadState("completed");
    } else if (userDataResult.httpState === "ERROR") {
      setSiteUserLoadState("error");
    } else if (userDataResult.httpState === "LOADING") {
      setSiteUserLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Table Configuration
  const columns: ColumnsType<IUserListData> = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: 60,
      render: (text, records, index) => <span>{index + 1}</span>,
    },
    {
      title: "FullName",
      dataIndex: "fullName",
      key: "fullName",
      render: (fullName, records, index) => (
        <Link to={`/adminpath/manage-users/${records.id}`}>
          <u>{fullName}</u>
        </Link>
      ),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      render: (userType, records, index) => <span>{userType}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email, records, index) => <span>{email}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNumber, records, index) => <span>{phoneNumber}</span>,
    },
    {
      title: "Region",
      dataIndex: "country",
      key: "country",
      render: (country, records, index) => <span>{country}</span>,
    },
    {
      title: "Date Join",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setDefaultFilter({ ...defaultFilter, page: page, perPage: pageSize });
    setLoadSiteUserData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {siteUserLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadSiteUserData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {siteUserLoadState !== "error" && (
        <>
          <Table
            loading={siteUserLoadState === "loading"}
            rowKey="id"
            size="small"
            columns={columns}
            scroll={{ x: 950 }}
            dataSource={tableData}
            pagination={{ hideOnSinglePage: true }}
          />

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserLists;
