import { BellOutlined, MenuOutlined } from "@ant-design/icons";
import { Badge, Drawer, Modal, Space } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IAdminAuthType } from "../../../apiservice/admin-AuthService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import AdminDetailsMeEditPassword from "../../Admin-Component-Groups/Admin-Manage-Admin-Users-Comps/Admin-Details-Me-Edit-Password/Admin-Details-Me-Edit-Password";
import AdminDetailsMe from "../../Admin-Component-Groups/Admin-Manage-Admin-Users-Comps/Admin-Details-Me/Admin-Details-Me";
import SideBarAdmin from "../SideBar-Admin/SideBar-Admin";
import "./TopBar-Admin.css";

export const TopBarAdmin = () => {
  const adminAuthData: IAdminAuthType = useAppSelector(
    (state: RootState) => state?.AdminAuthData
  );

  const [open, setOpen] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);

  const handleCancelModal = () => {
    setShowProfileModal(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className="w3-col w3-hide-small w3-hide-medium"
        style={{ width: "300px" }}
      >
        <br />
      </div>

      <div className="w3-rest shadow">
        {adminAuthData && (
          <div className="w3-padding">
            <div
              className="w3-col"
              style={{ paddingTop: "5px", paddingBottom: "5px" }}
            >
              <div style={{ paddingTop: "15px" }} className="w3-col l6 s4 m6">
                <div>
                  <MenuOutlined
                    className="w3-hide-large"
                    onClick={showDrawer}
                    rev={undefined}
                  />
                  <span> Linkmie (Admin) </span>
                </div>
              </div>
              <div
                style={{ paddingTop: "2px" }}
                className="w3-col l6 s8 m6 w3-right-align"
              >
                <span className="topBarNotificationBell">
                  <Link to={"/adminpath/notifications"}>
                    <Space size="middle">
                      <Badge
                        style={{ fontSize: "6px" }}
                        size="small"
                        count={
                          adminAuthData.data?.credentials
                            .noOfUnreadNotifications || 0
                        }
                      >
                        <BellOutlined />
                      </Badge>
                    </Space>
                  </Link>
                  &nbsp;
                </span>
                <img
                  onClick={() => {
                    setShowProfileModal(true);
                  }}
                  className="w3-circle"
                  style={{ width: "48px", maxWidth: "100%" }}
                  alt="logo"
                  src={adminAuthData?.data?.credentials.dpUrl}
                />
                <div
                  onClick={() => {
                    setShowProfileModal(true);
                  }}
                  style={{ float: "right", padding: "5px", textAlign: "left" }}
                >
                  <span className="profileCardName">
                    {adminAuthData?.data?.credentials.fullName}
                  </span>
                  <br />
                  <span className="profilePosition">
                    {adminAuthData?.data?.credentials?.role?.title || "-------"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Drawer
        zIndex={1000000}
        title="Linkmie"
        placement="left"
        onClose={onClose}
        open={open}
        width={300}
        style={{ background: "#1b2e3e", color: "#ffffff" }}
      >
        <SideBarAdmin onLinkChange={onClose}></SideBarAdmin>
      </Drawer>

      <Modal
        zIndex={appZIndex.modal}
        open={showProfileModal}
        title={"My Profile"}
        onCancel={handleCancelModal}
        width={700}
        footer={[<p style={{ minHeight: "00px" }}></p>]}
      >
        <div style={{ maxHeight: "300px", overflow: "scroll" }}>
          <AdminDetailsMe></AdminDetailsMe>
          <AdminDetailsMeEditPassword></AdminDetailsMeEditPassword>
        </div>
      </Modal>
    </>
  );
};

export default TopBarAdmin;
