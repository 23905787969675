import "./AdminManageUser.css";
import UserListswrapper from "../../../components/Admin-Component-Groups/Admin-Manage-Site-Users-Comps/User-Lists-wrapper/User-Lists-wrapper";

export const AdminManageUser = () => {
  return (
    <div>
      <UserListswrapper></UserListswrapper>
    </div>
  );
};

export default AdminManageUser;
