import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Input, Result, Tooltip } from "antd";
import { useState } from "react";
import { transferTransaction } from "../../../apiservice/transactions.service";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { appZIndex } from "../../../utils/appconst";
import "./walletForms.css";

type IWalletFormTransfer = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const WalletFormTransfer: React.FC<IWalletFormTransfer> = ({
  onFormFailure,
  onFormSuccess,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showFormResult, setShowFormResult] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => transferTransaction(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setShowFormResult(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      alert(result.data?.response?.data?.message || result.errorMsg);
      //Handle Error Here
    }
  };

  return (
    <div>
      {/* Wallet Form */}
      {!showFormResult && (
        <form onSubmit={handleSubmit}>
          {/* Forms Here */}
          <div>
            {/* Linkmie Email */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                <span>
                  Linkmie Email<span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Enter your Linkmie email to transfer funds seamlessly."
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-center">
                <Input
                  required
                  name="email"
                  value={payLoad?.email || ""}
                  style={{ width: "90%" }}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Linkmie Userid"
                />
              </div>
            </div>

            {/* Amount */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                <span>
                  Amount <span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Specify the amount you want to transfer."
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-center">
                <Input
                  required
                  name="amount"
                  type="number"
                  value={payLoad?.amount || ""}
                  style={{ width: "90%" }}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Amount"
                />
              </div>
            </div>

            {/* Description */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                <span>
                  Description <span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Add a brief description to your transfer."
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-center">
                <Input
                  required
                  name="description"
                  value={payLoad?.description || ""}
                  style={{ width: "90%" }}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Linkmie Userid"
                />
              </div>
            </div>
          </div>

          {/* Button Here */}
          <div
            className="w3-padding"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge">
              <button className="nextButton w3-btn" disabled={formLoading}>
                {!formLoading ? (
                  "Transfer"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </form>
      )}
      {showFormResult && (
        <div>
          <Result
            status="success"
            title=""
            subTitle="Your transfer has being successful. You may need to refresh this page for the transaction to reflect"
            extra={[
              <Button
                onClick={() => {
                  setShowFormResult(false);
                }}
                type="primary"
                key="console"
              >
                Okay
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default WalletFormTransfer;
