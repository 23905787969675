import "./Advertiser-Dashboard-profile.css";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { Button, Input, notification } from "antd";
import { useEffect, useState } from "react";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import Search from "antd/es/input/Search";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../utils/basic.utils";
import AdvertiserViewOfferList from "../../AdvertiserOfferComps/AdvertiserViewOfferList/AdvertiserViewOffer-List/AdvertiserViewOffer-List";
import ActivitiesListWrapper from "../../notificationsComps/activities-list-wrapper/activities-list-wrapper";
import useAuth from "../../../hooks/useAuth";
import { NotificationPlacement } from "antd/es/notification/interface";

export const AdvertiserDashboardprofile = () => {
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  type IAdvertiserStatus = {
    id: number;
    icon: string;
    text: string;
  };

  const AdvertiserStatusText: IAdvertiserStatus[] = [
    {
      id: 1,
      icon: "/images/advertiser_dashboard/task1.svg",
      text: `${
        authData.data?.credentials.advertiserDashboard?.totalOffers || 0
      } Total Offer`,
    },
    {
      id: 2,
      icon: "/images/advertiser_dashboard/task2.svg",
      text: `${
        authData.data?.credentials.advertiserDashboard?.noOfAcceptedOffers || 0
      } Accepted Offer`,
    },
    {
      id: 3,
      icon: "/images/advertiser_dashboard/task3.svg",
      text: `${
        authData.data?.credentials.advertiserDashboard?.noOfCompletedOffers || 0
      } Completed Offer`,
    },
  ];

  const AdvertiserStatusText2: IAdvertiserStatus[] = [
    {
      id: 1,
      icon: "/images/advertiser_dashboard/content1.svg",
      text: `${
        authData.data?.credentials.advertiserDashboard?.noOfContentRequests || 0
      } Total Content`,
    },
    {
      id: 2,
      icon: "/images/advertiser_dashboard/content2.svg",
      text: `${
        authData.data?.credentials.advertiserDashboard?.noOfGuestPostRequests ||
        0
      } Article Content`,
    },
    {
      id: 3,
      icon: "/images/advertiser_dashboard/content3.svg",
      text: `${
        authData.data?.credentials.advertiserDashboard?.noOfBannerRequests || 0
      } Banner Request`,
    },
  ];

  type NotificationType = "success" | "info" | "warning" | "error";

  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const [showAmount, setShowAmount] = useState(false);
  const [loadAuth, setLoadAuth] = useState(false);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  const amount = formatCurrency(authData.data?.credentials.wallet.balance || 0);

  const toggleAmountDisplay = () => {
    setShowAmount(!showAmount);
  };

  // Use Effect to check email verified
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!authData.data?.credentials.emailVerified) {
  //       navigate("/userauth/verify-email");
  //       // console.log("Email not Verified");
  //     }
  //   }, 1500);
  // }, []);

  const openNotificationWithIcon = (
    type: NotificationType,
    description,
    placement: NotificationPlacement = "bottomRight"
  ) => {
    api[type]({
      message: "",
      description,
      placement,
    });
  };

  return (
    <>
      {contextHolder}

      <div className="w3-col" style={{ minHeight: "400px" }}>
        {/* Publisher Name */}
        <div className="w3-col">
          <div className="w3-content">
            <div className="w3-container">
              <div className="w3-col l12 s12 m12">
                <div
                  className="w3-col w3-hide-small"
                  style={{ height: "20px" }}
                ></div>
                <h2 className="publisher-name">
                  Welcome, {authData.data?.credentials.firstName}
                </h2>

                <div className="publisher-name-header-wrapper">
                  <p className="publisher-content1">
                    create an offer and grow audience
                  </p>
                  <p className="publisher-content2">
                    {authData.data?.credentials.email}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Publisher Name */}

        {/* Second Section */}
        <div className="w3-col">
          <div className="w3-content">
            <div className="w3-container">
              {/* Wallet Balance */}
              <div className="w3-col l7 s12 w3-padding">
                <div className="wallet-wrapper">
                  <p>Wallet Balance</p>
                  <div className="wallet-display">
                    <h2 className="amount">
                      {showAmount ? `${amount}` : "*********"} &nbsp;
                      <span
                        className="toggle-eye-icon"
                        onClick={toggleAmountDisplay}
                      >
                        {showAmount ? (
                          <EyeFilled rev={undefined} />
                        ) : (
                          <EyeInvisibleFilled rev={undefined} />
                        )}
                      </span>
                    </h2>
                    <Link to={"/advertiser/wallet"} type="primary">
                      <Button className="wallet-withdraw">Deposit</Button>
                    </Link>
                  </div>
                </div>
              </div>
              {/* Wallet Balance */}

              {/* Complete Profile Bar */}
              <div className="w3-col l5 w3-padding">
                <div className="profile-status">
                  <div className="profile-header">
                    <p>Complete profile</p>
                    <p>100% Completed</p>
                  </div>
                  {/* <div className="profile-bars">
                    <div className="grey-bar"></div>
                    <div className="white-bar"></div>
                  </div> */}
                  <div className="rest-profile">
                    <div className="verify-email">
                      <span>
                        <img
                          src="/images/publisher_dashboard/check.svg"
                          alt=""
                        />
                      </span>
                      {authData.data?.credentials.emailVerified ? (
                        <p className="lineThrough">Verify email</p>
                      ) : (
                        <p>
                          <Link to={"/userauth/verify-email"} type="primary">
                            <u>Verify Email</u>
                          </Link>
                        </p>
                      )}
                    </div>
                    {/* <div className="complete-info">
                      <span>
                        <img
                          src="/images/publisher_dashboard/check.svg"
                          alt=""
                        />
                      </span>
                      <p>
                        <Link to={"/advertiser/profile-edit"} type="primary">
                          Complete Basic Info
                        </Link>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* Complete Profile Bar */}
            </div>
          </div>
        </div>
        {/* Second Section */}

        {/* Third Section */}
        <div className="w3-col">
          <div className="w3-content">
            <div className="w3-container">
              {/* OFFER AND CONTENT CREATION SECTION */}
              <div className="w3-col l7 s12 w3-padding">
                <div className="publisher-task-wrapper">
                  <div className="w3-col">
                    <div className="w3-content">
                      <div className="w3-col s6 l6 w3-padding">
                        <div className="publisher-task-wrapper-left">
                          {AdvertiserStatusText.map((task) => (
                            <div
                              className="publisher-task advertiser"
                              key={task.id}
                            >
                              <div className="w3-col">
                                <div className="w3-content">
                                  <div className="w3-col s2">
                                    <span className="publisher-task-icon">
                                      <img
                                        id={`image-${task.id}`}
                                        style={{
                                          height: "30px",
                                        }}
                                        src={task.icon}
                                        alt="task-icon"
                                      />
                                    </span>
                                  </div>
                                  <div className="w3-col s10">
                                    <p className="publisher-task-text advertiser">
                                      {task.text}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="w3-col s6 l6 w3-padding">
                        <div className="publisher-task-wrapper-right">
                          {AdvertiserStatusText2.map((task) => (
                            <div
                              className="publisher-task advertiser"
                              key={task.id}
                            >
                              <div className="w3-col">
                                <div className="w3-content">
                                  <div className="w3-col s2">
                                    <span className="publisher-task-icon">
                                      <img
                                        id={`image2-${task.id}`}
                                        style={{
                                          height: "30px",
                                        }}
                                        src={task.icon}
                                        alt="task-icon"
                                      />
                                    </span>
                                  </div>
                                  <div className="w3-col s10">
                                    <p className="publisher-task-text advertiser">
                                      {task.text}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* CREATE OFFER AND SHARE LINK */}
              <div className="w3-col l5 s12 w3-padding">
                <div className="publisher-create-portal-button-wrapper">
                  <Link
                    to={"/advertiser/offer-add-intro"}
                    className="create-portal-button w3-round"
                    type="primary"
                  >
                    <span>
                      <img
                        src="/images/advertiser_dashboard/content4.svg"
                        alt="portal-button-icon"
                        className="portal-button-icon"
                      />
                    </span>
                    Create Offer
                  </Link>
                  <div className="portal-share-link-wrapper">
                    <label htmlFor="">Referral link</label>
                    <br />
                    <Input
                      className="portal-share-link"
                      style={{ height: "50px" }}
                      placeholder=""
                      onClick={() => {
                        navigator.clipboard.writeText(
                          authData.data?.credentials.referralCode
                        );
                        // console.log("Text Copied");
                        openNotificationWithIcon(
                          "success",
                          "Referral Code Copied"
                        );
                      }}
                      value={authData.data?.credentials.referralCode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Third Section */}

        {/* FORTH SECTION */}
        {/* List of offers */}
        <div className="w3-col w3-margin-top w3-margin-bottom">
          <div className="w3-content">
            <div className="w3-container">
              <div>
                <h2 className="section-header-user-dashboard">
                  List of offers
                </h2>
              </div>
              <AdvertiserViewOfferList
                initialDefaultFilter={{ perPage: 5 }}
                hidePagination={true}
              ></AdvertiserViewOfferList>
            </div>
          </div>
        </div>
        {/* FORTH SECTION */}

        {/* LAST SECTION */}
        <ActivitiesListWrapper></ActivitiesListWrapper>
        {/* LAST SECTION */}
      </div>
    </>
  );
};

export default AdvertiserDashboardprofile;
