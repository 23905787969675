import WalletActions from "../walletActions/walletActions";
import WalletBalance from "../walletBalance/walletBalance";
import WalletTransactions from "../walletTransactions/walletTransactions";
import "./walletWrapper.css";

export const WalletWrapper = () => {
  return (
    <>
      <div>
        <div className="w3-container">
          <div className="w3-col">
            <h2 className="walletWrapperHeader">My Wallet </h2>
          </div>
          <div className="w3-col l6 s12 m6" style={{ padding: "5px" }}>
            <WalletBalance></WalletBalance>
            <WalletActions></WalletActions>
          </div>
          <div className="w3-col l6 s12 m6" style={{ padding: "5px" }}>
            <WalletTransactions></WalletTransactions>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletWrapper;
