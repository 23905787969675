import { Button, Modal, notification, Result } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { getReferrals } from "../../../apiservice/authService";
import { IReferralTypeData } from "../../../apiservice/authService.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { formatCurrency } from "../../../utils/basic.utils";
import { convertToShortDate } from "../../../utils/date.utils";
import { ILoadState } from "../../../utils/loading.utils.";

import "./Referral-list.css";

type IReferralList = {
  externalFilter?: any;
};

export const ReferralList: React.FC<IReferralList> = ({ externalFilter }) => {
  const [referralLoadState, setReferralLoadState] =
    useState<ILoadState>("loading");
  const [loadReferralsData, setLoadReferralsData] = useState(true);
  const [referralDefaultFilter, setreferralDefaultFilter] = useState({});
  const [tableData, setTableData] = useState<IReferralTypeData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setreferralDefaultFilter({ ...referralDefaultFilter, ...externalFilter });
      setLoadReferralsData(true);
      setReferralLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const referralDataResult = useFormatApiRequest(
    () => getReferrals(referralDefaultFilter),
    loadReferralsData,
    () => {
      setLoadReferralsData(false);
    },
    () => {
      processReferralsResult();
    }
  );

  // Process The Current Referral Data Result
  const processReferralsResult = async () => {
    if (referralDataResult.httpState === "SUCCESS") {
      setTableData(referralDataResult.data?.data);
      setReferralLoadState("completed");
    } else if (referralDataResult.httpState === "ERROR") {
      setReferralLoadState("error");
    } else if (referralDataResult.httpState === "LOADING") {
      setReferralLoadState("loading");
    }
  };

  // Table Configuration
  const columns: ColumnsType<IReferralTypeData> = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      render: (text, records, index) => <span>{index + 1}</span>,
    },
    {
      title: "Username",
      dataIndex: "id",
      key: "username",
      render: (text, records, index) => (
        <span> {records.fullName + " (" + records.username + ") "}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, records, index) => (
        <span>{formatCurrency(amount || 0)}</span>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
  ];

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {referralLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadReferralsData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {referralLoadState !== "error" && (
        <Table
          loading={referralLoadState === "loading"}
          rowKey="id"
          size="small"
          columns={columns}
          scroll={{ x: 950 }}
          dataSource={tableData}
          pagination={{ position: ["bottomLeft"] }}
        />
      )}
    </>
  );
};

export default ReferralList;
