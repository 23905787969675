import { HomeFilled } from "@ant-design/icons";
import { Button, Empty, notification, Pagination, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { adminGetTransactions } from "../../../../apiservice/admin-Users-Clone-Service";
import { IWalletTransactionsData } from "../../../../apiservice/transactions.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./AdminWalletTransactions.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminWalletTransactions = {
  initialDefaultFilter?: any;
  hidePagination?: boolean;
  externalFilter?: any;
};

export const AdminWalletTransactions: React.FC<IAdminWalletTransactions> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [walletLoadState, setWalletLoadState] = useState<ILoadState>("loading");
  const [loadWalletsData, setLoadWalletsData] = useState(true);
  const [walletDefaultFilter, setWalletDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10 }
  );
  const [tableData, setTableData] = useState<IWalletTransactionsData[]>([]);
  const [api, contextHolder] = notification.useNotification();

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setWalletDefaultFilter({
        ...walletDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadWalletsData(true);
      setWalletLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Wallets Details
  const walletDataResult = useFormatApiRequest(
    () => adminGetTransactions(walletDefaultFilter),
    loadWalletsData,
    () => {
      setLoadWalletsData(false);
    },
    () => {
      processWalletsResult();
    }
  );

  // Process The Current Wallet Data Result
  const processWalletsResult = async () => {
    if (walletDataResult.httpState === "SUCCESS") {
      setTableData(walletDataResult.data?.data);
      setTotalItems(walletDataResult.data?.meta?.total || 1);
      setWalletLoadState("completed");
    } else if (walletDataResult.httpState === "ERROR") {
      setWalletLoadState("error");
    } else if (walletDataResult.httpState === "LOADING") {
      setWalletLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setWalletDefaultFilter({
      ...walletDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadWalletsData(true);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col walletTransactionContainer">
        <h3 className="walletTransactionHeader"> {"Transactions"}</h3>

        {/* " Show Loading Indicator" */}
        {walletLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {walletLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title="Error"
              subTitle="Sorry, something went wrong, it could be a network Related error"
              extra={
                <Button onClick={() => setLoadWalletsData(true)} type="primary">
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {walletLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show When there is data " */}
        {walletLoadState === "completed" && (
          <>
            {tableData.map((item: IWalletTransactionsData, index: number) => (
              <div
                key={index}
                style={{ marginBottom: "30px" }}
                className=" w3-col w3-margin-top w3-margin-bottom"
              >
                <div className="w3-col" style={{ width: "60px" }}>
                  <span className="walletTransationIcon">
                    <HomeFilled ref={undefined} />
                  </span>
                </div>
                <div className="w3-col w3-right" style={{ width: "120px" }}>
                  <span
                    className={
                      "walletTransactionAmount " +
                      (Number(item.amount) < -1 && " w3-text-red")
                    }
                  >
                    {" "}
                    ${item.amount}{" "}
                  </span>
                </div>
                <div className="w3-rest">
                  <span className="walletTransactionTitle">
                    {item.description}
                  </span>
                  <br />
                  <span className="walletTransactionSubTitle">
                    {convertToShortDate(item.dateCreated)}
                  </span>
                </div>
              </div>
            ))}

            {!hidePagination && (
              <div className="w3-col w3-margin-top">
                <Pagination
                  current={currentPage || 1}
                  onChange={onPageChange}
                  pageSize={perPage}
                  total={totalItems}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AdminWalletTransactions;
