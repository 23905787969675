import { IAuthType } from "../../../../apiservice/authService.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import "./SharedProfile-View.css";

export const SharedProfileViewAboutCard = () => {
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <div className="w3-col l12">
          <div className="about-card-details">
            <div className="about-card-left">
              <p>Phone Number</p>
              <p>Email Address</p>
              <p>Agency</p>
              <p>City</p>
              <p>Country</p>
            </div>
            <div className="about-card-right">
              <p>
                <span>({authData.data?.credentials.countryCode})</span>
                {authData.data?.credentials.phoneNumber}
              </p>
              <p>{authData.data?.credentials.email}</p>
              <p>
                {authData.data?.credentials.advertiserDetails?.agencyName ||
                  "No Agency"}
              </p>
              <p>{authData.data?.credentials.city}</p>
              <p>{authData.data?.credentials.country}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharedProfileViewAboutCard;
