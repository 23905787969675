import { Button, Result, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getAdvertiserOfferPortal } from "../../../../apiservice/offers.service";
import { IOfferPortalsData } from "../../../../apiservice/offers.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { useAppDispatch } from "../../../../Redux/reduxCustomHook";
import { ILoadState } from "../../../../utils/loading.utils.";
import OfferFormsWrapper from "../AddOfferFormsWrapper/OfferFormsWrapper";
import AddOfferPortalDetailsCard from "./AddOffer-Portal-Details-card";
import "./AddOffer-Portal-Details.css";

export const AddOfferPortalDetails = () => {
  const [portalLoadState, setPortalLoadState] = useState<ILoadState>("loading");
  const [loadCurrentPortalData, setLoadCurrentPortalData] = useState(true);
  const [portalData, setPortalData] = useState<IOfferPortalsData | undefined>(
    undefined
  );
  const params = useParams();
  const dispatch = useAppDispatch();

  // A custom hook to Load Current/Active Portal based on the Url
  const resultCurrentPortalData = useFormatApiRequest(
    () => getAdvertiserOfferPortal(Number(params?.portalId)),
    loadCurrentPortalData,
    () => {
      setLoadCurrentPortalData(false);
    },
    () => {
      processCurrentPortalResult();
    }
  );

  // Process The Current Portal Data Result
  const processCurrentPortalResult = async () => {
    if (resultCurrentPortalData.httpState === "SUCCESS") {
      // console.log(resultCurrentPortalData.data.data);
      if (resultCurrentPortalData?.data?.data) {
        dispatch({
          type: "SELECT_ADVERTISER_OFFER_PORTAL",
          payload: resultCurrentPortalData?.data?.data || {},
        });
      }
      setPortalData(resultCurrentPortalData.data.data);
      setPortalLoadState("completed");
    } else if (resultCurrentPortalData.httpState === "ERROR") {
      setPortalLoadState("error");
    }
  };

  return (
    <>
      {/* " Show Loading Indicator" */}
      {portalLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {portalLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={<Button onClick={() => setLoadCurrentPortalData(true)} type="primary">Reload</Button>}
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {portalLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken create a new portal."
            extra={<Button type="primary">Go Back to search for portal</Button>}
          />
        </div>
      )}

      {/* " Display form when Portal Load State is Not loading for new form entry & completed for editing old entry" */}
      {portalLoadState === "completed" && (
        <div className="w3-col w3-padding-bottom">
          <AddOfferPortalDetailsCard
            offerPortalData={portalData}
          ></AddOfferPortalDetailsCard>
          <OfferFormsWrapper></OfferFormsWrapper>
        </div>
      )}
    </>
  );
};

export default AddOfferPortalDetails;
