import {
  CameraOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { authUpdateDP } from "../../../../apiservice/authService";
import { IAuthType } from "../../../../apiservice/authService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import useAuth from "../../../../hooks/useAuth";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import { getFormData } from "../../../../utils/basic.utils";
import "./SharedProfile-View.css";

export const SharedProfileViewPictureCard = () => {
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [payLoadFormData, setpayLoadFormData] = useState<FormData>(
    getFormData({})
  );
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [processRequest, setProcessRequest] = useState(false);
  const [loadAuth, setLoadAuth] = useState(false);
  const [canHandleUpload, setCanHandleUpload] = useState(false);

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });

  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileTriggerClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [payLoadFormData]);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value =
      name === "profileFile" ? event.target.files[0] : event.target.value;
    setpayLoadFormData(getFormData({ name: value }));
    setCanHandleUpload(true);
  };

  const handleInputRemoveChange = (event) => {
    setpayLoadFormData(getFormData({}));
    setCanHandleUpload(true);
  };

  // Use to Submit Form
  const handleSubmit = (event?: any) => {
    event?.preventDefault();
    if (canHandleUpload) {
      setFormLoading(true);
      setProcessRequest(true);
      setCanHandleUpload(false);
    }
  };

  // A custom hook to save Guest Post
  const resultChangeDp = useFormatApiRequest(
    () => authUpdateDP(payLoadFormData),
    processRequest,
    () => {
      setProcessRequest(false);
    },
    () => {
      processGuestPostFormApi();
    }
  );

  // Process Guest Post
  const processGuestPostFormApi = async () => {
    if (resultChangeDp.httpState === "SUCCESS") {
      setFormLoading(false);
      setLoadAuth(true);
      // Handle Success Here
    } else if (resultChangeDp.httpState === "ERROR") {
      setFormLoading(false);
      //Handle Error Here
    }
  };

  const toggleUploadButtons = () => {
    setShowUploadOptions(!showUploadOptions);
  };

  return (
    <>
      <div className="w3-col" style={{ minHeight: "200px" }}>
        {/* profile card picture */}
        <div className="w3-col w3-hide-small" style={{ height: "30px" }}></div>
        <div className="w3-col">
          <div className="w3-content">
            <div className="w3-container">
              <div className="w3-col l2 s2">
                <div className="profile-picture">
                  <img
                    className="w3-circle"
                    src={authData?.data?.credentials.dpUrl}
                    alt=""
                  />
                  <span className="edit-icon">
                    <a onClick={toggleUploadButtons}>
                      <img src="/images/shared-profile/edit-icon.svg" alt="" />
                    </a>
                  </span>
                </div>
                {showUploadOptions && (
                  <>
                    <div className=" w3-col w3-margin-top w3-center">
                      {!formLoading ? (
                        <>
                          <button
                            onClick={() => handleFileTriggerClick()}
                            className="w3-btn w3-green w3-small w3-round w3-margin-top"
                          >
                            <CameraOutlined />
                          </button>{" "}
                          &nbsp;
                          <button
                            onClick={handleInputRemoveChange}
                            className="w3-btn w3-red w3-small w3-round w3-margin-top"
                          >
                            <DeleteOutlined />
                          </button>
                        </>
                      ) : (
                        <LoadingOutlined rev={undefined} />
                      )}

                      <input
                        ref={fileInput}
                        type="file"
                        name="profileFile"
                        className="w3-col inputFieldUpload w3-hide"
                        accept="image/*"
                        onChange={handleInputChange}
                        // value={payLoad?.articleFile || ""}
                        style={{ width: "90%" }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="w3-col l10 s10 w3-padding">
                <div className="profile-background">
                  <img
                    src="/images/shared-profile/profile-background.svg"
                    alt=""
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name-display">
                    <p>{authData.data?.credentials.fullName}</p>
                    <p>{authData.data?.credentials.activeUserType}</p>
                  </div>
                  <div className="edit-profile-btn">
                    <Link
                      to={`/${authData.data?.credentials.activeUserType}/profile-edit`}
                      className="w3-btn w3-round profile-edit-btn"
                      type="primary"
                    >
                      <span>
                        <img
                          src="/images/shared-profile/edit-icon-white.svg"
                          alt=""
                        />
                      </span>
                      Edit Profile
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w3-col" style={{ height: "50px" }}></div>
      </div>
    </>
  );
};

export default SharedProfileViewPictureCard;
