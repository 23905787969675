import { LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useEffect, useState } from "react";
import { sampleApiCall } from "../../../apiservice/authService";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import "./PortalForm.css";

type IPortalFormFive = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
  onPrevClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const PortalFormFive: React.FC<IPortalFormFive> = ({
  onFormFailure,
  onFormSuccess,
  onPrevClick,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Forms Here */}
        <div>
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Site Description <span className="w3-text-red">*</span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="siteDescription"
                value={payLoad?.siteDescription || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Site Description"
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Domain Authority <span className="w3-text-red">*</span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="domainAuthority"
                value={payLoad?.domainAuthority || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Domain Authority"
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Spam Score <span className="w3-text-red">*</span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="spamScore"
                value={payLoad?.spamScore || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Spam Score"
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Domain Rating <span className="w3-text-red">*</span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="domainRating"
                value={payLoad?.domainRating || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Domain Rating"
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Organic Traffic <span className="w3-text-red">*</span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="organicTraffic"
                value={payLoad?.organicTraffic || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Domain Rating"
              />
            </div>
          </div>
        </div>
        {/* Button Here */}
        <div
          className="w3-padding w3-center"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <button
            onClick={onPrevClick}
            disabled={formLoading}
            className="prevButton w3-btn"
          >
            Back
          </button>
          <button className="nextButton w3-btn" disabled={formLoading}>
            {!formLoading ? (
              "Save and Continue"
            ) : (
              <LoadingOutlined rev={undefined} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PortalFormFive;
