import { Input } from "antd";
import { useState } from "react";
import { AdminWalletTransactions } from "../AdminWalletTransactions/AdminWalletTransactions";
import "./AdminwalletTransactionsWrapper.css";

export const AdminwalletTransactionsWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <>
      <div>
        <div className="w3-container">
          <div className="w3-col w3-margin-top">
            <div
              className="w3-col l3 s12 12"
              style={{ padding: "5px", paddingTop: "15px" }}
            >
              <span className="listOfPortalTitle"> Wallet Transactions </span>
            </div>

            <div className="w3-col l4 s12 m12">
              <div className="w3-col" style={{ padding: "5px" }}>
                <Input
                  name="searchstring"
                  value={payLoadFilter?.searchstring || ""}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="w3-col inputField"
                  placeholder="Search"
                />
              </div>
            </div>

            <div className="w3-col l5 s12 m12">
              <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                <select
                  name="status"
                  value={payLoadFilter?.status || ""}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="w3-col inputSelect"
                >
                  <option value="">Status</option>
                  <option value="pending"> Pending </option>
                  <option value="approved"> Approved</option>
                  <option value="rejected"> Rejected </option>
                </select>
              </div>
              <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                <span className="w3-btn selectBtn w3-round w3-col">Search</span>
              </div>
            </div>
          </div>
          <div className="w3-col l12 s12 m12" style={{ padding: "5px" }}>
            <AdminWalletTransactions></AdminWalletTransactions>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminwalletTransactionsWrapper;
