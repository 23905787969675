export const AuthRegsitrationReducer = (state: any = {}, action) => {
  switch (action.type) {
    case "AUTH_ADD_REGISTRATION_STEP":
      return { ...state, ...action.payload };
    case "AUTH_REMOVE_REGISTRATION_STEP":
      return {};
    default:
      return state;
  }
};
