import PublisherTaskDetailsWrapper from "../../../components/PublisherTask/PublisherTask-Details-Wrapper/PublisherTask-Details-Wrapper";
import "./PublisherTaskDetails";

export const PublisherTaskDetails = () => {
  return (
    <div>
      <PublisherTaskDetailsWrapper></PublisherTaskDetailsWrapper>
    </div>
  );
};

export default PublisherTaskDetails;
