import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Input, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { portalFormTwoSave } from "../../../apiservice/portals.service";
import { IPortalFormType } from "../../../apiservice/portals.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppDispatch, useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import "./PortalForm.css";

type IPortalFormTwo = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
  onPrevClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const PortalFormTwo: React.FC<IPortalFormTwo> = ({
  onFormFailure,
  onFormSuccess,
  onPrevClick,
}) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const portalFormData: IPortalFormType = useAppSelector(
    (state: RootState) => state?.PortalForm
  );

  // This is use to Update Redux Dispatch Values
  useEffect(() => {
    if (!portalFormData.stepOne?.websiteName) {
      dispatch({ type: "PORTAL_UPDATE_STAGE", payload: 0 });
      navigate("/publisher/portals");
    }
    if (portalFormData.stepOne) {
      setpayLoad({ ...portalFormData.stepTwo });
    }
  }, []);

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch({ type: "PORTAL_UPDATE_STEP_TWO_DATA", payload: payLoad });
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the login Api
  const result = useFormatApiRequest(
    () => portalFormTwoSave(Number(portalFormData.id), payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Forms Here */}
        <div>
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Site Description <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Give an overview of your site's content and purpose"
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="siteDescription"
                value={payLoad?.siteDescription || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Site Description"
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Domain Authority <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="The number that indicates your website's credibility and influence"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="domainAuthority"
                value={payLoad?.domainAuthority || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Domain Authority"
                readOnly
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Spam Score <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Indicate your site's spam risk level. Lower scores are better!"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="spamScore"
                value={payLoad?.spamScore || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Spam Score"
                readOnly
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Domain Rating <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Indicate the quality and authority of your site's domain."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="domainRating"
                value={payLoad?.domainRating || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Domain Rating"
                readOnly
              />
            </div>
          </div>

          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Organic Traffic <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="The number of visitors your site attracts without paid promotions"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="organicTraffic"
                value={payLoad?.organicTraffic || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Organic Traffic"
                type={"number"}
              />
            </div>
          </div>
        </div>
        {/* Button Here */}
        <div
          className="w3-padding w3-center"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <button
            onClick={onPrevClick}
            disabled={formLoading}
            className="prevButton w3-btn"
          >
            Back
          </button>
          <button className="nextButton w3-btn" disabled={formLoading}>
            {!formLoading ? (
              "Save and Continue"
            ) : (
              <LoadingOutlined rev={undefined} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PortalFormTwo;
