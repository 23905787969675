import { IPortalFormType } from "../../apiservice/portals.type";

const intialPortalFormState = {
  id: null,
  stageIndex: null,
  stepOne: {},
  stepTwo: {},
  stepThree: {},
  stepFour: {},
};

export const PortalFormReducer = (
  state: IPortalFormType = { ...intialPortalFormState },
  action
) => {
  switch (action.type) {
    case "PORTAL_UPDATE_ID":
      state.id = action.payload;
      return state;
    case "PORTAL_UPDATE_STAGE":
      state.stageIndex = action.payload;
      return state;
    case "PORTAL_UPDATE_STEP_ONE_DATA":
      state.stepOne = action.payload;
      return state;
    case "PORTAL_UPDATE_STEP_TWO_DATA":
      state.stepTwo = action.payload;
      return state;
    case "PORTAL_UPDATE_STEP_THREE_DATA":
      state.stepThree = action.payload;
      return state;
    case "PORTAL_UPDATE_STEP_FOUR_DATA":
      state.stepFour = action.payload;
      return state;
    case "PORTAL_UPDATE_ALL_FORMS":
      state = { ...action.payload };
      return state;
    case "PORTAL_REMOVE_ALL_FORM":
      state = { ...intialPortalFormState };
      return state;
    default:
      return state;
  }
};
