import AdminSiteUserDetailsWrapper from "../../../components/Admin-Component-Groups/Admin-Manage-Site-Users-Comps/User-Details-Wrapper/User-Details-Wrapper";
import "./AdminManageUserDetails.css";

export const AdminManageUserDetails = () => {
  return (
    <div>
      <AdminSiteUserDetailsWrapper></AdminSiteUserDetailsWrapper>
    </div>
  );
};

export default AdminManageUserDetails;
