import "../../App.css";
import { Routes, Route } from "react-router-dom";
import AdvertiserLayout from "./AdvertiserLayout/AdvertiserLayout";
import AdvertiserHome from "./AdvertiserHome/AdvertiserHome";
import Nopage from "../Nopage/Nopage";
import UserLogout from "../userProfileDashboard/UserLogout/UserLogout";
import UserProfile from "../userProfileDashboard/UserProfile/UserProfile";
import AdvertiserOffers from "./AdvertiserOffers/AdvertiserOffers";
import AdvertiserOfferAddIntro from "./AdvertiserOfferAddIntro/AdvertiserOfferAddIntro";
import AdvertiserOfferAddSelectPortal from "./AdvertiserOfferAddSelectPortal/AdvertiserOfferAddSelectPortal";
import AdvertiserOffersForms from "./AdvertiserOffersForms/AdvertiserOffersForms";
import UserWallet from "../userProfileDashboard/UserWallet/userWallet";
import { UserProfileEdit } from "../userProfileDashboard/UserProfile edit/UserProfileEdit";
import AdvertiserPayment from "./AdvertiserPayment/AdvertiserPayment";
import AdvertiserManageContents from "./AdvertiserManageContents/AdvertiserManageContents";
import AdvertiserManageContentsAdd from "./AdvertiserManageContents-add/AdvertiserManageContents-add";
import AdvertiserManageContentsProgress from "./AdvertiserManageContents-progress/AdvertiserManageContents-progress";
import UserSupport from "../userProfileDashboard/UserSupport/UserSupport";
import UserReferral from "../userProfileDashboard/UserReferral/UserReferral";
import AdvertiserOffersTasksDetails from "./AdvertiserOffersTasks-Details/AdvertiserOffersTasks-Details";
import UserNotifications from "../userProfileDashboard/UserNotifications/UserNotifications";
import AdvertiserManageContentsDetailsBanner from "./AdvertiserManageContentsDetails-Banner/AdvertiserManageContentsDetails-Banner";
import AdvertiserManageContentsDetailsGPost from "./AdvertiserManageContentsDetails-GPost/AdvertiserManageContentsDetails-GPost";

const AdvertiserPagesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<AdvertiserLayout />}>
        <Route index element={<AdvertiserHome />} />
        <Route path="*" element={<Nopage />} />
        <Route path="/offers" element={<AdvertiserOffers />} />
        <Route path="/offer-add-intro" element={<AdvertiserOfferAddIntro />} />
        <Route
          path="/offer-add-select-Portal"
          element={<AdvertiserOfferAddSelectPortal />}
        />
        <Route
          path="/offers-add-form/:portalId"
          element={<AdvertiserOffersForms />}
        />
        <Route
          path="/offers/:offerId"
          element={<AdvertiserOffersTasksDetails />}
        />
        <Route path="/wallet" element={<UserWallet />} />
        <Route path="/payment" element={<AdvertiserPayment />} />
        <Route path="/manage-content" element={<AdvertiserManageContents />} />
        <Route
          path="/manage-content-add"
          element={<AdvertiserManageContentsAdd />}
        />
        <Route
          path="/manage-content-progress"
          element={<AdvertiserManageContentsProgress />}
        />
        <Route
          path="/manage-content-details-guestPost/:id"
          element={<AdvertiserManageContentsDetailsGPost />}
        />
        <Route
          path="/manage-content-details-Banner/:id"
          element={<AdvertiserManageContentsDetailsBanner/>}
        />
        <Route path="/support" element={<UserSupport />} />
        <Route path="/referral" element={<UserReferral />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/profile-edit" element={<UserProfileEdit />} />
        <Route path="/notifications" element={<UserNotifications />} />
        <Route path="/logout" element={<UserLogout />} />
      </Route>
    </Routes>
  );
};

export default AdvertiserPagesRoute;
