import { Input, Radio } from "antd";
import { useState } from "react";
import AdvertiserContentList from "../AdvertiserContent-List/AdvertiserContent-List";
import "./AdvertiserContentList-Wrapper.css";

export const AdvertiserContentListWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <div className="w3-col">
      <div className="w3-container">
        <div className="w3-col" style={{ marginTop: "50px" }}>
          <div className="w3-col w3-margin-bottom">
            <Input
              name="searchstring"
              value={payLoadFilter?.searchstring || ""}
              onChange={handleInputChange}
              onBlur={updateFilter}
              className="w3-col inputField"
              placeholder="Search"
            />
          </div>

          <div className="w3-col w3-hide-large w3-hide-medium">
            <select
              required
              name="status"
              value={payLoadFilter?.status || "-1"}
              onChange={handleInputChange}
              className="w3-col inputSelect"
              onBlur={updateFilter}
            >
              <option value="">Select Status</option>
              <option value="-1"> All</option>
              <option value="0"> Pending</option>
              <option value="1">In Progress</option>
              <option value="2"> Rejected</option>
              <option value="3"> Completed</option>
            </select>
          </div>

          <div className="w3-col w3-hide-small">
            <Radio.Group
              name="status"
              value={payLoadFilter?.status || "-1"}
              onChange={(e) => {
                handleInputChange(e);
              }}
              buttonStyle="solid"
              style={{ width: "100%" }}
            >
              <Radio.Button value="-1">All</Radio.Button>
              <Radio.Button value="0">Pending</Radio.Button>
              <Radio.Button value="1">In Progress</Radio.Button>
              <Radio.Button value="2">Rejected</Radio.Button>
              <Radio.Button value="3">Completed</Radio.Button>
            </Radio.Group>
          </div>
        </div>

        <div className="w3-col w3-margin-top">
          <AdvertiserContentList
            externalFilter={externalFilter}
            tableType="banner"
          ></AdvertiserContentList>

          <AdvertiserContentList
            externalFilter={externalFilter}
            tableType="guestPost"
          ></AdvertiserContentList>
        </div>
      </div>
    </div>
  );
};

export default AdvertiserContentListWrapper;
