import { Link } from "react-router-dom";
import "./Footer.css";
import FooterSubscriberEmail from "./SubScribeEmail";

const Footer = () => {
  return (
    <div className="w3-col w3-white">
      <div className="w3-content">
        <div
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
            minHeight: "200px",
          }}
          className="w3-col w3-border-top w3-padding"
        >
          <div className="w3-col l4 m4 s12" style={{ padding: "10px" }}>
            <span className="logoTextFooter"> Linkmie </span>

            <p className="briefAbout" style={{ paddingTop: "20px" }}>
              Linkmie is more than just a place to host ads; we connect website
              owners with advertisers to create the best results and build
              lasting relationships. Our goal is to unite advertisers who want
              to broaden their audience with website owners who want to profit
              from their traffic.
            </p>

            <p style={{ paddingTop: "20px" }}>
              <span>
                <img
                  style={{ width: "36px", maxWidth: "100%" }}
                  alt="logo"
                  src={process.env.PUBLIC_URL + "/images/footericonig.svg"}
                />
                &nbsp; &nbsp;
              </span>
              <span>
                <img
                  style={{ width: "36px", maxWidth: "100%" }}
                  alt="logo"
                  src={process.env.PUBLIC_URL + "/images/footericontw.svg"}
                />
                &nbsp; &nbsp;
              </span>
              <span>
                <img
                  style={{ width: "36px", maxWidth: "100%" }}
                  alt="logo"
                  src={process.env.PUBLIC_URL + "/images/footericonfb.svg"}
                />
                &nbsp; &nbsp;
              </span>
              <span>
                <img
                  style={{ width: "36px", maxWidth: "100%" }}
                  alt="logo"
                  src={process.env.PUBLIC_URL + "/images/footericonln.svg"}
                />
                &nbsp;
              </span>
            </p>
          </div>

          <div className="w3-col l5 m5 s12">
            <div className="w3-col l6 m6 s12" style={{ padding: "10px" }}>
              <h3 className="footerHeader"> Company</h3>
              <Link className="footerLink" to="about">
                About
              </Link>
              <br />
              <Link className="footerLink" to="contactus">
                Contact
              </Link>
              <br />
            </div>

            <div className="w3-col l6 m6 s12" style={{ padding: "10px" }}>
              <h3 className="footerHeader"> Help </h3>
              <Link className="footerLink" to="/">
                Costumer Support
              </Link>
              <br />
              <Link className="footerLink" to="terms_and_condition">
                Terms and Conditions
              </Link>
              <br />
              <Link className="footerLink" to="privacy_policy">
                Privacy Policy
              </Link>
              <br />
            </div>
          </div>

          <div className="w3-col l3 m3 s12" style={{ padding: "10px" }}>
            <h3 className="footerHeader"> NewsLetter</h3>
            <FooterSubscriberEmail></FooterSubscriberEmail>
          </div>
        </div>

        <div
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
            minHeight: "80px",
          }}
          className="w3-col w3-border-top"
        >
          <p className="copyRightText ">
            © Copyright 2023, All Rights Reserved by Linkmie
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
