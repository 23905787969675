import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, notification } from "antd";
import { useState } from "react";
import {
  blockAdmin,
  resetAdminPassword,
} from "../../../../apiservice/admin-AuthService";
import { IAdminListsData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { appZIndex } from "../../../../utils/appconst";
import { convertToShortDate } from "../../../../utils/date.utils";
import CreateAdminForm from "../CreateAdmin-Form/CreateAdmin-Form";
import "./Admin-Details-Wrapper.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminDetailsCard = {
  adminUserData?: IAdminListsData | null;
  refreshPage?: () => void;
};

export const AdminDetailsCard: React.FC<IAdminDetailsCard> = ({
  adminUserData,
  refreshPage,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [loadBlockProfileApi, setLoadBlockProfileApi] = useState(false);
  const [loadResetPasswordApi, setLoadResetPasswordApi] = useState(false);
  const handleCancelModal = () => {
    setOpenModal(false);
  };
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  // Block Admin User
  const showBlockProfileApiConfirm = () => {
    confirm({
      title: adminUserData?.blocked
        ? "Are you sure you want to unblock this Admin"
        : "Are you sure you want to block this Admin",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadBlockProfileApi(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Block Admin
  const blockProfileApiResult = useFormatApiRequest(
    () =>
      blockAdmin(adminUserData?.id || 0, {
        unblock: adminUserData?.blocked,
      }),
    loadBlockProfileApi,
    () => {
      setLoadBlockProfileApi(false);
    },
    () => {
      processBlockProfileApi();
    }
  );

  // Process The Block
  const processBlockProfileApi = async () => {
    if (blockProfileApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon(
        "success",
        "",
        adminUserData?.blocked
          ? "This Admin had being unblock"
          : "This Admin had being block"
      );
      if (refreshPage) {
        refreshPage();
      }
    } else if (blockProfileApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Blocking this Admin");
    }
  };

  // Reset Admin Password
  const showResetPasswordApiConfirm = () => {
    confirm({
      title: "Are you sure you want to reset this Admin Password",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadResetPasswordApi(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Block Admin
  const resetPasswordApiResult = useFormatApiRequest(
    () => resetAdminPassword(adminUserData?.id || 0),
    loadResetPasswordApi,
    () => {
      setLoadResetPasswordApi(false);
    },
    () => {
      processResetPasswordApi();
    }
  );

  // Process The Block
  const processResetPasswordApi = async () => {
    if (resetPasswordApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon(
        "success",
        "",
        "Admin Password had being reseted"
      );
      if (refreshPage) {
        refreshPage();
      }
    } else if (resetPasswordApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Resetting this Admin User");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };
  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col w3-container" style={{ minHeight: "200px" }}>
        <div className="w3-border-bottom w3-padding">
          <h3 className="adminDetailsHeaderText">Admin Info </h3>
        </div>
        <div className="w3-col l6 s12 m6">
          {/* FullName */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>FullName</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminUserData?.fullName}</span>
              </p>
            </div>
          </div>

          {/* User Name */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>User Name</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminUserData?.username}</span>
              </p>
            </div>
          </div>

          {/*Email*/}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Email</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminUserData?.email || "-------"}</span>
              </p>
            </div>
          </div>

          {/*Role */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Role</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{adminUserData?.role?.title || "-------"}</span>
              </p>
            </div>
          </div>

          {/* Date Created */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Date Joined</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>
                  {convertToShortDate(
                    adminUserData?.dateCreated || Date.toString()
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="w3-col l6 s12 m6">
          <button
            onClick={() => {
              setOpenModal(true);
            }}
            className="w3-btn w3-margin-bottom w3-margin-top w3-border w3-round w3-border-green w3-text-green"
          >
            Edit Profile
          </button>
          <br />
          <button
            onClick={() => {
              showBlockProfileApiConfirm();
            }}
            className="w3-btn w3-margin-bottom w3-margin-top w3-border w3-round w3-border-purple w3-text-purple"
          >
            {adminUserData?.blocked ? "UnBlock Admin" : "Block Admin"}
          </button>
          <br />
          <button
            onClick={() => {
              showResetPasswordApiConfirm();
            }}
            className="w3-btn w3-margin-bottom w3-margin-top w3-border w3-round w3-border-blue w3-text-blue"
          >
            Reset Password
          </button>
          &nbsp;
        </div>
      </div>

      <Modal
        style={{ top: "20px", height: "500px", overflow: "scroll" }}
        zIndex={appZIndex.modal}
        open={openModal}
        title="Edit Admin"
        onCancel={handleCancelModal}
        width={700}
        className={"w3-round-large"}
        footer={[
          <p style={{ minHeight: "200px" }}>
            &nbsp; <br />
            &nbsp; <br />
          </p>,
        ]}
      >
        <CreateAdminForm
          adminUserData={adminUserData}
          isEditMode={true}
        ></CreateAdminForm>
      </Modal>
    </>
  );
};

export default AdminDetailsCard;
