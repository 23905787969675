import { IOfferPortalsData } from "../../apiservice/offers.type";

export const SelectedOfferPortalReducer = (
  state: IOfferPortalsData | {} = {},
  action
) => {
  switch (action.type) {
    case "SELECT_ADVERTISER_OFFER_PORTAL":
      return { ...state, ...action.payload };
    case "CLEAR_ADVERTISER_OFFER_PORTAL":
      return {};
    default:
      return state;
  }
};
