import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authSignUp } from "../../../apiservice/authService";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import UserAuthWrapper, {
  IOpenNotificationType,
} from "../userAuthWrapper/userAuthwrapper";
import "./../userAuth.css";
import { Input, Radio, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { IAuthType } from "../../../apiservice/authService.type";
import { storeJSON, storePlainString } from "../../../utils/localStorage";
import { USER_TOKEN_KEY, USER_AUTH_DATA_KEY } from "../../../hooks/useAuth";
import { places } from "../../../apiservice/places";
import { IPlace } from "../../../apiservice/places.type";
const { Option } = Select;

const UserSignUpDetails = () => {
  // User Type
  const [userType, setUserType] = useState("publisher");
  // Form Data
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadApi, setLoadApi] = useState(false);
  const [user, setUser] = useState<any>({});
  const [notificationMessage, setNotificationMessage] =
    useState<IOpenNotificationType | null>(null);

  const [country, setCountry] = useState<any[]>([]);
  const [city, setCity] = useState<any[]>([]);
  const [countryCode, setCountryCode] = useState<any[]>([]);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const allPlace: IPlace[] = places as IPlace[];

  const authRegistrationData = useAppSelector(
    (state: RootState) => state?.AuthRegistration
  );

  // This is use to Update Redux Dispatch Values
  useEffect(() => {
    setUserType(authRegistrationData?.type);
    setUser({ ...authRegistrationData });
    updateCountry();
  }, []);

  const updateCountry = () => {
    const countries = allPlace.map((place) => {
      return { name: place.emoji + " " + place.name, value: place.name };
    });

    const countriesCode = allPlace.map((place) => {
      return {
        name: place.emoji + " " + place.phone_code,
        value: place.phone_code,
      };
    });

    setCountry(countries);
    setCountryCode(countriesCode);
  };

  const updateState = (selectedCountry) => {
    let states;
    if (getCountryIndex(selectedCountry) === -1) {
      states = [];
    } else {
      states = allPlace[getCountryIndex(selectedCountry)].states.map(
        (state) => state.name
      );
    }

    setCity(states);
  };

  const getCountryIndex = (selectedCountry: string) => {
    console.log("Selected Country", selectedCountry);
    return country.findIndex((country) => selectedCountry === country?.value);
  };

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target?.name || event.target?.id;
    const value = event.target?.value;
    setUser((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setUser((values) => ({ ...values, [name]: value }));
    if (name === "country") {
      updateState(value);
    }
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setUser({ ...user, currencyCode: "USD" });
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to format the Sign Up Api
  const result = useFormatApiRequest(
    () => authSignUp(user),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processApi();
    }
  );

  // Process Api
  const processApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      const signupResult: IAuthType = result.data;

      storePlainString(USER_TOKEN_KEY, signupResult?.data?.token || "");
      storeJSON(USER_AUTH_DATA_KEY, signupResult);
      dispatch({ type: "AUTH_ADD_DATA", payload: signupResult });

      // Handle Success Here
      setNotificationMessage({
        type: "info",
        message: "",
        description: "Sign Up Succesful",
        background: "#D9FFB5",
      });
      dispatch({ type: "AUTH_REMOVE_REGISTRATION_STEP" });
      setTimeout(() => {
        navigate("/userauth/verify-email");
      }, 1500);
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      // Handle Error Here
      setNotificationMessage({
        type: "error",
        message: "",
        description: result.data?.response?.data?.message || result.errorMsg,
        background: "#FFC2B7",
      });
      dispatch({ type: "AUTH_ADD_REGISTRATION_STEP", payload: user });
    }
  };

  return (
    <>
      <UserAuthWrapper notificationMessage={notificationMessage}>
        <div>
          <div style={{ paddingTop: "20px", marginBottom: "20px" }}>
            <div className="w3-col l8 s8 m8">
              <span className="getStartedText"> Your Details </span>
              <br />
              <span className="getStartedUserTypeText"> As an {userType}</span>
            </div>
            <div className="w3-col l4 s4 m4">
              <span className="w3-right">
                <Link to="/userauth/signin">
                  <LeftOutlined style={{ zoom: "1.5" }} rev={undefined} />
                </Link>
              </span>
            </div>
          </div>

          <div style={{ paddingTop: "60px" }}>
            <form onSubmit={handleSubmit}>
              <div className="w3-row">
                <div
                  style={{ padding: "5px" }}
                  className="w3-col l6 s6 m6 w3-margin-bottom"
                >
                  <span className="inputTopName">
                    First Name <span className="asterisk"> * </span>
                  </span>
                  <Input
                    required
                    name="firstName"
                    value={user?.firstName || ""}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="FirstName"
                  />
                </div>
                <div
                  style={{ padding: "5px" }}
                  className="w3-col l6 s6 m6 w3-margin-bottom"
                >
                  <span className="inputTopName">
                    Last Name <span className="asterisk"> * </span>
                  </span>
                  <Input
                    required
                    name="lastName"
                    value={user?.lastName || ""}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="LastName"
                  />
                </div>
              </div>
              <div className="w3-col w3-margin-bottom">
                <span className="inputTopName">
                  Phone Number <span className="asterisk"> * </span>
                </span>
                <div className="w3-col">
                  <div className="w3-col" style={{ width: "120px" }}>
                    <Select
                      id="countryCode"
                      showSearch
                      value={user?.countryCode || "+234"}
                      onChange={(val) => {
                        handleSelectChange(val, "countryCode");
                      }}
                      className="w3-col"
                      placeholder="Country Code"
                      style={{ width: "100%", height: "50px" }}
                    >
                      <Option value="+234">+234</Option>
                      {countryCode.map((item: any, index: number) => (
                        <Option key={index} value={item?.value}>
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="w3-rest">
                    <Input
                      required
                      name="phoneNumber"
                      value={user?.phoneNumber || ""}
                      onChange={handleInputChange}
                      className="w3-col inputField"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
              </div>

              {userType === "publisher" ? (
                <div className="w3-col w3-margin-bottom">
                  <span className="inputTopName">Company Name (Optional)</span>
                  <Input
                    name="companyName"
                    value={user?.companyName || ""}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="Company Name"
                  />
                </div>
              ) : (
                <div>
                  <div className="w3-col w3-margin-bottom w3-margin-top">
                    <span className="inputTopName"> Are you an Agency? </span>
                    <span className="w3-right">
                      <Radio.Group
                        name="isAgency"
                        value={user?.isAgency || false}
                        onChange={handleInputChange}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </span>
                  </div>

                  {user?.isAgency && (
                    <div className="w3-col w3-margin-bottom w3-margin-top">
                      <span className="inputTopName">
                        Agency Name (Optional)
                      </span>
                      <Input
                        name="agencyName"
                        value={user?.agencyName || ""}
                        onChange={handleInputChange}
                        className="w3-col inputField"
                        placeholder="Agency Name"
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="w3-row">
                <div
                  style={{ padding: "5px" }}
                  className="w3-col l6 s6 m6 w3-margin-bottom"
                >
                  <span className="inputTopName"> Country (Optional)</span>
                  <br />
                  <Select
                    id="country"
                    value={user?.country || ""}
                    onChange={(val) => {
                      handleSelectChange(val, "country");
                    }}
                    showSearch
                    className="w3-col"
                    style={{ width: "90%", height: "50px" }}
                  >
                    <Option value="">Select Country</Option>
                    {country.map((item: any, index: number) => (
                      <Option key={index} value={item?.value}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div
                  style={{ padding: "5px" }}
                  className="w3-col l6 s6 m6 w3-margin-bottom"
                >
                  <span className="inputTopName"> City (Optional)</span>
                  <Select
                    id="city"
                    value={user?.city || ""}
                    onChange={(val) => {
                      handleSelectChange(val, "city");
                    }}
                    showSearch
                    className="w3-col"
                    style={{ width: "90%", height: "50px" }}
                  >
                    <Option value="">Select City</Option>
                    {city.map((item: string, index: number) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                  {/* <Input
                    name="city"
                    value={user?.city || ""}
                    onChange={handleInputChange}
                    className="w3-col inputField"
                    placeholder="City"
                  /> */}
                </div>
              </div>

              <div className="w3-col w3-margin-bottom">
                <span className="inputTopName">Referral Code (Optional)</span>
                <Input
                  name="referralCode"
                  value={user?.referralCode}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Referral Code"
                />
              </div>

              <div className="w3-col w3-margin-bottom w3-margin-top">
                <button
                  disabled={formLoading}
                  className="w3-btn w3-col submitButton"
                >
                  <span className="submitButtonText">
                    {!formLoading ? (
                      "Continue"
                    ) : (
                      <LoadingOutlined rev={undefined} />
                    )}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </UserAuthWrapper>
    </>
  );
};

export default UserSignUpDetails;
