import "./PrivacyPolicy.css";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";

const PrivacyPolicy = () => {
  type IGeneralProvisions = {
    description: string;
  };
  type IUserData = {
    title: string;
    description: string;
  };
  type IDataSubject = {
    list: string;
  };
  type TransferUserData1 = {
    list: string;
  };
  type TransferUserData2 = {
    list: string;
  };
  type RightOfServiceUsers = {
    list: string;
  };
  type AdvertisingAndAnalyticalTools = {
    list: string;
  };
  const generalProvisionsText: IGeneralProvisions[] = [
    {
      description:
        "The administrator of personal data of persons using the linkmie.com website and all its sub-sites (hereinafter: the Website) within the meaning of the Regulation of the European Parliament and of the Council (EU) 2016/679 of 27 April 2016 on the protection of natural persons in relation to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (hereinafter: RODO) is: Linkmie",
    },
    {
      description:
        "The Administrator can be contacted at the following email address: linkmie@gmail.com.  This Privacy Policy applies to each person visiting and using the Website (hereinafter: User).",
    },
    {
      description:
        "The Administrator informs users that all content available on the Website is protected by law, including copyright or trademark protection rights, and may not be used in any way without the Administrator's prior written consent under pain of invalidity, except for permitted personal use of works within the meaning of the Act on Copyright and Related Rights. ",
    },
    {
      description:
        "Any action beyond the above-mentioned permitted use is not permitted and may result in civil and criminal liability.",
    },
    {
      description:
        "The Administrator tries to protect the Users' privacy by taking all the steps needed by law to protect the Users' data from being lost, destroyed, shared, accessed by people who aren't supposed to, or used badly. Also, users can help keep their data, including personal information, safe on the Internet by changing their passwords often and using a mix of letters and numbers.",
    },
    {
      description:
        "It is up to each User to decide if they want to use their rights to control how their data is used. If you use your rights in a way that stops the Administrator from processing personal data to the extent that it is needed for the Administrator to provide the service, that is the same as not using the service in question.",
    },
    {
      description:
        "The Administrator is not in charge of how websites handle privacy when a User clicks on a link and goes to that site. Also, the Administrator can change this Privacy Policy at any time to keep it current.",
    },
  ];
  const userDataText: IUserData[] = [
    {
      title: "Data subject",
      description:
        "Type of personal data collected from Users visiting the Service",
    },
    {
      title: "Processing Purposes",
      description:
        "Performance of the Administrator's legal obligations operation of the Services and dispatch of newsletters, sending of requested commercial information, contact for direct marketing using automated calling systems.",
    },
    {
      title: "Newsletter Services",
      description:
        "The newsletter service, available under the Knowledge Base tab, is processed for direct marketing by the Administrator and entities interested in marketing their goods or services. Users can access commercial information related to the website, new content, and conferences and trainings. The basis for processing is the User's explicit consent. If the User does not consent, the Administrator will not process their data for marketing purposes.",
    },
  ];
  const dataSubjectText: IDataSubject[] = [
    {
      list: "Internet traffic information ",
    },
    {
      list: "Essential cookies",
    },
    {
      list: "e-mail address (user subscribing to the newsletter)	",
    },
  ];
  const transferUserData1Text: TransferUserData1[] = [
    {
      list: "internet service providers",
    },
    {
      list: "data centers",
    },
    {
      list: "email service providers",
    },
    {
      list: "administrative authorities",
    },
    {
      list: "ordinary courts, and ",
    },
    {
      list: "state authorities.",
    },
  ];
  const transferUserData2Text: TransferUserData2[] = [
    {
      list: "Providing information and data required by law",
    },
    {
      list: "Fulfilling a legal obligation",
    },
    {
      list: "Newsletters",
    },
    {
      list: "Commercial information and other necessary services.",
    },
  ];
  const rightOfServiceUsersText: RightOfServiceUsers[] = [
    {
      list: "The User has the right to request confirmation from the Administrator about the processing of their personal data, including the purpose, categories of data, recipients, intended retention period, right to request rectification, erasure, or restriction of processing, object to processing, lodge a complaint with a supervisory authority, and information on automated decision-making, including profiling. The Controller does not currently undertake or plan to undertake this type of action, but users can access the information and request the rectification, erasure, or restriction of their personal data processing. They can also lodge a complaint with a supervisory authority if necessary. ",
    },
    {
      list: "The user has the right to ask the Administrator to fix any wrong personal information about him or her immediately. Taking into account the reasons for the processing, the person whose data is being processed has the right to ask for incomplete personal data to be completed, even if that means giving an extra statement.",
    },
    {
      list: "The right to be forgotten allows users to request the immediate deletion of their personal data from the Administrator. The Administrator is obliged to do so without delay if the data is no longer necessary for the purposes for which they were collected or processed, the data subject has withdrawn consent, objects to the processing, is unlawfully processed, must be erased to comply with legal obligations under European Union or Member State law, or was collected in connection with information society services. However, the administrator may refuse to delete user data if its retention is necessary for settling claims or if required by applicable law.",
    },
    {
      list: "The data subject has the right to request the Controller to restrict processing in certain cases, such as if the user questions the correctness of their personal data, if processing is unlawful and the user objects to its erasure, if the controller no longer needs the data for the user's claim, or if the user has raised an objection to the processing, until the Controller determines if the legitimate grounds of the objection override the data subject's objection.",
    },
    {
      list: "The data subject has the right to receive their personal data in a structured, machine-readable format and to send it to another controller without restriction if certain conditions are met, such as processing is done based on permission or contract, and the processing is done using automated tools, as per Article 6(1)(a) of the RODO or Article 6(1)(b) of the RODO.",
    },
    {
      list: "You can ask the Administrator to send your personal information straight to another controller, if this is technically possible.",
    },
    {
      list: "You have the right to say 'NO' at any time to the Administrator's use of your personal information for direct marketing, including profiling, if the use is linked to direct marketing.",
    },
    {
      list: "When the User's personal information is handled with their permission, the User can change their mind at any time. When permission is revoked, it doesn't change the legality of the process based on consent before it was revoked. ",
    },
    {
      list: "You can contact the Administrator about this Privacy Policy and the security of personal data, as well as how to use your rights, through the following channels: Send an email to Linkmie@gmail.com .",
    },
  ];
  const AdvertisingAndAnalyticalToolsText: AdvertisingAndAnalyticalTools[] = [
    {
      list: "The website uses Google Analytics, a tool provided by Google LLC, to track users' interactions with other websites or applications. This data is collected anonymously and does not allow users to be identified. It includes geolocation, demographic data, device information, browser settings, screen resolution, language set, ISP, type of internet connection, and user behavior on the website.",
    },
    {
      list: "Google Ads is an advertising system that allows advertising messages to reach users through channels owned by Google. It collects data such as search queries entered in the Internet search engine and tracking user activity on other websites. Users can opt out of tracking by Google Ads through their browser settings.",
    },
    {
      list: "The website also uses remarketing, which is a way of conducting advertising activities on the Internet. Advertising activities are targeted at people who have visited the website. The user's interest in a product or service is determined through cookies-based mechanisms, and a specific marketing message is directed to them. Marketing messages are displayed when the user uses Google's search engine, browsing websites, or as part of their presence on social networks.",
    },
    {
      list: "Users can deactivate the remarketing option on their devices using the deactivation option available at https://www.google.com/settings/ads/onweb/. Google's privacy policy provides detailed information on the principles of processing and protection of personal data processed by Google.",
    },
    {
      list: "Facebook Pixel is a functionality provided by Meta Platforms Inc. that measures the effectiveness of ads based on user actions.",
    },
  ];
  return (
    <div
      className="w3-white privacy-policy-container w3-col"
      style={{ minHeight: "400px" }}
    >
      <div className="w3-col">
        <div className="w3-content">
          <div className="w3-container">
            <div className="w3-col l12 s12 m12 privacy-policy">
              {/* privacy policy header */}
              <div
                className="w3-col w3-hide-small"
                style={{ height: "20px" }}
              ></div>
              <h2>
                Linkmie WEBSITE PRIVACY POLICY (effective from 19.09.2023)
              </h2>
              {/* General provisions section */}
              <Bounce left>
                <h3>GENERAL PROVISIONS</h3>
                {generalProvisionsText.map((descriptions, index) => (
                  <p key={index}> {descriptions.description} </p>
                ))}
              </Bounce>

              {/* user data header */}
              <Bounce right>
                <h3>USER DATA</h3>

                <p>
                  The Administrator collects Users' personal data directly when
                  they visit the Website. The personal data is obtained by
                  automated means. Personal data collected by the Administrator
                </p>

                {/* USER DATA section */}
                {userDataText.map((data, index) => (
                  <div className="user-data" key={index}>
                    <h4> {data.title} </h4>
                    {index === 0 && (
                      <>
                        <p>{data.description}</p>
                        <ul>
                          {dataSubjectText.map((lists, index) => (
                            <li> {lists.list} </li>
                          ))}
                        </ul>
                      </>
                    )}
                    {index === 1 && <p>{data.description}</p>}
                    {index === 2 && (
                      <>
                        <p>{data.description}</p>
                        <p>
                          The newsletter service is activated through a form on
                          the Website and a link sent to the User's email
                          address. The User can withdraw consent at any time and
                          resign from the service by submitting a request to the
                          Administrator. The Administrator confirms the
                          commencement and termination of the newsletter service
                          through appropriate messages sent to the User's email
                          address.{" "}
                        </p>
                        <p>
                          Users can also consent to sending commercial
                          information for direct marketing of services or goods
                          offered by the Administrator through electronic
                          communication, particularly email, and being contacted
                          for direct marketing purposes through automated
                          calling systems.
                        </p>
                      </>
                    )}
                  </div>
                ))}
              </Bounce>

              {/* TRANSFER OF USER DATA SECTION */}
              <Bounce top>
                <h3>TRANSFER OF USER DATA</h3>

                <p>
                  The Administrator is responsible for communicating specific
                  categories of personal data to various recipients, including
                  users visiting the service.
                </p>
                <ul>
                  {transferUserData1Text.map((list, index) => (
                    <li key={index}> {list.list} </li>
                  ))}
                </ul>
                <p>
                  The purpose of the transfer is to fulfill the Administrator's
                  legitimate interest, such as:
                </p>
                <ul>
                  {transferUserData2Text.map((userData, index) => (
                    <li key={index}> {userData.list} </li>
                  ))}
                </ul>
              </Bounce>

              {/* Storage of User Data section */}
              <Bounce bottom>
                <h3>STORAGE OF USER DATA</h3>

                <p>
                  Personal data is stored until users exercise their rights,
                  revoke consent, or unsubscribe from the newsletter service. If
                  there is a legal dispute or the administrator has a legitimate
                  obligation, they may continue storing the data as necessary to
                  protect and exercise their rights or fulfill their
                  obligations. The storage period is outlined in section 5.
                  Users can opt out of newsletter services using the opt-out
                  option.
                </p>
              </Bounce>

              {/* Rights of Service Users section */}
              <Zoom bottom>
                <h3>RIGHTS OF SERVICE USERS</h3>

                <ol>
                  {rightOfServiceUsersText.map((list, index) => (
                    <li key={index}> {list.list} </li>
                  ))}
                </ol>
              </Zoom>

              {/* Advertising and Analytical Tools Used section */}
              <Bounce left>
                <h3>ADVERTISING AND ANALYTICAL TOOLS USED</h3>

                <ol>
                  {AdvertisingAndAnalyticalToolsText.map((value, index) => (
                    <li key={index}> {value.list} </li>
                  ))}
                </ol>
              </Bounce>

              {/* Exclusion of Liability section */}
              <Bounce right>
                <h3>EXCLUSION OF LIABILITY</h3>
                <p>
                  This Privacy Policy does not apply to services or companies
                  with contact details on the Website. Content on the Website is
                  purely informative and does not constitute an offer under the
                  Civil Code or a basis for claims against the Administrator.
                </p>
                <br />
                <p className="last">
                  {" "}
                  <b>NOTE:</b> If you do not agree with this Privacy Policy,
                  please do not use the Service.
                </p>
              </Bounce>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
