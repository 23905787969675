import "./AdminSettings.css";
import AdminSettingsWrapper from "../../../components/Admin-Component-Groups/Admin-Manage-Settings-Comp/Admin-Settings-Wrapper/Admin-Settings-Wrapper";

export const AdminSettings = () => {
  return (
    <div>
      <AdminSettingsWrapper></AdminSettingsWrapper>
    </div>
  );
};

export default AdminSettings;
