import { IUserListData } from "../../../../apiservice/admin-AuthService.type";
import "./User-Details-Wrapper.css";

type IAdminSiteUserDetailsCard = {
  siteUserData?: IUserListData | null;
  refreshPage?: () => void;
};

export const AdminSiteUserDetailsCardProfile: React.FC<
  IAdminSiteUserDetailsCard
> = ({ siteUserData, refreshPage }) => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "100px" }}>
        {/* profile card picture */}
        <div className="w3-col w3-hide-small" style={{ height: "30px" }}></div>
        <div className="w3-col">
          <div className="w3-content">
            <div className="w3-container">
              <div className="w3-col l2 s2">
                <div className="profile-picture">
                  <img className="w3-circle" src={siteUserData?.dpUrl} alt="" />
                </div>
              </div>
              <div className="w3-col l10 s10 w3-padding">
                <div className="profile-background">
                  <img
                    src="/images/shared-profile/profile-background.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSiteUserDetailsCardProfile;
