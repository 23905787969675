import { ExclamationCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { Modal, notification, Tooltip } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IAdminAuthType } from "../../../../apiservice/admin-AuthService.type";
import {
  adminAssignBannerRequest,
  adminCompleteBannerRequest,
  adminUpdateBannerRequest,
} from "../../../../apiservice/admin-Users-Clone-Service";
import { IAdminBannerRequestData } from "../../../../apiservice/admin-Users-clone.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import { appZIndex } from "../../../../utils/appconst";
import { getFormData } from "../../../../utils/basic.utils";
import "./Admin-ContentRequest-Details-Wrapper.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminContentRequestDetailsCard = {
  bannerRequest?: IAdminBannerRequestData | null;
  refreshPage?: () => void;
};

export const AdminContentRequestDetailsCardBanner: React.FC<
  IAdminContentRequestDetailsCard
> = ({ bannerRequest, refreshPage }) => {
  const [payLoad, setpayLoad] = useState<any>({});
  const [loadApi, setLoadApi] = useState(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [loadAcceptTask, setLoadAcceptTask] = useState(false);
  const [loadCompleteTask, setLoadCompleteTask] = useState(false);
  const [payLoadFormData, setpayLoadFormData] = useState<FormData>(
    getFormData({})
  );
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();
  const authData: IAdminAuthType = useAppSelector(
    (state: RootState) => state?.AdminAuthData
  );

  //Accept Task
  const showAcceptTaskConfirm = () => {
    confirm({
      title: "Are you sure you want to accept this task",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadAcceptTask(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Accept Task
  const acceptTaskApiResult = useFormatApiRequest(
    () =>
      adminAssignBannerRequest(bannerRequest?.id || 0, {
        self: true,
      }),
    loadAcceptTask,
    () => {
      setLoadAcceptTask(false);
    },
    () => {
      processAcceptTask();
    }
  );

  // Process The Accept Task
  const processAcceptTask = async () => {
    if (acceptTaskApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon(
        "success",
        "",
        "This Task Has Being Assigned to you"
      );
      if (refreshPage) {
        refreshPage();
      }
    } else if (acceptTaskApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Accepting this task");
    }
  };

  //Complete Task
  const showCompleteTaskConfirm = () => {
    confirm({
      title: "Are you sure you have completed this task",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadCompleteTask(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Complete Task
  const completeTaskApiResult = useFormatApiRequest(
    () => adminCompleteBannerRequest(bannerRequest?.id || 0),
    loadCompleteTask,
    () => {
      setLoadCompleteTask(false);
    },
    () => {
      processCompleteTask();
    }
  );

  // Process The Complete Task
  const processCompleteTask = async () => {
    if (completeTaskApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "This Task Has being completed");
      if (refreshPage) {
        refreshPage();
      }
    } else if (completeTaskApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Accepting this task");
    }
  };

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target?.name || event.target?.id;
    const value =
      name === "attachmentFile"
        ? event.target.files[0]
        : event.target.value || event.target.getContent();
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setpayLoadFormData(
      getFormData({
        ...payLoad,
      })
    );
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to save form data
  const result = useFormatApiRequest(
    () => adminUpdateBannerRequest(bannerRequest?.id || 0, payLoadFormData),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      openNotificationWithIcon("success", "", "Your Banner Has being saved");
      if (refreshPage) {
        refreshPage();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      openNotificationWithIcon(
        "error",
        "",
        result.data?.response?.data?.message ||
          result.errorMsg ||
          "Error Saving your banner"
      );
      //Handle Error Here
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };
  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col w3-padding w3-padding-bottom">
        {/* Task List  */}
        <div className="w3-col" style={{ padding: "10px" }}>
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <span className="taskDetailsTitle"> List Task </span>
            </div>
            <div
              className="w3-col l6 s6 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              <Link
                to="/adminpath/manage-banner-request"
                className="w3-btn taskBackBtn"
              >
                Back
              </Link>
            </div>
          </div>
        </div>

        {/* Task Title Header */}
        <div className="w3-col" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "10px", height: "60px" }}
          >
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <span className="taskDetailsTitle">
                Assigend to -{" "}
                {bannerRequest?.assigneeAdmin?.username
                  ? bannerRequest?.assigneeAdmin?.username
                  : "Nobody Yet"}
              </span>
            </div>
            <div
              className="w3-col l6 s6 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              <span className="w3-text-green"> {bannerRequest?.status}</span>
            </div>
          </div>
        </div>

        {/* Task Details */}
        <div className="w3-col l12 s12 m12" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "10px", minHeight: "200px" }}
          >
            <div className="w3-col w3-margin-bottom">
              <p>Task Info </p>
            </div>

            {/* Primary Colour */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Primary Colour</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {bannerRequest?.primaryColour} </span>
              </div>
            </div>

            {/* Description */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Description </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span>{bannerRequest?.description}</span>
              </div>
            </div>

            {/* Banner Size */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3">
                <span> Banner Size </span>
              </div>
              <div className="w3-col l9 s12 m9">
                <div className="w3-col l9 s12 m9 w3-margin-bottom">
                  <span>{bannerRequest?.size}</span>
                </div>
              </div>
            </div>

            {/* Owners Details */}
            <div className="w3-light-blue w3-border w3-border-blue w3-col w3-padding w3-round-large w3-margin-bottom">
              {/* Owner Details */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-margin-bottom">
                  <span> Owner Name </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-margin-bottom">
                  <span> {bannerRequest?.user.fullName} </span>
                </div>
              </div>

              {/* Owner Email  */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-margin-bottom">
                  <span> Owner Email </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-margin-bottom">
                  <span> {bannerRequest?.user.email} </span>
                </div>
              </div>

              {/* Owner Phone Number */}
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-margin-bottom">
                  <span> Owner Phone Number </span>
                </div>
                <div className="w3-col l9 s12 m9 w3-margin-bottom">
                  <span> {bannerRequest?.user.phoneNumber} </span>
                </div>
              </div>
            </div>

            <div className="w3-col w3-margin-bottom">
              <h3> Work on the Task Below.</h3>
            </div>

            {/* Upload Banner */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3">
                <span>
                  Upload Banner <span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Lospem ndnd"
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-center">
                <input
                  type="file"
                  name="attachmentFile"
                  accept="image/*,.pdf"
                  className="w3-col inputFieldUpload"
                  onChange={handleInputChange}
                  // value={payLoad?.bannerFile || ""}
                  style={{ width: "90%" }}
                />
              </div>
            </div>

            {/* Banner Preview */}
            {bannerRequest?.bannerUrl && (
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l12 s12 m12">
                  <span>
                    <b>Banner Preview</b>
                  </span>
                </div>
                <div className="w3-col l12 s12 m12">
                  <img
                    alt="bannerPreview"
                    style={{ maxWidth: "50%" }}
                    src={bannerRequest?.bannerUrl}
                  />
                </div>
              </div>
            )}

            {/* Correction */}
            {bannerRequest?.correction && (
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l12 s12 m12">
                  <span>
                    <b>Correction</b>
                  </span>
                </div>
                <div
                  className="w3-col l12 s12 m12 w3-border w3-round"
                  style={{ minHeight: "100px" }}
                >
                  {bannerRequest?.correction}
                </div>
              </div>
            )}

            {/* Save Button  */}
            <div className="w3-col w3-margin-bottom">
              <button
                onClick={showAcceptTaskConfirm}
                className="w3-btn requestBtnPrimaryBorderColor w3-round"
              >
                Assign To Me
              </button>
              &nbsp;
              <button
                onClick={handleSubmit}
                className="w3-btn requestBtnPrimaryBorderColor w3-round"
              >
                Save Banner
              </button>
              &nbsp;
              <button
                onClick={showCompleteTaskConfirm}
                className="w3-btn requestBtnPrimaryColor w3-round"
              >
                Send Banner
              </button>
            </div>
          </div>
        </div>

        <div></div>
      </div>
    </>
  );
};

export default AdminContentRequestDetailsCardBanner;
