import { useState } from "react";
import "./Contact.css";
import { Input, Form, Button } from "antd";
const { TextArea } = Input;

type Ifaqs = {
  question: string;
  accordion: string;
};
const ContactPage = () => {
  const [name, setName] = useState("");
  const [emailName, setEmailName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [input, setInput] = useState("");
  const [message, setMessage] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [selectedAccordionIndex, setSelectedAccordionIndex] = useState<
    number | null
  >(null);
  //name change
  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  //email name change
  const handleEmailNameChange = (event: any) => {
    setEmailName(event.target.value);
  };

  //email address change
  const handleEmailAddressChange = (event: any) => {
    setEmailAddress(event.target.value);
  };

  //phone Number change
  const handlePhoneNumberChange = (event: any) => {
    setPhoneNumber(event.target.value);
  };

  //messagebox change
  const handleInputChange = (event: any) => {
    setInput(event.target.value);
  };

  const handleSubmit = () => {
    setFormLoading(true);
    console.log("clicked");
    setMessage({
      name: name,
      emailName: emailName,
      emailAddress: emailAddress,
      phoneNumber: phoneNumber,
      input: input,
    });
    setFormLoading(false);
  };

  const handleAccordion = (id: number) => {
    if (selectedAccordionIndex === id) {
      setSelectedAccordionIndex(null);
    } else {
      setSelectedAccordionIndex(id);
    }
  };

  const faqsText: Ifaqs[] = [
    {
      question: " How do I choose a publisher?",
      accordion:
        "You choose publishers based on what goal you want to achieve. Go through the Publisher’s profile to see whose site’s statistics fit best with what you want to achieve as the advertiser.",
    },
    {
      question:
        "I added New Sites to Linkmie. However, they are stuck in the pending stage. What could be the issue?",
      accordion:
        "Linkmie Admins review and check all the sites added to the Linkmie platform. This review process takes time, which is why your site is still in the pending stage. Your website or blog will be updated and accepted if it meets our rules and standards, but it will be rejected if it doesn't.",
    },
    {
      question: "How are prices set?",
      accordion:
        "Linkmie doesn't influence the prices of offers. Every publisher reserves the right to fix a price for their service. Publishers pick what services they want to offer and how much they will cost.",
    },
    {
      question: "Can I as a publisher, reject an offer from Linkmie?",
      accordion:
        "You can decline a job if it doesn't meet your website's standards, but you must explain your decision on the dashboard.",
    },
    {
      question:
        "If I am not satisfied with a task performed by a publisher, can I get a refund?",
      accordion:
        "There are no refunds for any completed offer. If you are unsatisfied with the work done, you can send it back, highlighting what you want to be adjusted, and it will be corrected",
    },
    {
      question: "Can I order content only?",
      accordion:
        "Of course, you can order content only. Link mie is committed to ensuring you get the best content for your goals.",
    },
    {
      question:
        "Can an advertiser switch to a publisher role at Linkmie if they want to monetize their website?",
      accordion:
        "Yes, you can easily switch between the advertiser and Publisher roles with the same user account.",
    },
    {
      question: " How can I manage my notification settings?",
      accordion:
        'Go to your Account settings and click the "Notification settings" tab to set how you want to receive notifications.',
    },
    {
      question: "Can I change my password?",
      accordion:
        "You sure can. To change your password, go to your Account settings and click the 'change password' button. Then, type in your old password and your new one. If you can't remember your password, click 'Forgot password?' and you will receive an email containing a link to reset your password.",
    },
    {
      question: "Can I choose several publishers for one open offer?",
      accordion:
        "Yes, you can choose as many publishers as you want to work on the offer.",
    },
    {
      question:
        "Can I switch to the Advertiser role at Linkmie if I want to promote my website?",
      accordion:
        "Yes, you can switch between the Advertiser and Publisher roles within a single user account.",
    },
    {
      question: "Can I submit multiple sites for a single open offer?",
      accordion:
        "Sure, if you have more than one site that meets the buyer's requirements, you can bid for the offer with each one.",
    },
    {
      question: "Can I communicate with publishers?",
      accordion:
        "Yes. When a publisher accepts your offer, you can communicate with them to ensure that the offer is completed to match your goal.",
    },
  ];

  return (
    <div
      className="w3-white w3-col contact-page-wrapper"
      style={{ minHeight: "400px", position: "relative" }}
    >
      <div className="w3-col">
        <div className="w3-content">
          <div className="w3-container">
            <div className="contact-page-header">
              <div className="w3-col l6 s6">
                <h2 className="contact-page-head-text">
                  Contact our friendly <br /> <span className="team">Team</span>{" "}
                </h2>
              </div>
              <div className="w3-col l6 s6">
                <span className="contact-us-img">
                  <img src="images/contact/contact-us.svg" alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vector-topLeft">
        <img src="images/contact/vector-topLeft.svg" alt="" />
      </div>
      <div className="w3-col">
        <div className="w3-content">
          <div className="w3-container">
            <div className="contact-form">
              <Form autoComplete="off" onFinish={handleSubmit}>
                <div className="w3-col">
                  <div className="w3-col l6 w3-padding">
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your name",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        value={name}
                        onChange={handleNameChange}
                        allowClear
                        className="contact-input"
                        placeholder="First name"
                      />
                    </Form.Item>
                    <Form.Item
                      name="emailAddress"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email address",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        value={emailAddress}
                        onChange={handleEmailAddressChange}
                        allowClear
                        className="contact-input"
                        placeholder="Email address"
                      />
                    </Form.Item>
                  </div>
                  <div className="w3-col l6 w3-padding form-second-part">
                    <Form.Item
                      name="emailName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email name",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        value={emailName}
                        onChange={handleEmailNameChange}
                        allowClear
                        className="contact-input"
                        placeholder="Email name"
                      />
                    </Form.Item>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        className="contact-input"
                        allowClear
                        placeholder="Phone number"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="w3-col">
                  <div className="w3-col l12 w3-padding w3-center">
                    <Form.Item
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: "Please type in your message",
                        },
                      ]}
                    >
                      <TextArea
                        value={input}
                        onChange={handleInputChange}
                        allowClear
                        rows={12}
                        className="contact-input"
                        placeholder="Message"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        disabled={formLoading}
                        className="contact-submit-button"
                        htmlType="submit"
                      >
                        Send Message
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="vector-topRight">
        <img src="images/contact/vector-topRight.svg" alt="" />
      </div>
      <div className="vector-center">
        <img src="images/contact/vector-center.svg" alt="" />
      </div>
      <div className="w3-col">
        <div className="w3-content">
          <div className="w3-container faq-box">
            <div className="faq-section">
              <div className="faq-heading">
                <span>
                  <img src="/images/contact/Line 70.svg" alt="" />
                </span>
                <h2>FAQs</h2>
              </div>
              {faqsText.map((item: Ifaqs, index: number) => (
                <div key={index} className="w3-col m12 l12">
                  <div
                    onClick={() => handleAccordion(index)}
                    className="faq-map-section"
                  >
                    <div className="w3-col l11 s11">
                      <p className="faqs">{item.question}</p>
                    </div>
                    <div className="w3-col m1 l1 s1 symbol">
                      {selectedAccordionIndex === index ? "-" : "+"}
                    </div>
                  </div>
                  {selectedAccordionIndex === index && (
                    <div
                      className="w3-col l10 s10 faq-answer-section"
                      style={{ marginBottom: "20px" }}
                    >
                      {item.accordion}
                    </div>
                  )}
                </div>
              ))}
              {/* <div className="w3-col l11"></div>
                <div className="w3-col l1"></div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w3-col">
        
                <div className="faq-heading">
                  <img src="/images/contact/Line 70.svg" alt="" />
                  <h2>FAQs</h2>
                </div>
              </div>
              
            </div>
            <div style={{ height: "100px" }} className="w3-hide-small"></div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ContactPage;
