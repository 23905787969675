import { Input, Space } from "antd";
import "./Referrals-Comp.css";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useState } from "react";
import ReferralList from "./Referral-list";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";

export const ReferralsComp = () => {
  const { Search } = Input;
  const [emailLink, setEmailLink] = useState("");
  const [showContent, setShowContent] = useState(true);
  const [showIcon, setShowIcon] = useState(true);

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const toggleIcon = () => {
    setShowIcon(!showIcon);
  };

  const shareMessage = `${authData.data?.credentials.fullName} is inviting you to join Linkmie!

Linkmie is a platform that offers an all-inclusive solution for publishers and advertisers to guest post, place ads on relevant sites, and get high-quality marketing content.

To join the platform, follow this link https://fronttest.linkmie.com/userauth/signup and use ${authData.data?.credentials.referralCode}  as your invitation code!
`;

  return (
    <div className="w3-col w3-container" style={{ minHeight: "400px" }}>
      <div className="w3-col">
        <div className="w3-content">
          <div className="w3-col l6 s12">
            {/* left wrapper section */}
            <div className="referal-left-wrapper ">
              <p className="referal-left-header">
                Refer and get{" "}
                {authData.data?.credentials.settings.referralPercentage}%{" "}
              </p>
              <p className="referal-left-content">
                Share your unique link and enjoy{" "}
                {authData.data?.credentials.settings.referralPercentage}% off
                every successful sign-up!
              </p>

              {/* social media section */}
              <div className="social-media-flex-wrapper-1 w3-center">
                <div className="facebook-container">
                  <p>
                    {" "}
                    <a
                      href={`https://web.facebook.com/dialog/share?app_id=87741124305&href=${shareMessage}&display=popup&_rdc=1&_rdr`}
                    >
                      {" "}
                      <img src="/images/referral/facebook.svg" alt="" />
                      <br />
                      Share
                    </a>{" "}
                  </p>
                </div>
                <div className="twitter-container">
                  <p>
                    {" "}
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={`https://twitter.com/share?url=${"https://fronttest.linkmie.com/userauth/signup"}&text=${shareMessage}`}
                    >
                      <img src="/images/referral/Vector.svg" alt="" />
                      <br />
                      Twitter
                    </a>{" "}
                  </p>
                </div>
              </div>
              <div className="social-media-flex-wrapper-2 w3-center">
                <div className="copy-container">
                  <p>
                    {" "}
                    <a>
                      {" "}
                      <img src="/images/referral/copy.svg" alt="" />
                      <br />
                      Copy
                    </a>{" "}
                  </p>
                </div>
                <div className="whatsapp-container">
                  <p>
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={`https://api.whatsapp.com/send/?text=${shareMessage}&type=custom_url&app_absent=0`}
                    >
                      {/* <WhatsAppOutlined /> */}
                      <img src="/images/referral/whatsapp.svg" alt="" />
                      <br />
                      Whatsapp
                    </a>
                  </p>
                </div>
              </div>
              <div style={{ height: "215px" }}></div>
            </div>
          </div>

          {/* right wrapper container */}
          <div className="w3-col s12 l6">
            <div className="referal-right-wrapper">
              <div className="referal-right-header">
                <p>Refer a friend</p>
                <img src="/images/referral/elipse-right.svg" alt="" />
              </div>
              <div className="referal-user-wrapper">
                <div className="user-image-wrapper">
                  <img src="/images/referral/user-group.svg" alt="" />
                </div>
                <p>Invite your friend to Linkmie</p>
              </div>
              <div className="referal-stroke"></div>

              <div className="referal-user-wrapper">
                <div className="user-image-wrapper">
                  <img src="/images/referral/speakerphone.svg" alt="" />
                </div>
                <p>Share via social media and copy Link</p>
              </div>
              <div className="referal-stroke"></div>

              <div className="referal-user-wrapper">
                <div className="user-image-wrapper">
                  <img src="/images/referral/currency-dollar.svg" alt="" />
                </div>
                <p>You get $10 from every friend you refer after sign-up.</p>
              </div>
              <div className="referal-last-image">
                <img src="/images/referral/elipse-left.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* total referal section */}
      <div className="w3-col">
        <div className="w3-content w3-margin">
          <div className="w3-col l6 s12">
            <div className="total-referal-left">
              <img src="/images/referral/document-align.svg" alt="" />
              <div className="total-referal-left-p">
                <p>0 Total Referral</p>
              </div>
            </div>
          </div>
          <div className="w3-col l6 s12">
            <div className="total-referal-right">
              <p>Total earning</p>
              <div className="total-referal-right-wallet-wrapper">
                <div className="total-referal-content">
                  {showContent ? <p>$0</p> : <p>********</p>}
                </div>
                <span className="total-referal-image-wrapper">
                  {showIcon ? (
                    <EyeOutlined
                      style={{ fontSize: "21px" }}
                      onClick={() => {
                        toggleContent();
                        toggleIcon();
                      }}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      style={{ fontSize: "21px" }}
                      onClick={() => {
                        toggleContent();
                        toggleIcon();
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Referral List */}
      <div className="w3-col">
        <h3 className="referral-list-header w3-margin-top">
          List Of Referrals
        </h3>
        <ReferralList></ReferralList>
      </div>
    </div>
  );
};

export default ReferralsComp;
