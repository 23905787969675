import { Modal, notification, Tabs, TabsProps } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router";
import { IOfferFormResponse } from "../../../../apiservice/offers.type";
import { appZIndex } from "../../../../utils/appconst";
import OfferFormOne from "../AddOfferForms/OfferForm-1";
import OfferFormTwo from "../AddOfferForms/OfferForm-2";
import OfferFormThree from "../AddOfferForms/OfferForm-3";
import OfferFormPayment from "../AddOfferForms/OfferForm-Payment";
import "./OfferFormsWrapper.css";
type NotificationType = "success" | "info" | "warning" | "error";

export const OfferFormsWrapper = () => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [formResultData, setFormResultData] =
    useState<IOfferFormResponse | null>(null);
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();

  const onChange = (key: string) => {
    // console.log(key);
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  const formSuccess = (resultData?: IOfferFormResponse) => {
    openNotificationWithIcon(
      "success",
      "",
      "Your Entry has being saved succesfully"
    );
    if (resultData) {
      console.log("Success", resultData);
      setFormResultData(resultData);
    }
    setOpenPaymentModal(true);
  };

  const paymentSuccess = () => {
    openNotificationWithIcon("success", "", "Your Payment has being made");
    navigate("/advertiser/offers");
  };

  const failure = (err?: string) => {
    openNotificationWithIcon("error", "", err || "Error Saving your entry");
  };

  const handleCancelModal = () => {
    setOpenPaymentModal(false);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Link Insertion",
      children: (
        <OfferFormOne
          onFormSuccess={formSuccess}
          onFormFailure={failure}
        ></OfferFormOne>
      ),
    },
    {
      key: "2",
      label: "Guest Post",
      children: (
        <OfferFormTwo
          onFormSuccess={formSuccess}
          onFormFailure={failure}
        ></OfferFormTwo>
      ),
    },
    {
      key: "3",
      label: "Banner",
      children: (
        <OfferFormThree
          onFormSuccess={formSuccess}
          onFormFailure={failure}
        ></OfferFormThree>
      ),
    },
  ];

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-container">
        <Tabs
          centered
          type="card"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />

        <Modal
          zIndex={appZIndex.modal}
          open={openPaymentModal}
          title="Make Payment"
          onCancel={handleCancelModal}
          width={700}
          footer={[
            <p style={{ minHeight: "200px" }}>
              &nbsp; <br />
              &nbsp; <br />
            </p>,
          ]}
        >
          <OfferFormPayment
            formResultData={formResultData}
            onPaymentSuccess={paymentSuccess}
            onPaymentFailure={failure}
          ></OfferFormPayment>
        </Modal>
      </div>
    </>
  );
};

export default OfferFormsWrapper;
