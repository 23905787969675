import SharedUserProfileFormEditProfile from "../SharedUserProfile-Form/SharedUserProfile-Form-EditProfile";
import SharedProfileViewPictureCard from "../SharedUserProfile-View-Cards/SharedProfile-View-Picture-Card";
import "./SharedUserProfile-Wrapper-Edit.css";

export const SharedUserProfileWrapperEdit = () => {
  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <SharedProfileViewPictureCard></SharedProfileViewPictureCard>
        <div className="w3-col">
          <SharedUserProfileFormEditProfile></SharedUserProfileFormEditProfile>
        </div>
      </div>
    </>
  );
};

export default SharedUserProfileWrapperEdit;
