import { Input } from "antd";
import { IOfferListData } from "../../../apiservice/offers.type";

type IOfferTaskDetailsCard = {
  offerListData: IOfferListData | null;
  refreshPage?: () => void;
};

export const AdvertiserViewOfferDetailsCard: React.FC<
  IOfferTaskDetailsCard
> = ({ offerListData, refreshPage }) => {
  return (
    <>
      <div className="w3-col w3-padding-bottom">
        {/* Task List  */}
        <div className="w3-col" style={{ padding: "10px" }}>
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l12 s12 m12" style={{ padding: "5px" }}>
              <h2> Task Summary </h2>
            </div>
          </div>
        </div>

        {/* Task Title Header */}
        <div className="w3-col" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "10px", height: "60px" }}
          >
            <div className="w3-col l12 s12 m12" style={{ padding: "5px" }}>
              <span className="taskDetailsTitle">
                Task Name - {offerListData?.adType}
              </span>
            </div>
          </div>
        </div>

        {/* Portal Details */}
        <div className="w3-col" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "10px", minHeight: "60px" }}
          >
            {/* Portal Name */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Portal Name</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {offerListData?.portal.websiteName} </span>
              </div>
            </div>

            {/* Domaim Name */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Domain Name</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {offerListData?.portal.domainName} </span>
              </div>
            </div>

            {/* Amount Paid */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span>Amount Paid </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> ${offerListData?.currentTask.amount} </span>
              </div>
            </div>

            {/* Job Status */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-margin-bottom">
                <span> Job Status</span>
              </div>
              <div className="w3-col l9 s12 m9 w3-margin-bottom">
                <span> {offerListData?.currentTask.status} </span>
              </div>
            </div>

            {/* Preview URL */}
            {offerListData?.currentTask.previewUrl && (
              <div className="w3-col w3-margin-bottom">
                <div className="w3-col l3 s12 m3 w3-margin-bottom">
                  <span> Preview URL</span>
                </div>
                <div className="w3-col l9 s12 m9 w3-margin-bottom">
                  <a
                    className="w3-btn w3-border w3-border-blue  w3-text-blue w3-round"
                    href={offerListData?.currentTask.previewUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check Preview
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* End Portal Details */}

        {/* Task Details */}
        <div className="w3-col l12 s12 m12" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "10px", minHeight: "200px" }}
          >
            <div className="w3-col w3-margin-bottom">
              <p>Task Info </p>
            </div>

            {/* AdType = 1 is Guest Post */}
            {offerListData?.adTypeId === 1 && (
              <div>
                {/* Topic of Artcle */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Topic of Article</span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span> {offerListData.guestPostDetails?.topic} </span>
                  </div>
                </div>

                {/* Anchor Text */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Anchor Text</span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span>{offerListData.guestPostDetails?.anchorText}</span>
                  </div>
                </div>

                {/* Main Link */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Main Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <Input
                      name="link"
                      value={offerListData.guestPostDetails?.mainLink}
                      className="w3-col inputField"
                      placeholder="Copy Link"
                    />
                  </div>
                </div>

                {/* Anchor Link */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Anchor Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span>{offerListData.guestPostDetails?.anchorLink}</span>
                  </div>
                </div>

                {/* Keyword */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Keyword</span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span>{offerListData.guestPostDetails?.keywords}</span>
                  </div>
                </div>

                {/* Other Requirement  */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Other Requirement </span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span>
                      {offerListData.guestPostDetails?.otherRequirements}
                    </span>
                  </div>
                </div>

                {/* Article */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l12 s12 m12">
                    <span>
                      <b>Article</b>
                    </span>
                  </div>
                  <div className="w3-col l12 s12 m12">
                    <div
                      className="w3-col w3-border w3-round w3-margin-top"
                      style={{ minHeight: "200px" }}
                      dangerouslySetInnerHTML={{
                        __html: offerListData.guestPostDetails?.articleText
                          ? (
                              offerListData.guestPostDetails
                                ?.articleText as string
                            ).replace(/(<? *script)/gi, "illegalscript")
                          : "",
                      }}
                    ></div>
                  </div>
                </div>

                {/* Download Button */}
                <div className="w3-col w3-margin-bottom">
                  <a
                    download={offerListData.guestPostDetails?.articleUrl}
                    target="_blank"
                    rel="noreferrer"
                    href={offerListData.guestPostDetails?.articleUrl}
                    className="w3-btn taskBtnPrimaryColor w3-round"
                  >
                    Download
                  </a>
                </div>
              </div>
            )}

            {/* AdType = 2 is Link Insertion */}
            {offerListData?.adTypeId === 2 && (
              <div>
                {/* Anchor Text */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Anchor Text</span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span>
                      {offerListData.linkInsertionDetails?.anchorText}
                    </span>
                  </div>
                </div>

                {/* Description */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Description </span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span>
                      {offerListData.linkInsertionDetails?.description}
                    </span>
                  </div>
                </div>

                {/* AnchorLink */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Anchor Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <Input
                      required
                      name="link"
                      value={offerListData.linkInsertionDetails?.linkUrl}
                      readOnly={true}
                      className="w3-col inputField"
                      placeholder="Copy Link"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* AdType = 3 is Banner */}
            {offerListData?.adTypeId === 3 && (
              <div>
                {/* Click Link */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Click Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <Input
                      readOnly={true}
                      value={offerListData.bannerDetails?.clickLink}
                      name="link"
                      className="w3-col inputField"
                      placeholder="Click Link"
                    />
                  </div>
                </div>

                {/* PageLink */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Page Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <Input
                      required
                      name="link"
                      value={offerListData.bannerDetails?.pageLink}
                      readOnly={true}
                      className="w3-col inputField"
                      placeholder="Page Link"
                    />
                  </div>
                </div>

                {/* Banner Image */}
                <div className="w3-col w3-margin-bottom">
                  <img
                    alt="bannerImage"
                    style={{ maxWidth: "50%" }}
                    src={offerListData.bannerDetails?.bannerUrl}
                  />
                  {offerListData.bannerDetails?.bannerUrl}
                </div>

                {/* Download Button */}
                <div className="w3-col w3-margin-bottom">
                  <a
                    download={offerListData.bannerDetails?.bannerUrl}
                    target="_blank"
                    rel="noreferrer"
                    href={offerListData.bannerDetails?.bannerUrl}
                    className="w3-btn taskBtnPrimaryColor w3-round"
                  >
                    Download
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Task Details  */}
      </div>
    </>
  );
};

export default AdvertiserViewOfferDetailsCard;
