import AdvertiserContentRequestDetailsWrapperGpost from "../../../components/AdvertiserContentComps/AdvertiserViewContentDetails/AdvertiserViewContentDetails-Wrapper-Gpost";
import "./AdvertiserManageContentsDetails-GPost.css";

export const AdvertiserManageContentsDetailsGPost = () => {
  return (
    <AdvertiserContentRequestDetailsWrapperGpost></AdvertiserContentRequestDetailsWrapperGpost>
  );
};

export default AdvertiserManageContentsDetailsGPost;
