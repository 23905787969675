import { LeftOutlined, LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PasswordInput from "../../../components/Sharedcomponents/PasswordBtn/Password-input";
import UserAuthWrapper, {
  IOpenNotificationType,
} from "../userAuthWrapper/userAuthwrapper";
import "./../userAuth.css";
import { Input, Radio } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";

const UserSignUp = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [notificationMessage, setNotificationMessage] =
    useState<IOpenNotificationType | null>(null);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const authRegistrationData = useAppSelector(
    (state: RootState) => state?.AuthRegistration
  );

  // This is use to Update Redux Dispatch Values
  useEffect(() => {
    setUser({ ...authRegistrationData });
  }, []);

  // Use to collect Site Description Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (user?.password !== user?.confirmPassword) {
      setNotificationMessage({
        type: "error",
        message: "",
        description: "Password do not match",
        background: "#FFC2B7",
      });
      return;
    }

    if (user?.type === "") {
      setNotificationMessage({
        type: "error",
        message: "",
        description: "Please Select an Account Type",
        background: "#FFC2B7",
      });
      return;
    }
    setFormLoading(true);
    dispatch({ type: "AUTH_ADD_REGISTRATION_STEP", payload: user });
    navigate("/userauth/signup-step-2");
    setFormLoading(false);
  };

  return (
    <>
      <UserAuthWrapper notificationMessage={notificationMessage}>
        <div>
          <div style={{ paddingTop: "20px" }}>
            <span className="getStartedText">Join Linkmie</span>
            <span className="w3-right">
              <Link to="/userauth/signin">
                <LeftOutlined style={{ zoom: "1.5" }} rev={undefined} />
              </Link>
            </span>
          </div>

          <div style={{ paddingTop: "60px" }}>
            <form onSubmit={handleSubmit}>
              <div className="w3-col w3-margin-bottom">
                <Input
                  required
                  name="email"
                  value={user?.email}
                  onChange={handleInputChange}
                  className="w3-col inputField"
                  placeholder="Email Address"
                  prefix={
                    <MailOutlined className="inputPrefix" rev={undefined} />
                  }
                />
              </div>
              <div className="w3-col w3-margin-bottom w3-margin-top">
                <PasswordInput
                  required
                  name="password"
                  placeholder="Password"
                  value={user?.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w3-col w3-margin-bottom w3-margin-top">
                <PasswordInput
                  required
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={user?.confirmPassword}
                  onChange={handleInputChange}
                ></PasswordInput>
              </div>

              <div className="w3-col w3-margin-bottom w3-margin-top">
                <Radio.Group
                  className="w3-col"
                  name="type"
                  onChange={handleInputChange}
                  value={user?.type}
                >
                  <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                    <div className="inputRadio">
                      <Radio value={"advertiser"}>Advertiser</Radio>
                    </div>
                  </div>

                  <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                    <div className="inputRadio">
                      <Radio value={"publisher"}>Publisher</Radio>
                    </div>
                  </div>
                </Radio.Group>
              </div>

              <div className="w3-col w3-margin-bottom w3-margin-top">
                <button
                  disabled={formLoading}
                  className="w3-btn w3-col submitButton"
                >
                  <span className="submitButtonText">
                    {!formLoading ? (
                      "Sign Up"
                    ) : (
                      <LoadingOutlined rev={undefined} />
                    )}
                  </span>
                </button>
              </div>

              <div className="w3-col w3-left-align w3-margin-bottom acceptTNCtext w3-margin-top">
                By clicking sign up, you accept Linkmie's &nbsp;
                <Link className="acceptTNCtextLink" to="/terms_and_condition">
                  Terms and Condition
                </Link>
                &nbsp; and &nbsp;
                <Link className="acceptTNCtextLink" to="/privacy_policy">
                  Privacy Policy
                </Link>
              </div>

              <div
                style={{ marginTop: "20px" }}
                className="w3-col w3-margin-bottom"
              >
                <p className="w3-center dont-have-account-text">
                  Already have an account?
                  <span className="dont-have-account-text-sign-up">
                    <Link to="/userauth/signin"> Sign in</Link>
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </UserAuthWrapper>
    </>
  );
};

export default UserSignUp;
