import { Alert, Input } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import PortalList from "../PortalList/PortalList";
import "./PortalListWrapper.css";

export const PortalListWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <div className="w3-col">
      <div className="w3-container">
        <div className="w3-col w3-margin-top">
          <div className="w3-col l3 s12 m3">
            <button className="addPortalButton w3-btn">
              <Link to="/publisher/portal-form"> Add Portal </Link>
            </button>
          </div>

          <div className="w3-col l9 s12 m9">
            {(authData?.data?.credentials.publisherDashboard
              ?.noOfPendingPortals || 0) > 0 && (
              <Alert
                message={<b> Incomplete Portal </b>}
                description={
                  <span>
                    you have an incomplete portal registration, Click
                    <Link to="/publisher/portals">
                      <u> here </u>
                    </Link>
                    to continue
                  </span>
                }
                type="info"
                closable
                showIcon
              />
            )}
          </div>
        </div>

        <div className="w3-col w3-margin-top">
          <div
            className="w3-col l3 s12 12"
            style={{ padding: "5px", paddingTop: "15px" }}
          >
            <span className="listOfPortalTitle"> List Of Portal </span>
          </div>

          <div className="w3-col l4 s12 m12">
            <div className="w3-col" style={{ padding: "5px" }}>
              <Input
                name="searchstring"
                value={payLoadFilter?.searchstring || ""}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="w3-col inputField"
                placeholder="Search Portal"
              />
            </div>
          </div>

          <div className="w3-col l5 s12 m12">
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <select
                name="status"
                value={payLoadFilter?.status || ""}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="w3-col inputSelect"
              >
                <option value="">Status</option>
                <option value="0"> Pending </option>
                <option value="1"> Approved</option>
                <option value="2"> Rejected </option>
              </select>
            </div>
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <span className="w3-btn selectBtn w3-round w3-col">Search</span>
            </div>
          </div>
        </div>

        <div className="w3-col w3-margin-top">
          <PortalList externalFilter={externalFilter}></PortalList>
        </div>
      </div>
    </div>
  );
};

export default PortalListWrapper;
