import { Alert, Input, Radio } from "antd";
import { useState } from "react";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import PublisherTaskList from "../PublisherTask-List/PublisherTask-List";
import "./PublisherTask-List-wrapper-wrapper.css";

export const PublisherTaskListWrapper = () => {
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(value)
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
    if (name === "status") {
      setExternalFilter({ ...externalFilter, status: value });
    }
  };

  const handleButtonClick = (value) => {
    setpayLoadFilter((values) => ({ ...values, taskType: value }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <div className="w3-col">
      <div className="w3-container">
        {(authData?.data?.credentials.publisherDashboard?.noOfPendingTasks ||
          0) > 0 && (
          <Alert
            message={<b> List Task </b>}
            description={
              <span>
                You have{" "}
                {
                  authData?.data?.credentials.publisherDashboard
                    ?.noOfPendingTasks
                }{" "}
                undo list task, Check your list task to accept or reject
              </span>
            }
            type="info"
            closable
            showIcon
          />
        )}

        <div className="w3-col w3-margin-top">
          <div
            className="w3-col l3 s12 12"
            style={{ padding: "5px", paddingTop: "15px" }}
          >
            <span className="listOfPortalTitle"> Tasks </span>
          </div>

          <div className="w3-col l4 s12 m12">
            <div className="w3-col" style={{ padding: "5px" }}>
              <Input
                name="searchstring"
                value={payLoadFilter?.searchstring || ""}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="w3-col inputField"
                placeholder="Search Task"
              />
            </div>
          </div>

          <div className="w3-col l5 s12 m12">
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <select
                name="status"
                value={payLoadFilter?.status || "-1"}
                onChange={handleInputChange}
                onBlur={updateFilter}
                className="w3-col inputSelect"
              >
                <option value="">Select Task Status</option>
                <option value="-1"> All Task</option>
                <option value="0"> Pending Task</option>
                <option value="1"> Accepted Task</option>
                <option value="2"> Rejected Task </option>
                <option value="3"> Completed Task</option>
                <option value="4"> Confirmed Task </option>
              </select>
            </div>
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <button className="w3-btn w3-col w3-round selectBtn">
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="w3-col w3-margin-top">
          <PublisherTaskList
            externalFilter={externalFilter}
          ></PublisherTaskList>
        </div>
      </div>
    </div>
  );
};

export default PublisherTaskListWrapper;
