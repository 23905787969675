import { Button, Result, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { adminGetWithdrawal } from "../../../../apiservice/admin-Users-Clone-Service";
import { IAdminWithdrawalData } from "../../../../apiservice/admin-Users-clone.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import AdminWithdrawalDetailsWrapperCard from "./AdminWithdrawalDetailsWrapper-Card";
import "./AdminWithdrawalDetailsWrapper.css";

export const AdminWithdrawalDetailsWrapper = () => {
  const [walletLoadState, setAdminUserLoadState] =
    useState<ILoadState>("loading");
  const [loadCurrentWalletData, setLoadCurrentAdminData] = useState(true);
  const [adminWithdrawalData, setAdminWithdrawalData] =
    useState<IAdminWithdrawalData | null>(null);
  const params = useParams();

  // A custom hook to Load Current/Active Portal based on the Url
  const resultCurrentWalletData = useFormatApiRequest(
    () => adminGetWithdrawal(Number(params?.id), Number(params?.typeId)),
    loadCurrentWalletData,
    () => {
      setLoadCurrentAdminData(false);
    },
    () => {
      processCurrentWalletResult();
    }
  );

  // Process The Current Portal Data Result
  const processCurrentWalletResult = async () => {
    if (resultCurrentWalletData.httpState === "SUCCESS") {
      setAdminUserLoadState("completed");
      setAdminWithdrawalData(resultCurrentWalletData?.data?.data);
    } else if (resultCurrentWalletData.httpState === "ERROR") {
      setAdminUserLoadState("error");
    }
  };

  return (
    <>
      {/* " Show Loading Indicator" */}
      {walletLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {walletLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={<Button onClick={() => setLoadCurrentAdminData(true)} type="primary">Reload</Button>}
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {walletLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken create a new portal."
            extra={<Button type="primary">Go Back</Button>}
          />
        </div>
      )}

      {/* " Display form when Portal Load State is Not loading for new form entry & completed for editing old entry" */}
      {(walletLoadState === "notLoading" ||
        walletLoadState === "completed") && (
        <div className="w3-col w3-padding-bottom">
          <AdminWithdrawalDetailsWrapperCard
            adminWithdrawalData={adminWithdrawalData}
          ></AdminWithdrawalDetailsWrapperCard>
        </div>
      )}
    </>
  );
};

export default AdminWithdrawalDetailsWrapper;
