import "./AdminManageWallet.css";
import { AdminwalletTransactionsWrapper } from "../../../components/Admin-Component-Groups/Admin-Manage-Wallets-Comp/AdminwalletTransactionsWrapper/AdminwalletTransactionsWrapper";

export const AdminManageWallet = () => {
  return (
    <div>
      <AdminwalletTransactionsWrapper></AdminwalletTransactionsWrapper>
    </div>
  );
};

export default AdminManageWallet;
