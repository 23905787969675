import { NotificationsList } from "../notifications-list/notifications-list";
import "./notifications-list-wrapper.css";

export const NotificationsListWrapper = () => {
  return (
    <>
      <div>
        <div className="w3-container">
          <div className="w3-col">
            <h2 className="notificationWrapperHeader">Notifications</h2>
          </div>
          <div className="w3-col l12 s12 m12" style={{ padding: "5px" }}>
            <NotificationsList></NotificationsList>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsListWrapper;
