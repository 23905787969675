import "./AdvertiserOffersForms.css";
import AddOfferPortalDetails from "../../../components/AdvertiserOfferComps/AdvertiserAddOffer/AddOffer-Portal-Details/AddOffer-Portal-Details";

export const AdvertiserOffersForms = () => {
  return (
    <AddOfferPortalDetails ></AddOfferPortalDetails>
  );
};

export default AdvertiserOffersForms;
