import notification from "antd/es/notification";
import { useEffect } from "react";
import "./../userAuth.css";

export type NotificationType = "success" | "info" | "warning" | "error";

export type IOpenNotificationType = {
  type: NotificationType;
  message: string;
  description: string;
  background?: string;
  showNotification?: boolean;
};

type IUserAuthWrapper = {
  testId?: string;
  children: any;
  notificationMessage: IOpenNotificationType | null;
  imageType?: "user" | "admin";
};

const UserAuthWrapper: React.FC<IUserAuthWrapper> = (
  props: IUserAuthWrapper
) => {
  const [api, contextHolder] = notification.useNotification();

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // console.log(props.notificationMessage);
  // This is use to fire the notification
  useEffect(() => {
    if (props.notificationMessage) {
      openNotificationWithIcon(
        props.notificationMessage?.type,
        props.notificationMessage?.description,
        props.notificationMessage?.message,
        props.notificationMessage?.background
      );
    }
  }, [props.notificationMessage]);

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col">
        <div className="w3-content">
          <div className="w3-col l6 s12 m6 w3-hide-small">
            <div className="w3-col rightPanel w3-padding">
              <p
                className="w3-center authImageHolder"
                style={{ paddingTop: "50px" }}
              >
                <img
                  style={{ maxWidth: "70%" }}
                  alt="authImage"
                  src={`${
                    process.env.PUBLIC_URL + props.imageType !== "admin"
                      ? "/images/authimages.svg"
                      : "/images/admin-login/adminloginimg.svg"
                  }`}
                />
              </p>
            </div>
          </div>

          <div className="w3-col l6 s12 m6">
            <div className="w3-col wrapperFormContainer">
              <div
                className="w3-col wrapperFormBorder w3-round-xlarge"
                style={{ padding: "30px" }}
              >
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAuthWrapper;
