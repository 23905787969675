import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Input, Result, Tooltip } from "antd";
import { useState } from "react";
import { sampleApiCall } from "../../../../apiservice/authService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { appZIndex } from "../../../../utils/appconst";
import "./SharedUserProfile-Form.css";

type ISharedUserProfileFormEditPassword = {
  onFormSuccess?: () => void;
  onFormFailure?: (err?: string) => void;
};

export const SharedUserProfileFormEditPassword: React.FC<
  ISharedUserProfileFormEditPassword
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showFormResult, setShowFormResult] = useState(false);

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to Process Form
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      setShowFormResult(true);
      if (onFormSuccess) {
        onFormSuccess();
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
        alert(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      {/* Profile Form */}
      {!showFormResult && (
        <form onSubmit={handleSubmit}>
          {/* Forms Here */}

          <div className="profile-settings-form">
            <label htmlFor="">Change Password</label>
            <div style={{ height: "30px" }}></div>
            <Input
              required
              name="oldPassword"
              value={payLoad?.oldPassword || ""}
              onChange={handleInputChange}
              className="edit-password-input"
              placeholder="Old Password"
              type="password"
            />
            <Input
              required
              name="newPassword"
              value={payLoad?.newPassword || ""}
              onChange={handleInputChange}
              className="edit-password-input"
              placeholder="New Password"
              type="password"
            />
            <Input
              required
              name="confirmPassword"
              value={payLoad?.confirmPassword || ""}
              onChange={handleInputChange}
              className="edit-password-input"
              placeholder="Confirm Password"
              type="password"
            />
          </div>

          {/* Button Here */}
          <div className="w3-padding" style={{ marginBottom: "20px" }}>
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge">
              <button className="nextButton w3-btn" disabled={formLoading}>
                {!formLoading ? (
                  "Save changes"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </form>
      )}
      {showFormResult && (
        <div>
          <Result
            status="success"
            title=""
            subTitle="You have succesfully Updated Your Profile"
            extra={[
              <Button
                onClick={() => {
                  setShowFormResult(false);
                }}
                type="primary"
                key="console"
              >
                Okay
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default SharedUserProfileFormEditPassword;
