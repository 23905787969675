import { Link } from "react-router-dom";
import "./AdvertiserContentIntro.css";

export const AdvertiserContentIntro = () => {
  return (
    <div className="w3-content">
      {/* Article Description */}
      <div className="w3-col" style={{ marginTop: "20px" }}>
        <div className="w3-col l6 s12 m12 w3-center">
          <img
            style={{ maxWidth: "70%" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/content/blogImage.svg"}`}
          />
          <br />
        </div>

        <div className="w3-col l5 s12 m12">
          <div className="w3-col w3-margin-top">
            <div className="w3-padding">
              <br />
              <h4 className="advertiserContentIntroHeader">
                We create article
              </h4>
            </div>

            <div className="w3-padding" style={{ marginTop: "20px" }}>
              <p className="advertiserContentIntroParagraph">
              Our team of writers can create high-quality articles for your advertising strategy, ensuring attention to your audience. To begin, provide details about your campaign goals, subject matter, and desired messages. We will work closely with you to ensure the content aligns with your brand and goals, resulting in relevant and engaging content for your audience.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Banner Desccription */}
      <div className="w3-col" style={{ marginTop: "20px" }}>
        <div className="w3-col l1 s12 m12">
          <br />
        </div>
        <div className="w3-col l5 s12 m12">
          <div className="w3-col w3-margin-top">
            <div className="w3-padding">
              <br />
              <h4 className="advertiserContentIntroHeader">We create Banner</h4>
            </div>

            <div className="w3-padding" style={{ marginTop: "20px" }}>
              <p className="advertiserContentIntroParagraph">
              Our design team specializes in creating visually appealing and effective banner ads for your advertising efforts. We aim to create long-lasting impressions of your banners for your company's visual identity and message. Share your creative vision, including aesthetics, colors, and desired imagery or messaging.
              </p>
            </div>
          </div>
        </div>
        <div className="w3-col l6 s12 m12 w3-center">
          <img
            style={{ maxWidth: "70%" }}
            alt="heroImage"
            src={`${
              process.env.PUBLIC_URL + "/images/content/bannerImage.svg"
            }`}
          />
          <br />
        </div>
      </div>

      {/* Buttons */}
      <div className="w3-col" style={{ marginTop: "20px" }}>
        <div className="w3-col l6 s12 m12">
          <div
            className="w3-padding w3-center"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Link to="/advertiser/manage-content-add">
              <button className="nextButton w3-btn">Create Content</button>
            </Link>
          </div>
        </div>
        <div className="w3-col l6 s12 m12">
          <div
            className="w3-padding w3-center"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Link to="/advertiser/manage-content-progress">
              <button className="nextButton w3-btn">Track Content</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertiserContentIntro;
