import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, notification, Pagination, Result } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  confirmOfferTask,
  getAdvertiserOffers,
} from "../../../../apiservice/offers.service";
import { IOfferListData } from "../../../../apiservice/offers.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { appZIndex } from "../../../../utils/appconst";
import { formatCurrency } from "../../../../utils/basic.utils";
import { convertToShortDate } from "../../../../utils/date.utils";
import { ILoadState } from "../../../../utils/loading.utils.";
import AdvertiserViewOfferDetailsCard from "../../AdvertiserViewOfferDetails/AdvertiserViewOfferDetails-Card";
import "./AdvertiserViewOffer-List.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdvertiserViewOfferList = {
  externalFilter?: any;
  initialDefaultFilter?: any;
  hidePagination?: boolean;
};

export const AdvertiserViewOfferList: React.FC<IAdvertiserViewOfferList> = ({
  externalFilter,
  initialDefaultFilter,
  hidePagination = false,
}) => {
  const [offerLoadState, setOfferLoadState] = useState<ILoadState>("loading");
  const [loadOffersData, setLoadOffersData] = useState(true);
  const [loadComfirmOffer, setLoadComfirmOffer] = useState(false);
  const [offerDefaultFilter, setofferDefaultFilter] = useState(
    initialDefaultFilter || { page: 1, perPage: 10, paymentVerified: true }
  );
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const { confirm } = Modal;

  // Pagination Constant/Variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const perPage = 10;

  const navigate = useNavigate();

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setCurrentPage(1);
      setofferDefaultFilter({
        ...offerDefaultFilter,
        ...externalFilter,
        page: 1,
      });
      setLoadOffersData(true);
      setOfferLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const offerDataResult = useFormatApiRequest(
    () => getAdvertiserOffers(offerDefaultFilter),
    loadOffersData,
    () => {
      setLoadOffersData(false);
    },
    () => {
      processOffersResult();
    }
  );

  // Process The Current Offer Data Result
  const processOffersResult = async () => {
    if (offerDataResult.httpState === "SUCCESS") {
      setTableData(offerDataResult.data?.data);
      setTotalItems(offerDataResult.data?.meta?.total || 1);
      setOfferLoadState("completed");
    } else if (offerDataResult.httpState === "ERROR") {
      setOfferLoadState("error");
    } else if (offerDataResult.httpState === "LOADING") {
      setOfferLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  // Confirmation of Portal Task Completed
  const navigateToTrackContent = (index?) => {
    if (tableData[index].adTypeId === 1) {
      const linkId = tableData[index]?.guestPostDetails?.request?.id;
      navigate("/advertiser/manage-content-details-guestPost/" + linkId);
    } else if (tableData[index].adTypeId === 3) {
      const linkId = tableData[index]?.bannerDetails?.request?.id;
      navigate("/advertiser/manage-content-details-Banner/" + linkId);
    }
    setSelectedItemId(index);
  };

  // Confirmation of Portal Task Completed
  const showCompleteConfirm = (itemId?) => {
    setSelectedItemId(itemId);
    confirm({
      title: "Are you sure you want to confirm that this work has being done",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        setLoadComfirmOffer(true);
        setOfferLoadState("loading");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Comfirm Portal Task
  const confirmOfferApiResult = useFormatApiRequest(
    () => confirmOfferTask(selectedItemId),
    loadComfirmOffer,
    () => {
      setLoadComfirmOffer(false);
    },
    () => {
      processConfirmOffer();
    }
  );

  // Process The Delete Portal Result
  const processConfirmOffer = async () => {
    if (confirmOfferApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "You Have confirmed this Offer");
      // Reload all portal again
      setLoadOffersData(true);
    } else if (confirmOfferApiResult.httpState === "ERROR") {
      setOfferLoadState("completed");
      openNotificationWithIcon("error", "", "Error Confirming Offer");
    }
  };

  // Table Configuration
  const columns: ColumnsType<IOfferListData> = [
    {
      title: "Action",
      dataIndex: "id",
      key: "select",
      fixed: "left",
      width: 120,
      render: (text, records, index) => (
        <div className="w3-center">
          {records.currentTask.statusId === 0 && (
            <span className="w3-text-blue">Pending</span>
          )}
          {records.currentTask.statusId === 1 && (
            <span className="w3-text-amber">Processing</span>
          )}
          {records.currentTask.statusId === 2 && (
            // <button className="w3-btn selectBtn">Modify Offer</button>
            <span className="w3-text-red"> Offer Rejected. </span>
          )}
          {records.currentTask.statusId === 3 && (
            <button
              onClick={() => {
                showCompleteConfirm(records.id);
              }}
              className="w3-btn selectBtn"
            >
              Confirm Offer
            </button>
          )}
          {records.currentTask.statusId === 4 && (
            <span className="w3-text-green">Done</span>
          )}
          {records.currentTask.statusId === 5 && (
            <span className="w3-small w3-text-blue"> Waiting for Content </span>
          )}
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "adType",
      key: "adType",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, records, index) => <span>{records.category.title}</span>,
    },
    {
      title: "Portal Name",
      dataIndex: "portal",
      key: "Portal",
      render: (text, records, index) => (
        <span>{records.portal.websiteName}</span>
      ),
    },
    {
      title: "Payment Made",
      dataIndex: "currentTask",
      key: "currentTask",
      render: (text, records, index) => (
        <span>{formatCurrency(records.currentTask.amount)}</span>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateModified",
      key: "dateModified",
      render: (text) => <span>{convertToShortDate(text)}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, records, index) => (
        <>
          <span
            onClick={() => {
              setIndex(index);
            }}
            className="w3-btn w3-small w3-round selectBtn"
          >
            View
          </span>
          {records.currentTask.statusId === 5 && (
            <span
              onClick={() => {
                navigateToTrackContent(index);
              }}
              className="w3-btn w3-green w3-small w3-round selectBtn"
            >
              Track Content
            </span>
          )}
        </>
      ),
    },
  ];

  // Use to Control Pagination On Change Event
  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setofferDefaultFilter({
      ...offerDefaultFilter,
      page: page,
      perPage: pageSize,
    });
    setLoadOffersData(true);
  };

  // Set Index
  const setIndex = (index: number, modalTitle?: string) => {
    setSelectedItem(tableData[index]);
    setModalTitle(modalTitle || "");
    setOpenModal(true);
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {offerLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadOffersData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {offerLoadState !== "error" && (
        <>
          <div className="w3-col w3-margin-top">
            <Table
              loading={offerLoadState === "loading"}
              rowKey="id"
              size="small"
              columns={columns}
              scroll={{ x: 950 }}
              dataSource={tableData}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>

          {!hidePagination && (
            <div className="w3-col w3-margin-top">
              <Pagination
                current={currentPage || 1}
                onChange={onPageChange}
                pageSize={perPage}
                total={totalItems}
              />
            </div>
          )}

          <Modal
            zIndex={appZIndex.modal}
            open={openModal}
            title={modalTitle}
            onCancel={handleCancelModal}
            width={700}
            footer={[
              <p style={{ minHeight: "200px" }}>
                &nbsp; <br />
                &nbsp; <br />
              </p>,
            ]}
          >
            <AdvertiserViewOfferDetailsCard
              offerListData={selectedItem}
            ></AdvertiserViewOfferDetailsCard>
          </Modal>
        </>
      )}
    </>
  );
};

export default AdvertiserViewOfferList;
