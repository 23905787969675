import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Alert, Input, Tooltip } from "antd";
import { useState } from "react";
import { IAuthType } from "../../../apiservice/authService.type";
import { advertiserAddGuestPostRequest } from "../../../apiservice/offers.service";
import { IOfferFormResponse } from "../../../apiservice/offers.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { appZIndex } from "../../../utils/appconst";
import "./AdvertiserContentForms.css";

type IAdvertiserContentFormsOne = {
  onFormSuccess?: (resultData?: IOfferFormResponse) => void;
  onFormFailure?: (err?: string) => void;
};

export const AdvertiserContentFormsOne: React.FC<
  IAdvertiserContentFormsOne
> = ({ onFormFailure, onFormSuccess }) => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const amount = authData.data?.credentials?.settings?.guestPostRequestPrice;

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setpayLoad({
      ...payLoad,
      requestAmount: amount,
      offerAmount: amount,
    });
    setLoadApi(true);
    setFormLoading(true);
  };

  // A custom hook to save form data
  const result = useFormatApiRequest(
    () => advertiserAddGuestPostRequest(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);
      if (onFormSuccess) {
        onFormSuccess(result.data);
      }
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      if (onFormFailure) {
        onFormFailure(result.data?.response?.data?.message || result.errorMsg);
      }
      //Handle Error Here
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Forms Here */}
        <div>
          {/* Info Details Box */}
          <div className="w3-col w3-margin-bottom">
            <Alert
              description={
                <span>
                  Linkmie provide the best article to help your business or
                  organization grow, Input the necessary information as you wait
                  lets prepare an article for you.
                </span>
              }
              style={{ width: "92%" }}
              type="info"
              showIcon
            />
          </div>
          {/* Topic of Article */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Article Topic <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Specify the topic of your article."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="topic"
                value={payLoad?.topic || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Topic of Article"
              />
            </div>
          </div>

          {/* Main Link */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Main Link <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Enter the main URL where you want the the content to be published"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="mainLink"
                value={payLoad?.mainLink || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Main Link"
              />
            </div>
          </div>

          {/* Anchor Text */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Anchor Text<span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Specify the clickable text that user will see."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="anchorText"
                value={payLoad?.anchorText || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Anchor Text"
              />
            </div>
          </div>

          {/* Anchor Link */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Anchor Link <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Specify the URL that corresponds to the anchor text"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="anchorLink"
                value={payLoad?.anchorLink || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Anchor Link"
              />
            </div>
          </div>

          {/* Keywords */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Key Words <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="List keyword or phrase that are relevant to the content you are requesting."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="keywords"
                value={payLoad?.keywords || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Key Words"
              />
            </div>
          </div>

          {/* Other Requirement */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Other Requirements<span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Details any specific requirement or guideline for the content"
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                name="otherRequirements"
                value={payLoad?.otherRequirements || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Other Requirements"
              />
            </div>
          </div>
          
          {/* Number of Words */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Word Count <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Specify the desired word count for the content you're requesting."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <Input
                required
                type="number"
                name="noOfWords"
                value={payLoad?.noOfWords || ""}
                style={{ width: "90%" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Number of Words"
              />
            </div>
          </div>
          
          {/*  Describe Your Guest Post */}
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span>
                Describe Your Guest Post
                <span className="w3-text-red">*</span>
                <span className="tooltips" style={{ marginLeft: "5px" }}>
                  <Tooltip
                    color={"#2f3542"}
                    placement="right"
                    title="Briefly describe the content you want."
                    zIndex={appZIndex.tooltips}
                  >
                    <InfoCircleOutlined rev={undefined} />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div className="w3-col l9 s12 m9 w3-center">
              <textarea
                required
                name="description"
                value={payLoad?.description || ""}
                style={{ width: "90%", height: "200px", resize: "none" }}
                onChange={handleInputChange}
                className="w3-col inputField"
                placeholder="Describe Your Guest Post"
              ></textarea>
            </div>
          </div>
        </div>
        {/* Button Here */}
        <div
          className="w3-padding"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <div className="w3-col w3-border w3-border-blue w3-padding w3-container w3-round-xlarge">
            <div
              className="w3-col l6 s12 m6"
              style={{ padding: "5px", paddingTop: "15px" }}
            >
              Total ${amount}
            </div>
            <div
              className="w3-col l6 s12 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              <button className="nextButton w3-btn" disabled={formLoading}>
                {!formLoading ? (
                  "Make Payment"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdvertiserContentFormsOne;
