import { Button, Result, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { adminGetPortal } from "../../../../apiservice/admin-Users-Clone-Service";
import { IAdminPortalsData } from "../../../../apiservice/admin-Users-clone.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import AdminPortalDetailsCard from "./Admin-Portal-Details-Card";
import "./Admin-Portal-Details-Wrapper.css";

export const AdminPortalDetailsWrapper = () => {
  const [portalLoadState, setPortalLoadState] = useState<ILoadState>("loading");
  const [loadCurrentPortalData, setLoadCurrentPortalData] = useState(true);
  const [adminPortalData, setAdminPortalData] =
    useState<IAdminPortalsData | null>(null);
  const params = useParams();

  // A custom hook to Load Current/Active Portal based on the Url
  const resultCurrentPortalData = useFormatApiRequest(
    () => adminGetPortal(Number(params?.id)),
    loadCurrentPortalData,
    () => {
      setLoadCurrentPortalData(false);
    },
    () => {
      processCurrentPortalResult();
    }
  );

  // Process The Current Portal Data Result
  const processCurrentPortalResult = async () => {
    if (resultCurrentPortalData.httpState === "SUCCESS") {
      setAdminPortalData(resultCurrentPortalData.data?.data);
      setPortalLoadState("completed");
    } else if (resultCurrentPortalData.httpState === "ERROR") {
      setPortalLoadState("error");
    }
  };

  return (
    <>
      {/* " Show Loading Indicator" */}
      {portalLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {portalLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadCurrentPortalData(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {portalLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken create a new portal."
            extra={<Button type="primary">Go Back</Button>}
          />
        </div>
      )}

      {/* " Display form when Portal Load State is Not loading for new form entry & completed for editing old entry" */}
      {(portalLoadState === "notLoading" ||
        portalLoadState === "completed") && (
        <div className="w3-col w3-padding-bottom">
          <AdminPortalDetailsCard
            adminPortalData={adminPortalData}
            refreshPage={() => {
              setLoadCurrentPortalData(true);
            }}
          ></AdminPortalDetailsCard>
        </div>
      )}
    </>
  );
};

export default AdminPortalDetailsWrapper;
