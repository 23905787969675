import {
  BellOutlined,
  DownCircleOutlined,
  MenuOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { Badge, Drawer, Space } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import Sidebar from "../SideBar/Sidebar";
import "./topbar.css";
import TopBarMenuOption from "./topBarMenuOptions";

export const TopBar = () => {
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const [open, setOpen] = useState(false);
  const [openOption, setOpenOption] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className="w3-col w3-hide-small w3-hide-medium"
        style={{ width: "300px" }}
      >
        <br />
      </div>

      {/* Top Bar Card */}
      <div className="w3-rest shadow">
        {authData && (
          <div className="w3-padding">
            <div
              className="w3-col"
              style={{ paddingTop: "5px", paddingBottom: "5px" }}
            >
              <div style={{ paddingTop: "15px" }} className="w3-col l6 s4 m6">
                <div>
                  <MenuOutlined
                    className="w3-hide-large"
                    onClick={showDrawer}
                    rev={undefined}
                  />
                  <span>
                    <img
                      style={{ maxHeight: "60px" }}
                      className="favicon-header"
                      src="/Favicon.jpg"
                      alt=""
                    />
                  </span>
                  <span> Linkmie </span>
                </div>
              </div>
              <div
                style={{ paddingTop: "2px" }}
                className="w3-col l6 s8 m6 w3-right-align"
              >
                <span className="topBarNotificationBell">
                  <Link
                    to={
                      "/" +
                      authData.data?.credentials.activeUserType +
                      "/notifications"
                    }
                  >
                    <Space size="middle">
                      <Badge
                        style={{ fontSize: "6px" }}
                        size="small"
                        count={
                          authData.data?.credentials.noOfUnreadNotifications ||
                          0
                        }
                      >
                        <BellOutlined />
                      </Badge>
                    </Space>
                  </Link>
                  &nbsp;&nbsp;
                </span>
                <img
                  className="w3-circle"
                  style={{ width: "48px", maxWidth: "100%" }}
                  alt="logo"
                  src={authData?.data?.credentials.dpUrl}
                />
                <div
                  style={{ float: "right", padding: "5px", textAlign: "left" }}
                >
                  <span className="profileCardName">
                    {authData?.data?.credentials.fullName}
                  </span>
                  <br />
                  <span className="profilePosition">
                    {authData?.data?.credentials.activeUserType}
                  </span>
                  <span className="subProfileColor w3-right">
                    {openOption ? (
                      <UpCircleOutlined
                        onClick={() => {
                          setOpenOption(false);
                        }}
                        rel="undefined"
                      />
                    ) : (
                      <DownCircleOutlined
                        onClick={() => {
                          setOpenOption(true);
                        }}
                        rel="undefined"
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Option Menu */}

      <div
        className={
          "w3-col w3-white w3-card-2 topBarOptionMenu " +
          (!openOption && " w3-hide")
        }
      >
        <TopBarMenuOption
          onLinkChange={() => {
            setOpenOption(false);
            onClose();
          }}
        ></TopBarMenuOption>
      </div>

      <Drawer
        zIndex={1000000}
        title="Linkmie"
        placement="left"
        onClose={onClose}
        open={open}
        width={300}
      >
        <Sidebar onLinkChange={onClose}></Sidebar>
      </Drawer>
    </>
  );
};

export default TopBar;
