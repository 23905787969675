import { Input } from "antd";
import { useEffect, useState } from "react";
import { IAuthType } from "../../../../apiservice/authService.type";
import { places } from "../../../../apiservice/places";
import { IPlace } from "../../../../apiservice/places.type";
import {
  getSiteNiches,
  getSiteSubCategories,
} from "../../../../apiservice/site-divisions.service";
import {
  ISiteNicheData,
  ISubCategoryData,
} from "../../../../apiservice/site-divisions.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import AddOfferSelectPortalList from "../AddOffer-SelectPortal-List/AddOffer-SelectPortal-List";
import "./AddOffer-SelectPortal-List-wrapper.css";

export const AddOfferSelectPortalListwrapper = () => {
  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );
  const [payLoadFilter, setpayLoadFilter] = useState<any>({});
  const [externalFilter, setExternalFilter] = useState<any>({});

  const [loadSiteNiceApi, setLoadSiteNiceApi] = useState(true);
  const [siteNicheData, setSiteNicheData] = useState<ISiteNicheData[]>([]);

  const [loadSubCategoryApi, setLoadSubCategoryApi] = useState(true);
  const [subCategoryData, setSubCategoryData] = useState<ISubCategoryData[]>(
    []
  );

  const allPlace: IPlace[] = places as IPlace[];

  const [country, setCountry] = useState<any[]>([]);
  const [countryCode, setCountryCode] = useState<any[]>([]);

  // This is use to Load Country
  useEffect(() => {
    updateCountry();
  }, []);

  const updateCountry = () => {
    const countries = allPlace.map((place) => {
      return { name: place.emoji + " " + place.name, value: place.name };
    });

    const countriesCode = allPlace.map((place) => {
      return {
        name: place.emoji + " " + place.phone_code,
        value: place.phone_code,
      };
    });

    setCountry(countries);
    setCountryCode(countriesCode);
  };

  // A custom hook to Load Site Niche from the server
  const siteNicheResult = useFormatApiRequest(
    () => getSiteNiches(),
    loadSiteNiceApi,
    () => {
      setLoadSiteNiceApi(loadSiteNiceApi);
    },
    () => {
      processSiteNicheResultApi();
    }
  );

  // Process Site Niche Result
  const processSiteNicheResultApi = async () => {
    if (siteNicheResult.httpState === "SUCCESS") {
      setSiteNicheData(siteNicheResult.data?.data);
      // console.log(siteNicheResult.data);
    } else if (siteNicheResult.httpState === "ERROR") {
      // console.log(siteNicheResult.errorMsg);
    }
  };

  // A custom hook to Load Sub Category from the server
  const subCategoryResult = useFormatApiRequest(
    () => getSiteSubCategories(),
    loadSubCategoryApi,
    () => {
      setLoadSubCategoryApi(loadSubCategoryApi);
    },
    () => {
      processSubCategoryResultApi();
    }
  );

  // Process Site Sub Category Result
  const processSubCategoryResultApi = async () => {
    if (subCategoryResult.httpState === "SUCCESS") {
      setSubCategoryData(subCategoryResult.data?.data);
    } else if (subCategoryResult.httpState === "ERROR") {
      // console.log(subCategoryResult.errorMsg);
    }
  };

  // Use to collect Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoadFilter((values) => ({ ...values, [name]: value }));
  };

  // Use to Update Filter
  const updateFilter = () => {
    setExternalFilter({ ...payLoadFilter });
  };

  return (
    <div className="w3-col">
      <div className="w3-container">
        <div className="w3-col w3-margin-top">
          <h2 className="createOfferHeader"> Create Offer</h2>
          <p className="createOfferSubText">
            Hey! {authData.data?.credentials.fullName} create an attractive
            offer highlighting your advertising goals and connect with the right
            publishers
          </p>
        </div>

        <div className="w3-col" style={{ marginTop: "50px" }}>
          <div
            className="w3-col l3 s12 12"
            style={{ padding: "5px", paddingTop: "15px" }}
          >
            <span className="listOfPortalTitle"> List Of Portal </span>
          </div>

          <div className="w3-col l9 s12 m12">
            <div className="w3-col l9 s6 m12">
              <div className="w3-col" style={{ padding: "5px" }}>
                <Input
                  name="searchstring"
                  value={payLoadFilter?.searchstring || ""}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="w3-col inputField"
                  placeholder="Search Portal"
                />
              </div>
            </div>

            <div className="w3-col l3 s6 m12">
              <div className="w3-col l12 s12 m12" style={{ padding: "5px" }}>
                <select
                  name="siteSubCategoryId"
                  value={payLoadFilter?.siteSubCategoryId || ""}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="w3-col inputSelect"
                >
                  <option value="">All Category</option>
                  {subCategoryData.map(
                    (item: ISubCategoryData, index: number) => (
                      <option key={index} value={item.id}>
                        {item.title}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>

            <div className="w3-col l12 s12 m12">
              <div className="w3-col l3 s6 m6" style={{ padding: "5px" }}>
                <Input
                  name="organicTraffic"
                  value={payLoadFilter?.organicTraffic || ""}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="w3-col inputField"
                  placeholder="Organic Traffic"
                />
              </div>

              <div className="w3-col l3 s6 m6" style={{ padding: "5px" }}>
                <select
                  name="siteNicheId"
                  value={payLoadFilter?.siteNicheId || ""}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="w3-col inputSelect"
                >
                  <option value="">All Site Niche</option>
                  {siteNicheData.map((item: ISiteNicheData, index: number) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w3-col l3 s6 m6" style={{ padding: "5px" }}>
                <select
                  name="region"
                  value={payLoadFilter?.region || ""}
                  onChange={handleInputChange}
                  onBlur={updateFilter}
                  className="w3-col inputSelect"
                >
                  <option value="">All Region</option>
                  {country.map((item: any, index: number) => (
                    <option key={index} value={item?.value}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w3-col l3 s6 m6" style={{ padding: "5px" }}>
                <span className="w3-btn selectBtn w3-round w3-col">
                  {" "}
                  Search
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w3-col w3-margin-top">
          <AddOfferSelectPortalList
            externalFilter={externalFilter}
          ></AddOfferSelectPortalList>
        </div>
      </div>
    </div>
  );
};

export default AddOfferSelectPortalListwrapper;
