import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Modal, Result, Tooltip } from "antd";
import { useState } from "react";
import { sampleApiCall } from "../../../apiservice/authService";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { appZIndex } from "../../../utils/appconst";
import "./AdvertiserContentForms.css";
import PaystackPop from "@paystack/inline-js";
import { IOfferFormResponse } from "../../../apiservice/offers.type";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  payPalCreateOrderLink,
  payPalCaptureOrderLink,
} from "../../../apiservice/transactions.service";

type IAdvertiserContentFormsPayment = {
  formResultData?: IOfferFormResponse | null;
  onPaymentSuccess?: (resultData?: any) => void;
  onPaymentFailure?: (err?: string) => void;
};

export const AdvertiserContentFormsPayment: React.FC<
  IAdvertiserContentFormsPayment
> = ({ formResultData, onPaymentFailure, onPaymentSuccess }) => {
  const [loadWalletPaymentApi, setLoadWalletPaymentApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showPaypalModal, setShowPaypalModal] = useState<boolean>(false);
  const [showFormResult, setShowFormResult] = useState(false);

  const authData: IAuthType = useAppSelector(
    (state: RootState) => state?.AuthData
  );

  const exchangeRateToNaira =
    authData.data?.credentials?.settings?.usdToNairaExchangeRate;

  // Use to collect Input change Change
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values) => ({ ...values, [name]: value }));
  };

  // Initial PayPal Option
  const initialPayPalOptions = {
    clientId: "test",
    currency: "USD",
    intent: "capture",
  };

  const handleCancelModal = () => {
    setShowPaypalModal(false);
  };

  const payWithWallet = (event: any) => {
    event.preventDefault();
    setFormLoading(true);
    setLoadWalletPaymentApi(true);
  };

  // A custom hook to Process Wallet Payment
  const walletPaymentResult = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadWalletPaymentApi,
    () => {
      setLoadWalletPaymentApi(false);
    },
    () => {
      processWalletPaymentApi();
    }
  );

  // Process Api
  const processWalletPaymentApi = async () => {
    if (walletPaymentResult.httpState === "SUCCESS") {
      setFormLoading(false);
      setShowFormResult(true);
      if (onPaymentSuccess) {
        onPaymentSuccess();
      }
    } else if (walletPaymentResult.httpState === "ERROR") {
      setFormLoading(false);
      if (onPaymentFailure) {
        onPaymentFailure(
          walletPaymentResult.data?.response?.data?.message ||
            walletPaymentResult.errorMsg
        );
      }
    }
  };

  // Use to Submit Form to select Payment Gateway
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (payLoad?.topupMethod === "1") {
      payWithPayPal();
    } else if (payLoad?.topupMethod === "2") {
      payWithFlutterWave();
    } else if (payLoad?.topupMethod === "3") {
      payWithPayStack();
    } else {
      alert("Please select a payment method");
    }
  };

  const payWithPayStack = () => {
    // console.log(formResultData?.paystackMetadata);
    // console.log(formResultData?.data?.request?.amount);
    // console.log(exchangeRateToNaira);
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: process.env.REACT_APP_PAYSTACK_PK,
      email: authData.data?.credentials.email || "annonymouslinkmie@gmail.com",
      amount:
        (Number(formResultData?.data?.request?.amount) || 0) *
        100 *
        exchangeRateToNaira,
      currency: "NGN",
      metadata: formResultData?.paystackMetadata,
      onSuccess: (transaction) => {
        setShowFormResult(true);
        console.log(transaction.reference);
        if (onPaymentSuccess) {
          onPaymentSuccess();
        }
      },
      onCancel: () => {
        console.log("Pop Up closed");
        if (onPaymentFailure) {
          onPaymentFailure();
        }
      },
    });
  };

  const payWithFlutterWave = () => {
    alert("Payment Method not available");
  };

  const payWithPayPal = () => {
    setShowPaypalModal(true);
  };

  //PayPal Function
  async function createOrder() {
    const response = await fetch(payPalCreateOrderLink(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // use the "body" param to optionally pass additional order information
      // like product ids and quantities
      body: JSON.stringify(formResultData?.paypalMetadata),
    });
    const orderRes = await response.json();
    console.log(orderRes);

    return orderRes?.data.orderId;
  }

  function onApprove(data) {
    console.log(data);
    return fetch(payPalCaptureOrderLink(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderId: data.orderID,
      }),
    })
      .then((response) => response.json())
      .then((orderData) => {
        alert(`Transaction completed`);
        if (onPaymentSuccess) {
          onPaymentSuccess();
        }
      });
  }
  return (
    <div>
      {/* Wallet Form */}
      {!showFormResult && (
        <form onSubmit={handleSubmit}>
          {/* Forms Here */}
          <div>
            <div className="w3-col w3-margin-bottom w3-padding">
              <button
                onClick={payWithWallet}
                disabled={formLoading}
                className="transferFromWalletButton w3-btn w3-col w3-disabled"
              >
                {!formLoading ? (
                  "Transfer $0 from your Wallet"
                ) : (
                  <LoadingOutlined rev={undefined} />
                )}
              </button>
            </div>

            <div className="w3-col w3-padding w3-margin-bottom">
              <br />
              <span className="noFundInWalletText">
                Select, If no fund on wallet
              </span>
            </div>
            {/* Payment Method */}
            <div className="w3-col w3-margin-bottom">
              <div className="w3-col l3 s12 m3 w3-padding">
                <span>
                  Payment Method <span className="w3-text-red">*</span>
                  <span className="tooltips" style={{ marginLeft: "5px" }}>
                    <Tooltip
                      color={"#2f3542"}
                      placement="right"
                      title="Lospem ndnd"
                      zIndex={appZIndex.tooltips}
                    >
                      <InfoCircleOutlined rev={undefined} />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="w3-col l9 s12 m9 w3-center">
                <select
                  required
                  name="topupMethod"
                  value={payLoad?.topupMethod || ""}
                  style={{ width: "100%" }}
                  onChange={handleInputChange}
                  className="w3-col inputSelect"
                >
                  <option value="">Select Method</option>
                  <option value="1"> Paypal</option>
                  <option value="3"> Paystack</option>
                </select>
              </div>
            </div>
          </div>

          {/* Button Here */}
          <div
            className="w3-padding"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <div className="w3-col w3-border-blue w3-padding w3-container w3-round-xlarge">
              <button className="nextButton w3-btn">Proceed</button>
            </div>
          </div>
        </form>
      )}

      {showFormResult && (
        <div>
          <Result
            status="success"
            title=""
            subTitle="You have succesfully made payment"
            extra={[
              <Button
                onClick={() => {
                  setShowFormResult(false);
                }}
                type="primary"
                key="console"
              >
                Okay
              </Button>,
            ]}
          />
        </div>
      )}

      {/* Paypal Modal */}
      <Modal
        zIndex={appZIndex.modal}
        open={showPaypalModal}
        title={"Pay With PayPal"}
        onCancel={handleCancelModal}
        width={700}
        footer={[
          <p style={{ minHeight: "200px" }}>
            &nbsp; <br />
            &nbsp; <br />
          </p>,
        ]}
      >
        <div>
          <div className="w3-col" style={{ height: "100px" }}></div>

          {/* First Method */}
          <PayPalScriptProvider options={initialPayPalOptions}>
            <PayPalButtons createOrder={createOrder} onApprove={onApprove} />
          </PayPalScriptProvider>

          {/* Second Method */}
          {/* <PayPalScriptProvider
              options={{
                clientId: "test",
                dataClientToken:
                  "test",
              }}
            >
              <BraintreePayPalButtons
                createOrder={(data, actions) => {
                  return actions.braintree.createPayment({
                    flow: "checkout",
                    amount: "10.0",
                    currency: "USD",
                    intent: "capture",
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.braintree
                    .tokenizePayment(data)
                    .then((payload) => {
                      // call server-side endpoint to finish the sale
                    });
                }}
              />
            </PayPalScriptProvider> */}
        </div>
      </Modal>
    </div>
  );
};

export default AdvertiserContentFormsPayment;
