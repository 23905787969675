import { IAdminAuthType } from "../../../../apiservice/admin-AuthService.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import "./Admin-Details-Me.css";

export const AdminDetailsMe = () => {
  const authData: IAdminAuthType = useAppSelector(
    (state: RootState) => state?.AdminAuthData
  );
  return (
    <>
      <div className="w3-col w3-container" style={{ minHeight: "200px" }}>
        <div className="w3-border-bottom w3-padding">
          <h3 className="adminDetailsHeaderText"> My Infomation </h3>
        </div>
        <div className="w3-col l12 s12 m12">
          {/* FullName */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>FullName</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{authData?.data?.credentials.fullName}</span>
              </p>
            </div>
          </div>

          {/* User Name */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>User Name</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>{authData?.data?.credentials.username}</span>
              </p>
            </div>
          </div>

          {/*Role */}
          <div className="w3-col l12">
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>Role</p>
            </div>
            <div className="w3-col l6 s6 m6 w3-padding">
              <p>
                <span>
                  {authData?.data?.credentials.role.title || "-------"}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDetailsMe;
