import "./AdminManageAdminsDetails.css";
import AdminDetailsWrapper from "../../../components/Admin-Component-Groups/Admin-Manage-Admin-Users-Comps/Admin-Details-Wrapper/Admin-Details-Wrapper";

export const AdminManageAdminsDetails = () => {
  return (
    <div>
      <AdminDetailsWrapper></AdminDetailsWrapper>
    </div>
  );
};

export default AdminManageAdminsDetails;
