import { IAdminAuthType } from "../../../../apiservice/admin-AuthService.type";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import "./Admin-Dashboard-profile.css";

export const AdminDashboardPieChart = () => {
  const authData: IAdminAuthType = useAppSelector(
    (state: RootState) => state?.AdminAuthData
  );
  const graphData = {
    type: "doughnut",
    data: {
      labels: [
        // "Total Accepted Tasks",
        // "Total Pending Tasks",
        // "Total Rejected Tasks",
        // "Total Completed Tasks",
      ],
      datasets: [
        {
          label: "",
          data: [
            authData.data?.credentials?.dashboardDetails?.taskDetails
              ?.noOfAccepted || 0,
            authData.data?.credentials?.dashboardDetails?.taskDetails
              ?.noOfPending || 0,
            authData.data?.credentials?.dashboardDetails?.taskDetails
              ?.noOfRejected,
            authData.data?.credentials?.dashboardDetails?.taskDetails
              ?.noOfCompleted,
          ],
          backgroundColor: ["blue", "green", "orange", "purple"],
          borderWidth: 2,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "",
        },
      },
    },
  };
  const graphHtmlString = `<div>
  <canvas id="myChart"></canvas>
</div>

<script src="https://cdn.jsdelivr.net/npm/chart.js"></script>

<script>
  const ctx = document.getElementById("myChart");

  new Chart(ctx, ${JSON.stringify(graphData)});
</script>
`;
  return (
    <>
      <div className="w3-col w3-card w3-white w3-padding w3-round">
        <div className="w3-col">
          <h3>Task Analysis </h3>
        </div>
        <div className="w3-col">
          <iframe
            style={{ width: "100%", height: "300px", border: "0px" }}
            srcDoc={graphHtmlString}
            title="Graph"
          ></iframe>
        </div>
        <div className="w3-col">
          <div className="w3-col w3-margin-bottom">
            <div
              className="w3-col w3-round"
              style={{ width: "20px", height: "20px", background: "blue" }}
            ></div>
            <div className="w3-rest">
              &nbsp; Total Accepted Tasks :
              {authData.data?.credentials?.dashboardDetails?.taskDetails
                ?.noOfAccepted || 0}
            </div>
          </div>
          <div className="w3-col w3-margin-bottom">
            <div
              className="w3-col w3-round"
              style={{ width: "20px", height: "20px", background: "green" }}
            ></div>
            <div className="w3-rest">
              {" "}
              &nbsp; Total Pending Tasks :{" "}
              {authData.data?.credentials?.dashboardDetails?.taskDetails
                ?.noOfPending || 0}{" "}
            </div>
          </div>
          <div className="w3-col w3-margin-bottom">
            <div
              className="w3-col w3-round"
              style={{ width: "20px", height: "20px", background: "orange" }}
            ></div>
            <div className="w3-rest">
              {" "}
              &nbsp; Total Rejected Tasks :{" "}
              {authData.data?.credentials?.dashboardDetails?.taskDetails
                ?.noOfRejected || 0}{" "}
            </div>
          </div>
          <div className="w3-col w3-margin-bottom">
            <div
              className="w3-col w3-round"
              style={{ width: "20px", height: "20px", background: "purple" }}
            ></div>
            <div className="w3-rest">
              {" "}
              &nbsp; Total Completed Tasks :{" "}
              {authData.data?.credentials?.dashboardDetails?.taskDetails
                ?.noOfCompleted || 0}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboardPieChart;
