import AdminContentRequestDetailsWrapperBanner from "../../../components/Admin-Component-Groups/Admin-Manage-ContentRequest-Comp/Admin-ContentRequest-Details-Wrapper/Admin-ContentRequest-Details-Wrapper-Banner";
import "./AdminManageRequestgBannerDetails.css";

export const AdminManageRequestgBannerDetails = () => {
  return (
    <div>
      <AdminContentRequestDetailsWrapperBanner></AdminContentRequestDetailsWrapperBanner>
    </div>
  );
};

export default AdminManageRequestgBannerDetails;
