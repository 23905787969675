import "./Admin-Dashboard-profile.css";
import { useState } from "react";
import { useAppSelector } from "../../../../Redux/reduxCustomHook";
import { RootState } from "../../../../Redux/store";
import { IAdminAuthType } from "../../../../apiservice/admin-AuthService.type";
import { UserCardList } from "../../Admin-Manage-Site-Users-Comps/user-card-list/user-card-list";
import { SearchOutlined } from "@ant-design/icons";
import AdminPortalList from "../../Admin-Manage-Portals-Comps/AdminPortalList/AdminPortalList";
import AdminViewOfferList from "../../Admin-Manage-Offers/AdminViewOffer/AdminViewOffer-List/AdminViewOffer-List";
import AdminTaskList from "../../Admin-Manage-Tasks/Admin-Task-List/Admin-Task-List";
import AdminWalletWithdrawalList from "../../Admin-Manage-Wallets-Comp/AdminWalletWithdrawalList/AdminWalletWithdrawalList";
import AdminDashboardPieChart from "./Admin-Dashboard-Pie-Chart";
import AdminDashboardGraphWrappper from "./Admin-Dashboard-Graph-Wrapper";

export const AdminDashboardprofile = () => {
  type IAdminStatus = {
    id: number;
    icon: string;
    stat: number;
    description: string;
  };

  const authData: IAdminAuthType = useAppSelector(
    (state: RootState) => state?.AdminAuthData
  );

  const walletPermission = [1, 3];

  const [showAmount, setShowAmount] = useState(false);

  const toggleAmountDisplay = () => {
    setShowAmount(!showAmount);
  };

  const adminStatusText1: IAdminStatus[] = [
    {
      id: 1,
      icon: "/images/admin-dashboard/icon1.svg",
      stat: authData.data?.credentials.dashboardDetails.noOfPublishers || 0,
      description: "Publishers",
    },
    {
      id: 2,
      icon: "/images/admin-dashboard/icon2.svg",
      stat: authData.data?.credentials.dashboardDetails.totalTasks || 0,
      description: "Tasks",
    },
  ];

  const adminStatusText2: IAdminStatus[] = [
    {
      id: 1,
      icon: "/images/admin-dashboard/icon3.svg",
      stat: authData.data?.credentials.dashboardDetails.noOfAdvertisers || 0,
      description: "Advertisers",
    },
    {
      id: 2,
      icon: "/images/admin-dashboard/icon4.svg",
      stat: authData.data?.credentials.dashboardDetails.totalOffers || 0,
      description: "Offers",
    },
  ];

  const adminStatusText3: IAdminStatus[] = [
    {
      id: 1,
      icon: "/images/admin-dashboard/icon5.svg",
      stat: authData.data?.credentials.dashboardDetails.totalPortals || 0,
      description: "Portals",
    },
  ];

  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        {/* SEARCH BAR */}
        <div className="w3-col">
          <div className="w3-content">
            <div className="w3-container">
              <div className="w3-col l12 s12 m12">
                <div className="admin-dashboard-serachbar">
                  <form action="/action_page.php">
                    <span className="search-icon">
                      <SearchOutlined />
                    </span>
                    <input
                      className="admin-dashboard-searchbar input-admin-dashboard"
                      type="text"
                      placeholder="Search"
                      name="search"
                    ></input>
                  </form>
                  <h2 className="admin-dahsboard-header">Dashboard</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SEARCH BAR */}

        {/* Second Section */}
        <div className="w3-col">
          <div className="w3-content">
            <div className="w3-container">
              {/* ADMIN STATS WRAPPER */}
              <div className="w3-col l8 s12">
                <div className="admin-stats-wrapper">
                  <div className="w3-col">
                    <div className="w3-content">
                      <div className="w3-col l4 s4 w3-padding">
                        <div className="admin-stat-container">
                          {adminStatusText1.map((status) => (
                            <div className="per-admin-stat" key={status.id}>
                              <div className="w3-col">
                                <div className="w3-content">
                                  <div
                                    className="w3-col s2"
                                    style={{ marginRight: "5px" }}
                                  >
                                    <span className="admin-status-icon">
                                      <img src={status.icon} alt="" />
                                    </span>
                                  </div>
                                  <div className="w3-col s9">
                                    <p className="admin-status-stat">
                                      {status.stat}
                                    </p>
                                    <p className="admin-status-description">
                                      {status.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="w3-col l4 s4 w3-padding">
                        <div className="admin-stat-container">
                          {adminStatusText2.map((status) => (
                            <div className="per-admin-stat" key={status.id}>
                              <div className="w3-col">
                                <div className="w3-content">
                                  <div
                                    className="w3-col s2"
                                    style={{ marginRight: "5px" }}
                                  >
                                    <span className="admin-status-icon">
                                      <img src={status.icon} alt="" />
                                    </span>
                                  </div>
                                  <div className="w3-col s9">
                                    <p className="admin-status-stat">
                                      {status.stat}
                                    </p>
                                    <p className="admin-status-description">
                                      {status.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="w3-col l4 s4 w3-padding">
                        <div className="admin-stat-container">
                          {adminStatusText3.map((status) => (
                            <div className="per-admin-stat" key={status.id}>
                              <div className="w3-col">
                                <div className="w3-content">
                                  <div
                                    className="w3-col s2"
                                    style={{ marginRight: "5px" }}
                                  >
                                    <span className="admin-status-icon">
                                      <img src={status.icon} alt="" />
                                    </span>
                                  </div>
                                  <div className="w3-col s9">
                                    <p className="admin-status-stat">
                                      {status.stat}
                                    </p>
                                    <p className="admin-status-description">
                                      {status.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ADMIN NEW MEMBERS STAT */}
              <div className="w3-col l4 s12 w3-padding">
                <UserCardList
                  initialDefaultFilter={{ perPage: 3 }}
                ></UserCardList>
              </div>
            </div>
          </div>
        </div>
        {/*Second Section */}

        {/* Chart Session */}
        {authData.data?.credentials.roleId && (
          <div className="w3-col w3-margin-top w3-margin-bottom">
            <div className="w3-content">
              <div className="w3-container">
                <div className="w3-col">
                  <div className="w3-col l8 s12 m12" style={{ padding: "5px" }}>
                    <AdminDashboardGraphWrappper></AdminDashboardGraphWrappper>
                  </div>
                  <div className="w3-col l4 s12 m12" style={{ padding: "4px" }}>
                    <AdminDashboardPieChart></AdminDashboardPieChart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Third Section */}

        {/* Third Section */}
        {authData.data?.credentials.roleId && (
          <div className="w3-col w3-margin-top w3-margin-bottom">
            <div className="w3-content">
              <div className="w3-container">
                <div>
                  <h2 className="section-header-admin-dashboard">
                    Newly Created Portal
                  </h2>
                  <AdminPortalList
                    initialDefaultFilter={{ perPage: 5 }}
                    hidePagination={true}
                  ></AdminPortalList>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Third Section */}

        {/* Fourth Section */}
        {authData.data?.credentials.roleId && (
          <div className="w3-col w3-margin-top w3-margin-bottom">
            <div className="w3-content">
              <div className="w3-container">
                <div>
                  <h2 className="section-header-admin-dashboard">
                    Newly Created Offer
                  </h2>
                  <AdminViewOfferList
                    initialDefaultFilter={{ perPage: 5 }}
                    hidePagination={true}
                  ></AdminViewOfferList>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Fourth Section */}

        {/* Fifth Section */}
        {authData.data?.credentials.roleId && (
          <div className="w3-col w3-margin-top w3-margin-bottom">
            <div className="w3-content">
              <div className="w3-container">
                <div>
                  <h2 className="section-header-admin-dashboard">
                    Ongoing Task
                  </h2>
                  <AdminTaskList
                    initialDefaultFilter={{ perPage: 5 }}
                    hidePagination={true}
                  ></AdminTaskList>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Fifth Section */}

        {/*Sixth Section */}
        {walletPermission.some(
          (val) => val === authData.data?.credentials.roleId || 0
        ) && (
          <div className="w3-col w3-margin-top w3-margin-bottom">
            <div className="w3-content">
              <div className="w3-container">
                <div>
                  <h2 className="section-header-admin-dashboard">
                    Recent Withdrawal
                  </h2>
                  <AdminWalletWithdrawalList
                    initialDefaultFilter={{ perPage: 5 }}
                    hidePagination={true}
                  ></AdminWalletWithdrawalList>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Sixth Section */}
      </div>
    </>
  );
};

export default AdminDashboardprofile;
