import { ExclamationCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { Input, Modal, notification } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  acceptTask,
  completeTask,
  rejectTask,
} from "../../../apiservice/tasks.service";
import { IPublisherTaskData } from "../../../apiservice/tasks.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { appZIndex } from "../../../utils/appconst";
import "./PublisherTask-Details-Wrapper.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IPublisherTaskDetailsCard = {
  publisherTaskData: IPublisherTaskData | null;
  refreshPage?: () => void;
};

export const PublisherTaskDetailsCard: React.FC<IPublisherTaskDetailsCard> = ({
  publisherTaskData,
  refreshPage,
}) => {
  const [loadAcceptTask, setLoadAcceptTask] = useState(false);
  const [loadRejectTask, setLoadRejectTask] = useState(false);
  const [loadCompleteTask, setLoadCompleteTask] = useState(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [completeTaskpayload, setCompleteTaskpayload] = useState<any>({});
  const [showSubmitTask, setShowSubmitTask] = useState<boolean>(false);
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  //Accept Task
  const showAcceptTaskConfirm = () => {
    confirm({
      title: "Are you sure you want to accept this task",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadAcceptTask(true);
        setFormLoading(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Accept Task
  const acceptTaskApiResult = useFormatApiRequest(
    () => acceptTask(publisherTaskData?.id || 0),
    loadAcceptTask,
    () => {
      setLoadAcceptTask(false);
    },
    () => {
      processAcceptTask();
    }
  );

  // Process The Accept Task
  const processAcceptTask = async () => {
    if (acceptTaskApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "This Task Has Being Accepted");
      if (refreshPage) {
        refreshPage();
      }
      setFormLoading(false);
    } else if (acceptTaskApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Accepting this task");
      setFormLoading(false);
    }
  };

  //Reject Task
  const showRejectTaskConfirm = () => {
    confirm({
      title: "Are you sure you want to reject this task",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadRejectTask(true);
        setFormLoading(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Reject Task
  const rejectTaskApiResult = useFormatApiRequest(
    () => rejectTask(publisherTaskData?.id || 0),
    loadRejectTask,
    () => {
      setLoadRejectTask(false);
    },
    () => {
      processRejectTask();
    }
  );

  // Process The Reject Task
  const processRejectTask = async () => {
    if (rejectTaskApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "This Task Has Being Rejected");
      if (refreshPage) {
        refreshPage();
      }
      setFormLoading(false);
    } else if (rejectTaskApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Rejecting this task");
      setFormLoading(false);
    }
  };

  // Use to collect Site Description Change
  const handleInputChangeCompleteTask = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCompleteTaskpayload((values) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmitCompleteTask = (event: any) => {
    event.preventDefault();
    showCompleteTaskConfirm();
  };

  //Complete Task
  const showCompleteTaskConfirm = () => {
    confirm({
      title: "Are you sure you have completed this task",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      zIndex: appZIndex.modal,
      onOk() {
        console.log("OK");
        setLoadCompleteTask(true);
        setFormLoading(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // A custom hook to Complete Task
  const completeTaskApiResult = useFormatApiRequest(
    () => completeTask(publisherTaskData?.id || 0, completeTaskpayload),
    loadCompleteTask,
    () => {
      setLoadCompleteTask(false);
    },
    () => {
      processCompleteTask();
    }
  );

  // Process The Complete Task
  const processCompleteTask = async () => {
    if (completeTaskApiResult.httpState === "SUCCESS") {
      openNotificationWithIcon("success", "", "This Task Has marked completed");
      if (refreshPage) {
        refreshPage();
      }
      setFormLoading(false);
    } else if (completeTaskApiResult.httpState === "ERROR") {
      openNotificationWithIcon("error", "", "Error Accepting this task");
      setFormLoading(false);
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };
  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div className="w3-col w3-padding w3-padding-bottom">
        {/* Task List  */}
        <div className="w3-col" style={{ padding: "10px" }}>
          <div className="w3-col w3-margin-bottom">
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <span className="taskDetailsTitle"> List Task </span>
            </div>
            <div
              className="w3-col l6 s6 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              <Link to="/publisher/task" className="w3-btn taskBackBtn">
                Back
              </Link>
            </div>
          </div>
        </div>

        {/* Task Title Header */}
        <div className="w3-col" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "10px", height: "60px" }}
          >
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <span className="taskDetailsTitle">
                Task Name - {publisherTaskData?.offer.adType}
              </span>
            </div>
            <div
              className="w3-col l6 s6 m6 w3-right-align"
              style={{ padding: "5px" }}
            >
              {publisherTaskData?.statusId === 0 && (
                <>
                  <button
                    disabled={formLoading}
                    onClick={showAcceptTaskConfirm}
                    className="w3-btn taskBtnPrimaryColor w3-round"
                  >
                    Accept
                  </button>
                  &nbsp;
                  <button
                    disabled={formLoading}
                    onClick={showRejectTaskConfirm}
                    className="w3-btn w3-border w3-round w3-border-red w3-text-red"
                  >
                    Reject
                  </button>
                </>
              )}
              &nbsp;
              {publisherTaskData?.statusId === 1 && (
                <button
                  onClick={() => {
                    setShowSubmitTask(true);
                  }}
                  className="w3-btn w3-white w3-border w3-round w3-border-blue w3-text-blue"
                >
                  Complete
                </button>
              )}
              {publisherTaskData?.statusId === 2 && (
                <span className="w3-text-red"> Task Rejected</span>
              )}
              {publisherTaskData?.statusId === 3 && (
                <span className="w3-text-blue"> Waiting Confirmation</span>
              )}
              {publisherTaskData?.statusId === 4 && (
                <span className="w3-text-green"> Confirmed</span>
              )}
            </div>
          </div>
        </div>

        {/* Task Completion Link */}
        {((publisherTaskData && publisherTaskData?.statusId > 2) ||
          showSubmitTask) && (
          <div className="w3-col" style={{ padding: "10px" }}>
            <div
              className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
              style={{ paddingTop: "10px", height: "75px" }}
            >
              <form onSubmit={handleSubmitCompleteTask}>
                <div className="w3-col l9 s9 m9" style={{ padding: "5px" }}>
                  <Input
                    required
                    name="previewUrl"
                    onChange={handleInputChangeCompleteTask}
                    value={completeTaskpayload?.previewUrl}
                    className="w3-col inputField"
                    placeholder="Enter Link of Task"
                  />
                </div>
                <div
                  className="w3-col l3 s3 m3 w3-right-align"
                  style={{ padding: "5px" }}
                >
                  <button
                    disabled={formLoading}
                    className="w3-btn w3-border w3-round w3-blue w3-text-white"
                  >
                    {!formLoading ? (
                      "Submit Task"
                    ) : (
                      <LoadingOutlined rev={undefined} />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Task Details */}
        <div className="w3-col l12 s12 m12" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "10px", minHeight: "200px" }}
          >
            <div className="w3-col w3-margin-bottom">
              <p>Task Info </p>
            </div>

            {/* AdType = 1 is Guest Post */}
            {publisherTaskData?.offer.adTypeId === 1 && (
              <div>
                {/* Topic of Artcle */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Topic of Article</span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span> {publisherTaskData.offer.guestPost?.topic} </span>
                  </div>
                </div>

                {/* Anchor Text */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Anchor Text</span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span>{publisherTaskData.offer.guestPost?.anchorText}</span>
                  </div>
                </div>

                {/* Main Link */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Main Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <Input
                      name="link"
                      value={publisherTaskData.offer.guestPost?.mainLink}
                      className="w3-col inputField"
                      placeholder="Copy Link"
                    />
                  </div>
                </div>

                {/* Anchor Link  */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Anchor Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <span>{publisherTaskData.offer.guestPost?.anchorLink}</span>
                  </div>
                </div>

                {/* Key word */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Key words</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <span>{publisherTaskData.offer.guestPost?.keywords}</span>
                  </div>
                </div>

                {/* Other Requirement */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Other Requirement </span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <span>
                      {publisherTaskData.offer.guestPost?.otherRequirements}
                    </span>
                  </div>
                </div>

                {/* Article */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l12 s12 m12">
                    <span>
                      <b>Article</b>
                    </span>
                  </div>
                  <div className="w3-col l12 s12 m12">
                    <div
                      className="w3-col w3-border w3-round w3-margin-top"
                      style={{ minHeight: "200px" }}
                      dangerouslySetInnerHTML={{
                        __html: publisherTaskData.offer.guestPost?.articleText
                          ? (
                              publisherTaskData.offer.guestPost
                                ?.articleText as string
                            ).replace(/(<? *script)/gi, "illegalscript")
                          : "",
                      }}
                    ></div>
                  </div>
                </div>

                {/* Download Button */}
                <div className="w3-col w3-margin-bottom">
                  <a
                    download={publisherTaskData.offer.guestPost?.articleUrl}
                    target="_blank"
                    rel="noreferrer"
                    href={publisherTaskData.offer.guestPost?.articleUrl}
                    className="w3-btn taskBtnPrimaryColor w3-round"
                  >
                    Download Attachment
                  </a>
                </div>
              </div>
            )}

            {/* AdType = 2 is Link Insertion */}
            {publisherTaskData?.offer.adTypeId === 2 && (
              <div>
                {/* Anchor Text */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Anchor Text</span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span>
                      {publisherTaskData.offer.linkInsertion?.anchorText}
                    </span>
                  </div>
                </div>

                {/* Description */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3 w3-margin-bottom">
                    <span> Description </span>
                  </div>
                  <div className="w3-col l9 s12 m9 w3-margin-bottom">
                    <span>
                      {publisherTaskData.offer.linkInsertion?.description}
                    </span>
                  </div>
                </div>

                {/* AnchorLink */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Anchor Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <Input
                      required
                      name="link"
                      value={publisherTaskData.offer.linkInsertion?.linkUrl}
                      readOnly={true}
                      className="w3-col inputField"
                      placeholder="Copy Link"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* AdType = 3 is Banner */}
            {publisherTaskData?.offer.adTypeId === 3 && (
              <div>
                {/* Click Link */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Click Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <Input
                      readOnly={true}
                      value={publisherTaskData.offer.banner?.clickLink}
                      name="link"
                      className="w3-col inputField"
                      placeholder="Click Link"
                    />
                  </div>
                </div>

                {/* PageLink */}
                <div className="w3-col w3-margin-bottom">
                  <div className="w3-col l3 s12 m3">
                    <span> Page Link</span>
                  </div>
                  <div className="w3-col l9 s12 m9">
                    <Input
                      required
                      name="link"
                      value={publisherTaskData.offer.banner?.pageLink}
                      readOnly={true}
                      className="w3-col inputField"
                      placeholder="Page Link"
                    />
                  </div>
                </div>

                {/* Banner Image */}
                <div className="w3-col w3-margin-bottom">
                  <img
                    alt="bannerImage"
                    style={{ maxWidth: "50%" }}
                    src={publisherTaskData.offer.banner?.bannerUrl}
                  />
                  {publisherTaskData.offer.banner?.bannerUrl}
                </div>

                {/* Download Button */}
                <div className="w3-col w3-margin-bottom">
                  <a
                    download={publisherTaskData.offer.banner?.bannerUrl}
                    target="_blank"
                    rel="noreferrer"
                    href={publisherTaskData.offer.banner?.bannerUrl}
                    className="w3-btn taskBtnPrimaryColor w3-round"
                  >
                    Download
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Task Details  */}
      </div>
    </>
  );
};

export default PublisherTaskDetailsCard;
