import { Button, Result, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getPublisherTask } from "../../../apiservice/tasks.service";
import { IPublisherTaskData } from "../../../apiservice/tasks.type";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import { ILoadState } from "../../../utils/loading.utils.";
import PublisherTaskDetailsCard from "./PublisherTask-Details-Card";
import "./PublisherTask-Details-Wrapper.css";

export const PublisherTaskDetailsWrapper = () => {
  const [taskLoadState, setTaskLoadState] = useState<ILoadState>("loading");
  const [loadCurrentTaskData, setLoadCurrentTaskData] = useState(true);
  const [publisherTaskData, setPublisherTaskData] =
    useState<IPublisherTaskData | null>(null);
  const params = useParams();

  // A custom hook to Load Current/Active Task based on the Url
  const resultCurrentTaskData = useFormatApiRequest(
    () => getPublisherTask(Number(params?.taskId)),
    loadCurrentTaskData,
    () => {
      setLoadCurrentTaskData(false);
    },
    () => {
      processCurrentPortalResult();
    }
  );

  // Process The Current Task Data Result
  const processCurrentPortalResult = async () => {
    if (resultCurrentTaskData.httpState === "SUCCESS") {
      setPublisherTaskData(resultCurrentTaskData.data?.data);
      setTaskLoadState("completed");
    } else if (resultCurrentTaskData.httpState === "ERROR") {
      setTaskLoadState("error");
    }
  };

  return (
    <>
      {/* " Show Loading Indicator" */}
      {taskLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {taskLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={<Button onClick={() => setLoadCurrentTaskData(true)} type="primary">Reload</Button>}
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {taskLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken create a new portal."
            extra={<Button type="primary">Create a new Portal</Button>}
          />
        </div>
      )}

      {/* " Display form when Task Load State is Not loading for new form entry & completed for editing old entry" */}
      {(taskLoadState === "notLoading" || taskLoadState === "completed") && (
        <div className="w3-col w3-padding-bottom">
          <PublisherTaskDetailsCard
            publisherTaskData={publisherTaskData}
            refreshPage={() => {
              setLoadCurrentTaskData(true);
            }}
          ></PublisherTaskDetailsCard>
        </div>
      )}
    </>
  );
};

export default PublisherTaskDetailsWrapper;
