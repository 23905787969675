import "./AdminManageBannerRequest.css";
import AdminContentRequestListWrapperBanner from "../../../components/Admin-Component-Groups/Admin-Manage-ContentRequest-Comp/Admin-ContentRequest-List-Wrapper/Admin-ContentRequest-List-Wrapper-Banner";

export const AdminManageBannerRequest = () => {
  return (
    <div>
      <AdminContentRequestListWrapperBanner></AdminContentRequestListWrapperBanner>
    </div>
  );
};

export default AdminManageBannerRequest;
