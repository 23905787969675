import { Steps } from "antd";
import "./PortalForm.css";

type IPortalFormCardWrapper = {
  currentTabIndex?: number | undefined;
  children?: any;
};

export const PortalFormCardWrapper: React.FC<IPortalFormCardWrapper> = ({
  currentTabIndex = 0,
  children,
}) => {
  const description = "";
  const stepItems = [
    {
      title: "Basic Info",
      description,
    },
    {
      title: "SEO Matric",
      description,
    },
    {
      title: "Info",
      description,
    },
    {
      title: "Complete",
      description,
    },
  ];
  return (
    <div className="w3-container">
      <div className="w3-col" style={{ marginTop: "50px" }}>
        <div className="w3-col l1 m1">
          <br />
        </div>
        <div className="w3-col l10 m10">
          <div className="w3-col w3-white w3-card w3-round">
            <div className="w3-border-bottom  w3-padding w3-margin-top">
              <Steps
                current={currentTabIndex}
                size="small"
                labelPlacement="vertical"
                items={stepItems}
              />
            </div>

            <div className="w3-padding" style={{ marginTop: "20px" }}>
              {children}
            </div>
          </div>
        </div>
        <div className="w3-col l1 m1">
          <br />
        </div>
      </div>
    </div>
  );
};

export default PortalFormCardWrapper;
