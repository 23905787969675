import "./About.css";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

const AboutPage = () => {
  type Ifocus = {
    index: string;
    header: string;
    content: string;
  };

  const ourFocusText: Ifocus[] = [
    {
      index: "01",
      header: "Collaborations on a Global Scale",
      content:
        "Your target customers are most likely open to more than one geographical area. Hence, our platform gives you access to place ads with publishers from around the globe, allowing you to reach your target audience.",
    },
    {
      index: "02",
      header: "Customer First Approach",
      content:
        "We prioritise user experience and satisfaction; hence, our platform simplifies content creation and user interaction for an assured success story.",
    },
    {
      index: "03",
      header: "Customised Offers",
      content:
        "At Linkmie, we focus on ensuring you get your desired results, which is why we work with the details you provide us and your site analytics to suggest the best advertiser/publisher for you. ",
    },
    {
      index: "04",
      header: "Community Building",
      content:
        "Linkmie focuses on building a thriving community for content writers, publishers, and advertisers to interact, partner, and achieve goals.",
    },
    {
      index: "05",
      header: "Quality Content",
      content:
        "Our platform connects content writers, publishers, and advertisers, prioritising high-quality content creation for user benefits and advertiser goals.",
    },
    {
      index: "06",
      header: "Openness",
      content:
        "We focus on establishing transparent communication between advertisers, publishers, and content writers to ensure that all parties' needs are satisfied and that successful relationships are established.",
    },
  ];

  return (
    <div className="w3-white w3-col" style={{ minHeight: "400px" }}>
      <div className="w3-col about">
        <div className="w3-col l3 s12 m3 w3-hide-small w3-hide-medium">
          <div
            style={{ height: "217px" }}
            className="w3-col w3-hide-small"
          ></div>
          <div className="w3-col">
            <Flip top>
              <span className="about-elipse-top">
                <img
                  alt="about-elipse"
                  src={`${
                    process.env.PUBLIC_URL + "/images/about/pinkelipse.png"
                  }`}
                />
              </span>
            </Flip>
          </div>
        </div>

        <div className="w3-col l6 s12 m12">
          <div style={{ height: "100px" }} className="w3-hide-small"></div>
          <div className="about-content">
            <div className="w3-container">
              <div>
                <span className="about-linkmie">
                  About <span>Linkmie</span>
                </span>
                <br />
                <p className="about-guestpost">
                  #1 guestpost and publisher company
                </p>
              </div>
              <Bounce left>
                <div className="about-subtext">
                  Linkmie is a platform founded by experienced digital marketers
                  who understand the digital marketing space and the importance
                  of connecting with the correct platforms for visibility and
                  reaching the target audience. Linkmie aims to transform
                  advertising partnerships by connecting website owners and
                  advertisers, creating an environment that drives visibility
                  and reach, increases income flow, and is the bedrock of
                  success stories.
                  <br />
                  <br />
                  Linkmie focuses on solving long-standing digital marketing
                  problems, driving quality website traffic, and connecting with
                  target customers. As solutions to these, we offer services
                  such as guest posting, ad placements, content writing, and
                  other content marketing services.
                  <br />
                  <br />
                  We stand out from other platforms by focusing on quality,
                  openness, and collaboration. By registering on our platform,
                  advertisers can place their products on relevant, high-quality
                  websites registered on our platform, resulting in targeted
                  exposure and conversions. Also, website owners can make money
                  off their websites by collaborating with advertisers who want
                  to promote products and services within their niche.
                </div>
              </Bounce>
            </div>
          </div>
          <div style={{ height: "47px" }} className="w3-hide-small"></div>
        </div>

        <div className="w3-col l3 s12 m3 w3-hide-small w3-hide-medium">
          <Flip top>
            <span className="w3-right-align about-elipse-top right">
              <img
                alt="about-elipse right"
                src={`${
                  process.env.PUBLIC_URL + "/images/about/brownelipse.png"
                }`}
              />
            </span>
          </Flip>
        </div>
      </div>
      <div className="w3-col our-focus">
        <div className="w3-content">
          <div className="w3-container">
            <h2 className="w3-center our-focus-header w3-padding">Our Focus</h2>
            <div className="w3-row-padding l4 s12 m6">
              {ourFocusText.map((item: Ifocus, index: number) => (
                <div
                  key={index}
                  className="w3-col l4 s12 m6 w3-padding"
                  style={{ height: "200px" }}
                >
                  <Fade left>
                    <div className="our-focus-container">
                      <div className="our-focus-left">
                        <div className="index-focus">{item.index}</div>
                        <div className="svg-focus">
                          <img src="/images/about/dash.svg" alt="" />
                        </div>
                      </div>
                      <div className="our-focus-right">
                        <div className="header-focus"> {item.header} </div>
                        <p className="content-focus"> {item.content} </p>
                      </div>
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w3-col our-mission">
        <div className="w3-content">
          <div className="w3-container">
            <div className="w3-col l3 s12 m3 w3-padding">
              <span className="mission-icons">
                <img
                  className="rectangle-left"
                  src="/images/about/rectangle.svg"
                  alt="rectangle svg"
                />
                <img
                  className="circle-left"
                  src="/images/about/circle.svg"
                  alt="circle svg"
                />
              </span>
            </div>
            <Zoom left>
              <div className="w3-col l6 s12 m6 w3-center w3-padding">
                <span className="quote-about">
                  <img
                    className="quote-right"
                    src="/images/about/quote.svg"
                    alt="quote right"
                  />
                </span>

                <p className="our-focus-content">
                  At Linkmie, our mission is to enhance the online presence of
                  advertisers and publishers by facilitating collaboration and
                  promoting digital success. The platform promotes transparency,
                  creativity, and beneficial relationships. Also, our dedication
                  to excellence aims to drive a flourishing digital ecosystem
                  where publishers and advertisers find opportunities to
                  collaborate and achieve goals.
                </p>
                {/* <h2 className="our-focus-name">Joshua Samuel, CEO</h2> */}
              </div>
            </Zoom>
            <div className="w3-col l3 s12 m3 w3-padding">
              <span className="mission-icons">
                <img
                  className="circle-right"
                  src="/images/about/circle.svg"
                  alt="circle svg"
                />
                <img
                  className="rectangle-right"
                  src="/images/about/rectangle.svg"
                  alt="rectangle svg"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
