import { Button, Modal, notification, Result, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAdvertiserOfferPortals } from "../../../../apiservice/offers.service";
import { IOfferPortalsData } from "../../../../apiservice/offers.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import "./AddOffer-SelectPortal-List.css";

type IAddOfferSelectPortalList = {
  externalFilter?: any;
};

export const AddOfferSelectPortalList: React.FC<IAddOfferSelectPortalList> = ({
  externalFilter,
}) => {
  const [portalLoadState, setPortalLoadState] = useState<ILoadState>("loading");
  const [loadPortalsData, setLoadPortalsData] = useState(true);
  const [portalDefaultFilter, setportalDefaultFilter] = useState({});
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const { confirm } = Modal;

  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setportalDefaultFilter({ ...portalDefaultFilter, ...externalFilter });
      setLoadPortalsData(true);
      setPortalLoadState("loading");
    }
  }, [externalFilter]);

  // A custom hook to Load All Portals
  const PortalDataResult = useFormatApiRequest(
    () => getAdvertiserOfferPortals(portalDefaultFilter),
    loadPortalsData,
    () => {
      setLoadPortalsData(false);
    },
    () => {
      processPortalsResult();
    }
  );

  // Process The Current Portal Data Result
  const processPortalsResult = async () => {
    if (PortalDataResult.httpState === "SUCCESS") {
      setTableData(PortalDataResult.data?.data);
      setPortalLoadState("completed");
    } else if (PortalDataResult.httpState === "ERROR") {
      setPortalLoadState("error");
    } else if (PortalDataResult.httpState === "LOADING") {
      setPortalLoadState("loading");
    }
  };

  // Table Configuration
  const columns: ColumnsType<IOfferPortalsData> = [
    {
      title: "S/N",
      width: 60,
      dataIndex: "sn",
      key: "sn",
      render: (text, records, index) => <span>{index + 1}</span>,
    },
    {
      title: "Portal Name & Type/Region",
      dataIndex: "websiteName",
      key: "websiteName",
      render: (text, records, index) => (
        <span>{`${text} (${records.leadingCountry})`}</span>
      ),
    },
    {
      title: "Category",
      dataIndex: "categories",
      key: "categories",
      render: (text, records, index) =>
        records.subCategories.map((item: any, index: number) => (
          <Tag className="tagText" color={"green"}>
            {item?.title}
          </Tag>
        )),
    },
    {
      title: "Site Niche",
      dataIndex: "siteNiche",
      key: "siteNiche",
      render: (siteNiche) => <span>{siteNiche?.title}</span>,
    },
    {
      title: "Link Type",
      dataIndex: "linkType",
      key: "linkType",
      render: (text) => <span>{text}</span>,
    },
    // {
    //   title: "Offer Price",
    //   dataIndex: "id",
    //   key: "price",
    //   render: (price) => <span>{"$10.00"}</span>,
    // },
    {
      title: "",
      dataIndex: "id",
      key: "select",
      fixed: "right",
      width: 120,
      render: (text, records, index) => (
        <div className="w3-center">
          <Link
            className="w3-btn selectBtn"
            to={`/advertiser/offers-add-form/${records.id}`}
          >
            Select
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}

      {/* " Show Loading Error" */}
      {portalLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button onClick={() => setLoadPortalsData(true)} type="primary">
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show Loading Table" */}
      {portalLoadState !== "error" && (
        <Table
          loading={portalLoadState === "loading"}
          rowKey="id"
          size="small"
          columns={columns}
          scroll={{ x: 950 }}
          dataSource={tableData}
          pagination={{ position: ["bottomLeft"] }}
        />
      )}
    </>
  );
};

export default AddOfferSelectPortalList;
