import { EditOutlined } from "@ant-design/icons";
import { IPortalFormType } from "../../../apiservice/portals.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import "./PortalDetailsWrapper.css";

type IPortalDetailsCard = {
  onEditClick: (index) => void;
};

export const PortalDetailsCard: React.FC<IPortalDetailsCard> = ({
  onEditClick,
}) => {
  const portalFormData: IPortalFormType = useAppSelector(
    (state: RootState) => state?.PortalForm
  );

  return (
    <>
      <div className="w3-col w3-padding w3-padding-bottom">
        <div className="w3-col" style={{ padding: "10px" }}>
          <div
            className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge w3-margin-bottom"
            style={{ paddingTop: "15px", height: "60px" }}
          >
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
              <span className="portalDetailsTitle">
                Portal Name -{portalFormData.stepOne?.websiteName}
              </span>
            </div>
            <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}></div>
          </div>
        </div>

        <div className="w3-row">
          {/* Basic Info Card */}
          <div className="w3-col l6 s12 m6" style={{ padding: "10px" }}>
            <div
              className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge"
              style={{ paddingTop: "15px", minHeight: "300px" }}
            >
              <h5 className="portalDetailsCardTitle"> Basic Information </h5>

              {/* Website Name */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Website name </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.websiteName}
                  </span>
                </div>
              </div>
              {/* Website Name */}

              {/* Domain Name */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Domain name </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.domainName}
                  </span>
                </div>
              </div>
              {/* Dommain Name */}

              {/* Website Lanaguage */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> WebSite Language </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.websiteLanguage}
                  </span>
                </div>
              </div>
              {/*  Website Lanaguage */}

              {/* Website Niche */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Site Niche </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.siteNiche?.title}
                  </span>
                </div>
              </div>
              {/*  Website Niche */}

              {/* Website Role */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Website Role</span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.websiteRole}
                  </span>
                </div>
              </div>
              {/*  Website Role  */}

              {/*  Edit Pen */}
              <div
                className="w3-col w3-margin-top w3-right-align"
                style={{ padding: "5px" }}
              >
                <span
                  onClick={() => {
                    onEditClick(0);
                  }}
                  className="editPen"
                >
                  <EditOutlined rev={undefined} />
                </span>
              </div>
              {/*  Edit Pen */}
            </div>
          </div>
          {/* Basic Info Card */}

          {/* SEO METRIC */}
          <div className="w3-col l6 s12 m6" style={{ padding: "10px" }}>
            <div
              className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge"
              style={{ paddingTop: "15px", minHeight: "300px" }}
            >
              <h5 className="portalDetailsCardTitle"> SEO Metrics </h5>
              {/* Site Description */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Site Description </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.siteDescription}
                  </span>
                </div>
              </div>
              {/* Site Description  */}

              {/* Domain Authority */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Domain Authority </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.domainAuthority}
                  </span>
                </div>
              </div>
              {/* Dommain Authority */}

              {/* Spam Score */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Spam Score </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.spamScore}
                  </span>
                </div>
              </div>
              {/*  Spam Score */}

              {/* Domain Rating  */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Domain Rating </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.domainRating}
                  </span>
                </div>
              </div>
              {/*  Domain Rating  */}

              {/* Organic Traffic */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Organic Traffic </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.organicTraffic}
                  </span>
                </div>
              </div>
              {/*  Organic Traffic  */}

              {/*  Edit Pen */}
              <div
                className="w3-col w3-margin-top w3-right-align"
                style={{ padding: "5px" }}
              >
                <span
                  onClick={() => {
                    onEditClick(1);
                  }}
                  className="editPen"
                >
                  <EditOutlined rev={undefined} />
                </span>
              </div>
              {/*  Edit Pen */}
            </div>
          </div>
          {/* SEO METRIC */}
        </div>

        <div className="w3-row">
          {/* Site Info */}
          <div className="w3-col l6 s12 m6" style={{ padding: "10px" }}>
            <div
              className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge"
              style={{ paddingTop: "15px", minHeight: "300px" }}
            >
              <h5 className="portalDetailsCardTitle"> Site Info </h5>

              {/* Category */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Category </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue"></span>
                </div>
              </div>
              {/* Category  */}

              {/* Leading Country */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Leading Country </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.leadingCountry}
                  </span>
                </div>
              </div>
              {/* Leading Country */}

              {/*  Marked Sponsor */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Marked Sponsored </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.markedSponsored}
                  </span>
                </div>
              </div>
              {/*  Marked Sponsor */}

              {/* Link Type */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Link Type</span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.linkTypeId}
                  </span>
                </div>
              </div>
              {/*  Link Type  */}

              {/* Turn Around Time  */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Turn Around Time </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.turnAroundTime}
                  </span>
                </div>
              </div>
              {/*  Turn Around Time   */}

              {/* Minimum Word Count  */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Minimum Word Count </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.minimumWordCount}
                  </span>
                </div>
              </div>
              {/*  Minimum Link Count    */}

              {/* Maximum Word Count  */}
              <div className="w3-col w3-margin-top">
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramTitle"> Maximum Link Count </span>
                </div>
                <div className="w3-col l6 s6 m6" style={{ padding: "5px" }}>
                  <span className="paramValue">
                    {portalFormData.apiFields?.maximumLinkCount}
                  </span>
                </div>
              </div>
              {/*  Maximum Link Count    */}

              {/*  Edit Pen */}
              <div
                className="w3-col w3-margin-top w3-right-align"
                style={{ padding: "5px" }}
              >
                <span
                  onClick={() => {
                    onEditClick(2);
                  }}
                  className="editPen"
                >
                  <EditOutlined rev={undefined} />
                </span>
              </div>
              {/*  Edit Pen */}
            </div>
          </div>
          {/* Site Info */}

          {/* Pricing  */}
          <div className="w3-col l6 s12 m6" style={{ padding: "10px" }}>
            <div
              className="w3-col w3-card-2 w3-container  w3-white w3-round-xlarge"
              style={{ paddingTop: "15px", minHeight: "420px" }}
            >
              <h5 className="portalDetailsCardTitle"> Prices</h5>

              <div>
                {portalFormData.apiFields?.portalPricings.map(
                  (item, index: number) => (
                    <div key={index}>
                      <h4> {item?.title}</h4>
                      <span className="paramTitle">
                        Guest Post Price {item.price?.guestPost}
                      </span>
                      <br />
                      <span className="paramTitle">
                        Banner Price {item.price?.banner}
                      </span>
                      <br />
                      <span className="paramTitle">
                        Link Insertion Price {item.price?.linkInsertion}
                      </span>
                      <br />
                      <hr />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          {/* Pricing  */}
        </div>
      </div>
    </>
  );
};

export default PortalDetailsCard;
