import {
  CloseCircleOutlined,
  DashboardOutlined,
  FileImageOutlined,
  MoneyCollectOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  TeamOutlined,
  UngroupOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Badge, Space } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IAdminAuthType } from "../../../apiservice/admin-AuthService.type";
import { IAuthType } from "../../../apiservice/authService.type";
import { useAppSelector } from "../../../Redux/reduxCustomHook";
import { RootState } from "../../../Redux/store";
import "./SideBar-Admin.css";

type ISideBarType = {
  onLinkChange?: () => void;
  onUserType?: string;
};

const SideBarAdmin: React.FC<ISideBarType> = ({ onLinkChange }) => {
  const location = useLocation();
  const [currentUrlPath, setCurrentUrlPath] = useState("");
  const [menu, setMenu] = useState<IMenuType[]>([]);

  const adminAuthData: IAdminAuthType = useAppSelector(
    (state: RootState) => state?.AdminAuthData
  );

  type IMenuType = {
    icon: string | any;
    url: string;
    title: string;
    notificationCount?: number;
    onClick?: () => void;
  };

  const superAdminMenu: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/adminpath",
      title: "Dashboard",
    },
    {
      icon: <PlusCircleOutlined />,
      url: "/adminpath/manage-admin-create-admin",
      title: "Create Admin",
    },
    {
      icon: <TeamOutlined />,
      url: "/adminpath/manage-admin",
      title: "Manage Admins",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.adminDetails.total ||
        0,
    },
    {
      icon: <UsergroupAddOutlined />,
      url: "/adminpath/manage-users",
      title: "Manage User",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.userDetails.total || 0,
    },
    {
      icon: <UserSwitchOutlined />,
      url: "/adminpath/manage-advertiser",
      title: "Manage Advertiser",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.userDetails
          .noOfAdvertisers || 0,
    },
    {
      icon: <UserSwitchOutlined />,
      url: "/adminpath/manage-publisher",
      title: "Manage Publisher",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.userDetails
          .noOfPublishers || 0,
    },
    {
      icon: <UnorderedListOutlined />,
      url: "/adminpath/manage-publisher-portal",
      title: "Manage Portals",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.portalDetails
          .noOfPending || 0,
    },
    {
      icon: <MoneyCollectOutlined />,
      url: "/adminpath/manage-withdrawal",
      title: "Manage Withdrawal",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.withdrwalDetails
          .noOfPending || 0,
    },
    {
      icon: <UngroupOutlined />,
      url: "/adminpath/manage-guestpost-request",
      title: "Manage Guest Post",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.guestPostRequestDetails
          .noOfPending || 0,
    },
    {
      icon: <FileImageOutlined />,
      url: "/adminpath/manage-banner-request",
      title: "Manage Banner Request",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.bannerRequestDetails
          .noOfPending || 0,
    },
    { icon: <SettingOutlined />, url: "/adminpath/settings", title: "Setting" },
    { icon: <PhoneOutlined />, url: "/adminpath/support", title: "Support" },
    {
      icon: <CloseCircleOutlined />,
      url: "/adminpath/logout",
      title: "Logout",
    },
  ];

  const generalAdminMenu: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/admin",
      title: "Dashboard",
    },
    {
      icon: <UsergroupAddOutlined />,
      url: "/adminpath/manage-users",
      title: "Manage User",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.userDetails.total || 0,
    },
    {
      icon: <UserSwitchOutlined />,
      url: "/adminpath/manage-advertiser",
      title: "Manage Advertiser",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.userDetails
          .noOfAdvertisers || 0,
    },
    {
      icon: <UserSwitchOutlined />,
      url: "/adminpath/manage-publisher",
      title: "Manage Publisher",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.userDetails
          .noOfPublishers || 0,
    },
    {
      icon: <UnorderedListOutlined />,
      url: "/adminpath/manage-publisher-portal",
      title: "Manage Portals",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.portalDetails
          .noOfPending || 0,
    },
    {
      icon: <UngroupOutlined />,
      url: "/adminpath/manage-guestpost-request",
      title: "Manage Guest Post",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.guestPostRequestDetails
          .noOfPending || 0,
    },
    {
      icon: <FileImageOutlined />,
      url: "/adminpath/manage-banner-request",
      title: "Manage Banner Request",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.bannerRequestDetails
          .noOfPending || 0,
    },
    { icon: <SettingOutlined />, url: "/adminpath/settings", title: "Setting" },
    { icon: <PhoneOutlined />, url: "/adminpath/support", title: "Support" },
    {
      icon: <CloseCircleOutlined />,
      url: "/adminpath/logout",
      title: "Logout",
    },
  ];

  const accountAdminMenu: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/adminpath",
      title: "Dashboard",
    },
    {
      icon: <UsergroupAddOutlined />,
      url: "/adminpath/manage-users",
      title: "Manage User",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.userDetails.total || 0,
    },
    {
      icon: <UserSwitchOutlined />,
      url: "/adminpath/manage-advertiser",
      title: "Manage Advertiser",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.userDetails
          .noOfAdvertisers || 0,
    },
    {
      icon: <UserSwitchOutlined />,
      url: "/adminpath/manage-publisher",
      title: "Manage Publisher",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.userDetails
          .noOfPublishers || 0,
    },
    {
      icon: <UnorderedListOutlined />,
      url: "/adminpath/manage-publisher-portal",
      title: "Manage Portals",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.portalDetails
          .noOfPending || 0,
    },
    {
      icon: <MoneyCollectOutlined />,
      url: "/adminpath/manage-withdrawal",
      title: "Manage Withdrawal",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.withdrwalDetails
          .noOfPending || 0,
    },
    { icon: <SettingOutlined />, url: "/adminpath/settings", title: "Setting" },
    { icon: <PhoneOutlined />, url: "/adminpath/support", title: "Support" },
    {
      icon: <CloseCircleOutlined />,
      url: "/adminpath/logout",
      title: "Logout",
    },
  ];

  const contentAdminMenu: IMenuType[] = [
    {
      icon: <DashboardOutlined rev={undefined} />,
      url: "/adminpath",
      title: "Dashboard",
    },
    {
      icon: <UngroupOutlined />,
      url: "/adminpath/manage-guestpost-request",
      title: "Manage Guest Post",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.guestPostRequestDetails
          .noOfPending || 0,
    },
    {
      icon: <FileImageOutlined />,
      url: "/adminpath/manage-banner-request",
      title: "Manage Banner Request",
      notificationCount:
        adminAuthData.data?.credentials.dashboardDetails.bannerRequestDetails
          .noOfPending || 0,
    },
    { icon: <SettingOutlined />, url: "/adminpath/settings", title: "Setting" },
    { icon: <PhoneOutlined />, url: "/adminpath/support", title: "Support" },
    {
      icon: <CloseCircleOutlined />,
      url: "/adminpath/logout",
      title: "Logout",
    },
  ];

  useEffect(() => {
    // execute on location change
    setCurrentUrlPath(location.pathname);
    if (onLinkChange) {
      onLinkChange();
    }
    updateMenuType();
  }, [location, adminAuthData]);

  const updateMenuType = () => {
    if (adminAuthData.data?.credentials.roleId === 1) {
      setMenu(superAdminMenu);
    } else if (adminAuthData.data?.credentials.roleId === 2) {
      setMenu(generalAdminMenu);
    } else if (adminAuthData.data?.credentials.roleId === 3) {
      setMenu(accountAdminMenu);
    } else if (adminAuthData.data?.credentials.roleId === 4) {
      setMenu(contentAdminMenu);
    } else {
      setMenu(superAdminMenu);
    }
  };

  return (
    <>
      <div className="w3-col sideBarAdminColor">
        <div className="w3-padding">
          <h3>
            Linkmie <br /> Admin Dashboard
          </h3>
        </div>
        <div className="w3-col w3-padding">
          {menu.map((item: IMenuType, index: number) => (
            <Link
              key={index}
              className={
                "w3-btn bottonAdminSideBar " +
                (currentUrlPath === item.url
                  ? "buttonAdminSideBarSelected"
                  : "")
              }
              to={item.url}
            >
              <span
                className={
                  "buttonTextAdminSideBar " +
                  (currentUrlPath === item.url
                    ? " buttonTextAdminSideBarSelected "
                    : "")
                }
              >
                <span style={{ float: "left" }}>
                  {item.icon}
                  &nbsp;{item.title}&nbsp;
                </span>
                <span style={{ float: "right" }}>
                  <Space size="middle">
                    <Badge
                      style={{ fontSize: "10px" }}
                      size="default"
                      count={item.notificationCount || 0}
                    ></Badge>
                  </Space>
                </span>
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default SideBarAdmin;
