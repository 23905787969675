import { Button, Result, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../apiservice/admin-AuthService";
import { IUserListData } from "../../../../apiservice/admin-AuthService.type";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import { ILoadState } from "../../../../utils/loading.utils.";
import AdminViewOfferList from "../../Admin-Manage-Offers/AdminViewOffer/AdminViewOffer-List/AdminViewOffer-List";
import AdminPortalList from "../../Admin-Manage-Portals-Comps/AdminPortalList/AdminPortalList";
import AdminTaskList from "../../Admin-Manage-Tasks/Admin-Task-List/Admin-Task-List";
import AdminWalletTransactions from "../../Admin-Manage-Wallets-Comp/AdminWalletTransactions/AdminWalletTransactions";
import { AdminSiteUserDetailsCardDetails } from "./User-Details-Card-Details";
import AdminSiteUserDetailsCardProfile from "./User-Details-Card-Profile";
import AdminSiteUserDetailsWalletBalance from "./User-Details-Card-Wallet-Balance";
import "./User-Details-Wrapper.css";
import AdminUserReferralList from "./User-Referral-List/Admin-User-Referral-list";

export const AdminSiteUserDetailsWrapper = () => {
  const [userLoadState, setUserLoadState] = useState<ILoadState>("loading");
  const [loadCurrentSiteUser, setLoadCurrentSiteUser] = useState(true);
  const [siteUserData, setSiteUserData] = useState<IUserListData | null>(null);
  const params = useParams();

  // A custom hook to Load Current/Active User based on the Url
  const resultCurrentUserData = useFormatApiRequest(
    () => getUser(Number(params?.id)),
    loadCurrentSiteUser,
    () => {
      setLoadCurrentSiteUser(false);
    },
    () => {
      processCurrentUserResult();
    }
  );

  // Process The Current User Data Result
  const processCurrentUserResult = async () => {
    if (resultCurrentUserData.httpState === "SUCCESS") {
      setSiteUserData(resultCurrentUserData?.data?.data);
      setUserLoadState("completed");
    } else if (resultCurrentUserData.httpState === "ERROR") {
      setUserLoadState("error");
    }
  };

  return (
    <>
      {/* " Show Loading Indicator" */}
      {userLoadState === "loading" && (
        <div
          className="w3-col w3-center w3-padding-bottom"
          style={{ paddingTop: "100px" }}
        >
          <Spin size="large" />
        </div>
      )}

      {/* " Show Loading Error" */}
      {userLoadState === "error" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong, it could be a network Related error"
            extra={
              <Button
                onClick={() => setLoadCurrentSiteUser(true)}
                type="primary"
              >
                Reload
              </Button>
            }
          />
        </div>
      )}

      {/* " Show No Data/Broken Link" */}
      {userLoadState === "noData" && (
        <div className="w3-col w3-padding-bottom">
          <Result
            status="warning"
            title="Seems this link has being deleted or it is broken."
            extra={<Button type="primary">Go Back</Button>}
          />
        </div>
      )}

      {/* " Display form when Portal Load State is Not loading for new form entry & completed for editing old entry" */}
      {(userLoadState === "notLoading" || userLoadState === "completed") && (
        <div className="w3-col w3-padding-bottom">
          <AdminSiteUserDetailsCardProfile
            siteUserData={siteUserData}
          ></AdminSiteUserDetailsCardProfile>
          <AdminSiteUserDetailsCardDetails
            refreshPage={() => {
              setLoadCurrentSiteUser(true);
            }}
            siteUserData={siteUserData}
          ></AdminSiteUserDetailsCardDetails>

          {(siteUserData?.userTypeId === 1 ||
            siteUserData?.userTypeId === 3) && (
            <>
              <div className="w3-col w3-container w3-margin-top">
                <h3 className="adminSiteUserDetailsHeaderText">
                  List of Portal
                </h3>
                <AdminPortalList
                  initialDefaultFilter={{ userId: params.id }}
                ></AdminPortalList>
              </div>
              <div className="w3-col w3-container w3-margin-top">
                <h3 className="adminSiteUserDetailsHeaderText">Task History</h3>
                <AdminTaskList
                  initialDefaultFilter={{ userId: params.id }}
                ></AdminTaskList>
              </div>
            </>
          )}

          {(siteUserData?.userTypeId === 2 ||
            siteUserData?.userTypeId === 3) && (
            <div className="w3-col w3-container w3-margin-top">
              <h3 className="adminSiteUserDetailsHeaderText">Offer History</h3>
              <AdminViewOfferList
                initialDefaultFilter={{ userId: params.id }}
              ></AdminViewOfferList>
            </div>
          )}

          <div className="w3-col w3-container w3-margin-top">
            <h3 className="adminSiteUserDetailsHeaderText">List of Referral</h3>
            <AdminUserReferralList></AdminUserReferralList>
          </div>

          <div className="w3-col w3-container w3-margin-top">
            <h3 className="adminSiteUserDetailsHeaderText">Wallet Summary</h3>
            <AdminSiteUserDetailsWalletBalance
              siteUserData={siteUserData}
            ></AdminSiteUserDetailsWalletBalance>
          </div>

          <AdminWalletTransactions
            initialDefaultFilter={{ userId: params.id }}
          ></AdminWalletTransactions>
        </div>
      )}
    </>
  );
};

export default AdminSiteUserDetailsWrapper;
