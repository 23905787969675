import { Tabs, TabsProps } from "antd";
import SharedUserProfileFormEditPassword from "../SharedUserProfile-Form/SharedUserProfile-Form-EditPassword";
import SharedProfileViewAboutCard from "../SharedUserProfile-View-Cards/SharedProfile-View-About-Card";
import SharedProfileViewActivityCard from "../SharedUserProfile-View-Cards/SharedProfile-View-Activity-Card";
import SharedProfileViewPictureCard from "../SharedUserProfile-View-Cards/SharedProfile-View-Picture-Card";
import "./SharedUserProfile-Wrapper.css";

export const SharedUserProfileWrapper = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "About",
      children: <SharedProfileViewAboutCard></SharedProfileViewAboutCard>,
    },
    {
      key: "2",
      label: "Recent Activites",
      children: <SharedProfileViewActivityCard></SharedProfileViewActivityCard>,
    },
    {
      key: "3",
      label: "Settings",
      children: (
        <SharedUserProfileFormEditPassword></SharedUserProfileFormEditPassword>
      ),
    },
  ];

  return (
    <>
      <div className="w3-col" style={{ minHeight: "400px" }}>
        <SharedProfileViewPictureCard></SharedProfileViewPictureCard>
        <div className="w3-col">
          <div className="w3-content">
            <div className="w3-container">
              <Tabs
                tabBarStyle={{
                  borderBottom: "2px solid rgba(81, 82, 81, 0.56)",
                  fontSize: "16px",
                }}
                style={{ color: "red" }}
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharedUserProfileWrapper;
