import AdvertiserContentRequestDetailsWrapperBanner from "../../../components/AdvertiserContentComps/AdvertiserViewContentDetails/AdvertiserViewContentDetails-Wrapper-Banner";
import "./AdvertiserManageContentsDetails-Banner.css";

export const AdvertiserManageContentsDetailsBanner = () => {
  return (
    <AdvertiserContentRequestDetailsWrapperBanner></AdvertiserContentRequestDetailsWrapperBanner>
  );
};

export default AdvertiserManageContentsDetailsBanner;
